export const MessageType = {
  EMPTY: "EMPTY",
  ARRAY_EMPTY: "ARRAY_EMPTY",
  NUMERIC_EMPTY: "NUMERIC_EMPTY",
  EQUAL_GREATER_ZERO: "EQUAL_GREATER_ZERO",
  GREATER_ZERO: "GREATER_ZERO",
  DUPLICATE: "DUPLICATE",
  NUMERIC_FORMAT: "NUMERIC_FORMAT",
  MONTH_INVALID_45: "MONTH_INVALID_45",
  MONTH_INVALID_50: "MONTH_INVALID_50",
  DATE_INVALID_50: "DATE_INVALID_50",
  DATE_INVALID_45: "DATE_INVALID_45",
  INPUT_INVALID_STRING: "INPUT_INVALID_STRING",
  DATE_FORMAT: "DATE_FORMAT",
  DATE_FORMAT_50: "DATE_FORMAT_50",
  DATE_FORMAT_45: "DATE_FORMAT_45",
  DATE_EQUAL_LATER_CURRENT: "DATE_EQUAL_LATER_CURRENT",
  DATE_EQUAL_LATER: "DATE_EQUAL_LATER",
  NOT_FOUND: "NOT_FOUND",
  EQUAL_BETWEEN: "EQUAL_BETWEEN",
  MORETHAN_ZERO: "MORETHAN_ZERO",
  UPDATED: "UPDATED",
  DELETED: "DELETED",
  CONFIRM_SAVE: "CONFIRM_SAVE",
  CONFIRM_DELETE: "CONFIRM_DELETE",
  CONFIRM_CANCEL: "CONFIRM_CANCEL",
  CLOSE_SCREEN: "CLOSE_SCREEN",
  DELETE_EFFECTIVE_DATE_TO_PASS: "DELETE_EFFECTIVE_DATE_TO_PASS",
  DELETE_EFFECTIVE_DATE_FROM_PASS: "DELETE_EFFECTIVE_DATE_FROM_PASS",
  ADDED: "ADDED",
  LENGTH: "LENGTH",
  TYPE_IMAGE: "TYPE_IMAGE",
  SIZE_IMAGE: "SIZE_IMAGE",
  TYPE_EXCEL: "TYPE_EXCEL",
  TYPE_PDF: " TYPE_PDF",
  FILE_NAME: " FILE_NAME",
  MUST_BE_SELECTED: "MUST_BE_SELECTED",
  INVALID_DATE: "INVALID_DATE",
  MORETHAN_TODAY: "MORETHAN_TODAY",
  INVALID: "INVALID",
  LESS_OR_EQUAL: "LESS_OR_EQUAL",
};

export const MSG_TYPE = { CONFIRM: "confirm", INFO: "info", ERROR: "error" };
