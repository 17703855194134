import { useMutateData, useCustomQuery, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;

const CompanyPlantApiService = () => ({
  useCompanyPlantGetCountryQuery: ({ dataOwner }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/path/country/${dataOwner}/FROM_COUNTRY_CD`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useCompanyPlantGetCompanyAbbreviation: ({ dataOwner }) => {
    const { data, refetch } = useCustomQuery({
      key: ["company-abbr", dataOwner],
      url: `${BaseServicePath}/category/company/${dataOwner}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return { data, refetch };
  },
  useCompanyPlantGetCompanyCategory: ({ dataOwner }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/${dataOwner}/COMPANY_CATEGORY/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useCompanyPlantGetPlantCategory: ({ dataOwner }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/${dataOwner}/PLANT_CATEGORY/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useCompanyPlantSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/company-plant/search`,
        method: "POST",
        data: body,
      });
    };
  },
  useCompanyPlantCreateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [["company-abbr"]],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/company-plant/create`,
        method: "POST",
        data: body,
      });
    };
  },
  useCompanyPlantEditMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (body, companyId) => {
      return await mutate({
        url: `${BaseServicePath}/company-plant/edit/${companyId}`,
        method: "PATCH",
        data: body,
      });
    };
  },
  useCompanyPlantDeleteMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: ["company-abbr"],
    });
    return async ({ companyId, body }) => {
      return await mutate({
        url: `${BaseServicePath}/company-plant/delete/${companyId}`,
        method: "DELETE",
        data: body,
      });
    };
  },
  useCompanyPlantDownloadTemplateMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body) => {
      return await mutate(
        {
          url: `${BaseServicePath}/company-plant/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, `${fileName}`);
          },
        }
      );
    };
  },
});
export const {
  useCompanyPlantGetCountryQuery,
  useCompanyPlantGetCompanyAbbreviation,
  useCompanyPlantGetCompanyCategory,
  useCompanyPlantGetPlantCategory,
  useCompanyPlantSearchMutation,
  useCompanyPlantCreateMutation,
  useCompanyPlantEditMutation,
  useCompanyPlantDeleteMutation,
  useCompanyPlantDownloadTemplateMutation,
} = CompanyPlantApiService();
