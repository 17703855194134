import { API_INVOICE_SHIPPING_INFO } from "shared/constants/api-name/invoice/shipmentInfoMain";

const DEFAULT_SHIPPING_INFO = {
  exporterId: "",
  exporterCd: "",
  importerId: "",
  loadingPort: "",
  dischargingPort: "",
  etd: "",
  vesselName: "",
  orderTypeId: "",
  carFamilyCode: "",
  rows: [],
};
const DEFAULT_STATE_WDN95031 = {
  exporterComp: "",
  importerComp: "",
  etdDt: "",
  consignee: "",
  loadingPort: "",
  dischargingPort: "",
  broker: "",
  vesselName: "",
  exporterCd: "",
  importerCd: "",
  importerId: "",
  shippingHdrID: "",
  contSessionId: "",
  //r-package
  containerNo: "",
  containerType: "",
  dataOwner: "",
  vanInfoHdrID: "",
  shippingInfoDContId: "",
  subScreenViewed: "N",
  [API_INVOICE_SHIPPING_INFO.SHIPPING_SESSION_ID]: "",
  [API_INVOICE_SHIPPING_INFO.CONT_SESSION_ID]: "",
  rows: [],
  rPackage: [],
};

const GROUP_CODE_TYPE = {
  R_MODULE: "RM",
  OTHER_MAT: "OT",
};
export { DEFAULT_SHIPPING_INFO, DEFAULT_STATE_WDN95031, GROUP_CODE_TYPE };
