import { useQuery, useMutation } from "@tanstack/react-query";
import queryClient from "shared/libs/reactQuery/queryClient";
import axios from "configs/axiosConfig";
export const HttpMethodKeys = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

// Export a function useRequestProcessor, which returns useQuery and useMutate
export const useCustomQuery = ({
  key = "",
  url,
  method = "GET",
  body = {},
  params = {},
  enabled = false,
  select = undefined,
  keepPreviousData = false,
  invalidateKeys,
  ...options
}) => {
  if (!HttpMethodKeys[method]) {
    throw new Error(`Unsupported method: ${method}`);
  }
  const queryKey = [key, url];
  const query = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const { data } = await axios({ url, method, data: body, params });
      return data?.result;
    },
    enabled: enabled,
    options: {
      select: undefined,
      keepPreviousData: false,
    },
    onSettled: () => {
      invalidateKeys.forEach(keys => {
        queryClient.invalidateQueries({ queryKey: keys });
      });
    },
  });
  const response = query;
  return response;
};

export const useMutateData = ({ invalidateKeys, ...options }) => {
  const mutate = useMutation({
    mutationFn: async mutateOptions => {
      const { data } = await axios(mutateOptions);
      return data;
    },
    onSettled: () => {
      invalidateKeys.forEach(keys => {
        queryClient.invalidateQueries({ queryKey: keys });
      });
    },
    ...options,
  });

  return { mutate: mutate.mutateAsync };
};

export const useMutateDownload = ({ invalidateKeys, ...options }) => {
  const mutate = useMutation({
    mutationFn: async mutateOptions => {
      const response = await axios(mutateOptions);
      return response;
    },
    onSettled: () => {
      invalidateKeys.forEach(keys => {
        queryClient.invalidateQueries({ queryKey: keys });
      });
    },
    ...options,
  });

  return { mutate: mutate.mutateAsync };
};
