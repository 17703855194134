import axios from "axios";

const apiClient = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX,
  headers: {
    // Accept: "application/json",
    // "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    // "ngrok-skip-browser-warning": "6024",
    // "x-key": "123123123", // add apiKey
  },
  withCredentials: false,
  // TODO: timeout
  // timeout: 60000 * 0.5, // 60000 = 1นาที
  timeoutErrorMessage: "Axios TIMEOUT",
});

// Request interceptor
apiClient.interceptors.request.use(
  (config) => {
    // if (localStorage.getItem("token")) {
    //   config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    // }

    return config;
  },
  (error) => {
    // Handle request errors here

    return Promise.reject(error);
  }
);

// Response interceptor
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if (
    //   error.response?.status === 401 &&
    //   !error.response?.config?.url?.includes("auth")
    // ) {
    //   if (!isOnServer() && !Router.asPath.includes("/login")) {
    //     removeAccessToken();
    //     Router.push("/auth/login");
    //   }
    // }
    throw error;
    if (error.response) {
      if (error.response.status === 400) {
        console.log("---errr", error.response);
        return Promise.reject(error);
      } else if (error.response.status === 401) {
        alert(
          `Error: ${error.response.status} | Unauthorized access. Please log in.`
        );
      } else if (error.response.status === 500) {
        alert(
          `Error: ${error.response.status} | Internal server error. Please try again later.`
        );
      } else {
        alert(`Error: ${error.response.status}`);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
