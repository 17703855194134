import { HttpStatusCode } from "axios";
import axios from "configs/axiosConfig";
import { responseErrors, saveAs } from "utils";
import { ResponseText } from "utils/5xxResponseText";
const BaseServicePath = process.env.REACT_APP_API_REPORT;

export const waitAndDownload = (limit, response) => {
  console.log("START");
  const timer = 2;
  let count = 0;
  const { docId } = response?.data;
  const state = setInterval(async () => {
    count += timer;
    try {
      if (count >= limit) {
        clearInterval(state);
        console.error("TimeOut");
        throw new Error("TimeOut");
      }
    } catch (error) {
      clearInterval(state);
      console.error("An error occurred while checking the download status:", error);
      return error;
    }

    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}${BaseServicePath}/download/status?docId=${docId}`,
        method: "GET",
      });
      const { headers, data } = response;

      if (data?.status === "3") {
        console.log("STATUS", response?.status);
        //Download file
        clearInterval(state);
        const responseDownload = await axios({
          url: `${process.env.REACT_APP_API_URL}${BaseServicePath}/download?docId=${docId}`,
          method: "GET",
          responseType: "arraybuffer",
        });
        console.log("onSuccess", responseDownload);
        saveAs(responseDownload);
        return;
      } else if (headers?.status === HttpStatusCode.InternalServerError) {
        clearInterval(state);
        console.log("ERROR");
      }
    } catch (error) {
      clearInterval(state);
      console.error("An error occurred while checking the download status:", error);
      const { response } = error;
      console.error(`${response?.data?.error?.code}: ${response?.data?.error?.message}`);
      return response;
    }
  }, timer * 1000);
};

export const waitAndDownloadExcel = (limit, response, setMsg, stopLoading) => {
  const timer = 2;
  let count = 0;
  console.log("response", response);

  const { docId } = response?.result;
  const state = setInterval(async () => {
    count += timer;
    try {
      if (count >= limit) {
        clearInterval(state);
        console.error("TimeOut");
        setMsg([`${ResponseText[504]}`]);
        stopLoading();
        throw new Error("TimeOut");
      }
    } catch (error) {
      clearInterval(state);
      console.error("An error occurred while checking the download status:", error);
      setMsg([`An error occurred while checking the download status:, ${error}`]);
      stopLoading();
      return error;
    }

    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}${BaseServicePath}/download/status?docId=${docId}`,
        method: "GET",
      });
      const { headers, data } = response;

      if (data?.status === "3") {
        console.log("STATUS", response?.status);
        //Download file
        clearInterval(state);
        const responseDownload = await axios({
          url: `${process.env.REACT_APP_API_URL}${BaseServicePath}/download?docId=${docId}`,
          method: "GET",
          responseType: "arraybuffer",
        });
        console.log("onSuccess", responseDownload);
        saveAs(responseDownload);
        stopLoading();
        return;
      } else if (data?.status === "6") {
        clearInterval(state);
        setMsg([`${ResponseText[500]}`]);
        stopLoading();
      }
    } catch (error) {
      stopLoading();
      clearInterval(state);
      console.error("An error occurred while checking the download status:", error);
      const msg = responseErrors(error);
      setMsg(msg);
      return response;
    }
  }, timer * 1000);
};

export const waitAndPrintBarcode = (limit, response, setMsg, stopLoading) => {
  console.log("START");
  const timer = 2;
  let count = 0;
  const { docId } = response?.data?.result;
  const state = setInterval(async () => {
    // console.log(response);
    // console.log(count);
    count += timer;
    try {
      if (count >= limit) {
        clearInterval(state);
        console.error("TimeOut");
        setMsg([`${ResponseText[504]}`]);
        stopLoading();
        throw new Error("TimeOut");
      }
    } catch (error) {
      clearInterval(state);
      console.error("An error occurred while checking the download status:", error);
      setMsg([`An error occurred while checking the download status:, ${error}`]);
      stopLoading();
      return error;
    }

    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}${BaseServicePath}/download/status?docId=${docId}`,
        method: "GET",
      });
      const { headers, data } = response;
      // console.log("data", data);

      if (data?.status === "3") {
        // if (response?.status === HttpStatusCode.Accepted) {
        console.log("STATUS", response?.status);
        //Download file
        clearInterval(state);
        const responseDownload = await axios({
          url: `${process.env.REACT_APP_API_URL}${BaseServicePath}/download?docId=${docId}`,
          method: "GET",
          responseType: "arraybuffer",
        });
        console.log("onSuccess", responseDownload);
        saveAs(responseDownload);
        stopLoading();
        return;
      } else if (data?.status === "6") {
        clearInterval(state);
        setMsg([`${ResponseText[500]}`]);
        stopLoading();
      }
    } catch (error) {
      stopLoading();
      clearInterval(state);
      console.error("An error occurred while checking the download status:", error);
      const { error: err } = error;
      const { response } = error;
      // console.error(`${response?.data?.error?.code}: ${response?.data?.error?.message}`);
      setMsg([`${err?.code}: ${err?.message}`]);
      return response;
    }
  }, timer * 1000);
};
