import React from "react";
import { useGridApiContext } from "@mui/x-data-grid";
import { InputDatePicker } from ".";
import { getLocalDate } from "utils/init-config-date";

const InputEditTableDate = ({
  value,
  field,
  id,
  disabled = false,
  setValue = undefined,
  className,
  required,
  params,
}) => {
  const apiRef = useGridApiContext();

  const handleValueChange = e => {
    console.log("e ->", e);
    const newValue = e;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    if (setValue) {
      if (!getLocalDate(newValue, "DD/MM/YYYY").isValid()) {
        setValue(null);
      } else {
        setValue(newValue);
      }
    }
  };

  return (
    <div style={{ margin: "0 5px", display: "grid", width: "100%" }} id={field}>
      <InputDatePicker onChange={handleValueChange} value={value} disabled={disabled} required={required} />

      {/* <InputText
        type="date"
        value={formatDate(value, "YYYY-MM-DD")}
        onChange={handleValueChange}
        disabled={disabled}
        className={className}
        required={required}
        style={{ height: "30px" }}
        {...params}
      /> */}
    </div>
  );
};

export default InputEditTableDate;
