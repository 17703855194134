import React, { Fragment } from "react";
import { Typography } from "components/UI";

import { Checkbox, FormControlLabel } from "@mui/material";
export default function TanStackCheckBox({
  renderValue,
  getValue,
  row,
  column,
  table,
  placement,
  label = "",
  onChange,
  checked = false,
  ...props
}) {
  const [item, setItem] = React.useState(checked);
  const tableMeta = table.options.meta;
  const rowData = row.original;
  const handleValueChange = event => {
    const newValue = event.target.checked; // The new value entered by the user
    setItem(newValue);
    tableMeta?.updateData({ rowIndex: rowData?.rowNumber, columnId: column.id, value: newValue });
  };
  React.useEffect(() => {
    setItem(checked);
  }, [checked]);
  return (
    <Fragment>
      <FormControlLabel
        {...props}
        value={placement}
        labelPlacement={placement}
        label={
          <Typography
            content={label}
            sx={{
              color: "#000",
              fontFamily: "Kanit",
              fontWeight: "bold",
            }}
            noWrap={false}
          />
        }
        onChange={handleValueChange}
        control={<Checkbox size="medium" checked={item} {...props} />}
      />
    </Fragment>
  );
}
