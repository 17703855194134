import React from "react";
import { Divider, Grid } from "@mui/material";
import { InputButton, LoadingButton } from "components/UI";
//UTIL
import { findObject, messageDisplay, responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";

//Type
import { BUTTON_VALUE } from "shared/constants";
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import { formatRowWareHouseSummary, transformObjectGraph } from "../hooks/format";
import { DEFAULT_WAREHOUSE_SUMMARY } from "../constants/constant";
//Service
import {
  useWareHouseSummarySearchTableMutation,
  useWareHouseSummarySearchGraphMutation,
  useWareHouseSummaryGenerateGraphMutation,
  useWareHouseSummaryStatusGenerateGraphMutation,
} from "shared/services/inventory";
import { ResponseText } from "utils/5xxResponseText";
import { deepClone } from "@mui/x-data-grid/utils/utils";

export default function ButtonCriterial(props) {
  const {
    form,
    setForm,
    setRows,
    setRPkg,
    setGraph,
    defaultGraphData,
    setDefaultGraph,
    defaultRows,
    setDefaultRows,
    setMode,
    onSearch,
    setOnSearch,
    getWarehouse,
    setOpenGraph,
    setViewBtn,
    loadingView: { isLoadingView, setIsLoadingView },
    stateBtn: { searchBtn, clearBtn, editBtn, viewBtn, setGeneratedGraph, generatedGraph },
    setMsg: { setMsgAlert, setMsgError },
    loading: { startLoading, stopLoading },
    dataList: { rPackageOwnerList, rPackageTypeList },
    rowSelection: { setRowModesModel, setRowSelectionModel },
    functionId,
    setAppId,
    appId,
  } = props;
  let state;
  const searchTable = useWareHouseSummarySearchTableMutation();
  const searchGraph = useWareHouseSummarySearchGraphMutation();
  const generateGraph = useWareHouseSummaryGenerateGraphMutation();
  const statusGraph = useWareHouseSummaryStatusGenerateGraphMutation();
  const checkStatusBatch = (limit, response) => {
    // 60 sec * n min
    const timer = 2;
    let count = 0;

    const { appId } = response;
    state = setInterval(async () => {
      count += timer;
      try {
        if (count >= limit) {
          clearInterval(state);
          setIsLoadingView(false);
          console.error("TimeOut");
          setMsgError([ResponseText[504]]);
          throw new Error("TimeOut");
        }
      } catch (error) {
        clearInterval(state);
        setIsLoadingView(false);
        console.error("An error occurred while checking the generate graph:", error);
        setMsgError(prev => [
          ...prev,
          [
            messageDisplay({
              type: MSG_TYPE.ERROR,
              code: "MSTD0067AERR",
              msg: ["Daily Inventory Simulation Batch process is error"],
            }),
          ],
        ]);
      }
      try {
        const response = await statusGraph({ appId: appId });
        const { status } = response;
        if (status === "success") {
          clearInterval(state);
          setIsLoadingView(false);
          return true;
        } else if (status === "error") {
          clearInterval(state);
          setIsLoadingView(false);
          console.error("ERROR: Please contract administrator Daily Inventory Simulation Batch process is error");
          // setMsgError(["ERROR: Please contract administrator Daily Inventory Simulation Batch process is error"]);
          setMsgError(prev => [
            ...prev,
            [
              messageDisplay({
                type: MSG_TYPE.ERROR,
                code: "MSTD0067AERR",
                msg: ["Daily Inventory Simulation Batch process is error"],
              }),
            ],
          ]);
          return false;
        }
      } catch (error) {
        clearInterval(state);
        console.error("An error occurred while checking the generate graph:", error);
        setMsgError([error]);
        const { response } = error;
        return response;
      }
    }, timer * 1000);
  };
  const checkGraph = async bodySearch => {
    try {
      const response = await generateGraph({
        dataOwner: bodySearch?.dataOwner,
        rPkgOwner: bodySearch?.rPkgOwner,
        location: bodySearch?.warehouse,
        rPkgType: bodySearch?.rPkgType,
      });
      if (!response) {
        setIsLoadingView(false);
        setViewBtn(true);
        setGeneratedGraph(true);
        setMsgError(prev => [
          ...prev,
          [
            messageDisplay({
              type: MSG_TYPE.ERROR,
              code: "MSTD0067AERR",
              msg: ["Daily Inventory Simulation Batch process is error"],
            }),
          ],
        ]);
        return;
      }
      setIsLoadingView(true);
      // 60 sec * 10 min
      checkStatusBatch(600, response);
      setAppId(response.appId);
    } catch (error) {
      console.error(error);
    }
  };
  const handleView = async e => {
    try {
      e.preventDefault();

      setMode(ModeAction.VIEW);
      setMsgError([]);
      setMsgAlert([]);
      if (defaultGraphData.length > 0) {
        setOpenGraph(true);
        return;
      }
      setGraph([]);
      setDefaultGraph([]);
      const bodySearch = {
        dataOwner: form?.dataOwner,
        rPkgOwner: !form?.rPackageOwnerId
          ? ""
          : findObject({
              data: rPackageOwnerList,
              value: form?.rPackageOwnerId,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            }),
        rPkgType: !form?.rPackageTypeId
          ? ""
          : findObject({
              data: rPackageTypeList,
              value: form?.rPackageTypeId,
              property: "rPkgTypeId",
              field: "rPkgType",
            }),
        location: getWarehouse(form?.wareHouseId),
      };
      const { isSuccess, errors } = validationSearchForm({
        data: bodySearch,
        rule: [
          {
            field: "rPkgOwner",
            type: MessageType.EMPTY,
            key: ["R-Package Owner"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      const data = await searchGraph(bodySearch, { appId: appId });
      stopLoading();

      if (data?.result?.data?.length <= 0) {
        setGraph([]);
        setDefaultGraph([]);
        setOpenGraph(false);
        setOnSearch(true);
      }
      const transform = transformObjectGraph(data?.result);

      setGraph(transform);
      setDefaultGraph(transform);
      setOpenGraph(true);
      setOnSearch(true);
      return;
    } catch (error) {
      if (error?.statusCode === 500) {
        return setMsgError([error?.message]);
      }
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      stopLoading();
      return;
    }
  };
  const handleSearch = async e => {
    try {
      e.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      setGraph([]);
      setDefaultGraph([]);
      // Reset Data of Table
      setOnSearch(false);
      setMode("Initial");
      setRowSelectionModel([]);
      setRowModesModel({});
      setRows([]);
      setDefaultRows([]);
      clearInterval(state);
      state = 0;
      const bodySearch = {
        dataOwner: form?.dataOwner,
        rPkgOwner: !form?.rPackageOwnerId
          ? ""
          : findObject({
              data: rPackageOwnerList,
              value: form?.rPackageOwnerId,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            }),
        rPkgType: !form?.rPackageTypeId
          ? ""
          : findObject({
              data: rPackageTypeList,
              value: form?.rPackageTypeId,
              property: "rPkgTypeId",
              field: "rPkgType",
            }),
        warehouse: getWarehouse(form.wareHouseId),
      };
      const { isSuccess, errors } = validationSearchForm({
        data: bodySearch,
        rule: [
          {
            field: "rPkgOwner",
            type: MessageType.EMPTY,
            key: ["R-Package Owner"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      const searchData = await searchTable(bodySearch);
      stopLoading();

      // check status
      checkGraph(bodySearch);
      if (!searchData?.result?.length) {
        setMsgError([
          messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0059AERR",
            msg: "",
          }),
        ]);
        return;
      }
      const { data, rPkg } = formatRowWareHouseSummary(searchData?.result);
      setRows(deepClone(data));
      setDefaultRows(deepClone(data));
      setRPkg(rPkg);
      // setGraph(searchGraph.result);
      // setDefaultGraph(searchGraph?.result);
      setMode(ModeAction.VIEW);
      setOnSearch(true);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      stopLoading();
      return;
    }
  };
  const handleClear = async () => {
    setMsgError([]);
    setMsgAlert([]);
    // reset form
    setForm(DEFAULT_WAREHOUSE_SUMMARY);
    setMode("Initial");
    setOnSearch(false);
    setRowModesModel({});
    setRowSelectionModel([]);
    setRows([]);
    setDefaultRows([]);
    setRPkg([]);
    setGeneratedGraph(false);
    setIsLoadingView(false);
    // setPagination({});
    // setPageNumber(1);
  };
  const handleEdit = async () => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      //tiger table mode edit
      setMode(ModeAction.EDIT);

      return;
    } catch (error) {
      console.error(error);
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  return (
    <Grid item sx={{ m: "2px 0" }}>
      <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", mt: 2, mb: 2 }}>
        <LoadingButton
          id="button-view"
          value={BUTTON_VALUE.VIEW}
          onClick={handleView}
          disabled={generatedGraph || viewBtn}
          loading={isLoadingView}
          name={`${functionId}Search`}
        />
        {/* <InputButton id="button-view" value={BUTTON_VALUE.VIEW} onClick={handleView} disabled={viewBtn} /> */}
        <LoadingButton
          id="button-search"
          value={BUTTON_VALUE.SEARCH}
          onClick={handleSearch}
          disabled={searchBtn}
          loading={isLoadingView}
          name={`${functionId}Search`}
        />
        {/* <InputButton id="button-search" value={BUTTON_VALUE.SEARCH} onClick={handleSearch} disabled={searchBtn} /> */}
        <InputButton
          id="button-clear"
          value={BUTTON_VALUE.CLEAR}
          onClick={handleClear}
          disabled={clearBtn}
          onKeyDown={e => {
            if (!onSearch && e.key === "Tab") {
              e.preventDefault();
              document.getElementById("select-rPkg-owner").focus();
            }
          }}
          name={`${functionId}Clear`}
        />
        <InputButton
          id="button-edit"
          value={BUTTON_VALUE.EDIT}
          onClick={handleEdit}
          disabled={editBtn}
          onKeyDown={e => {
            if (onSearch && e.key === "Tab") {
              e.preventDefault();
              document.getElementById("select-rPkg-owner").focus();
            }
          }}
          name={`${functionId}Edit`}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}
