import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  TableSortLabel,
  Typography,
} from "@mui/material";

const StyledTable = () => {
  const rows = [
    {
      name: "Item 1",
      description: "Description 1",
      owner: "Owner 1",
      action: "Action 1",
    },
    {
      name: "Item 2",
      description: "Description 2",
      owner: "Owner 2",
      action: "Action 2",
    },
    {
      name: "Item 3",
      description: "Description 3",
      owner: "Owner 3",
      action: "Action 3",
    },
    // Add more rows as needed
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("asc");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = property => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedRows = rows.sort((a, b) => {
    const isAsc = order === "asc";
    return isAsc ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]);
  });

  return (
    <TableContainer component={Paper} elevation={1}>
      <Typography variant="h6" component="div" sx={{ padding: "16px" }}>
        Table
      </Typography>
      <Table sx={{ borderCollapse: "collapse" }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === "name"}
                direction={orderBy === "name" ? order : "asc"}
                onClick={() => handleSort("name")}
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}>
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", textTransform: "uppercase" }}>Description</TableCell>
            <TableCell sx={{ fontWeight: "bold", textTransform: "uppercase" }}>Owner</TableCell>
            <TableCell sx={{ fontWeight: "bold", textTransform: "uppercase" }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            <TableRow key={index} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f5f5f5" } }}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.owner}</TableCell>
              <TableCell>{row.action}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page"
      />
    </TableContainer>
  );
};

export default StyledTable;
