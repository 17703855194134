import { useMutateData, useCustomQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_INVOICE;

const ShippingInfoApiService = () => ({
  useShippingInfoExporterQuery: loginUserCompanyAbbr => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/shipping-information-maintenance/get-exporter`,
      method: "POST",
      body: { loginUserCompanyAbbr: loginUserCompanyAbbr },
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
      invalidateKeys: ["loading-port"],
    });
    return data;
  },
  useShippingInfoImporterQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/shipping-information-maintenance/get-importer`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
      invalidateKeys: ["discharging-port"],
    });
    return data;
  },
  useShippingInfoOrderTypeQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/shipping-information-maintenance/get-order-type`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useShippingInfoCarFamilyCodeQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/shipping-information-maintenance/get-car-family-code`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useShippingInfoLoadingPortQuery: expCd => {
    const data = useCustomQuery({
      key: ["loading-port", expCd],
      url: `${BaseServicePath}/shipping-information-maintenance/get-loading-port`,
      method: "POST",
      body: { expCd: expCd },
      enabled: !!expCd,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useShippingInfoDischargingPortQuery: impCompanyId => {
    const data = useCustomQuery({
      key: ["discharging-port", impCompanyId],
      url: `${BaseServicePath}/shipping-information-maintenance/get-discharging-port`,
      method: "POST",
      body: { impCompanyId: impCompanyId },
      enabled: !!impCompanyId,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useShippingInfoConsigneeQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/shipping-information-maintenance/get-consignee`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useShippingInfoBrokerQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/shipping-information-maintenance/get-broker`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useShippingInfoSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/shipping-information-maintenance/search`,
        data: searchData,
        method: "POST",
      });
    };
  },
  useShippingInfoCreateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async insertData => {
      return await mutate({
        url: `${BaseServicePath}/shipping-information-maintenance/insert-header`,
        data: insertData,
        method: "POST",
      });
    };
  },
  useShippingInfoUpdateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async updateData => {
      return await mutate({
        url: `${BaseServicePath}/shipping-information-maintenance/update`,
        data: updateData,
        method: "POST",
      });
    };
  },
  useShippingInfoDeleteMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async deleteData => {
      return await mutate({
        url: `${BaseServicePath}/shipping-information-maintenance/delete`,
        data: deleteData,
        method: "POST",
      });
    };
  },
  // Update Table temp
  useShippingUpdateTempContainerMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async updateData => {
      return await mutate({
        url: `${BaseServicePath}/shipping-information-maintenance/update-temp-container`,
        data: updateData,
        method: "POST",
      });
    };
  },

  useShippingUpdateTempRPkgMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async updateData => {
      return await mutate({
        url: `${BaseServicePath}/shipping-information-maintenance/update-temp-r-package`,
        data: updateData,
        method: "POST",
      });
    };
  },
});

export const {
  //get
  useShippingInfoExporterQuery,
  useShippingInfoImporterQuery,
  useShippingInfoOrderTypeQuery,
  useShippingInfoCarFamilyCodeQuery,
  useShippingInfoLoadingPortQuery,
  useShippingInfoDischargingPortQuery,
  useShippingInfoConsigneeQuery,
  useShippingInfoBrokerQuery,
  // post
  useShippingInfoSearchMutation,
  useShippingInfoCreateMutation,
  useShippingInfoUpdateMutation,
  useShippingInfoDeleteMutation,

  //Update Temp Table
  useShippingUpdateTempContainerMutation,
  useShippingUpdateTempRPkgMutation,
} = ShippingInfoApiService();
