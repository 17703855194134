import React, { Fragment, useEffect, useState } from "react";
//Components
import { Box, Stack, Link, Divider } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, InputButton } from "components/UI";
import ButtonRPkg from "./components/ButtonRPkg";
import DetailSection from "../ContainerSubScreen/components/DetailSection";
import TableRPkg from "./components/TableRPkg";
//Util
import { responseErrors } from "utils";
import SumQtyRPackage from "../../utils/sum-qty-r-package";
// Hook
import { useConfirmDialogContext } from "context/confirmDialogContext";

import useColRPackage from "pages/Invoice/ShippingInformationMaintenanceScreen/hooks/useColRPackage";
import {
  transformDataRPkg,
  transformRPKgUpdateTemp,
} from "pages/Invoice/ShippingInformationMaintenanceScreen/hooks/transformData";
//Service
import {
  useRPackageGetRPkgOwnerQuery,
  useRPackageGetRPkgTypeQuery,
  useShippingUpdateTempRPkgMutation,
} from "shared/services/invoice";
// Type
import { ModeAction } from "state/enum";
import { FIRST_PAGE } from "shared/constants";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { GROUP_CODE_TYPE } from "../../constants/constant";
export default function RPackageSubScreen({
  setOnOpen,
  form,
  setForm,
  modeOfWDN95031,
  setDataRPkg,
  dataRPkg,
  setSearchCont,
  rowContainer,
  setRowContainer,
  updateTempContainer,
}) {
  const { startLoading, stopLoading } = useLoadingContext();
  const confirmDialogCtx = useConfirmDialogContext();
  const sumQtyRPkg = new SumQtyRPackage();
  //Form
  const [fieldTable, setFieldTable] = useState({ rPkgOwnerId: "" });
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(ModeAction.VIEW);
  // Table
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(FIRST_PAGE);

  //Api
  const { data: rPackageOwnerList } = useRPackageGetRPkgOwnerQuery();
  const { data: rPackageTypeList } = useRPackageGetRPkgTypeQuery(fieldTable?.rPkgOwnerId);
  const updateTempTable = useShippingUpdateTempRPkgMutation();
  const handleClose = async () => {
    const confirm = await confirmDialogCtx.success({ type: "closeScreen" });
    if (!confirm) {
      return;
    }
    setOnOpen(false);
  };
  const updateTempRPkg = async (rPkgList, sessionId) => {
    try {
      const contdUpdate = transformRPKgUpdateTemp(rPkgList, sessionId);
      const result = await updateTempTable(contdUpdate);
      // console.log("R-Package sessionId: ", result?.sesionnId);
      return result?.sesionnId;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      // Reset Data of Table
      setPageNumber(FIRST_PAGE);
      setPagination({});

      setMode(ModeAction.VIEW);
      setOnSearch(false);

      setRows([]);
      setRowSelectionModel([]);
      setRowModesModel({});
      const searchData = dataRPkg;

      // update r-package to container state
      if (!searchData?.result?.data?.length) {
        setRows([]);
        setOnSearch(false);
        return;
      }

      const data = transformDataRPkg(searchData?.result?.data, rPackageOwnerList);

      setForm(prev => ({ ...prev, rPackage: data }));
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  const calQty = async () => {
    try {
      let rmQty = 0;
      let otQty = 0;

      let containerNo;
      const tempContainer = [...rowContainer];
      for (const index in tempContainer) {
        rmQty = 0;
        otQty = 0;
        const container = tempContainer[index];
        containerNo = form?.containerType === "P" ? container?.planContainerNo : container?.actualContainerNo;
        if (containerNo === form?.containerNo) {
          for (const indx in rows) {
            const rPackage = rows[indx];

            let groupCd = rPackage?.groupCd;
            if (!rPackage?.groupCd) {
              groupCd = await sumQtyRPkg.checkGroup(rPackage?.rPkgType);
            }

            if (groupCd === GROUP_CODE_TYPE.R_MODULE) {
              rmQty += Number(rPackage?.planQty);
            } else if (groupCd === GROUP_CODE_TYPE.OTHER_MAT) {
              otQty += Number(rPackage?.planQty);
            }
          }
          container.planRModuleQty = rmQty;
          container.planRBoxDunQty = otQty;
          container.contSessionId = form?.contSessionId;
          break;
        }
        continue;
      }

      setRowContainer(tempContainer);
      await updateTempContainer(rowContainer, form?.shippingSessionId);
      return;
    } catch (error) {
      stopLoading();
      const err = responseErrors(error);
      setMsgError(err);
      return;
    }
  };
  const handleConfirmPlan = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const confirm = await confirmDialogCtx.success({ type: "WDN95031_CONFIRM_MATCHING" });
      if (!confirm) {
        return;
      }

      await calQty();
      setOnOpen(false);
    } catch (error) {
      stopLoading();
      const err = responseErrors(error);
      setMsgError(err);
      return;
    }
  };
  const columns = React.useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColRPackage({
      rows,
      mode,
      setFieldTable,
      rPackageOwnerList,
      rPackageTypeList,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, mode, rPackageTypeList]);
  useEffect(() => {
    try {
      handleSearch();
    } catch (error) {
      const err = responseErrors(error);
      setMsgError(err);
      return;
    }
    return;
  }, [1]);
  return (
    <Fragment>
      <Stack>
        <Link href="#" color="#000" onClick={handleClose} size="small" 
          // sx={{ fontSize: "12px" }}
        >
          Close
        </Link>
      </Stack>
      <Stack sx={{ mb: 2 }}>
        <HeaderContentBar title="WDN95032 : Shipping Information Maintenance - R-Package Sub Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
      </Stack>
      <Stack sx={{ pl: "1rem", pr: "1rem" }}>
        <Box>
          <DetailSection onRPkgScreen={true} form={form} />
        </Box>
        <Box width="100%" sx={{ mb: 2 }}>
          <Divider />
        </Box>
        <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <ButtonRPkg
            mode={mode}
            setMode={setMode}
            rows={rows}
            setRows={setRows}
            columns={columns}
            form={form}
            setForm={setForm}
            modeOfWDN95031={modeOfWDN95031}
            setOnSearch={setOnSearch}
            updateTempRPkg={updateTempRPkg}
            loading={{ startLoading, stopLoading }}
            setMsg={{ setMsgAlert, setMsgError }}
            pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
            rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
            functionId={"WDN95032"}
          />
        </Box>
        <Box sx={{ height: `${onSearch ? 0 : "300px"}` }}>
          {onSearch && (
            <>
              <Box>
                <TableRPkg
                  mode={mode}
                  setMode={setMode}
                  rows={rows}
                  setRows={setRows}
                  columns={columns}
                  form={form}
                  setForm={setForm}
                  sumQtyRPkg={sumQtyRPkg}
                  setOnSearch={setOnSearch}
                  updateTempRPkg={updateTempRPkg}
                  loading={{ startLoading, stopLoading }}
                  setMsg={{ setMsgAlert, setMsgError }}
                  rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
                  pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
                  functionId={"WDN95032"}
                />
              </Box>
              {[ModeAction.VIEW, ModeAction.SELECTED].includes(modeOfWDN95031) ? (
                <div></div>
              ) : (
                ![ModeAction.ADD, ModeAction.EDIT].includes(mode) && (
                  <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <InputButton value="Confirm Plan" onClick={handleConfirmPlan} name={"WDN95032Confirm"} />
                  </Box>
                )
              )}
            </>
          )}
        </Box>
      </Stack>
    </Fragment>
  );
}
