export const responseErrors = (err = null) => {
  console.log("err ", err);
  if (!err) return;
  const errors = err?.response?.data?.errors;

  if (!errors && err?.response?.data?.message) {
    return [`${err?.response?.data?.message}\n`];
  } else if (!errors && err?.message) {
    return [`${err?.message}\n`];
  } else if (!errors) {
    return [`MSTD0059AERR: No data found\n`];
  }
  const res = errors?.map(val => {
    const code = val?.code;
    const message = val?.message;

    if (code) {
      return `${code}: ${message}\n`;
    }
    return null;
  });
  return res;
};

export const responseDownloadFileErrors = buf => {
  if (!buf) return;
  console.log(buf);

  // convert arraybuffer to json
  const text = String.fromCharCode.apply(null, Array.from(new Uint8Array(buf)));

  console.log(text);
  let responseError;
  if (!text) {
    //Try to check JSON
    responseError = buf?.response?.data;
  } else responseError = JSON.parse(text);

  const errors = responseError?.errors;
  const res = errors?.map(val => {
    const code = val?.code;
    const message = val?.message;

    if (code) {
      return `${code}: ${message}\n`;
    }
    return null;
  });
  return res;
};
