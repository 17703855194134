import React, { Fragment, useState } from "react";
import { Box, Divider, Grid } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, InputButton } from "components/UI";
import CriterialPrint from "./components/CriterialPrint";
import TablePrint from "./components/TablePrint";
import ButtonPrint from "./components/ButtonPrint";
//util
import { responseErrors } from "utils";
//service
import {
  usePrintDlvTypeShtDownloadMutation,
  usePrintDlvTypeShtGetPackStatusQuery,
  usePrintDlvTypeShtGetReceiveListQuery,
  usePrintDlvTypeShtGetSenderListQuery,
  usePrintDlvTypeShtGetShippingInfoQuery,
  useUPrintDlvTypeShtGetDeliveryTypeQuery,
} from "shared/services/main-server";
// type
import { ModeAction } from "state/enum";
import { BUTTON_VALUE } from "shared/constants";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { userProfile } from "constant";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";

export default function PrintDeliveryInstructionSheetScreen() {
  const { startLoading, stopLoading } = useLoadingContext();
  // Form
  const [form, setForm] = useState({
    actualDtFrom: "",
    actualDtTo: "",
    deliveryType: "",
    sender: "",
    receiver: "",
    shippingInfo: "",
    containerNo: "",
    deliveryInstrSheetNo: "",
    packStatus: "",
  });
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(ModeAction.VIEW);

  // Table
  const [rows, setRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  //API
  const { data: deliveryTypeList } = useUPrintDlvTypeShtGetDeliveryTypeQuery();
  const { data: packStatusList } = usePrintDlvTypeShtGetPackStatusQuery();
  const { data: shippingInfoList } = usePrintDlvTypeShtGetShippingInfoQuery();
  const { data: receiverList } = usePrintDlvTypeShtGetReceiveListQuery(form?.deliveryType);
  const { data: senderList } = usePrintDlvTypeShtGetSenderListQuery(form?.deliveryType);
  const download = usePrintDlvTypeShtDownloadMutation();
  const handleDownload = async () => {
    try {
      let dlvInstShtList = [];
      rowSelectionModel.forEach(selected => {
        rows?.forEach(row => {
          if (row?.rowNumber === selected) {
            dlvInstShtList.push(row.dlvInstrShtNo);
            return;
          }
        });
      });
      const body = {
        userId: userProfile.userId,
        dlvInstShtList: dlvInstShtList,
      };
      startLoading();
      const { data } = await download(body);
      waitAndDownloadExcel(60, data, setMsgError, stopLoading);
      stopLoading();
      return;
    } catch (error) {
      const msgErr = responseErrors(error);
      setMsgError(msgErr);
      stopLoading();
      return;
    }
  };
  return (
    <Fragment>
      <Box sx={{ padding: "1rem 1rem 0 1rem" }}>
        <HeaderContentBar title="WDN97053 : Print Delivery Instruction Sheet Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
      </Box>
      <Box sx={{ padding: "0 1rem 1rem 1rem" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CriterialPrint
              setRows={setRows}
              setMode={setMode}
              setOnSearch={setOnSearch}
              setRowSelectionModel={setRowSelectionModel}
              form={form}
              setForm={setForm}
              dataList={{ deliveryTypeList, packStatusList, senderList, receiverList, shippingInfoList }}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <ButtonPrint
              form={form}
              setForm={setForm}
              setRows={setRows}
              setMode={setMode}
              onSearch={onSearch}
              setOnSearch={setOnSearch}
              setRowSelectionModel={setRowSelectionModel}
              setMsg={{ setMsgAlert, setMsgError }}
              loading={{ startLoading, stopLoading }}
              dataList={{ deliveryTypeList, packStatusList, senderList, receiverList, shippingInfoList }}
              functionId={"WDN97053"}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {onSearch && (
            <>
              <Grid item xs={12}>
                <TablePrint
                  mode={mode}
                  setMode={setMode}
                  rows={rows}
                  rowSelectionModel={rowSelectionModel}
                  setRowSelectionModel={setRowSelectionModel}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <InputButton
                  id="button-download"
                  value={BUTTON_VALUE.DOWNLOAD}
                  onClick={handleDownload}
                  disabled={![ModeAction.SELECTED].includes(mode)}
                  onKeyDown={e => {
                    console.log(onSearch && e.key === "Tab" && mode === ModeAction.SELECTED);
                    if (onSearch && e.key === "Tab" && mode === ModeAction.SELECTED) {
                      document.getElementById("date-picker-actual-from").focus();
                    }
                  }}
                  name={"WDN97053Download"}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Fragment>
  );
}
