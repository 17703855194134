import React from "react";
import { Link } from "@mui/material";

export default function LinkFile({
  id,
  value,
  variant = "contained",
  color = "#0d47a1",
  component = "button",
  disabled = false,
  onClick,
  size = "medium",
  style = {},
  props,
}) {
  return (
    <Link
      {...props}
      data-testid={id}
      id={id}
      component={component}
      variant={variant}
      color={color}
      disabled={disabled}
      onClick={onClick}
      size={size}
      underline="always"
      sx={{
        textTransform: "none",
        marginRight: "8px",
        ...style,
      }}>
      {value}
    </Link>
  );
}
