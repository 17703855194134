import React, { Fragment } from "react";
import { responseErrors } from "utils";
// Service
import DataTable from "pages/DataTable";
import { API_LOG_MONITORING } from "shared/constants/api-name/common/logMonitoring";
import { PAGINATION, ROW_PER_PAGE } from "shared/constants";
export default function TableBar(props) {
  const {
    mode,
    setMode,
    rows,
    setRows,
    columns,
    onSearch,
    searchForm,
    columnsHidden,
    getSearch,
    setMsg: { setMsgAlert, setMsgError },
    rowSelection: { rowSelectionModel, setRowSelectionModel, rowModesModel, setRowModesModel },
    pagination: { pagination, setPagination, pageNumber, setPageNumber },
  } = props;
  const handleChangePagination = async (event, value) => {
    try {
      setPageNumber(value);
      const body = {
        [API_LOG_MONITORING.DATA_OWNER]: searchForm.dataOwner,
        [API_LOG_MONITORING.MODULE_ID]: searchForm.moduleId,
        funtionId: searchForm.functionId,
        [API_LOG_MONITORING.STATUS_ID]: searchForm.statusId,
        [API_LOG_MONITORING.LEVEL_ID]: searchForm.messageType,
        [API_LOG_MONITORING.USER_ID]: searchForm.userId,
        [API_LOG_MONITORING.APP_ID]: searchForm.appId,
        [API_LOG_MONITORING.DATE_FORM]: !searchForm.dateFrom ? "" : searchForm.dateFrom,
        [API_LOG_MONITORING.DATE_TO]: !searchForm.dateTo ? "" : searchForm.dateTo,
        [PAGINATION.PAGE_NUMBER]: value,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const { searchData, data } = await getSearch(body);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const msg = responseErrors(error);
      setMsgError(msg);
    }
  };
  return (
    <Fragment>
      <DataTable
        mode={mode}
        onSearch={onSearch}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        setMode={setMode}
        rows={rows}
        rowModesModel={rowModesModel}
        columns={columns}
        setRowModesModel={setRowModesModel}
        pagination={pagination}
        pageNumber={pageNumber}
        handleChangePagination={handleChangePagination}
        columnGroupingModel={null}
        columnVisibilityModel={{
          [API_LOG_MONITORING.ROW_NUMBER]: false,
          [API_LOG_MONITORING.MESSAGE_TYPE]: false,
          ...columnsHidden,
        }}
        checkboxSelection={false}
        rowHeightAuto={true}
      />
    </Fragment>
  );
}
