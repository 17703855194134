const COLUMN_NAME = {
  NO: "No.",
  CATEGORY: "Category",
  SUB_CATEGORY: "Sub Category",
  CODE: "Code",
  VALUE: "Value",
  REMARK: "Remark",
  STATUS: "Status",
  UPDATE_DATE: "Update Date",
  UPDATE_BY: "Update By",
  CREATE_DATE: "Create Date",
  CREATE_BY: "Create By",
};
export default COLUMN_NAME;
