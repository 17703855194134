import { userProfile } from "constant";

const DEFAULT_WAREHOUSE_SUMMARY = {
  rPackageOwnerId: "",
  rPackageOwner: "",
  rPackageTypeId: "",
  wareHouseId: "",
  dataOwner: userProfile.dataOwner,
  userId: userProfile.userId,
};

const DEFAULT_COLOR_GRAPH = {
  RED: "rgb(255, 48, 48)",
  ORANGE: "rgb(255, 159, 64)",
  YELLOW: "rgb(255, 205, 86)",
  GREEN: "rgb(0, 255, 85)",
  BLUE: "rgb(54, 162, 235)",
  PURPLE: "rgb(153, 102, 255)",
  GREY: "rgb(201, 203, 207)",
  BLACK: "rgb(0, 0, 0)",
};
export { DEFAULT_WAREHOUSE_SUMMARY, DEFAULT_COLOR_GRAPH };
