import { isUndefined } from "lodash";
import { useEffect, useState, useRef } from "react";
export default function useLocalStorage(stateKey, defaultValue) {
  const [state, setState] = useState(defaultValue);
  const isNewSession = useRef(true);

  // listens to changes state
  useEffect(() => {
    // check session that there is a new session or rerender component
    // new session (new tab): state has the default state
    if (isNewSession.current) {
      const currentState = localStorage.getItem(stateKey);
      if (!isUndefined(currentState)) {
        setState(JSON.parse(currentState));
      } else {
        setState(defaultValue);
      }
      isNewSession.current = false;
      return;
    }
    try {
      //  initial state
      localStorage.setItem(stateKey, JSON.stringify(state));
    } catch (error) {
      console.log(error);
    }
  }, [state, stateKey, defaultValue]);

  // listens event storage and every time that there is a change
  useEffect(() => {
    const onReceiveMsg = e => {
      const { key, newValue } = e;
      if (key === stateKey) {
        // setState(JSON.parse(newValue));
      }
    };
    window.addEventListener("storage", onReceiveMsg);
    return () => window.removeEventListener("storage", onReceiveMsg);
  }, [stateKey, setState]);

  return [state, setState];
}
