import { ModeAction } from "state/enum";
export const useButtonMode = props => {
  const {
    mode,
    setDownloadBtn,
    setAllExporterBtn,
    setSearchBtn,
    setClearBtn,
    setAddBtn,
    setEditBtn,
    setCancelShipmentBtn,
  } = props;

  switch (mode) {
    case ModeAction.VIEW:
      setAllExporterBtn(false);
      setDownloadBtn(false);
      setSearchBtn(false);
      setClearBtn(false);

      setCancelShipmentBtn(true);
      setAddBtn(false);
      setEditBtn(true);
      break;

    case ModeAction.ADD:
      setAllExporterBtn(true);
      setDownloadBtn(true);
      setSearchBtn(true);
      setClearBtn(true);

      setCancelShipmentBtn(true);
      setAddBtn(true);
      setEditBtn(true);
      break;

    case ModeAction.EDIT:
      setAllExporterBtn(true);
      setDownloadBtn(true);
      setSearchBtn(true);
      setClearBtn(true);

      setCancelShipmentBtn(true);
      setAddBtn(true);
      setEditBtn(true);
      break;

    case ModeAction.SELECTED:
      setAllExporterBtn(false);
      setDownloadBtn(false);
      setSearchBtn(false);
      setClearBtn(false);

      setCancelShipmentBtn(false);
      setAddBtn(false);
      setEditBtn(false);
      break;

    default:
      break;
  }
};
