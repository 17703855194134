import { saveAs } from '../../utils/download-file'
import { useMutateData, useMutateDownload } from "shared/services/base.service";

import useMutation from "hooks/useMutation";

const BaseServicePath = process.env.REACT_APP_API_PREFIX;

export const useUploadSearch = () => {
  return useMutation({
    url: `/download-upload/search`,
    method: "POST",
  });
};

const DownloadMasterApiService = () => ({
  useDownloadTemplateExcel: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate(
        {
          url: `${BaseServicePath}/download-upload/template/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, `${fileName}_Template.xlsx`);
          },
        }
      );
    };
  },
  useDownloadWorkingCalendarMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate(
        {
          url: `${BaseServicePath}/download-upload/working-calendar/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, `Working_Calendar_${getLocalDate().format("YYYYMMDDHHmmssSSS")}.xlsx`);
          },
        }
      );
    };
  },
  useDownloadTransportLeadTimeMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate(
        {
          url: `${BaseServicePath}/download-upload/transport-leadtime/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, `Transportation_Leadtime_${getLocalDate().format("YYYYMMDDHHmmssSSS")}.xlsx`);
          },
        }
      );
    };
  },
  useDownloadCustomStockLeadTimeMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate(
        {
          url: `${BaseServicePath}/download-upload/custom-stock-leadtime/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, `Custom_Stock_Leadtime_${getLocalDate().format("YYYYMMDDHHmmssSSS")}.xlsx`);
          },
        }
      );
    };
  },
  useDownloadSopMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate(
        {
          url: `${BaseServicePath}/download-upload/sop/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, `SOP_${getLocalDate().format("YYYYMMDDHHmmssSSS")}.xlsx`);
          },
        }
      );
    };
  },
  useDownloadKatashikiMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate(
        {
          url: `${BaseServicePath}/download-upload/katashi-subseries/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, `Katashiki_Subseries_${getLocalDate().format("YYYYMMDDHHmmssSSS")}.xlsx`);
          },
        }
      );
    };
  },
  useDownloadLeadTimeMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate(
        {
          url: `${BaseServicePath}/download-upload/leadtime/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, `Leadtime_${getLocalDate().format("YYYYMMDDHHmmssSSS")}.xlsx`);
          },
        }
      );
    };
  },
  useDownloadAveLeadTimeGroupMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate(
        {
          url: `${BaseServicePath}/download-upload/average-leadtime-group/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, `Average_Lead_Time_Group_${getLocalDate().format("YYYYMMDDHHmmssSSS")}.xlsx`);
          },
          // onError: (error, variables, context) => {

          // },
        }
      );
    };
  },
  useUploadFileMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async uploadData => {
      return await mutate(
        {
          url: `${BaseServicePath}/master-upload`,
          data: uploadData,
          method: "POST",
          headers: {
            "content-type": "multipart/form-data",
          },
        },
        { retry: false }
      );
    };
  },
});
export const {
  useDownloadWorkingCalendarMutation,
  useDownloadTransportLeadTimeMutation,
  useDownloadCustomStockLeadTimeMutation,
  useDownloadSopMutation,
  useDownloadKatashikiMutation,
  useDownloadLeadTimeMutation,
  useDownloadAveLeadTimeGroupMutation,
  useUploadFileMutation,
  useDownloadTemplateExcel,
} = DownloadMasterApiService();
