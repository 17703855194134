import { Box } from "@mui/material";
import { LinkFile } from "components/UI";
import React from "react";
import { TEMPLATE_NAME_UPLOAD } from "../constants/uploadRpkg";
import { responseDownloadFileErrors } from "utils";
import { useDownloadTemplateMutation } from "shared/services/inventory-forecast";
import { TEMPLATE_FILE_CODE } from "shared/constants";
import { userProfile } from "constant";
import { useLoadingContext } from "shared/contexts/LoadingContext";

export default function TemplateBar({ setMsg: { setMsgError, setMsgAlert } }) {
  const { startLoading, stopLoading } = useLoadingContext();
  const exportTemplate = useDownloadTemplateMutation();
  const handleDownloadTemplate = async templateName => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const infoFile = TEMPLATE_FILE_CODE.find(v => v.value === templateName);
      // const fileName = infoFile?.fileName;
      const body = { dataOwner: userProfile.dataOwner, cd: infoFile?.cd };
      startLoading();
      await exportTemplate(body);
      stopLoading();
      return true;
    } catch (error) {
      stopLoading();
      const msgError = responseDownloadFileErrors(error);
      setMsgError(msgError);
      return false;
    }
  };
  return (
    <Box>
      <LinkFile
        id={TEMPLATE_NAME_UPLOAD.R_PKG_RCV}
        component="button"
        variant="body2"
        value={TEMPLATE_NAME_UPLOAD.R_PKG_RCV}
        onClick={() => handleDownloadTemplate(TEMPLATE_NAME_UPLOAD.R_PKG_RCV)}
      />
      <LinkFile
        id={TEMPLATE_NAME_UPLOAD.R_PKG_STS_CHG}
        component="button"
        variant="body2"
        value={TEMPLATE_NAME_UPLOAD.R_PKG_STS_CHG}
        onClick={() => handleDownloadTemplate(TEMPLATE_NAME_UPLOAD.R_PKG_STS_CHG)}
      />
      <LinkFile
        id={TEMPLATE_NAME_UPLOAD.R_PKG_DLV}
        component="button"
        variant="body2"
        value={TEMPLATE_NAME_UPLOAD.R_PKG_DLV}
        onClick={() => handleDownloadTemplate(TEMPLATE_NAME_UPLOAD.R_PKG_DLV)}
      />
    </Box>
  );
}
