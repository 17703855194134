import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { COLUMN_NAME } from "../constants/table.constant";
import { ModeAction } from "state/enum";
import { API_SHIPMENT, COMMON_ENUM } from "shared/constants";
import SelectionCheckBox from "components/UI/DataTable/CustomTable/SelectionCheckBox";
import { TanStackUploadFile, LinkFile } from "components/UI";
import { isEmpty } from "lodash";
import { Box } from "@mui/material";
const INDEX_OF_ETA = 10;

export default function useColMapping({ rPkg, mode, rows, handleUploadFile, handleUploadFilePdf, handleLink }) {
  const columnHelper = createColumnHelper();
  const column = [
    {
      id: "select",
      enableRowSpan: true,
      cell: ({ row }) => {
        return (
          <SelectionCheckBox
            {...{
              checked: row?.getIsSelected(),
              disabled: !row?.getCanSelect(),
              indeterminate: row?.getIsSomeSelected(),
              onChange: row?.getToggleSelectedHandler(),
            }}
          />
        );
      },
    },
    {
      header: COLUMN_NAME.NO,
      accessorKey: API_SHIPMENT.ROW_NUMBER,
      key: "key",
      size: 100,
      align: "center",
      enableRowSpan: true,
      cell: ({ row, getValue }) => {
        if (row?.original?.rowNumber === rows.slice(-1)?.[0]?.rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return getValue();
      },
    },
    {
      header: COLUMN_NAME.FORECAST_MONTH,
      accessorKey: API_SHIPMENT.FORECAST_MONTH,
      size: 50,
      align: "center",
      enableRowSpan: true,
    },
    {
      header: COLUMN_NAME.EXPORTER,
      accessorKey: API_SHIPMENT.EXPORTER,
      size: 50,
      align: "center",
      enableRowSpan: true,
    },
    {
      header: COLUMN_NAME.IMPORTER,
      accessorKey: API_SHIPMENT.IMPORTER,
      size: 30,
      align: "center",
      enableRowSpan: true,
    },
    {
      header: COLUMN_NAME.SHIPMENT_CODE,
      accessorKey: API_SHIPMENT.SHIPMENT_CODE,
      size: 100,
      align: "center",
      enableRowSpan: true,
    },
    {
      header: COLUMN_NAME.CARRIER,
      accessorKey: API_SHIPMENT.CARRIER,
      size: 100,
      align: "center",
      enableRowSpan: true,
    },
    {
      header: COLUMN_NAME.PLAN_ACTUAL,
      accessorKey: "planActual",
      size: 100,
      align: "center",
    },
    {
      header: COLUMN_NAME.ETD,
      accessorKey: API_SHIPMENT.ETD_DATE,
      size: 50,
      align: "center",
    },
    {
      header: COLUMN_NAME.ETA,
      accessorKey: API_SHIPMENT.ETA_DATE,
      size: 50,
      align: "center",
    },
    {
      header: COLUMN_NAME.ACTUAL_FILE,
      accessorKey: API_SHIPMENT.ORG_ACTL_FILE_NAME,
      align: "center",
      size: 150,
      enableRowSpan: true,
      cell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <TanStackUploadFile
              id="add-upload-file"
              fileType=".xlsx"
              // required={true}
              disabled={!params?.row?.original?.isNew}
              uploadProcess={handleUploadFile}
              maxLength={50}
              {...params}
            />
          );
        } else if (mode === ModeAction.EDIT && params.row.getIsSelected()) {
          if (
            [COMMON_ENUM.SHIPMENT_STATUS_ACTUAL, COMMON_ENUM.SHIPMENT_STATUS_ACTUAL_NCV].includes(
              params?.row?.original["shipmentStsId"]
            )
          ) {
            return (
              <TanStackUploadFile
                fileType=".xlsx"
                // required={true}
                uploadProcess={handleUploadFile}
                maxLength={50}
                disabled={false}
                {...params}
              />
            );
          } else if (![COMMON_ENUM.SHIPMENT_STATUS_PLAN].includes(params?.row?.original["shipmentStsId"])) {
            return (
              <TanStackUploadFile
                fileType=".xlsx"
                // required={true}
                uploadProcess={handleUploadFile}
                maxLength={50}
                disabled={false}
                {...params}
              />
            );
          } else {
            return (
              <TanStackUploadFile
                fileType=".xlsx"
                // required={true}
                uploadProcess={handleUploadFile}
                maxLength={50}
                disabled={false}
                {...params}
              />
            );
          }
        } else if (isEmpty(params?.getValue())) {
          return (
            <TanStackUploadFile
              fileType=".xlsx"
              // required={true}
              uploadProcess={handleUploadFile}
              maxLength={50}
              disabled={true}
              {...params}
            />
          );
        } else {
          return (
            <LinkFile
              component="button"
              variant="body2"
              value={params?.getValue()}
              onClick={() => handleLink(params?.getValue())}
            />
          );
        }
      },
    },
    columnHelper.group({
      header: COLUMN_NAME.UPLOAD_FILE,
      footer: props => props.column.id,
      columns: [
        {
          header: COLUMN_NAME.NVC_FILE,
          // accessorKey: API_SHIPMENT.NCV_FILE_NAME,
          accessorKey: API_SHIPMENT.ORG_NCV_FILE_NAME,
          size: 50,
          align: "center",
          enableRowSpan: true,
          cell: params => {
            if (ModeAction.EDIT === mode && params.row.getIsSelected()) {
              if (
                [COMMON_ENUM.SHIPMENT_STATUS_ACTUAL, COMMON_ENUM.SHIPMENT_STATUS_ACTUAL_NCV].includes(
                  params?.row?.original["shipmentStsId"]
                )
              ) {
                return (
                  <Box>
                    <LinkFile
                      component="button"
                      variant="body2"
                      value={params?.getValue()}
                      onClick={() => handleLink(params?.getValue())}
                    />
                    <TanStackUploadFile
                      fileType=".pdf,.xlsx"
                      uploadProcess={handleUploadFilePdf}
                      maxLength={50}
                      disabled={false}
                      {...params}
                    />
                  </Box>
                );
              } else if (![COMMON_ENUM.SHIPMENT_STATUS_PLAN].includes(params?.row?.original["shipmentStsId"])) {
                return (
                  <TanStackUploadFile
                    fileType=".pdf,.xlsx"
                    // required={true}
                    uploadProcess={handleUploadFilePdf}
                    maxLength={50}
                    disabled={false}
                    {...params}
                  />
                );
              } else {
                return (
                  <TanStackUploadFile
                    fileType=".pdf,.xlsx"
                    // required={true}
                    uploadProcess={handleUploadFilePdf}
                    maxLength={50}
                    disabled={true}
                    {...params}
                  />
                );
              }
            } else if (isEmpty(params?.getValue())) {
              return (
                <TanStackUploadFile
                  fileType=".pdf,.xlsx"
                  // required={true}
                  uploadProcess={handleUploadFilePdf}
                  maxLength={50}
                  disabled={true}
                  {...params}
                />
              );
            } else {
              return (
                <LinkFile
                  component="button"
                  variant="body2"
                  value={params?.getValue()}
                  onClick={() => handleLink(params?.getValue())}
                />
              );
            }
          },
        },
        {
          header: COLUMN_NAME.BL_FILE,
          // accessorKey: API_SHIPMENT.BL_FILE_NAME,
          accessorKey: API_SHIPMENT.ORG_BL_FILE_NAME,
          size: 50,
          align: "center",
          enableRowSpan: true,
          cell: params => {
            if (ModeAction.EDIT === mode && params.row.getIsSelected()) {
              if (
                [COMMON_ENUM.SHIPMENT_STATUS_ACTUAL, COMMON_ENUM.SHIPMENT_STATUS_ACTUAL_NCV].includes(
                  params?.row?.original["shipmentStsId"]
                )
              ) {
                return (
                  <Box>
                    <LinkFile
                      component="button"
                      variant="body2"
                      value={params?.getValue()}
                      onClick={() => handleLink(params?.getValue())}
                    />
                    <TanStackUploadFile
                      fileType=".pdf,.xlsx"
                      // required={true}
                      uploadProcess={handleUploadFilePdf}
                      maxLength={50}
                      disabled={false}
                      {...params}
                    />
                  </Box>
                );
              } else if (![COMMON_ENUM.SHIPMENT_STATUS_PLAN].includes(params?.row?.original["shipmentStsId"])) {
                return (
                  <TanStackUploadFile
                    fileType=".pdf,.xlsx"
                    // required={true}
                    uploadProcess={handleUploadFilePdf}
                    maxLength={50}
                    disabled={false}
                    {...params}
                  />
                );
              } else {
                return (
                  <TanStackUploadFile
                    fileType=".pdf,.xlsx"
                    // required={true}
                    uploadProcess={handleUploadFilePdf}
                    maxLength={50}
                    disabled={true}
                    {...params}
                  />
                );
              }
            } else if (isEmpty(params?.getValue())) {
              return (
                <TanStackUploadFile
                  fileType=".pdf,.xlsx"
                  // required={true}
                  uploadProcess={handleUploadFilePdf}
                  maxLength={50}
                  disabled={true}
                  {...params}
                />
              );
            } else {
              return (
                <LinkFile
                  component="button"
                  variant="body2"
                  value={params?.getValue()}
                  onClick={() => handleLink(params?.getValue())}
                />
              );
            }
          },
        },
      ],
    }),
    {
      header: COLUMN_NAME.SHIPMENT_SUMMARY_H_ID,
      accessorKey: API_SHIPMENT.SHIPMENT_SUMMARY_H_ID,
      size: 50,
      align: "center",
      enableRowSpan: true,
    },
    {
      header: COLUMN_NAME.SHIPMENT_SUM_UPDATE_DATE,
      accessorKey: API_SHIPMENT.SHIPMENT_SUM_UPDATE_DATE,
      size: 50,
      align: "center",
    },
    {
      header: COLUMN_NAME.SHIPMENT_CANCEL_FLAG,
      accessorKey: API_SHIPMENT.SHIPMENT_CANCEL_FLAG,
      size: 50,
      align: "center",
    },
    // {
    //   header: COLUMN_NAME.SHIPMENT_STATUS,
    //   accessorKey: API_SHIPMENT.SHIPMENT_STATUS,
    //   size: 50,
    //   align: "center",
    // },
  ];
  let newCol = [];
  if (!rPkg) {
    console.log("R-Package Col not found");
    return;
  }
  newCol = rPkg.map((val, index) => ({
    header: val,
    accessorKey: val,
    size: 100,
    align: "center",
  }));
  column.splice(INDEX_OF_ETA, 0, ...newCol);
  return column;
}
