import { ModeAction } from "state/enum";

export const useButtonModeContainer = props => {
  const {
    mode,
    rows,
    stateButton: { setEditBtn, setAddBtn, setCopyBtn, setDeleteBtn },
    modeOfWDN95030,
  } = props;
  // check mode of WDN95030

  if ([ModeAction.VIEW, ModeAction.SELECTED].includes(modeOfWDN95030)) {
    setAddBtn(true);
    setCopyBtn(true);
    setEditBtn(true);
    setDeleteBtn(true);
    return;
  }
  // check record of WDN95031
  if (!rows?.length) {
    setAddBtn(false);
    setCopyBtn(true);
    setEditBtn(true);
    setDeleteBtn(true);
    return;
  }
  switch (mode) {
    case ModeAction.VIEW:
      setAddBtn(false);
      setCopyBtn(true);
      setEditBtn(true);
      setDeleteBtn(true);
      break;
    case ModeAction.ADD:
      setAddBtn(true);
      setCopyBtn(true);
      setEditBtn(true);
      setDeleteBtn(true);
      break;
    case ModeAction.COPY:
      setAddBtn(true);
      setCopyBtn(true);
      setEditBtn(true);
      setDeleteBtn(true);
      break;
    case ModeAction.EDIT:
      setAddBtn(true);
      setCopyBtn(true);
      setEditBtn(true);
      setDeleteBtn(true);
      break;
    case ModeAction.SELECTED:
      setAddBtn(false);
      setCopyBtn(false);
      setEditBtn(false);
      setDeleteBtn(false);
      break;

    default:
      break;
  }
};
