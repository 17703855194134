// export const YEAR_COLUMNS_NAME = {
//   NO: "No.",
//   FORECAST_YEAR: "Forecast Year (YYYY)",
//   DATA_TYPE: "Data Type",
//   FILE_NAME: "File Name",
//   UPLOAD_STATUS: "Upload Status",
//   UPLOAD_DATE: "Upload Date (DD/MM/YYYY)",
//   UPLOAD_BY: "Upload By",
//   GENERATE_STATUS: "Generate Status",
//   GENERATE_DATE: "Generate Date (DD/MM/YYYY)",
//   GENERATE_BY: "Generate By",
// };
export const MONTH_COLUMNS_NAME = {
  NO: "No.",
  FORECAST_MONTH: "Forecast Month (MM/YYYY)",
  DATA_TYPE: "Data Type",
  FILE_NAME: "File Name",
  UPLOAD_STATUS: "Upload Status",
  UPLOAD_DATE: "Upload Date (DD/MM/YYYY)",
  UPLOAD_BY: "Upload By",
  GENERATE_STATUS: "Generate Status",
  GENERATE_DATE: "Generate Date (DD/MM/YYYY)",
  GENERATE_BY: "Generate By",
  FILE_UPLOAD_ID: "File Upload ID",
  MODE: "Mode",
};
export const APMC_COLUMNS_NAME = {
  NO: "No.",
  IMPORT_EXPORT: "Importer/ Exporter",
  FORECAST_MONTH: "Forecast Month (MM/YYYY)",
  DATA_TYPE: "Data Type",
  MODE: "Mode",
  FILE_NAME: "File Name",
  UPLOAD_STATUS: "Upload Status",
  UPLOAD_DATE: "Upload Date (DD/MM/YYYY)",
  UPLOAD_BY: "Upload By",
  GENERATE_STATUS: "Generate Status",
  GENERATE_DATE: "Generate Date (DD/MM/YYYY)",
  GENERATE_BY: "Generate By",
};

export const TEMPLATE_NAME = {
  // OAP: "OAP Template",
  // RAP: "RAP Template",
  DELIVERY_PLAN: "Delivery Plan Template",
  PRODUCTION: "Production",
  PACKING: "Packing",
  RETURN_SHIPPING: "Return Shipping",
  TRANSFERRING: "Transferring",
  RECEIVING: "Receiving",
};
