import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSuccess: false,
  userCompanyAbbr: null,
  companyInfo: null,
  token: null,
};
const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    setIsSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
    setUserCompanyAbbr: (state, action) => {
      state.userCompanyAbbr = action.payload;
    },
    setCredentials: (state, { payload: { companyInfo, token, refreshToken } }) => {
      state.companyInfo = companyInfo;
      state.token = token;
    },
  },
});
export const { setCredentials, setUserCompanyAbbr, setIsSuccess } = common.actions;

export default common.reducer;

export const commonReducer = state => state.common.userCompanyAbbr;
