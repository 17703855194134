import React from "react";
import { Box } from "@mui/material";
import { InputButton } from "components/UI";
// util
import { responseErrors, responseSuccess } from "utils";
import { validationSearchForm } from "utils/validation";
// service
import { useUploadRPkgContUploadRPgMutation } from "shared/services/main-server";
import { MessageType } from "state/enum";
import { userProfile } from "constant";
import { BUTTON_VALUE } from "shared/constants";
import { useLoadingContext } from "shared/contexts/LoadingContext";

export default function ButtonUploadRPkg({
  form,
  setForm,
  setMsg: { setMsgError, setMsgAlert },
  setAppId,
  functionId,
}) {
  const { startLoading, stopLoading } = useLoadingContext();
  const uploadRPkg = useUploadRPkgContUploadRPgMutation();
  const handleUpload = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      setAppId(null);
      const body = {
        userCompanyCd: userProfile.dataOwner,
        userId: userProfile.userId,
        file: form.file,
        fileName: form.fileName,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "fileName",
            type: MessageType.EMPTY,
            key: ["Excel File"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        setForm({ file: "", fileName: "" });
        window.scrollTo(0, 0);
        return;
      }
      const uploadData = new FormData();
      uploadData.append("file", body.file);
      uploadData.append("userId", body.userId);
      uploadData.append("userCompanyCd", body.userCompanyCd);

      startLoading();
      const response = await uploadRPkg(uploadData);
      stopLoading();
      // console.log(response);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      setAppId(response?.appId);
      setForm({ file: "", fileName: "" });
      return true;
    } catch (error) {
      stopLoading();
      const msgError = responseErrors(error);
      setMsgError(msgError);
      return false;
    }
  };
  return (
    <Box>
      <InputButton
        id="button-upload-rPkg"
        value={BUTTON_VALUE.UPLOAD}
        onClick={handleUpload}
        name={`${functionId}Upload`}
      />
    </Box>
  );
}
