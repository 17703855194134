import React, { Fragment } from "react";
// Component
import { Grid } from "@mui/material";
import { InputButton, LinkFile } from "components/UI";
// Hook
import { useConfirmDialogContext } from "context/confirmDialogContext";
// Service
import {
  useDownloadTemplateMutation,
  useMappingCancelMutation,
  useMappingCheckAddMutation,
  useMappingCheckEditMutation,
} from "shared/services/inventory-forecast";
//UTIL
import { findObject, messageDisplay, responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
// Constant & Type
import {
  API_SHIPMENT,
  BUTTON_VALUE,
  COMMON_ENUM,
  FIRST_PAGE,
  ROW_PER_PAGE,
  TEMPLATE_FILE_CODE,
} from "shared/constants";
import { ModeAction, MSG_TYPE } from "state/enum";
import { getLocalDate } from "utils/init-config-date";
import { userProfile } from "constant";
import dayjs from "dayjs";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { compareTo } from "pages/Forecast/ShipmentManagementScreen/hooks/compareTo";
import axios from "axios";
import { useCustomQuery } from "shared/services/base.service";
import httpStatus from "http-status";
export default function ActionTableBar(props) {
  const confirmDialogCtx = useConfirmDialogContext();
  const {
    form,
    mode,
    setMode,
    setForm,
    columns,
    refresh,
    rows,
    setRows,
    setOnSearch,
    setMsg: { setMsgError, setMsgAlert },
    buttonState: { addBtn, editBtn, cancelShipmentBtn },
    dataList: { exporterList, importerList, rPkgList },
    rowSelection: { selectedRowKeys, setSelectedRowKeys, selectedRows, setSelectedRows },
    pagination: { pageNumber, setPageNumber, pagination, setPagination },
    functionId,
  } = props;
  const { startLoading, stopLoading } = useLoadingContext();
  const exportTemplateGrouping = useDownloadTemplateMutation();
  const cancelShipment = useMappingCancelMutation();
  const checkEdit = useMappingCheckEditMutation();
  const checkAdd = useMappingCheckAddMutation();

  const handleAddBtn = async () => {
    try {
      const body = {
        [API_SHIPMENT.DATA_OWNER]: form?.dataOwner,
        [API_SHIPMENT.COMPANY]: form?.companyAbbr,
        [API_SHIPMENT.COMPANY_ABBR]: form?.companyAbbr,
        [API_SHIPMENT.OPERATION]: form?.operationId,
        [API_SHIPMENT.EXPORTER]: form?.exporterId,
        [API_SHIPMENT.IMPORTER]: form?.importerId
          ? importerList.find(v => v.companyId === form?.importerId).companyAbbr
          : "",
        [API_SHIPMENT.R_RKG_OWNER]: form.rPkgOwnerId
          ? rPkgList.find(v => v.rPkgOwnerCompId === form?.rPkgOwnerId).rPkgOwnerCompAbbr
          : "",
        [API_SHIPMENT.FORECAST_MONTH]: form.forecastMonth,
        [API_SHIPMENT.SHIPMENT_STATUS]: form.shipmentStsId,
        [API_SHIPMENT.ETD_FORM]: form.etdFrom,
        [API_SHIPMENT.ETD_TO]: form.etdTo,
        [API_SHIPMENT.ETA_FORM]: form.etaFrom,
        [API_SHIPMENT.ETA_TO]: form.etaTo,
        // shipmentCd: rowSelected.shipmentCd,
        pageNumber: pageNumber,
        rowsPerPage: ROW_PER_PAGE,
      };
      const response = await checkAdd(body);
      console.log(response);
      // if (response.statusCode === httpStatus[200]) {
      setMsgAlert([]);
      setMsgError([]);
      setForm(old => ({
        ...old,
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.FILE_NAME]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: "",
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: "",
          [API_SHIPMENT.ORG_BL_FILE_NAME]: "",
        },
        [API_SHIPMENT.FILE]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: {},
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: {},
          [API_SHIPMENT.ORG_BL_FILE_NAME]: {},
        },
      }));
      const firstRow = rows.find(obj => obj.no === 1);
      // const formDt = getLocalDate(form.forecastMonth, "MM/YYYY");
      const formDt = getLocalDate(form.forecastMonth, "MM/YYYY");
      // check first row
      // check row forecast month is before form forecast month
      // if (dayjs(firstRow.forecastMonth, "MM/YYYY").isBefore(formDt, "month")) {
      //   const msg = messageDisplay({
      //     type: MSG_TYPE.ERROR,
      //     code: "MDN92024AERR",
      //     msg: [`${firstRow?.shipmentCd}(ETD=${firstRow?.etdDt})`],
      //   });
      //   setMsgError([msg]);
      //   window.scrollTo(0, 0);
      //   return;
      // } else if (!firstRow?.orgNcvFileName) {
      //   const msg = messageDisplay({
      //     type: MSG_TYPE.ERROR,
      //     code: "MDN92024AERR",
      //     msg: [`${firstRow?.shipmentCd}(ETD=${firstRow?.etdDt})`],
      //   });
      //   setMsgError([msg]);
      //   window.scrollTo(0, 0);
      //   return;
      // }

      let maxNo = -1;
      setMsgError([]);
      setMsgAlert([]);
      setMode(ModeAction.ADD);
      setOnSearch(true);
      setForm(old => ({
        ...old,
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.FILE_NAME]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: "",
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: "",
          [API_SHIPMENT.ORG_BL_FILE_NAME]: "",
        },
        [API_SHIPMENT.FILE]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: {},
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: {},
          [API_SHIPMENT.ORG_BL_FILE_NAME]: {},
        },
      }));
      const field = {};
      columns.forEach(column => {
        if (column.accessorKey === API_SHIPMENT.ROW_NUMBER) {
          maxNo = rows?.length;
          field[column.accessorKey] = rows.length ? maxNo + 1 : maxNo;
        } else {
          field[column.accessorKey] = "";
        }
      });
      const planField = {
        ...field,
        rowSpan: 2,
        [API_SHIPMENT.FORECAST_MONTH]: form?.forecastMonth,
        [API_SHIPMENT.EXPORTER]: form?.exporterId,
        // [API_SHIPMENT.EXPORTER]: form?.exporterId
        //   ? exporterList.find(v => v.companyAbbr === form?.exporterId).impExpCd
        //   : "",
        [API_SHIPMENT.IMPORTER]: form?.importerId
          ? importerList.find(v => v.companyId === form?.importerId).companyAbbr
          : "",
        [API_SHIPMENT.PLAN_ACTL]: "Plan",
        isNew: true,
      };
      setRows(prev => [
        ...prev,
        {
          ...planField,
        },
        {
          ...field,
          [API_SHIPMENT.FORECAST_MONTH]: form?.forecastMonth,
          [API_SHIPMENT.EXPORTER]: form?.exporterId,
          [API_SHIPMENT.IMPORTER]: form?.importerId
            ? importerList.find(v => v.companyId === form?.importerId).companyAbbr
            : "",
          [API_SHIPMENT.PLAN_ACTL]: "Actual",
          isNew: true,
        },
      ]);
      const maxNoModel = rows.length ? maxNo + 1 : maxNo;
      setSelectedRows(prev => ({
        ...prev,
        [maxNoModel]: true,
      }));
      setSelectedRowKeys(prev => [...prev, { ...planField }]);
      return;
      // } else {
      //   let tempError = { ...response };
      //   tempError.response.data.message = `MDN92024AERR: Please finish Shipment No. ${tempError.response.data.result["SHIPMENT_CD"]}(ETD=${tempError.response.data.result["PLAN_ETD_DT"]}) with upload actual data and NCV before process new shipment`;
      //   return;
      // }
    } catch (error) {
      console.error(error);
      let tempError = { ...error };
      tempError.response.data.message = `MDN92024AERR: Please finish Shipment No. ${tempError.response.data.result["SHIPMENT_CD"]}(ETD=${tempError.response.data.result["PLAN_ETD_DT"] ?? tempError.response.data.result["ACTL_ETD_DT"]}) with upload actual data and NCV before process new shipment`;
      const err = responseErrors(tempError);
      setMsgError([err]);
      window.scroll(0, 0);
      return;
    }
  };
  const handleEditBtn = async () => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      setForm(old => ({
        ...old,
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.FILE_NAME]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: "",
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: "",
          [API_SHIPMENT.ORG_BL_FILE_NAME]: "",
        },
        [API_SHIPMENT.FILE]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: {},
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: {},
          [API_SHIPMENT.ORG_BL_FILE_NAME]: {},
        },
      }));
      // if (pageNumber === 1) {
      //   const selectNo = Object.keys(selectedRows)[0];
      //   const beforeRow = rows.find((obj, index) => index + 1 === Number(selectNo) - 1);
      //   if (beforeRow !== null && beforeRow !== undefined) {
      //     if (compareTo(beforeRow["shipmentStsId"], COMMON_ENUM.SHIPMENT_STATUS_ACTUAL_NCV) < 0) {
      //       const msg = messageDisplay({
      //         type: MSG_TYPE.ERROR,
      //         code: "MDN92024AERR",
      //         msg: [`${beforeRow.shipmentCd}(ETD=${beforeRow.etdDt})`],
      //       });
      //       setMsgError([msg]);
      //       window.scrollTo(0, 0);
      //       return;
      //     }
      //   } else {
      //     setMode(ModeAction.EDIT);
      //     return;
      //   }
      // } else {
      const selectNo = Object.keys(selectedRows)[0];
      const rowSelected = rows.find((obj, index) => index === Number(selectNo));
      console.log("rowSelected", rowSelected);
      const body = {
        [API_SHIPMENT.DATA_OWNER]: form?.dataOwner,
        [API_SHIPMENT.COMPANY]: form?.companyAbbr,
        [API_SHIPMENT.COMPANY_ABBR]: form?.companyAbbr,
        [API_SHIPMENT.OPERATION]: form?.operationId,
        [API_SHIPMENT.EXPORTER]: form?.exporterId,
        [API_SHIPMENT.IMPORTER]: form?.importerId
          ? importerList.find(v => v.companyId === form?.importerId).companyAbbr
          : "",
        [API_SHIPMENT.R_RKG_OWNER]: form.rPkgOwnerId
          ? rPkgList.find(v => v.rPkgOwnerCompId === form?.rPkgOwnerId).rPkgOwnerCompAbbr
          : "",
        [API_SHIPMENT.FORECAST_MONTH]: form.forecastMonth,
        [API_SHIPMENT.SHIPMENT_STATUS]: form.shipmentStsId,
        [API_SHIPMENT.ETD_FORM]: rowSelected.etdDt,
        [API_SHIPMENT.ETD_TO]: form.etdTo,
        [API_SHIPMENT.ETA_FORM]: form.etaFrom,
        [API_SHIPMENT.ETA_TO]: form.etaTo,
        shipmentCd: rowSelected.shipmentCd,
        pageNumber: pageNumber,
        rowsPerPage: ROW_PER_PAGE,
      };
      const response = await checkEdit(body);
      console.log("response :>>>", response);

      if (response !== null && response.result.allow) {
        setMode(ModeAction.EDIT);
        return;
      } else if (response !== null && !response.result.allow) {
        setMsgError([response.result.message]);
        window.scroll(0, 0);
        return;
      } else {
        setMode(ModeAction.EDIT);
        return;
      }
      // }
    } catch (error) {
      console.error(error);
      let tempError = { ...error };
      tempError.response.data.message = tempError.response.data.result.message;
      const err = responseErrors(tempError);
      setMsgError([err]);
      window.scroll(0, 0);
      return;
    }
  };
  const handleCancelShipmentBtn = async () => {
    setMsgError([]);
    setMsgAlert([]);

    const confirm = await confirmDialogCtx.success({
      type: "FORECAST_SHIPMENT_CONFIRM_CANCEL",
    });
    if (!confirm) return;

    try {
      const selectedNo = Object.keys(selectedRows)[0];
      const data = rows?.find((v, index) => index === Number(selectedNo));
      // console.log(data);

      const shipmentId = data?.shipmentSummaryHId;
      const body = {
        [API_SHIPMENT.DATA_OWNER]: form?.dataOwner,
        [API_SHIPMENT.COMPANY]: form?.companyAbbr,
        [API_SHIPMENT.COMPANY_ABBR]: form?.companyAbbr,
        [API_SHIPMENT.MANUAL_UPLOAD_INV_FLAG]: COMMON_ENUM.FLAG_YES,
        [API_SHIPMENT.UPDATE_DATE]: data?.updateDt,
        [API_SHIPMENT.UPDATE_BY]: form?.userId,
      };
      startLoading();
      const response = await cancelShipment(shipmentId, body);
      const msg = responseSuccess(response);

      // const msg = messageDisplay({ type: MSG_TYPE.INFO, code: "MDN90016AINF", msg: [""] });
      setMsgAlert([msg]);
      await refresh();
      stopLoading();
      setMode(ModeAction.VIEW);
      setSelectedRowKeys([]);
      setSelectedRows({});
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      stopLoading();

      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      window.scrollTo(0, 0);
      return false;
    }
  };
  const handleDownloadTemplate = async () => {
    setMsgAlert([]);
    setMsgError([]);
    try {
      const infoFile = TEMPLATE_FILE_CODE.find(v => v.value === "Actual Upload");
      const body = { [API_SHIPMENT.DATA_OWNER]: userProfile.dataOwner, cd: infoFile?.cd };
      startLoading();
      await exportTemplateGrouping(body);
      stopLoading();
    } catch (error) {
      stopLoading();
      const err = responseDownloadFileErrors(error);
      setMsgError([err]);
      window.scroll(0, 0);
      return;
    }
  };
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <InputButton
            id="btn_cancel"
            value={"Cancel Shipment"}
            disabled={cancelShipmentBtn}
            onClick={handleCancelShipmentBtn}
            name={`${functionId}CancelShipment`}
          />
          <InputButton
            id="btn_edit"
            value={BUTTON_VALUE.EDIT}
            disabled={editBtn}
            onClick={handleEditBtn}
            name={`${functionId}Edit`}
          />
          <InputButton
            id="btn_add"
            value={"Add Shipment"}
            disabled={addBtn}
            onClick={handleAddBtn}
            name={`${functionId}AddShipment`}
          />
          <LinkFile value={"Actual Upload Template"} onClick={handleDownloadTemplate} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
