import React from "react";
import { EditableDatePicker, EditableDropDown, EditableTextField } from "components/UI";
import { ModeAction } from "state/enum";
import { API_NAME_IMPORT_INVOICE_DOWNLOAD } from "shared/constants";
import { IMPORT_INVOICE_DOWNLOAD_COLUMNS } from "../constants/import.type";

export const useColumn = ({ rows, mode, dataList: { brokerList } }) => {
  return [
    // Col 1
    {
      field: API_NAME_IMPORT_INVOICE_DOWNLOAD.ROW_NUMBER,
      headerName: IMPORT_INVOICE_DOWNLOAD_COLUMNS.NO,
      headerAlign: "center",
      align: "right",
      flex: 0.1,
      minWidth: 50,
      sortable: false,
      editable: false,
      renderCell: params => {
        if (params?.row?.rowNumber === rows.slice(-1)?.[0].rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params.value;
      },
    },
    // Col 2
    {
      field: API_NAME_IMPORT_INVOICE_DOWNLOAD.EXP_COMPANY_ABBR,
      headerName: IMPORT_INVOICE_DOWNLOAD_COLUMNS.EXPORTER,
      headerAlign: "center",
      align: "center",
      flex: 0.1,
      minWidth: 120,
      sortable: false,
      editable: false,
    },
    // Col 3
    {
      field: API_NAME_IMPORT_INVOICE_DOWNLOAD.INV_NO,
      headerName: IMPORT_INVOICE_DOWNLOAD_COLUMNS.INVOICE_NO,
      headerAlign: "center",
      align: "left",
      flex: 0.1,
      minWidth: 200,
      sortable: false,
      editable: false,
    },
    // Col4
    {
      field: API_NAME_IMPORT_INVOICE_DOWNLOAD.INV_DT,
      headerName: IMPORT_INVOICE_DOWNLOAD_COLUMNS.INVOICE_DATE,
      headerAlign: "center",
      align: "center",
      flex: 0.1,
      minWidth: 120,
      sortable: false,
      editable: false,
    },
    // Col 5
    {
      field: API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_DT,
      headerName: IMPORT_INVOICE_DOWNLOAD_COLUMNS.ETD_DATE,
      headerAlign: "center",
      align: "center",
      flex: 0.1,
      minWidth: 120,
      sortable: false,
      editable: false,
      disableClickEventBubbling: true,
    },
    // Col 6
    {
      field: API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_DT,
      headerName: IMPORT_INVOICE_DOWNLOAD_COLUMNS.ETA_DATE,
      headerAlign: "center",
      align: "center",
      flex: 0.1,
      minWidth: 120,
      sortable: false,
      editable: false,
    },
    // Col7
    {
      field: API_NAME_IMPORT_INVOICE_DOWNLOAD.VESSEL_NAME,
      headerName: IMPORT_INVOICE_DOWNLOAD_COLUMNS.VESSEL_NAME,
      headerAlign: "center",
      align: "left",
      flex: 0.1,
      minWidth: 220,
      sortable: false,
      editable: false,
    },
    // Col8
    {
      field: API_NAME_IMPORT_INVOICE_DOWNLOAD.R_PKG_TYPE,
      headerName: IMPORT_INVOICE_DOWNLOAD_COLUMNS.RPACKAGE_TYPE,
      headerAlign: "center",
      align: "left",
      flex: 0.1,
      minWidth: 120,
      sortable: false,
      editable: false,
    },
    // Col 9
    {
      field: API_NAME_IMPORT_INVOICE_DOWNLOAD.R_PKG_QTY,
      headerName: IMPORT_INVOICE_DOWNLOAD_COLUMNS.RPACKAGE_QTY,
      headerAlign: "center",
      align: "right",
      flex: 0.1,
      minWidth: 120,
      sortable: false,
      editable: false,
    },
    // Col 10
    {
      field: API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_CUSTOMS_STK_DT,
      headerName: IMPORT_INVOICE_DOWNLOAD_COLUMNS.IMPORT_CUSTOMS_STOCK_DATE,
      headerAlign: "center",
      align: "center",
      flex: 0.1,
      minWidth: 180,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.EDIT) {
          return <EditableDatePicker {...params} required fullWidth />;
        } else {
          return params.value;
        }
      },
    },
    // Col 11
    {
      field: API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_BROKER_COMPANY_ID,
      headerName: IMPORT_INVOICE_DOWNLOAD_COLUMNS.BROKER,
      headerAlign: "center",
      align: "left",
      flex: 0.1,
      minWidth: 150,
      sortable: false,
      editable: true,
      renderCell: params => {
        return brokerList?.find(v => v.companyId === params.value)?.companyAbbr;
      },
      renderEditCell: params => {
        if (mode === ModeAction.EDIT) {
          return (
            <EditableDropDown
              {...params}
              style={{ margin: "0px 15px", width: "100%" }}
              defaultValue=""
              menu={brokerList?.map(val => ({
                key: val.companyId === null ? "" : val.companyId,
                value: val.companyAbbr === null ? "" : val.companyAbbr,
              }))}
              placeholder="<Select>"
              autoFocus={true}
            />
          );
        } else {
          return brokerList?.find(v => v.companyId === params.value)?.companyAbbr;
        }
      },
    },
    // Col 12
    {
      field: API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_ENTRY_NO,
      headerName: IMPORT_INVOICE_DOWNLOAD_COLUMNS.IMPORT_ENTRY_NO,
      headerAlign: "center",
      align: "left",
      flex: 0.1,
      minWidth: 190,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} defaultValue="" maxLength={20} />;
        }
        return params.value;
      },
    },
  ];
};
