const REPORT_ROUTES_LIST = {
  YEARLY: "/report/yearly-forecast-report",
  MONTHLY: "/report/monthly-forecast-report",
  DAILY_INVENTORY: "/report/daily-inventory-management-report",
  DELIVERY_PLAN: "/report/delivery-plan-actual-report",
  STOCK_IN_OUT: "/report/stock-in-out-report",
  DISCREPANCY: "/report/discrepancy-report",
  R_PACKAGE_CUSTOMS: "/report/rpackage-customs-balance-report",
  RETURNING: "/report/returning-punctuality-report",
  STOCK_SUMMARY: "/report/stock-summary-report",
  AGING_STOCK_USAGE: "/report/aging-stock-usage-raw-data-report",
  TRANSACTION_RAW_DATA: "/report/transaction-raw-data-report",
  R_PACKAGE_CATALOG: "/report/rpackage-catalog-report",
  STOCK_VISUALIZATION: "/report/stock-visualization-report",
  AGING_STOCK: "/report/aging-stock-report",
  AVERAGE_LEAD: "/report/average-lead-time-report",
};

const FORM_DEFAULT_YEARLY = {};

const FORM_DEFAULT_MONTH = {
  reportId: "1",
  countryId: "",
  warehouseId: "",
  month: "",
  dataTypeId: "",
  rPkgTypeOwner: "",
  rPkgTypeCategory: "",
  rPkgType: "",
};

const FORM_DEFAULT_DAILY_INVENTORY = {
  reportId: "1",
  countryId: "",
  warehouseId: "",
  month: "",
  dataTypeId: "",
  rPkgTypeOwner: "",
  rPkgTypeCategory: "",
  rPkgType: "",
};

const FORM_DEFAULT_DELIVERY_PLAN = {
  reportId: "1",
  rPkgMaker: "",
  rPkgTypeOwner: "",
  rPkgType: "",
  monthForm: "",
  monthTo: "",
  poNumber: "",
};

const FORM_DEFAULT_STOCK_IN_OUT = {
  reportId: "1",
  rPkgTypeOwner: "",
  rPkgType: "",
  monthForm: "",
  monthTo: "",
  locationFrom: "",
  locationTo: "",
};

const FORM_DEFAULT_DISCREPANCY = {};

const FORM_DEFAULT_R_PACKAGE_CUSTOMS = {
  reportId: "1",
  rPkgTypeOwner: "",
  rPkgType: "",
  destinationCountry: "",
  destinationCompany: "",
  sourceCountryId: "",
  sourceCompanyId: "",
  loadDate: "",
  statusId: "",
  numberOfRecords: "",
};

const FORM_DEFAULT_RETURNING = {
  reportId: "1",
  rPkgTypeOwner: "",
  rPkgType: "",
  monthForm: "",
  monthTo: "",
};

const FORM_DEFAULT_STOCK_SUMMARY = {
  reportId: "1",
  rPkgTypeCategory: "",
  rPkgTypeOwner: "",
  rPkgType: "",
  reportType: "",
};

const FORM_DEFAULT_AGING_STOCK_USAGE = {
  reportId: "1",
  rPkgTypeOwner: "",
  rPkgType: "",
  currentLocation: "",
  currentStatus: "",
  deadStockPeriodFrom: "",
  deadStockPeriodTo: "",
  usageTimesFrom: "",
  usageTimesTo: "",
};

const FORM_DEFAULT_TRANSACTION_RAW_DATA = {
  reportId: "1",
  transactionDateFrom: "",
  transactionDateTo: "",
  rPkgTypeOwner: "",
  rPkgType: "",
  transactionMode: "",
  transactionCategory: "",
  locationFrom: "",
  locationTo: "",
  barcodeId: "",
};

const FORM_DEFAULT_R_PACKAGE_CATALOG = {
  reportId: "1",
  rPkgTypeOwner: "",
  rPkgTypeCategory: "",
};

const FORM_DEFAULT_STOCK_VISUALIZATION = {
  reportId: "1",
  dateFrom: "",
  dateTo: "",
  location: "",
  rPkgTypeOwner: "",
  rPkgTypeCategory: "",
  rPkgType: "",
};

const FORM_DEFAULT_AGING_STOCK = {
  reportId: "1",
  location: "",
  rPkgTypeOwner: "",
  rPkgType: "",
  rPkgStatus: "",
  packStatus: "",
  maximumAgingDay: "",
  wip1Empty: "",
  wip2Empty: "",
  fgEmpty: "",
  fgPack: "",
  ngEmpty: "",
  ngPack: "",
  total: "",
};

const FORM_DEFAULT_AVERAGE_LEAD = {
  reportId: "1",
  reportBasis: "",
  locationType: "",
  locationGroup: "",
  operation: "",
  routePointName: "",
  rpkgStatusFrom: "",
  rpkgStatusTo: "",
  packStatusFrom: "",
  packStatusTo: "",
  periodFrom: "",
  periodTo: "",
  rPkgTypeOwner: "",
  rPkgType: "",
  transactionCategory: "",
};

export {
  REPORT_ROUTES_LIST,
  FORM_DEFAULT_YEARLY,
  FORM_DEFAULT_MONTH,
  FORM_DEFAULT_DAILY_INVENTORY,
  FORM_DEFAULT_DELIVERY_PLAN,
  FORM_DEFAULT_STOCK_IN_OUT,
  FORM_DEFAULT_DISCREPANCY,
  FORM_DEFAULT_R_PACKAGE_CUSTOMS,
  FORM_DEFAULT_RETURNING,
  FORM_DEFAULT_STOCK_SUMMARY,
  FORM_DEFAULT_AGING_STOCK_USAGE,
  FORM_DEFAULT_TRANSACTION_RAW_DATA,
  FORM_DEFAULT_R_PACKAGE_CATALOG,
  FORM_DEFAULT_STOCK_VISUALIZATION,
  FORM_DEFAULT_AGING_STOCK,
  FORM_DEFAULT_AVERAGE_LEAD,
};
