import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Select, FormControl, InputLabel, styled } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import usePreventScrollOnFocus from "./PreventScrollOnFocus";
import InputBase from "@mui/material/InputBase";

const StyledSelectField = styled(InputBase)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    padding: "4px 8px",
    "&:focus": {
      border: `1.5px solid black !important`,
      borderRadius: ".3rem",
      color: "#000",
    },
  },
}));

const InputEditTableDropDown = ({
  value,
  memu,
  field,
  id,
  disabled = false,
  setValue = undefined,
  className,
  required,
  placeholder,
  defaultValue = "",
  isShow = true,
  autoFocus = false,
  isCurrency = false,
  onSearchAdd = false,
  clearValueWithoutChange = [],
  
}) => {
  const apiRef = useGridApiContext();
  const ref = React.useRef();
  const preventScrollOnFocus = usePreventScrollOnFocus();

  const [isSmallScreen] = useState(window.innerHeight <= 740);

  const handleValueChange = event => {
    if (clearValueWithoutChange?.length) {
      clearValueWithoutChange.forEach((key) => {
          apiRef.current.setEditCellValue({ id, field: key, value: null });
      });
    }

    const newValue = event.target.value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    if (setValue) setValue(newValue);

  };

  return (
    isShow && (
      <div style={{ margin: "auto", width: "90%" }}>
        <FormControl style={{ width: "100%" }}>
          <InputLabel
            id="test-select-label"
            shrink={false}
            size="small"
            variant="outlined"
            style={{
              left: isCurrency ? "10%" : "40px",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}>
            {memu ? (!memu?.map(item => item.key).includes(value) ? placeholder : "") : placeholder}
          </InputLabel>
          <Select
            ref={ref}
            sx={{
              minWidth: "100%",
              backgroundColor: "white",
              "&:focus": {
                border: "1px solid #000 !important",
              },
            }}
            value={value}
            autoFocus={autoFocus}
            onChange={handleValueChange}
            onFocus={preventScrollOnFocus}
            disabled={disabled}
            className={className}
            required={required}
            MenuProps={{
              PaperProps: {
                style: {
                  overflowY: "scroll",
                  maxHeight: `${memu?.length > 30 ? "40%" : "60%"}`,
                  transform: onSearchAdd ? (isSmallScreen ? "translateY(50%)" : null) : null,
                },
              },
              onFocus: preventScrollOnFocus,
              disableScrollLock: true,
            }}
            input={<StyledSelectField />}>
            <MenuItem value={defaultValue}>
              <span>{placeholder}</span>
            </MenuItem>
            {
              memu && memu.map((val, index) => (
                <MenuItem key={index} value={val.key}>
                  {val.value}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </div>
    )
  );
};

export default InputEditTableDropDown;
