export const COLUMN_ON_DEMAND_BATCH = {
  NO: "No.",
  APP_ID: "Application ID",
  PROJECT_CODE: " Project Code",
  BATCH_ID: "Batch ID",
  BATCH_NAME: "Batch Name",
  END_POINT: "Endpoint",
  CREATE_BY: "Create By",
  CREATE_DATE: "Create Date",
  UPDATE_DATE: "Update Date",
  UPDATE_BY: "Update By",
  RUNNING_COUNT: "Running Count",
  RUN_AS: "Run As",
};
