import { Grid } from "@mui/material";

const Logo = () => {
  return (
    <Grid container spacing={2} width="100%" height="125%" style={{ margin: "auto" }}>
      <Grid item xs={12} sx={{ justifyContent: "center", alignContent: "center" }}>
        <center>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/polygon.svg`}
            alt="Polygon Logo"
            width={500}
            height={450}
          />
          {/* <img src={`${process.env.PUBLIC_URL}/polygon.gif`} alt="Polygon Logo" width={700} height={650} /> */}
        </center>
      </Grid>
    </Grid>
  );
};

export default Logo;
