export const getFunctionIDTemplate = dataType => {
  switch (dataType) {
    case "PK":
      return "BDN920A0";
    case "PD":
      return "BDN92090";
    case "PT":
      return "BDN920H0";
    case "TR":
      return "BDN920I0";
    case "RC":
      return "BDN920J0";
    default:
      return "";
  }
};
