export const UPLOAD_STATUS = {
  SUCCESS: "Success",
  WARNING: "Warning",
  ERROR: "Error",
  DUPLICATE: "Duplicate",
  PROCESSING: "Processing",
};
export const GENERATE_STATUS = {
  GENERATED: "Generated",
  ERROR: "Error",
  WAIT: "Waiting",
  ON_PROCESS: "Process",
};
export const APMC_COLUMNS = {
  NO: "No.",
  IMPORT_EXPORT: "Importer/ Exporter",
  FORECAST_MONTH: "Forecast Month (MM/YYYY)",
  DATA_TYPE: "Data Type",
  MODE: "Mode",
  FILE_NAME: "File Name",
  UPLOAD_STATUS: "Upload Status",
  UPLOAD_DATE: "Upload Date (DD/MM/YYYY)",
  UPLOAD_BY: "Upload By",
  GENERATE_STATUS: "Generate Status",
  GENERATE_DATE: "Generate Date (DD/MM/YYYY)",
  GENERATE_BY: "Generate By",
};
export const TEMPLATE_NAME = {
  PRODUCTION: "Production",
  PACKING: "Packing",
  RETURN_SHIPPING: "Return Shipping",
  TRANSFERRING: "Transferring",
  RECEIVING: "Receiving",
};
