import React from "react";

import InputEditTableText from "components/UI/InputEditTableText";
import { formatDate } from "./date";
import { InputEditTableCurrency, InputEditTableDate, InputEditTableDropDown } from "components/UI";
import InputEditTableCheckbox from "components/UI/InputEditTableCheckbox";
import { findObject } from "./find-object";

export const columEditTable = ({
  field,
  flex,
  headerName = "",
  sortable = false,
  editable = false,
  width = undefined,
  minWidth = undefined,
  type,
  maxLength = 30,
  maxLimit = 10000,
  decimalScale = 0,
  required = false,
  align = "center",
  headerAlign = "center",
  disabled = false,
  inputOption = {},
  rule = [],
  onFocus = false,
  regularExp,
  renderCell,
}) => {
  const columEditType = {
    text: params => (
      <InputEditTableText
        {...params}
        required={required}
        maxLength={maxLength}
        style={{ width: "100%" }}
        {...inputOption}
        // regularExp={regularExp}
        id={field}
      />
    ),
    date: params => <InputEditTableDate {...params} required={required} disabled={disabled} />,
    checkbox: params => <InputEditTableCheckbox {...params} disabled={disabled} />,
    dropdown: params => (
      <InputEditTableDropDown
        {...params}
        required={required}
        disabled={disabled}
        memu={inputOption.memu}
        placeholder={inputOption.placeholder}
        autoFocus={onFocus}
      />
    ),
    number: params => (
      <InputEditTableCurrency
        style={{ width: "100%", textAlign: "right" }}
        {...params}
        required={required}
        maxLimit={maxLimit}
        decimalScale={decimalScale}
        disabled={disabled}
        autoFocus={onFocus}
      />
    ),
  };

  const columType = params => {
    switch (type) {
      case "text":
        return params?.value;
      case "date":
        return formatDate(params.value, "DD/MM/YYYY");
      case "checkbox":
        return <InputEditTableCheckbox {...params} disabled />;
      case "dropdown":
        // eslint-disable-next-line no-case-declarations
        const name =
          params.value &&
          findObject({
            data: inputOption.data,
            value: params.value,
            property: inputOption.property,
            field: inputOption.field,
          });
        return <p>{name}</p>;

      default:
        return params?.value;
    }
  };

  let result = {
    field: field,
    sortable: sortable,
    editable: editable,
    width: width,
    minWidth: minWidth,
    flex: flex,
    align: align,
    headerAlign: headerAlign,
    rule: rule,
    renderHeader: () => (headerName !== "" ? headerName : <div></div>),
    renderEditCell: params => {
      return columEditType[type](params);
    },
    renderCell: params => {
      if (renderCell) {
        return renderCell(params);
      }
      return columType(params);
    },
  };

  return result;
};
