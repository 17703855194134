import { Grid } from "@mui/material";
import { DropDown } from "components/UI";
import React, { Fragment } from "react";

export default function HeaderSection({ form, setForm, locationList, dataTypeList }) {
  return (
    <Fragment>
      <Grid container spacing={1} sx={{ alignItems: "center" }}>
        <Grid item xs={12}>
          <strong>Generate and Send Interface File to Plant Server</strong>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={2}>
          <strong>Plant Server Location :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="dropdown-location"
            value={form?.locationId}
            onChange={e => {
              setForm(prev => ({ ...prev, locationId: e.target.value }));
            }}
            defaultValue=""
            autoFocus={true}
            placeholder="<All>"
            menu={locationList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1}>
          <strong>Data Type :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="dropdown-datatype"
            value={form?.dataTypeId}
            onChange={e => setForm(prev => ({ ...prev, dataTypeId: e.target.value }))}
            defaultValue=""
            placeholder="<All>"
            menu={dataTypeList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
