import React, { useState } from "react";
import { Grid, Divider } from "@mui/material";
import { InputButton, InputDropDown, InputText } from "components/UI";
import { useGetPrintAs, useRePrint } from "service/barcodeprint";
import { userProfile } from "constant";
import { responseDownloadFileErrors, responseErrors } from "utils";
import InputCurrency from "components/UI/InputCurrency";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { waitAndPrintBarcode } from "shared/services/common-dowload/download-helper-service";

const RePrintMode = ({ mode, setMsgAlert, setMsgError, functionId }) => {
  const [form, setForm] = React.useState({
    mode: mode,
    printAs: "",
    barcodeId: "",
    numberOfCopy: 1,
  });

  const [disabledPrintBarcodeBtn, setDisabledPrintBarcodeBtn] = useState(false);
  // api
  const { data: printAsData } = useGetPrintAs({
    dataOwner: userProfile.dataOwner,
  });
  const confirmDialogCtx = useConfirmDialogContext();
  const createDataAsync = useRePrint();
  const { startLoading, stopLoading } = useLoadingContext();

  // handle print
  const handlePrint = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const saveConfirm = await confirmDialogCtx.success({
        type: "confirmPrint",
      });
      if (!saveConfirm) {
        return;
      }
      const item = printAsData?.find(val => val.plantCd === form.printAs);

      const body = {
        dataOwner: userProfile.dataOwner,
        printType: form?.mode,
        printAs: !item ? "" : item?.companyCd,
        // numOfCopy: form.numberOfCopy,
        numOfCopy: form.numberOfCopy.toString(),
        barcodeId: form.barcodeId,
        //TODO fixed printLocation: "TMATH-EM",
        printLocation: userProfile.comAbbr,
        user: {
          userInfo: {
            userId: userProfile.userId,
            email: userProfile.mail,
          },
        },
      };
      startLoading();
      const response = await createDataAsync(body);
      waitAndPrintBarcode(60, response, setMsgError, stopLoading);
      // stopLoading();

      // setDisabledPrintBarcodeBtn(true);
      return;
    } catch (error) {
      stopLoading();
      console.log(error);
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  const handleClear = () => {
    setMsgAlert([]);
    setMsgError([]);
    setForm({
      mode: mode,
      printAs: "",
      barcodeId: "",
      numberOfCopy: 1,
    });
    setDisabledPrintBarcodeBtn(false);
  };
  return (
    <>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{margin: "auto 0px", width: "325px"}}>*Print As : </strong>
        <InputDropDown
          id="select_printAs"
          value={form.printAs}
          focus={true}
          onChange={e =>
            setForm(old => ({
              ...old,
              printAs: e.target.value,
            }))
          }
          memu={printAsData?.map(val => ({
            key: val.plantCd,
            value: val.impExpCd,
          }))}
          placeholder="<Select>"
          defaultValue=""
          required
        />
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{margin: "auto 0px", width: "325px"}}>*R-Module Barcode ID : </strong>
        <InputText
          fullWidth
          id="input_barcodeId"
          value={form.barcodeId}
          onChange={e =>
            setForm(old => ({
              ...old,
              barcodeId: e.target.value.match(/^[a-zA-Z0-9]*$/) ? e.target.value?.toUpperCase() : old.barcodeId,
            }))
          }
          required
          maxLength={14}
        />
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{margin: "auto 0px", width: "325px"}}>*Number of Copy : </strong>
        <InputCurrency
          fullWidth
          id="input_numOfCopy"
          value={form?.numberOfCopy}
          onChange={e =>
            setForm(old => ({
              ...old,
              numberOfCopy: e.value,
            }))
          }
          maxLimit={10}
          required
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end" marginTop="24px">
        <InputButton
          value="Print Barcode"
          onClick={handlePrint}
          disabled={disabledPrintBarcodeBtn}
          name={`${functionId}PrintBarcode`}
        />
        <InputButton
          value="Clear"
          onClick={handleClear}
          onKeyDown={e => {
            if (e.key === "Tab") {
              e.preventDefault();
              document.getElementById("select_printAs").focus();
            }
          }}
          name={`${functionId}Clear`}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} />
      </Grid>
    </>
  );
};

export default RePrintMode;
