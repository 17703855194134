import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Divider,
  Paper,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  Button,
} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import dayjs from "dayjs";
import { AlertMessage, InputButton, InputText } from "components/UI";
import { getLocalDate } from "utils/init-config-date";

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

export function ModalLogistic({ open = true, setOpen, initialData = [] }) {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([0, 1, 2, 3]);
  const [right, setRight] = React.useState([4, 5, 6, 7]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const [msgError, setMsgError] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveClick = () => {
    console.log("handleSaveClick");
  };
  const handleCancelClick = () => {
    console.log("handleCancelClick");
  };

  const customList = items => (
    <Paper sx={{ overflow: "auto", height: "350px", border: "1px solid #3333" }}>
      <List dense component="div" role="list">
        {items.map(value => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`List item ${value + 1}`} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth={"lg"} open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            <Box className="area-row-header">
              <div className="btn-close" onClick={handleClose}>
                Close
              </div>
              <div>User: UserPNG4</div>
            </Box>
            <Box className="area-row-header">
              <div>WDN91051 : Logistic Route Master Maintenance Sub Screen</div>
              <div>{getLocalDate().format("DD MMM YYYY HH:mm")}</div>
            </Box>
          </DialogContentText>
          {/* AlertMessage */}
          <AlertMessage type={"warning"} message={msgError} />

          <Box className="box">
            <Grid
              container
              spacing={2}
              sx={{
                alignItems: "center",
              }}>
              <Grid item xs={2}>
                <strong>* Logistic Route Name</strong>
              </Grid>
              <Grid item xs={9}>
                <InputText required fullWidth />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box className="box">
            <Grid
              container
              sx={{
                alignItems: "center",
              }}>
              <Grid item xs={2}>
                <strong>Country (From): </strong>
              </Grid>
              <Grid item xs={3}>
                <InputText disabled fullWidth style={{ background: "#f8f8f8" }} />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <strong>Start Route Point Name: </strong>
              </Grid>
              <Grid item xs={3}>
                <InputText disabled fullWidth style={{ background: "#f8f8f8" }} />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <strong>Country (To): </strong>
              </Grid>
              <Grid item xs={3}>
                <InputText disabled fullWidth style={{ background: "#f8f8f8" }} />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <strong>End Route Point Name: </strong>
              </Grid>
              <Grid item xs={3}>
                <InputText disabled fullWidth style={{ background: "#f8f8f8" }} />
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Box>
          <Box className="box">
            <Grid
              container
              sx={{
                alignItems: "center",
              }}>
              <Grid item xs={5}>
                <p>
                  <strong>Available Paths :</strong>
                </p>
                {customList(left)}
              </Grid>
              <Grid item xs={1} sx={{ textAlign: "center" }}>
                {/* <Button
                  color="secondary"
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllRight}
                  disabled={left.length === 0}
                  aria-label="move all right"
                >
                  ≫
                </Button> */}
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right">
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left">
                  &lt;
                </Button>
                {/* <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                  aria-label="move all left"
                >
                  ≪
                </Button> */}
              </Grid>
              <Grid item xs={5}>
                <p>
                  <strong>Selected Paths :</strong>
                </p>
                {customList(right)}
              </Grid>
              <Grid item xs={1} sx={{ textAlign: "center" }}>
                <Button sx={{ my: 0.5 }} variant="outlined" size="small">
                  &uarr;
                </Button>
                <Button sx={{ my: 0.5 }} variant="outlined" size="small">
                  &darr;
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box className="box">
            <Grid container>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <InputButton value={"Save"} onClick={handleSaveClick} size="small" />
                <InputButton value={"Cancel"} onClick={handleCancelClick} size="small" />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
