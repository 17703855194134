import React, { Fragment, useEffect, useState } from "react";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { useGridApiContext } from "@mui/x-data-grid";
import { Button, Typography, styled, IconButton, Box } from "@mui/material";
import { CloudUpload, Close, AttachFile } from "@mui/icons-material";
import AlertMessage from "../AlertMessage";
const StyledInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
  display: "none",
  marginLeft: 1,
});
const StyledLabel = styled(Typography)({
  padding: "4px 8px",
  // fontSize: "13px",
  whiteSpace: "nowrap",

  textOverflow: "ellipsis",
  overflow: "hidden",
  height: "1.3rem",
  color: "#000",
});
export default function InputEditableUploadFile({
  id,
  fileType,
  params,
  field,
  onChange,
  required,
  className,
  style,
  value,
  type,
  autoFocus = false,
  disabled = false,
  maxLength = 250,
  setValue = undefined,
  uploadProcess = async () => {},
  ...props
}) {
  const LENGTH_TWENTY = 20;
  const confirmDialogCtx = useConfirmDialogContext();
  const apiRef = useGridApiContext();

  const [isHaveFile, setIsHaveFile] = useState(false);
  const [filename, setFilename] = useState("");

  const handleFileUpload = async event => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      if (setValue) setValue(file.name);
      const result = await uploadProcess(field, file, maxLength);
      if (result) {
        setFilename(file.name);
        setIsHaveFile(true);
        apiRef.current.setEditCellValue({ id: id, field: field, value: file.name });
      }
    } catch (error) {
      console.error(error);
      return <AlertMessage type={"warning"} message={error} />;
    }
  };
  const handleDeleteClick = async () => {
    try {
      const saveConfirm = await confirmDialogCtx.success({
        type: "confirmDelete",
      });
      if (!saveConfirm) {
        return;
      }
      const result = await uploadProcess({ field, file: {} });
      if (result) {
        setFilename("");
        setIsHaveFile(false);
        apiRef.current.setEditCellValue({ id, field, value: "" });
      }
    } catch (error) {
      console.error(error);
      return <AlertMessage type={"warning"} message={error} />;
    }
  };
  useEffect(() => {
    if (filename) {
      setIsHaveFile(true);
    } else {
      setIsHaveFile(false);
      setFilename("");
    }
  }, [filename, isHaveFile]);

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          alignItems: "center",
          ...style,
        }}>
        {!isHaveFile && (
          <Button
            id="label-upload-file"
            color="secondary"
            aria-label="label-upload-file"
            component="label"
            variant="contained"
            size="small"
            disabled={disabled}
            startIcon={<CloudUpload />}>
            Upload
            <StyledInput
              id="input-upload-file"
              type="file"
              accept={fileType}
              onChange={handleFileUpload}
              disabled={disabled}
              className={className}
            />
          </Button>
        )}
        {isHaveFile ? (
          <StyledLabel
            id="label-file-name"
            aria-label="label-file-name"
            component="span"
            disabled={disabled}
            required={required}
            sx={{
              mt: "3px",
              borderRadius: "4px",
              maxWidth: `${filename?.length < LENGTH_TWENTY ? "80%" : "50%"}`,
            }}>
            <AttachFile sx={{ mr: 0.5 }} fontSize="inherit" /> {filename}
          </StyledLabel>
        ) : (
          <StyledLabel
            id="label-no-file"
            aria-label="label-file-name"
            component="span"
            disabled={disabled}
            required={required}
            sx={{
              mt: "2px",
              padding: "5.5px 12.5px",
              borderRadius: "1px 5px 5px 1px",
              maxWidth: "75%",
            }}>
            'No Choose file'
          </StyledLabel>
        )}
        {isHaveFile && (
          <IconButton
            id="button-delete-file"
            variant="text"
            color="secondary"
            size="small"
            disabled={disabled}
            onClick={handleDeleteClick}
            sx={{ borderRadius: 10, size: "10px" }}>
            <Close />
          </IconButton>
        )}
      </Box>
    </Fragment>
  );
}
