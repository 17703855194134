import { Grid } from "@mui/material";
import { DatePickerField, DropDown } from "components/UI";
import { FORM_DEFAULT_DAILY_INVENTORY } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import {
  useExcelDownloadOnlineMutation,
  useReportCommonGetCountryListQuery,
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
  useReportCommonGetWarehouseListQuery,
  useReportCommonRPackageCategoryQuery,
  useExcelDownloadOnlineJavaMutation,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseDownloadFileErrors } from "utils";

import { useLoadingContext } from "shared/contexts/LoadingContext";
import { ResponseText } from "utils/5xxResponseText";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";

export default function DailyInventoryManagementReport(props) {
  const [form, setForm] = useState(FORM_DEFAULT_DAILY_INVENTORY);

  const { data: countryList, failureReason: countryFailureReason } = useReportCommonGetCountryListQuery(
    !form?.countryId ? "" : form?.countryId
  );
  const {
    data: warehouseList,
    failureReason: warehouseFailureReason,
    refetch,
  } = useReportCommonGetWarehouseListQuery({
    countryCd: form?.countryId,
  });

  const { data: rPkgOwnerList, failureReason: rPkgOwnerFailureReason } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgCategoryList, failureReason: rPkgCategoryFailureReason } = useReportCommonRPackageCategoryQuery();
  const { data: rPkgTypeList } = useReportCommonGetRPackageTypeQuery({
    rPackageOwner: userProfile.rPkgOwnerCompAbbr,
  });

  useEffect(() => {
    let message = [];
    if (countryFailureReason?.response?.status >= 404) {
      message.push(`Country Owner List: ${ResponseText[countryFailureReason?.response?.status]}`);
    }
    if (warehouseFailureReason?.response?.status >= 404) {
      message.push(`Warehouse List: ${ResponseText[warehouseFailureReason?.response?.status]}`);
    }
    if (rPkgOwnerFailureReason?.response?.status >= 404) {
      message.push(`R-Package Owner List: ${ResponseText[rPkgOwnerFailureReason?.response?.status]}`);
    }
    if (rPkgCategoryFailureReason?.response?.status >= 404) {
      message.push(`R-Package Category List: ${ResponseText[rPkgCategoryFailureReason?.response?.status]}`);
    }
    props.setMsgServerErrorAlert(message);
  }, [
    countryFailureReason?.response?.status,
    warehouseFailureReason?.response?.status,
    rPkgOwnerFailureReason?.response?.status,
    rPkgCategoryFailureReason?.response?.status,
    props,
  ]);

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  // const generateReport = useExcelDownloadOnlineJavaMutation();
  const generateReport = useExcelDownloadOnlineMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      setDisabledSubmitBtn(true);
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        userId: userProfile.userId, //
        displayName: userProfile.displayName,
        dataOwner: userProfile.dataOwner, //
        userCompAbbr: userProfile.dataOwner, //
        country: form?.countryId, //
        warehouse: form?.warehouseId,
        rPackageOwner: form?.rPkgTypeOwner,
        rPackageType: form?.rPkgType,
        month: form?.month,
        rPackageCategory: form?.rPkgTypeCategory,
      };
      const pathReport = "generate-daily-inventory-management-report";
      startLoading();
      const response = await generateReport(body, pathReport);
      waitAndDownloadExcel(60, response.data, props.setMsgError, stopLoading);
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      // if (error?.response?.status >= 500) {
      //   props.setMsgServerErrorAlert([ResponseText[error?.response?.status]]);
      // } else {
      const msgError = responseDownloadFileErrors(error);
      props.setMsgError(msgError);
      // }
      setDisabledSubmitBtn(false);
      stopLoading();
    }
  };

  useEffect(() => {
    document.getElementById("select-country").focus();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{margin: "auto 0px", width: "300px" }}>*Report : </strong>
        <DropDown
          id="select-report"
          fullWidth
          required
          defaultValue=""
          placeholder="<Select>"
          menu={[{ key: "1", value: "Daily Inventory Management Report" }]}
          value={form?.reportId}
          disabled
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{margin: "auto 0px", width: "300px" }}>*Country : </strong>
        <DropDown
          id="select-country"
          fullWidth
          required
          value={form?.countryId}
          defaultValue=""
          placeholder="<Select>"
          // autoFocus={true}
          menu={countryList?.map(val => ({
            key: val.cd,
            value: val.value,
          }))}
          onChange={async e => {
            setForm(old => ({
              ...old,
              countryId: e.target.value,
              warehouseId: "",
            }));
            // refetch();
          }}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{margin: "auto 0px", width: "300px" }}>*Warehouse : </strong>
        <DropDown
          id="select-warehouse"
          fullWidth
          required
          value={form?.warehouseId}
          defaultValue=""
          placeholder="<Select>"
          // autoFocus={true}
          menu={warehouseList?.map(val => ({
            key: `${val.companyAbbr}:${val.impExpCd}`,
            value: `${val.companyAbbr}:${val.impExpCd}`,
          }))}
          onChange={e =>
            setForm(old => ({
              ...old,
              warehouseId: e.target.value,
            }))
          }
          disabled={!form?.countryId}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{margin: "auto 0px", width: "300px" }}>*Month : </strong>
        <DatePickerField
          views={["year", "month"]}
          format="MM/YYYY"
          mask="MM/YYYY"
          fullWidth={true}
          value={form?.month}
          onChange={e =>
            setForm(old => ({
              ...old,
              month: e,
            }))
          }
          required
          autoFocus={false}
        />
      </Grid>
      <Grid item xs={1} style={{margin: "auto 0px"}}>
        (MM/YYYY)
      </Grid>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{margin: "auto 0px", width: "300px" }}>*R-Package Owner : </strong>
        <DropDown
          id="select-rpackage-owner"
          fullWidth
          required
          value={form?.rPkgTypeOwner}
          defaultValue=""
          placeholder="<Select>"
          // autoFocus={true}
          menu={rPkgOwnerList?.map(val => ({
            key: val.rPkgOwnerCompAbbr,
            value: val.rPkgOwnerCompAbbr,
          }))}
          onChange={e =>
            setForm(old => ({
              ...old,
              rPkgTypeOwner: e.target.value,
              rPkgTypeCategory: "",
              rPkgType: "",
            }))
          }
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{margin: "auto 0px", width: "300px" }}>*R-Package Category : </strong>
        <DropDown
          id="select-rpackage-category"
          fullWidth
          required
          value={form?.rPkgTypeCategory}
          defaultValue=""
          placeholder="<Select>"
          // autoFocus={true}
          rest={{
            label: "label",
          }}
          menu={rPkgCategoryList?.map(val => ({
            key: val.cd,
            value: val.value,
          }))}
          onChange={e =>
            setForm(old => ({
              ...old,
              rPkgTypeCategory: e.target.value,
              rPkgType: "",
            }))
          }
          disabled={!form.rPkgTypeOwner}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{margin: "auto 0px", width: "300px" }}>R-Package Type : </strong>
        <DropDown
          id="select-rpackage-type"
          fullWidth
          value={form?.rPkgType}
          defaultValue=""
          placeholder="<All>"
          // autoFocus={true}
          menu={rPkgTypeList?.map(val => ({
            key: val.rPkgType,
            value: val.rPkgType,
          }))}
          onChange={e =>
            setForm(old => ({
              ...old,
              rPkgType: e.target.value,
            }))
          }
          disabled={!form.rPkgTypeCategory}
        />
      </Grid>

      <ButtonAction
        handleDownload={handleDownload}
        setFrom={setForm}
        defaultFrom={FORM_DEFAULT_DAILY_INVENTORY}
        disabled={disabledSubmitBtn}
        setDisabled={setDisabledSubmitBtn}
        clearMsg={() => {
          props.setMsgAlert([]);
          props.setMsgError([]);
        }}
        firstField={"select-country"}
      />
    </Grid>
  );
}
