import { userProfile } from "constant";
import { API_ON_DEMAND_BATCH } from "shared/constants";

const DEFAULT_ON_DEMAND_BATCH = {
  [API_ON_DEMAND_BATCH.DATA_OWNER]: userProfile?.dataOwner,
  [API_ON_DEMAND_BATCH.PROJECT_CODE]: "",
  [API_ON_DEMAND_BATCH.BATCH_ID]: "",
  [API_ON_DEMAND_BATCH.BATCH_NAME]: "",
  [API_ON_DEMAND_BATCH.CREATE_BY]: "",
  [API_ON_DEMAND_BATCH.CREATE_DATE]: "",
  [API_ON_DEMAND_BATCH.UPDATE_BY]: "",
  [API_ON_DEMAND_BATCH.UPDATE_DATE]: "",
  [API_ON_DEMAND_BATCH.END_POINT]: "",
  [API_ON_DEMAND_BATCH.USER_ID]: userProfile?.userId,
};
export { DEFAULT_ON_DEMAND_BATCH };
