import { LogLevel } from "@azure/msal-browser";
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

// const authority = "https://login.microsoftonline.com/f6cb9261-8a3a-4261-979f-50eeb15b4984";

export const msalConfig = {
  auth: {
    // clientId: "1146a4b9-3a81-42c7-8eed-bd6ff109c512", // This is the ONLY mandatory field that you need to supply.
    // clientId: "c94bd170-a037-404c-b47f-29251bc83480", // This is the ONLY mandatory field that you need to supply.
    clientId: process.env.REACT_APP_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    // authority: process.env.REACT_APP_AUTHORITY, // Defaults to "https://login.microsoftonline.com/common"
    authority: `${process.env.REACT_APP_LOGIN_URL}/${process.env.REACT_APP_TENANT_ID}`, // Defaults to "https://login.microsoftonline.com/common"
    // authority: `https://login.microsoftonline.com/f6cb9261-8a3a-4261-979f-50eeb15b4984`, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
    // postLogoutRedirectUri: "https://myapplications.microsoft.com?tenant=f6cb9261-8a3a-4261-979f-50eeb15b4984&userid=9ed18e70-53bb-4717-a34b-d92ce82afc3e"
  },
  cache: {
    cacheLocation: "cookieStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    // secureCookies: true, // Ensures cookies are only sent over HTTPS
    // cookieSameSite: "Strict", // Controls cookie behavior
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [process.env.REACT_APP_SCOPES],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
