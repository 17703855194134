import { Grid } from "@mui/material";
import { DatePickerField, DropDown } from "components/UI";
import { FORM_DEFAULT_R_PACKAGE_CUSTOMS } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import {
  useExcelDownloadMutation,
  useExcelDownloadMutationInquiry,
  useExcelDownloadOnlineMutation,
  useGetAvailableStatusListQuery,
  useGetDestinationCompanyListQuery,
  useGetDestinationCountryListQuery,
  useGetNumOfRecordListQuery,
  useGetSourceCompanyListQuery,
  useGetSourceCountryListQuery,
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
import axios from "axios";
import { getLocalDate } from "utils/init-config-date";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { ResponseText } from "utils/5xxResponseText";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";

export default function RpackageCustomsBalanceReport(props) {
  const [form, setForm] = useState(FORM_DEFAULT_R_PACKAGE_CUSTOMS);

  const { data: rPkgOwnerList, failureReason: rPkgOwnerFailureReason } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgTypeList, failureReason: rPkgTypeFailureReason } = useReportCommonGetRPackageTypeQuery({
    rPackageOwner: userProfile.dataOwner,
  });
  const { data: destinationList, failureReason: destinationFailureReason } = useGetDestinationCountryListQuery();
  const { data: sourceList, failureReason: sourceFailureReason } = useGetSourceCountryListQuery();
  const { data: statusList, failureReason: statusFailureReason } = useGetAvailableStatusListQuery();
  const { data: numList, failureReason: numFailureReason } = useGetNumOfRecordListQuery();
  const {
    data: sourceCompanyList,
    failureReason: sourceCompanyFailureReason,
    refetch: sourceCompanyRefetch,
  } = useGetSourceCompanyListQuery({
    countryCd: form?.sourceCountryId,
    dataOwner: form?.rPkgTypeOwner,
  });
  const {
    data: destinationCompanyList,
    failureReason: destinationCompanyFailureReason,
    refetch: destinationCompanyRefetch,
  } = useGetDestinationCompanyListQuery({
    countryCd: form?.destinationCountry,
    dataOwner: form?.rPkgTypeOwner,
  });

  useEffect(() => {
    sourceCompanyRefetch();
  }, [form.sourceCountryId, form?.rPkgTypeOwner, sourceCompanyRefetch]);

  useEffect(() => {
    destinationCompanyRefetch();
  }, [form.destinationCountry, form?.rPkgTypeOwner, destinationCompanyRefetch]);

  useEffect(() => {
    let message = [];
    if (rPkgOwnerFailureReason?.response?.status >= 404) {
      message.push(`R-Package Owner List: ${ResponseText[rPkgOwnerFailureReason?.response?.status]}`);
    }
    if (rPkgTypeFailureReason?.response?.status >= 404) {
      message.push(`R-Package Type List: ${ResponseText[rPkgTypeFailureReason?.response?.status]}`);
    }
    if (destinationFailureReason?.response?.status >= 404) {
      message.push(`Destination Company List: ${ResponseText[destinationFailureReason?.response?.status]}`);
    }
    if (sourceFailureReason?.response?.status >= 404) {
      message.push(`Source Company List: ${ResponseText[sourceFailureReason?.response?.status]}`);
    }
    if (statusFailureReason?.response?.status >= 404) {
      message.push(`Status List: ${ResponseText[statusFailureReason?.response?.status]}`);
    }
    if (numFailureReason?.response?.status >= 404) {
      message.push(`Number of Records List: ${ResponseText[numFailureReason?.response?.status]}`);
    }
    if (sourceCompanyFailureReason?.response?.status >= 404) {
      message.push(`Source Company List: ${ResponseText[sourceCompanyFailureReason?.response?.status]}`);
    }
    if (destinationCompanyFailureReason?.response?.status >= 404) {
      message.push(`Destination Company List: ${ResponseText[destinationCompanyFailureReason?.response?.status]}`);
    }
    props.setMsgServerErrorAlert(message);
  }, [
    rPkgOwnerFailureReason?.response?.status,
    rPkgTypeFailureReason?.response?.status,
    destinationFailureReason?.response?.status,
    sourceFailureReason?.response?.status,
    statusFailureReason?.response?.status,
    numFailureReason?.response?.status,
    sourceCompanyFailureReason?.response?.status,
    destinationCompanyFailureReason?.response?.status,
    props,
  ]);

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const generateReport = useExcelDownloadOnlineMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        userId: userProfile.userId,
        displayName: userProfile.displayName,
        dataOwner: userProfile.dataOwner,
        loginUserCompanyAbbr: userProfile.comAbbr,
        rPackageOwner: form?.rPkgTypeOwner,
        rPackageType: form?.rPkgType,
        loadDataFrom: form?.loadDate,
        destinationCountry: form?.destinationCountry,
        sourceCountry: form?.sourceCountryId,
        destinationCompany: form?.destinationCompany,
        sourceCompany: form?.sourceCompanyId,
        customsBalanceStatus: form?.statusId,
        reportRecordsNumber: form?.numberOfRecords,
      };
      const pathReport = "generate-r-package-customs-balance-report";
      startLoading();
      const response = await generateReport(body, pathReport);
      waitAndDownloadExcel(60, response.data, props.setMsgError, stopLoading);
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      // if (error?.response?.status >= 500) {
      //   props.setMsgServerErrorAlert([ResponseText[error?.response?.status]]);
      // } else {
      const msgError = responseErrors(error);
      props.setMsgError(msgError);
      // }
      setDisabledSubmitBtn(false);
      stopLoading();
    }
  };

  useEffect(() => {
    document.getElementById("select-rpackage-owner").focus();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>*Report : </strong>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "1", value: "R-Package Customs Balance Report" }]}
            value={form?.reportId}
            disabled
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>*R-Package Owner : </strong>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            required
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={rPkgOwnerList?.map(val => ({
              key: val.rPkgOwnerCompAbbr,
              value: val.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
                rPkgType: "",
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>*R-Package Type : </strong>
          <DropDown
            id="select-rpackage-type"
            fullWidth
            value={form?.rPkgType}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={rPkgTypeList?.map(val => ({
              key: val.rPkgType,
              value: val.rPkgType,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgType: e.target.value,
              }))
            }
            disabled={!form.rPkgTypeOwner?.length}
            required
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>Destination Country : </strong>
          <DropDown
            id="select-destination-country"
            fullWidth
            value={form?.destinationCountry}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={destinationList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                destinationCountry: e.target.value,
                destinationCompany: "",
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>Destination Company : </strong>
          <DropDown
            id="select-destination-company"
            fullWidth
            value={form?.destinationCompany}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={destinationCompanyList?.map(val => ({
              key: val.companyAbbr,
              value: val.companyAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                destinationCompany: e.target.value,
              }))
            }
            disabled={!form?.destinationCountry?.length}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>Source Country : </strong>
          <DropDown
            id="select-source-country"
            fullWidth
            value={form?.sourceCountryId}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={sourceList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                sourceCountryId: e.target.value,
                sourceCompanyId: "",
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>Source Company : </strong>
          <DropDown
            id="select-source-company"
            fullWidth
            value={form?.sourceCompanyId}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={sourceCompanyList?.map(val => ({
              key: val.companyAbbr,
              value: val.companyAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                sourceCompanyId: e.target.value,
              }))
            }
            disabled={!form?.sourceCountryId?.length}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>Load Date (From) : </strong>
          <DatePickerField
            id="input-load-date"
            className="input-load-date"
            views={["year", "month", "day"]}
            format="DD/MM/YYYY"
            mask="DD/MM/YYYY"
            fullWidth={true}
            value={form?.loadDate}
            onChange={e =>
              setForm(old => ({
                ...old,
                loadDate: e,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} style={{ margin: "auto 0"}}>
          (DD/MM/YYYY)
        </Grid>
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>Status : </strong>
          <DropDown
            id="select-status"
            fullWidth
            value={form?.statusId}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={statusList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                statusId: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>Number of Records : </strong>
          <DropDown
            id="select-number-of-records"
            fullWidth
            value={form?.numberOfRecords}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={numList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                numberOfRecords: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />

        <ButtonAction
          handleDownload={handleDownload}
          setFrom={setForm}
          defaultFrom={FORM_DEFAULT_R_PACKAGE_CUSTOMS}
          disabled={disabledSubmitBtn}
          setDisabled={setDisabledSubmitBtn}
          clearMsg={() => {
            props.setMsgAlert([]);
            props.setMsgError([]);
          }}
          firstField={"select-rpackage-owner"}
        />
      </Grid>
    </>
  );
}
