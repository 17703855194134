import React, { Fragment } from "react";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import { InputButton } from "components/UI";
import { API_LOG_MONITORING } from "shared/constants/api-name/common/logMonitoring";
export default function FilterButtonBar(props) {
  const {
    column: { setColumnsHidden, displayColumns, setDisplayColumns, setOnRefresh },
  } = props;
  // handle change display columns
  const handleChangeDisplayColumns = event => {
    const { checked, value } = event.target;
    setDisplayColumns(prevState => ({
      ...prevState,
      [value]: checked,
    }));
  };
  // handle refresh
  const handleRefresh = () => {
    setOnRefresh(true);
    setColumnsHidden({
      [API_LOG_MONITORING.APP_ID]: !displayColumns.appId,
      [API_LOG_MONITORING.MODULE_ID]: !displayColumns.moduleId,
      [API_LOG_MONITORING.FUNCTION_ID]: !displayColumns.functionId,
      [API_LOG_MONITORING.STATUS]: !displayColumns.status,
      [API_LOG_MONITORING.LEVEL_ID]: !displayColumns.levelId,
      [API_LOG_MONITORING.USER_ID]: !displayColumns.userId,
    });
  };
  return (
    <Fragment>
      <Grid container display="flex">
        <Grid xs={12} item>
          <strong>Non-Display/Download Options</strong>
        </Grid>
        <Grid item sx={{ display: "flex", flex: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                value="appId"
                checked={displayColumns.appId}
                onChange={handleChangeDisplayColumns}
              />
            }
            label="App ID"
          />
        </Grid>
        <Grid item sx={{ display: "flex", flex: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                value="moduleId"
                checked={displayColumns.moduleId}
                onChange={handleChangeDisplayColumns}
              />
            }
            label="Module"
          />
        </Grid>
        <Grid item sx={{ display: "flex", flex: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                value="functionId"
                checked={displayColumns.functionId}
                onChange={handleChangeDisplayColumns}
              />
            }
            label="Function"
          />
        </Grid>
        <Grid item sx={{ display: "flex", flex: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                value="status"
                checked={displayColumns.status}
                onChange={handleChangeDisplayColumns}
              />
            }
            label="Status"
          />
        </Grid>
        <Grid item sx={{ display: "flex", flex: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                value="levelId"
                checked={displayColumns.levelId}
                onChange={handleChangeDisplayColumns}
              />
            }
            label="Level"
          />
        </Grid>
        <Grid item sx={{ display: "flex", flex: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                value="userId"
                checked={displayColumns.userId}
                onChange={handleChangeDisplayColumns}
              />
            }
            label="User ID"
          />
        </Grid>
        <Grid item sx={{ display: "flex", flex: 1 }}>
          <InputButton value="Refresh" onClick={handleRefresh} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
