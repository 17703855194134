import React, { Fragment } from "react";
import { Grid, Divider } from "@mui/material";
// SHARED COMPONENTS
import { DropDown, InputMask } from "components/UI";
// Type
import { ModeAction } from "state/enum";
import { API_MONTHLY } from "shared/constants/api-name/forecast/monthly";

export default function SubHeaderSection(props) {
  const {
    dataList: { uploadStatusList, modeList, dataTypeList },
    mode,
    searchForm,
    setSearchForm,
  } = props;
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={5} style={{ display: "flex" }}>
          <strong style={{margin: "auto 0px", width: "325px"}}>*Mode :</strong>
          <DropDown
            id="input-monthly-mode"
            value={searchForm?.modeCd}
            onChange={(e) => {
              setSearchForm((prev) => ({
                ...prev,
                [API_MONTHLY.MODE_CD]: e.target.value,
              }));
            }}
            menu={modeList?.map((val) => ({
              key: val.cd,
              value: val.value,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            required
            autoFocus={true}
            placeholder="<Select>"
            defaultValue=""
          />
        </Grid>
        <Grid item xs={7}></Grid>
        <Grid item xs={5} style={{ display: "flex" }}>
          <strong style={{margin: "auto 0px", width: "325px"}}>*Forecast Monthly : </strong>
          <InputMask
            id="input-monthly-month"
            mask="mM/YYYY"
            required
            fullWidth={true}
            formatChars={{
              Y: "[0-9]",
              m: "[0-1]",
              M: "[0-9]",
            }}
            maskChar={null}
            alwaysShowMask={false}
            value={searchForm.forecastMonth}
            autoFocus={true}
            onChange={(e) => {
              setSearchForm((prev) => ({
                ...prev,
                [API_MONTHLY.FORECAST_MONTH]: e.target.value,
              }));
            }}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
        <Grid item xs={7} style={{ margin: "auto 0px" }}>
          <strong>(MM/YYYY)</strong>
        </Grid>
        <Grid item xs={5} style={{ display: "flex" }}>
          <strong style={{margin: "auto 0px", width: "325px"}}>Data Type : </strong>
          <DropDown
            id="select-monthly-data-type"
            value={searchForm.dataTypeCd}
            onChange={(e) => {
              setSearchForm((prev) => ({
                ...prev,
                [API_MONTHLY.DATA_TYPE_CD]: e.target.value,
              }));
            }}
            menu={dataTypeList?.map((val) => ({
              key: val.cd,
              value: val.value,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            placeholder="<All>"
            defaultValue=""
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5} style={{ display: "flex" }}>
          <strong style={{margin: "auto 0px", width: "325px"}}>Upload status : </strong>
          <DropDown
            id="select_monthly_upload_status"
            value={searchForm.uploadStatusCd}
            onChange={(e) => {
              setSearchForm((prev) => ({
                ...prev,
                [API_MONTHLY.UPLOAD_STATUS_CD]: e.target.value,
              }));
            }}
            menu={uploadStatusList?.map((val) => ({
              key: val.cd,
              value: val.value,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            placeholder="<All>"
            defaultValue=""
          />
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Fragment>
  );
}
