import React, { Fragment, useCallback } from "react";
import { DataGridTable } from "components/UI";
//UTIL
import { responseErrors, messageDisplay } from "utils";
// Type
import { ModeAction, MessageType, MSG_TYPE } from "state/enum";
import { PAGINATION } from "shared/constants/table.constant";
import { API_ON_DEMAND_BATCH, ROW_PER_PAGE } from "shared/constants";
import { Box } from "@mui/material";
export default function TableBar(props) {
  const {
    form,
    rows,
    setRows,
    columns,
    pagination,
    setPagination,
    pageNumber,
    setPageNumber,
    onSearch,
    mode,
    setMode,
    rowModesModel,
    setRowModesModel,
    rowSelectionModel,
    setRowSelectionModel,
    getSearch,
    setMsg: { setMsgError, setMsgAlert },
    handleCellClick,
  } = props;
  const handleChangePagination = async (event, value) => {
    try {
      setPageNumber(value);
      const body = {
        [API_ON_DEMAND_BATCH.DATA_OWNER]: form?.dataOwner,
        [API_ON_DEMAND_BATCH.PROJECT_CODE]: form?.projectCode,
        [API_ON_DEMAND_BATCH.BATCH_ID]: form?.batchId,
        [PAGINATION.PAGE_NUMBER]: value,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const { searchData, data } = await getSearch(body);
      if (!data.length) {
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: "" })]);
        return;
      }
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      window.scrollTo(0, 0);
      return;
    }
  };
  const handleProcessRowUpdateError = useCallback(error => {
    // console.error(` children:${error.message}, severity: ${error}`);
  }, []);
  // const processRowUpdate = () => {};

  return (
    <Fragment>
      <DataGridTable
        id="table-screen"
        mode={mode}
        onSearch={onSearch}
        setMode={setMode}
        rows={rows}
        isMultipleSelection={false}
        checkboxSelection={true}
        onCellClick={false}
        rowHeight={45}
        // autoHeight={true}
        processRowUpdate={null}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        // handleRowDisableSelect={handleRowDisableSelect}
        rowSelection={{
          rowModesModel: rowModesModel,
          setRowModesModel: setRowModesModel,
          rowSelectionModel: rowSelectionModel,
          setRowSelectionModel: setRowSelectionModel,
        }}
        pagination={{
          pagination: pagination,
          pageNumber: pageNumber,
          handleChangePagination: handleChangePagination,
        }}
        column={{
          columns: columns,
          columnGroupingModel: null,
          columnVisibilityModel: { rowNumber: false },
        }}
        handleCellClick={handleCellClick}
      />
    </Fragment>
  );
}
