import React from "react";
import { TextField, styled } from "@mui/material";
import InputMask from "react-input-mask";
import { useGridApiContext } from "@mui/x-data-grid";

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    borderRadius: ".3rem",
    backgroundColor: theme.palette.primary.light,
    textAlign: "left",
    padding: "4px 10px",
  },
  "& .MuiOutlinedInput-root": {
    // margin: "0px 5px",
  },
  "&.MuiFormControl-root-MuiTextField-root .MuiInputBase-input": {
    "&:focus": {
      border: "1px solid #000 !important",
    },
  },
}));

// maskChar :this character can be used to fill the missing part of the input string; the default value is _ (underscore)
// formatChars: you can change the default characters and regular expressions used for masking (in the mask property) by changing this property
// alwaysShowMask : the library usually shows the mask when the user focuses on the masked input; set this property to true if you always want to show the mask
export default function InputEditTableMask({
  id,
  field,
  mask,
  value,
  maskChar = null,
  alwaysShowMask = false,
  formatChars,
  disabled = false,
  required = false,
  autoFocus = false,
  // ...rest
}) {
  const apiRef = useGridApiContext();
  const ref = React.useRef();

  const handleChange = event => {
    const newValue = event.target.value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id: id, field: field, value: newValue });
  };
  return (
    <InputMask
      mask={mask}
      formatChars={formatChars}
      value={value}
      onChange={handleChange}
      maskChar={maskChar}
      alwaysShowMask={alwaysShowMask}
      disabled={disabled}
      ref={ref}
      required={required}>
      {inputProps => <StyledTextField disabled={disabled} autoFocus={autoFocus} {...inputProps} ref={ref} />}
    </InputMask>
  );
}
