import { Grid } from "@mui/material";
import { DatePickerField, DropDown, InputTextField } from "components/UI";
import { FORM_DEFAULT_R_PACKAGE_CATALOG } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import {
  useExcelDownloadOnlineMutation,
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonRPackageCategoryListQuery,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
import axios from "axios";
import { getLocalDate } from "utils/init-config-date";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { ResponseText } from "utils/5xxResponseText";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";

export default function RPackageCatalogReport(props) {
  const [form, setForm] = useState(FORM_DEFAULT_R_PACKAGE_CATALOG);

  const { data: rPkgOwnerList, failureReason: rPkgOwnerFailureReason } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgCategoryList, failureReason: rPkgCategoryFailureReason } =
    useReportCommonRPackageCategoryListQuery();

  useEffect(() => {
    let message = [];
    if (rPkgOwnerFailureReason?.response?.status >= 404) {
      message.push(`R-Package Owner List: ${ResponseText[rPkgOwnerFailureReason?.response?.status]}`);
    }
    if (rPkgCategoryFailureReason?.response?.status >= 404) {
      message.push(`R-Package Category List: ${ResponseText[rPkgCategoryFailureReason?.response?.status]}`);
    }
    props.setMsgServerErrorAlert(message);
  }, [rPkgOwnerFailureReason?.response?.status, rPkgCategoryFailureReason?.response?.status, props]);

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const generateReport = useExcelDownloadOnlineMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        userId: userProfile.userId,
        displayName: userProfile.displayName,
        dataOwner: "TMATH",
        userLocation: "",
        rPackageOwner: form?.rPkgTypeOwner,
        rPackageCategory: form?.rPkgTypeCategory,
      };
      const pathReport = "generate-r-package-catalog-report";
      startLoading();
      const response = await generateReport(body, pathReport);
      waitAndDownloadExcel(60, response.data, props.setMsgError, stopLoading);
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      // if (error?.response?.status >= 500) {
      //   props.setMsgServerErrorAlert([ResponseText[error?.response?.status]]);
      // } else {
      const msgError = responseErrors(error);
      props.setMsgError(msgError);
      // }
      setDisabledSubmitBtn(false);
      stopLoading();
    }
  };

  useEffect(() => {
    document.getElementById("select-rpackage-owner").focus();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={5} style={{ display: "flex", padding: "7px 0", }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>*Report : </strong>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<All>"
            menu={[{ key: "1", value: "R-Package Catalog Report" }]}
            value={form?.reportId}
            disabled
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "7px 0", }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>R-Package Owner : </strong>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPkgOwnerList?.map(val => ({
              key: val.rPkgOwnerCompAbbr,
              value: val.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "7px 0", }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>R-Package Category : </strong>
          <DropDown
            id="select-rpackage-category"
            fullWidth
            value={form?.rPkgTypeCategory}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPkgCategoryList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeCategory: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />

        <ButtonAction
          handleDownload={handleDownload}
          setFrom={setForm}
          defaultFrom={FORM_DEFAULT_R_PACKAGE_CATALOG}
          disabled={disabledSubmitBtn}
          setDisabled={setDisabledSubmitBtn}
          clearMsg={() => {
            props.setMsgAlert([]);
            props.setMsgError([]);
          }}
          firstField={"select-rpackage-owner"}
        />
      </Grid>
    </>
  );
}
