import useQuery from "hooks/useQuery";

export const useGetBarcodeMode = ({ dataOwner }) => {
  return useQuery({
    url: `/category/${dataOwner}/BARCODE_PRINT_MASTER_SCREEN_MODE/ASC`,
    method: "GET",
    select: (data) => data.result,
    enabled: true,
    keepPreviousData: true,
  });
};

export const useGetMaker = ({ dataOwner }) => {
  return useQuery({
    url: `/category/barcode-print/company-plant/${dataOwner}`,
    method: "GET",
    select: (data) => data.result,
    enabled: true,
    keepPreviousData: true,
  });
};

export const useGetPONumberType = ({ dataOwner, impExpCd }) => {
  return useQuery({
    url: `/category/barcode-print/delivery/${dataOwner}/${impExpCd}`,
    method: "GET",
    select: (data) => data.result,
    enabled: impExpCd !== "" && impExpCd !== undefined,
    keepPreviousData: true,
  });
};

export const useGetRPackageType = ({ dataOwner, rPkgOwnerCompAbbr }) => {
  return useQuery({
    url: `/category/barcode-print/r-package/${dataOwner}/${rPkgOwnerCompAbbr}`,
    method: "GET",
    select: (data) => data.result,
    enabled: true,
    keepPreviousData: true,
  });
};

export const useGetPrintAs = ({ dataOwner }) => {
  return useQuery({
    url: `/category/barcode-print/print-as/${dataOwner}`,
    method: "GET",
    select: (data) => data.result,
    enabled: true,
    keepPreviousData: true,
  });
};
