export const ResponseText ={
  "400": "BAD REQUEST",
  "404": "NOT FOUND",
  "500": "INTERNAL SERVER ERROR",
  "501": "NOT IMPLEMENTED",
  "502": "BAD GATEWAY",
  "503": "SERVICE UNAVAILABLE",
  "504": "GATEWAY TIMEOUT",
  "505": "HTTP VERSION NOT SUPPORTED",
  "506": "VARIANT ALSO NEGOTIATES",
  "507": "INSUFFICIENT STORAGE",
  "508": "LOOP DETECTED",
  "510": "NOT EXTENDED",
  "511": "NETWORK AUTHENTICATION REQUIRED"
}