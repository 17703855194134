import React from "react";
import { TextField, styled } from "@mui/material";
import { isEmpty } from "lodash";
const StyledTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInput-root": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    backgroundColor: theme.palette.primary.light,
    borderRadius: ".3rem",
  },
  "& .MuiInput-root.Mui-focused": {
    border: `1.5px solid black !important`,
    borderRadius: ".3rem",
  },
}));

export default function TanStackTextField({
  renderValue,
  getValue,
  row,
  column,
  table,
  id,
  fullWidth = false,
  placeholder = "",
  value = "",
  onChange = undefined,
  required = false,
  style = {},
  type = "text",
  disabled,
  maxLength,
  autoFocus = false,
  regularExp,
  multiline = false,
  ...props
}) {
  const [item, setItem] = React.useState(value);
  const tableMeta = table.options.meta;
  const rowData = row.original;
  //onBlur to trigger the updateData function when finish fill text,
  const onBlur = e => {
    const newValue = e.target.value;
    // if (isEmpty(newValue)) return false;
    setItem(newValue);
    tableMeta?.updateData({ rowIndex: rowData?.rowNumber, columnId: column.id, value: newValue });
  };
  const handleChange = event => {
    const newValue = event.target.value;

    if (regularExp && !regularExp?.test(newValue)) {
      // event.preventDefault();
      return;
    }
    setItem(newValue);
    // tableMeta?.updateData({ rowIndex: rowData?.rowNumber, columnId: column.id, value: newValue });
  };
  return (
    <StyledTextField
      {...props}
      margin="dense"
      size="small"
      variant="standard"
      inputProps={{
        maxLength: maxLength,
      }}
      InputProps={{
        sx: {
          // padding: "5px 5px",
          // minHeight: "32px",
          height: "auto",
          // fontSize: 12,
          ...style,
        },
        disableUnderline: true,
        required: required,
      }}
      multiline={multiline}
      autoFocus={autoFocus}
      type={type}
      fullWidth={fullWidth}
      placeholder={placeholder}
      // onKeyDown={event => ["e", "E", "+", "-"].includes(event.key) && event.preventDefault()}
      // error={!!error}
      // helperText={error}
      value={item}
      onChange={handleChange}
      onBlur={onBlur}
      required={required}
      disabled={disabled}
    />
  );
}
