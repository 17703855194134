import React, { Fragment, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
// Utils
import { responseDownloadFileErrors, responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";
// Components
import FilterBar from "pages/Common/Monitoring/OnDemandExcelDownloadMonitorScreen/components/FilterBar";
import ButtonBar from "pages/Common/Monitoring/OnDemandExcelDownloadMonitorScreen/components/ButtonBar";
import ActionTableBar from "pages/Common/Monitoring/OnDemandExcelDownloadMonitorScreen/components/ActionTableBar";
import TableBar from "pages/Common/Monitoring/OnDemandExcelDownloadMonitorScreen/components/TableBar";
import { AlertMessage } from "components/UI";
// Hooks
import useColumn from "pages/Common/Monitoring/OnDemandExcelDownloadMonitorScreen/hooks/useColumn";
//Constants
import { ModeAction, MessageType } from "state/enum";
import { API_NAME_ON_DEMAND_EXCEL } from "shared/constants";
import { userProfile } from "constant";
import { DEFAULT_FORM } from "pages/Common/Monitoring/OnDemandExcelDownloadMonitorScreen/constants/onDemand.constant";
//Service
import {
  useOnDemandExcelDownloadMutation,
  useOnDemandExcelReportStatusQuery,
  useOnDemandExcelSearchMutation,
} from "shared/services/common/monitoring";
import { getLocalDate, getLocalDateStr } from "utils/init-config-date";

export default function OnDemandExcelDownloadMonitorScreen() {
  const functionId = "WSTD3020";

  // Form
  const [form, setForm] = useState({
    [API_NAME_ON_DEMAND_EXCEL.REQUEST_DATE]: getLocalDateStr(),
    [API_NAME_ON_DEMAND_EXCEL.REPORT_STATUS]: "",
    [API_NAME_ON_DEMAND_EXCEL.REPORT_NAME]: "",
    [API_NAME_ON_DEMAND_EXCEL.DATA_OWNER]: userProfile?.dataOwner,
    [API_NAME_ON_DEMAND_EXCEL.USER_ID]: userProfile.userId,
    [API_NAME_ON_DEMAND_EXCEL.ORDER_BY_DATE]: false,
    [API_NAME_ON_DEMAND_EXCEL.DOC_ID]: "",
  });
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(ModeAction.VIEW);

  // Table
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  //API
  const { data: reportStatsList } = useOnDemandExcelReportStatusQuery();
  const searchOnDemand = useOnDemandExcelSearchMutation();
  const downloadOnDemand = useOnDemandExcelDownloadMutation();
  // get search
  const getSearch = async body => {
    try {
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_NAME_ON_DEMAND_EXCEL.REQUEST_DATE,
            type: MessageType.EMPTY,
            key: ["Request Date"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }

      const searchData = await searchOnDemand(body);
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_NAME_ON_DEMAND_EXCEL.ROW_NUMBER]: item?.rowNumber,
          [API_NAME_ON_DEMAND_EXCEL.DOC_ID]: item?.docId,
          [API_NAME_ON_DEMAND_EXCEL.REPORT_NAME]: item?.reportName,
          [API_NAME_ON_DEMAND_EXCEL.FILE_NO]: item?.fileNo,
          [API_NAME_ON_DEMAND_EXCEL.FILE_COUNT]: item?.fileCnt,
          [API_NAME_ON_DEMAND_EXCEL.NO_OF_ZIP_FILE]: item?.noOfZipFile,
          [API_NAME_ON_DEMAND_EXCEL.FILE_NAME]: item?.fileName,
          [API_NAME_ON_DEMAND_EXCEL.REQUEST_DATE]: item?.requestDt,
          [API_NAME_ON_DEMAND_EXCEL.REPORT_STATUS]: item?.reportStatus,
          [API_NAME_ON_DEMAND_EXCEL.STATUS_ID]: item?.status,
          [API_NAME_ON_DEMAND_EXCEL.GENERATED_BY]: item?.generatedBy,
          [API_NAME_ON_DEMAND_EXCEL.FILE_SIZE]: item?.fileSize,
          [API_NAME_ON_DEMAND_EXCEL.FILE_STATUS]: item?.fileStatus,
          [API_NAME_ON_DEMAND_EXCEL.OVERRIDE_PATH]: item?.overridePath,
        })) ?? [];

      return { searchData, data };
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);

      // return;
    }
  };
  const handleDownloadExcel = async (row, event) => {
    setMsgError([]);
    setMsgAlert([]);
    try {
      const body = {
        [API_NAME_ON_DEMAND_EXCEL.DATA_OWNER]: userProfile?.dataOwner,
        [API_NAME_ON_DEMAND_EXCEL.DOC_ID]: row?.docId,
        [API_NAME_ON_DEMAND_EXCEL.FILE_NAME]: row?.fileName,
        [API_NAME_ON_DEMAND_EXCEL.OVERRIDE_PATH]: row?.overridePath,
      };

      await downloadOnDemand(body);
    } catch (error) {
      const err = responseDownloadFileErrors(error);
      setMsgError(err);
      window.scroll(0, 0);
      return;
    }
  };
  const columns = useMemo(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    () => useColumn(rows, mode, handleDownloadExcel),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, mode]
  );
  return (
    <Fragment>
      <Box sx={{ padding: "1rem 1rem 0 1rem" }}>
        <HeaderContentBar title="WSTD3020 : On Demand Excel Download Monitoring Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
      </Box>
      <Box sx={{ padding: "0 1rem 0 1rem" }}>
        <FilterBar form={form} setForm={setForm} mode={mode} reportStatusList={reportStatsList} />
      </Box>
      <Box sx={{ padding: "6px 6px 6px 6px" }}>
        <ButtonBar
          form={form}
          setForm={setForm}
          setRows={setRows}
          setMode={setMode}
          setOnSearch={setOnSearch}
          setRowModesModel={setRowModesModel}
          setRowSelectionModel={setRowSelectionModel}
          pagination={{ pageNumber, setPageNumber, setPagination }}
          setMsg={{ setMsgError, setMsgAlert }}
          functionId={"WSTD3020"}
        />
      </Box>
      {onSearch && (
        <Box sx={{ padding: "1rem" }}>
          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "1rem",
                mb: 1,
              }}>
              <ActionTableBar
                mode={mode}
                form={form}
                rows={rows}
                setRows={setRows}
                setMode={setMode}
                getSearch={getSearch}
                pagination={{ pageNumber, setPagination, setPageNumber }}
                rowSelection={{ rowSelectionModel, setRowSelectionModel }}
                setMsg={{ setMsgError, setMsgAlert }}
                functionId={functionId}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                paddingRight: "1rem",
                mb: 1,
              }}>
              <TableBar
                form={form}
                mode={mode}
                setMode={setMode}
                onSearch={onSearch}
                rows={rows}
                setRows={setRows}
                columns={columns}
                setPagination={setPagination}
                setPageNumber={setPageNumber}
                setMsg={{ setMsgAlert, setMsgError }}
                rowModesModel={rowModesModel}
                setRowModesModel={setRowModesModel}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                pagination={pagination}
                pageNumber={pageNumber}
                getSearch={getSearch}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Fragment>
  );
}
