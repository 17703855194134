import React, { Fragment } from "react";

// Components
import { Grid, Divider } from "@mui/material";
import { InputButton } from "components/UI";
import ActionTableBar from "./ActionTableBar";
// Utils
import { messageDisplay, responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";
// Constant
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import { PAGINATION } from "shared/constants/table.constant";
import { API_ON_DEMAND_BATCH, FIRST_PAGE } from "shared/constants";
import { BUTTON_VALUE } from "shared/constants/button.constant";
import { ROW_PER_PAGE } from "shared/constants";
import { useOnDemandBatchSearchMutation } from "shared/services/common/monitoring";
import { useLoadingContext } from "shared/contexts/LoadingContext";
export default function ButtonBar(props) {
  const {
    mode,
    setMode,
    form,
    setForm,
    rows,
    setRows,
    onSearch,
    setOnSearch,
    setAppId,
    setMsg: { setMsgError, setMsgAlert },
    pagination: { pagination, setPagination, pageNumber, setPageNumber },
    rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
    functionId,
  } = props;
  const { startLoading, stopLoading } = useLoadingContext();
  //API
  const searchOnDemandBatch = useOnDemandBatchSearchMutation();
  const handleSearchBtn = async e => {
    try {
      e.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      setRows([]);
      setPagination({});
      setMode(ModeAction.VIEW);
      setPageNumber(FIRST_PAGE);
      setOnSearch(false);
      setRowModesModel({});
      setRowSelectionModel([]);
      setAppId(null);
      const body = {
        [API_ON_DEMAND_BATCH.DATA_OWNER]: form?.dataOwner,
        [API_ON_DEMAND_BATCH.PROJECT_CODE]: form?.projectCode,
        [API_ON_DEMAND_BATCH.BATCH_ID]: form?.batchId,
        [PAGINATION.PAGE_NUMBER]: pageNumber,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_ON_DEMAND_BATCH.PROJECT_CODE,
            type: MessageType.EMPTY,
            key: ["Project Code"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      const searchData = await searchOnDemandBatch(body);
      stopLoading();
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_ON_DEMAND_BATCH.ROW_NUMBER]: item?.rowNumber,
          [API_ON_DEMAND_BATCH.PROJECT_CODE]: item?.projectCode,
          [API_ON_DEMAND_BATCH.BATCH_ID]: item?.batchId,
          [API_ON_DEMAND_BATCH.BATCH_NAME]: item?.batchname,
          [API_ON_DEMAND_BATCH.RUNNING_COUNT]: item?.runningCount,
          [API_ON_DEMAND_BATCH.RUN_AS]: item?.runAs,
          [API_ON_DEMAND_BATCH.END_POINT]: item?.endPoint,
          [API_ON_DEMAND_BATCH.SUPPORT_ID]: item?.supportId,
          [API_ON_DEMAND_BATCH.SCHEMA]: item?.schema,
          [API_ON_DEMAND_BATCH.CREATE_BY]: item?.createBy,
          [API_ON_DEMAND_BATCH.CREATE_DATE]: item?.createDate,
          [API_ON_DEMAND_BATCH.UPDATE_BY]: item?.updateBy,
          [API_ON_DEMAND_BATCH.UPDATE_DATE]: item?.updateDate,
        })) ?? [];
      if (!data.length) {
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: "" })]);
        return false;
      }
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
      setOnSearch(true);
      return;
    } catch (error) {
      console.log(error);
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleClearBtn = () => {
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(false);
    setMode(ModeAction.VIEW);
    setPageNumber(1);
    setRowModesModel({});
    setRowSelectionModel([]);
    setRows([]);
    setForm({
      [API_ON_DEMAND_BATCH.DATA_OWNER]: form.dataOwner,
      [API_ON_DEMAND_BATCH.PROJECT_CODE]: "",
      [API_ON_DEMAND_BATCH.BATCH_ID]: "",
      [API_ON_DEMAND_BATCH.BATCH_NAME]: "",
      [API_ON_DEMAND_BATCH.CREATE_BY]: "",
      [API_ON_DEMAND_BATCH.CREATE_DATE]: "",
      [API_ON_DEMAND_BATCH.UPDATE_BY]: "",
      [API_ON_DEMAND_BATCH.UPDATE_DATE]: "",
      [API_ON_DEMAND_BATCH.END_POINT]: "",
      [API_ON_DEMAND_BATCH.USER_ID]: form?.userId,
    });
    setAppId(null);
    document.getElementById("input-project-code").focus();
    return;
  };
  return (
    <Fragment>
      <Grid container spacing={0.5}>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", flexFlow: "row wrap", justifyContent: "flex-end", alignItems: "center" }}>
          <InputButton
            id="btn-search"
            value={BUTTON_VALUE.SEARCH}
            disabled={false}
            onClick={handleSearchBtn}
            name={`${functionId}Search`}
          />
          <InputButton
            id="btn-clear"
            value={BUTTON_VALUE.CLEAR}
            disabled={false}
            onClick={handleClearBtn}
            onKeyDown={e => {
              if (!onSearch && e.key === "Tab") {
                e.preventDefault();
                document.getElementById("input-project-code").focus();
              }
            }}
            name={`${functionId}Clear`}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
          <Divider />
        </Grid>
        {onSearch && (
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", flexFlow: "row wrap" }}>
            <ActionTableBar
              mode={mode}
              form={form}
              rows={rows}
              setMode={setMode}
              setAppId={setAppId}
              setRowSelectionModel={setRowSelectionModel}
              setRowModesModel={setRowModesModel}
              setMsg={{ setMsgError: setMsgError, setMsgAlert: setMsgAlert }}
              rowSelection={{
                rowSelectionModel: rowSelectionModel,
              }}
              functionId={functionId}
            />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
}
