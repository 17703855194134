import React, { Fragment, useEffect, useMemo, useState } from "react";
// Components
import { Grid, Box } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, InputButton, PopupDialog } from "components/UI";
import ButtonCriterial from "./components/ButtonCriterial";
import GraphSection from "./components/GraphSection";
import TableSection from "./components/TableSection";
import ButtonGraph from "./components/ButtonGraph";
import CriterialSection from "./components/CriterialSection";
//UTIL
import { forEach, isEmpty } from "lodash";
import { findObject, responseErrors, responseSuccessArray } from "utils";
import { validationSearchForm } from "utils/validation";
// Hook
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import useButtonMode from "./hooks/useButtonMode";
//Service
import {
  useWareHouseSummaryGetRPkgOwnerQuery,
  useWareHouseSummaryGetRPkgTypeQuery,
  useWareHouseSummaryGetHolidayQuery,
  useWareHouseSummaryGetWareHouseQuery,
  useWareHouseSummarySearchTableMutation,
  useWareHouseSummarySearchGraphMutation,
  useWarehouseConfirmAllocateMutation,
} from "shared/services/inventory";
//Type
import { MessageType, ModeAction } from "state/enum";

//!mock
import { formatRowUpdated, formatRowWareHouseSummary } from "./hooks/format";
import { DEFAULT_WAREHOUSE_SUMMARY } from "./constants/constant";
import useColumnTanStack from "./hooks/useColumnTanStack";
import { userProfile } from "constant";
import LabelGraph from "./components/LabelGraph";
import { deepClone } from "@mui/x-data-grid/utils/utils";

export default function RPackageWarehouseAllocationSummaryScreen() {
  const confirmDialogCtx = useConfirmDialogContext();
  const { startLoading, stopLoading } = useLoadingContext();
  // Form
  const [form, setForm] = useState(DEFAULT_WAREHOUSE_SUMMARY);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState("Initial");
  const [generatedGraph, setGeneratedGraph] = useState(false);

  //Button State
  const [viewBtn, setViewBtn] = useState(false);
  const [searchBtn, setSearchBtn] = useState(false);
  const [clearBtn, setClearBtn] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const [simulateBtn, setSimulateBtn] = useState(false);
  const [cancelBtn, setCancelBtn] = useState(false);
  const [confirmBtn, setConfirmBtn] = useState(false);
  const [isLoadingView, setIsLoadingView] = useState(false);

  // Table
  const [rows, setRows] = useState([]);
  const [defaultRows, setDefaultRows] = useState([]);
  const [rPkg, setRPkg] = useState([]);
  const [graphData, setGraph] = useState([]);
  const [defaultGraphData, setDefaultGraph] = useState([]);
  const [appId, setAppId] = useState("");
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const getWarehouse = wareHouseId => {
    if (isEmpty(wareHouseId)) return "";
    const warehouse = wareHouseList?.find(ele => ele.plantId === wareHouseId);
    return `${warehouse.companyAbbr}:${warehouse.impExpCd}`;
  };

  const [openGraph, setOpenGraph] = useState(false);
  // API
  const { data: rPackageOwnerList } = useWareHouseSummaryGetRPkgOwnerQuery();
  const { data: holidayList } = useWareHouseSummaryGetHolidayQuery(form.rPackageOwner);

    // console.table(holidayList)
  

  const { data: wareHouseList } = useWareHouseSummaryGetWareHouseQuery(form.rPackageOwner);
  const { data: rPackageTypeList } = useWareHouseSummaryGetRPkgTypeQuery({
    rPkgOwner: form.rPackageOwner,
    warehouse: !form.wareHouseId ? "" : getWarehouse(form.wareHouseId),
  });
  const searchTable = useWareHouseSummarySearchTableMutation();
  const searchGraph = useWareHouseSummarySearchGraphMutation();
  const confirmAllocate = useWarehouseConfirmAllocateMutation();
  const handleClearCriterial = () => {
    setDefaultGraph([]);
    setGraph([]);
    setOnSearch(false);
    setMode("Initial");
    setRows([]);
  };
  const getGraph = async () => {
    try {
      const body = {
        dataOwner: form?.dataOwner,
        rPkgOwner: !form?.rPackageOwnerId
          ? ""
          : findObject({
              data: rPackageOwnerList,
              value: form?.rPackageOwnerId,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            }),
        rPkgType: !form?.rPackageTypeId
          ? ""
          : findObject({
              data: rPackageTypeList,
              value: form?.rPackageTypeId,
              property: "rPkgTypeId",
              field: "rPkgType",
            }),
        location: getWarehouse(form.wareHouseId),
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "rPkgOwner",
            type: MessageType.EMPTY,
            key: ["R-Package Owner"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      const response = await searchGraph(body, { appId: appId });
      // if (!response?.result?.length) {
      //   setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: "" })]);
      //   return false;
      // }
      return response;
    } catch (error) {
      if (error?.statusCode === 500) {
        return setMsgError([error?.message]);
      }
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return false;
    }
  };
  const getSearch = async body => {
    try {
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "rPkgOwner",
            type: MessageType.EMPTY,
            key: ["R-Package Owner"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      const searchData = await searchTable(body);
      const { data, rPkg } = formatRowWareHouseSummary(searchData?.result);
      // await sleep(2000); // waiting for 2 second
      // const searchGraph = await getGraph(body);
      stopLoading();
      return { data, rPkg, searchGraph };
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleAllocated = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const allocateConfirm = await confirmDialogCtx.success({
        type: "INVENTORY_CONFIRM_ALLOCATED",
      });
      if (!allocateConfirm) {
        return;
      }
      setRows([]);
      setDefaultRows([]);
      const checkAllocateDt = rowsChanged.filter(row => row.plantId && row.allocateDt);
      // TODO validate forecastDt if don't have data show error and which have error
      const updatedRow = formatRowUpdated(checkAllocateDt, getWarehouse);
      const updateData = {
        dataOwner: form?.dataOwner,
        userId: userProfile?.userId,
        rPackageOwner: form?.rPackageOwnerId,
        rPackageType: form?.rPackageTypeId,
        wareHouseId: form?.wareHouseId,
        rows: updatedRow,
      };
      startLoading();
      const response = await confirmAllocate(updateData);
      const { error, info } = responseSuccessArray(response);
      if (info?.length > 0) {
        setMsgAlert(info);
      }
      if (error?.length > 0) {
        setMsgError(error);
      }
      window.scrollTo(0, 0);
      setMode(ModeAction.VIEW);

      // Search after updated
      const bodySearch = {
        dataOwner: form?.dataOwner,
        rPkgOwner: !form?.rPackageOwnerId
          ? ""
          : findObject({
              data: rPackageOwnerList,
              value: form?.rPackageOwnerId,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            }),
        rPkgType: !form?.rPackageTypeId
          ? ""
          : findObject({
              data: rPackageTypeList,
              value: form?.rPackageTypeId,
              property: "rPkgTypeId",
              field: "rPkgType",
            }),
        warehouse: getWarehouse(form.wareHouseId),
      };
      const { data, rPkg } = await getSearch(bodySearch);
      stopLoading();
      setRows(deepClone(data));
      setDefaultRows(deepClone(data));
      setRPkg(rPkg);
      // setGraph(searchRes?.result?.graphData);
      setOnSearch(true);
      setMode(ModeAction.VIEW);
      setRowModesModel({});
      setRowSelectionModel([]);
      return;
    } catch (error) {
      const errors = responseErrors(error);
      stopLoading();
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  const { columns, rowsChanged } = useMemo(() => {

    // console.log("holidayList ", holidayList.map(i => i.holidayDt))
    

    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useColumnTanStack({
        rPkg,
        wareHouseList,
        mode,
        holidayList,
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rPkg, wareHouseList, mode, holidayList]);
  //console.log('rowsChanged',rowsChanged)
  const rowsChangedNo = rowsChanged.map(row => row.no);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useButtonMode({
      mode,
      stateButton: {
        setSearchBtn,
        setClearBtn,
        setEditBtn,
        setSimulateBtn,
        setCancelBtn,
        setConfirmBtn,
        setViewBtn,
      },
    });
  }, [mode]);
  // API
  return (
    <Fragment>
      <Box sx={{ padding: "1rem 1rem 0 1rem" }}>
        <HeaderContentBar title="WDN93050 : R-Package Warehouse Allocation Maintenance Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
      </Box>
      <Box sx={{ padding: "0 1rem 0 1rem" }}>
        <CriterialSection
          mode={mode}
          form={form}
          setForm={setForm}
          handleClearCriterial={handleClearCriterial}
          setMsgError={setMsgError}
          dataList={{ rPackageOwnerList, rPackageTypeList, wareHouseList }}
        />
        {/* view search clear edit */}
        <ButtonCriterial
          form={form}
          setForm={setForm}
          rows={rows}
          setRows={setRows}
          rPkg={rPkg}
          setRPkg={setRPkg}
          defaultRows={defaultRows}
          setDefaultRows={setDefaultRows}
          graphData={graphData}
          defaultGraphData={defaultGraphData}
          setGraph={setGraph}
          setDefaultGraph={setDefaultGraph}
          setOpenGraph={setOpenGraph}
          mode={mode}
          setMode={setMode}
          onSearch={onSearch}
          setOnSearch={setOnSearch}
          getWarehouse={getWarehouse}
          getGraph={getGraph}
          stateBtn={{ searchBtn, clearBtn, editBtn, viewBtn, setGeneratedGraph, generatedGraph }}
          loading={{ startLoading, stopLoading }}
          loadingView={{ isLoadingView, setIsLoadingView }}
          setViewBtn={setViewBtn}
          setSimulateBtn={setSimulateBtn}
          dataList={{ rPackageOwnerList, rPackageTypeList, wareHouseList }}
          setMsg={{ setMsgAlert, setMsgError }}
          rowSelection={{ setRowModesModel, setRowSelectionModel }}
          functionId={"WDN93050"}
          setAppId={setAppId}
          appId={appId}
        />
      </Box>

      {onSearch && (
        <Box sx={{ padding: "0 1rem 0 1rem" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "right",
              }}>
              <ButtonGraph
                form={form}
                rPkg={rPkg}
                setGraph={setGraph}
                defaultGraphData={defaultGraphData}
                setDefaultGraph={setDefaultGraph}
                setOpenGraph={setOpenGraph}
                setRows={setRows}
                defaultRows={defaultRows}
                setMode={setMode}
                setRPkg={setRPkg}
                setOnSearch={setOnSearch}
                getGraph={getGraph}
                getWarehouse={getWarehouse}
                isLoadingView={isLoadingView}
                stateBtn={{ simulateBtn, cancelBtn, setGeneratedGraph, generatedGraph }}
                setMsg={{ setMsgAlert, setMsgError }}
                dataList={{
                  rPackageOwnerList,
                  rPackageTypeList,
                }}
                loading={{ startLoading, stopLoading }}
                rowSelection={{
                  rowModesModel,
                  setRowModesModel,
                  rowSelectionModel,
                  setRowSelectionModel,
                }}
                rowsChanged={rowsChanged}
                functionId={"WDN93050"}
              />
            </Grid>
            <Grid item xs={12}>
              <TableSection
                mode={mode}
                rows={rows}
                columns={columns}
                rowSelection={{
                  rowModesModel,
                  setRowModesModel,
                  rowSelectionModel,
                  setRowSelectionModel,
                }}
                rowsChanged={rowsChangedNo}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "right",
              }}>
              <InputButton
                id="button-allocate"
                value="Confirm Allocation"
                disabled={confirmBtn}
                onClick={handleAllocated}
                name={"WDN93050ConfirmAllocation"}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {/* Popup Graph */}
      <PopupDialog
        id="container"
        maxWidth="lg"
        onFooter={true}
        onOpen={openGraph}
        content={
          <Box sx={{ margin: "0.5rem 0.5rem", padding: "0.5rem 0.5rem" }}>
            <GraphSection
              setOpenGraph={setOpenGraph}
              setMsg={{ setMsgAlert, setMsgError }}
              graphData={mode === ModeAction.VIEW ? defaultGraphData : graphData}
            />
          </Box>
        }
        contentFooter={
          <Box sx={{ margin: "1rem 1rem", padding: "1rem 1rem", border: "1px solid black" }}>
            <LabelGraph />
          </Box>
        }
      />
    </Fragment>
  );
}
