export const API_NAME_IMPORT_INVOICE_DOWNLOAD = {
  // Table Data
  ROW_NUMBER: "rowNumber",
  EXP_COMPANY_ABBR: "expCompanyAbbr",
  INV_NO: "invNo",
  EXP_CD: "expCd",
  IMP_CD: "impCd",
  INV_DT: "invDt",
  ETD_DT: "etdDt",
  ETA_DT: "etaDt",
  VESSEL_NAME: "vesselName",
  R_PKG_TYPE: "rPkgType",
  R_PKG_QTY: "rPkgQty",
  IMP_CUSTOMS_STK_DT: "impCustomsStkDt",
  IMP_BROKER_COMPANY_ABBR: "impBrokerCompanyAbbr",
  IMP_BROKER_COMPANY_ID: "impBrokerCompanyId",
  IMP_ENTRY_NO: "impEntryNo",
  ORG_BL_FILE_NAME: "orgBlFileName",
  BL_FILE_NAME: "blFileName",
  NON_COMM_INV_HID: "nonCommInvHId",
  UPDATE_DT: "updateDt",
  UPDATE_BY: "updateBy",
  INV_EDITABLE: "invEditable",
  TOTAL_M3_DISP_FLAG: "totalM3DispFlag",

  // Search
  ETD_FROM: "etdFrom",
  ETD_TO: "etdTo",
  ETA_FROM: "etaFrom",
  ETA_TO: "etaTo",
  EXPORTER_ID: "exporterId",
  DATA_OWNER: "dataOwner",
  USER_ID: "userId",
  USER_LOCATION: "uerLocation",
};
