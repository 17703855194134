import { useCustomQuery, useMutateData, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;

const masterApiService = () => ({
  //GET FORECAST_TYPE
  useGetForecastTypeQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/TMATH/FORECAST_TYPE/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //GET FORECAST MODE
  useGetForecastModeDescQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/TMATH/DATA_MODE/DESC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //GET FORECAST DATA_TYPE
  useGetForecastDataTypeQuery: () => {
    return useCustomQuery({
      url: `${BaseServicePath}/category/TMATH/YEARLY_MONTHLY_UPL_SCR_MONTHLY_DATA_TYPE/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
  },
  //GET FORECAST UPLOAD_STATUS
  useGetForecastUploadStatusQuery: () => {
    return useCustomQuery({
      url: `${BaseServicePath}/category/COMMON/UPLOAD_STATUS/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
  },
  //GET APMC FORECAST
  useGetApmcForecastDataTypeQuery: () => {
    return useCustomQuery({
      url: `${BaseServicePath}/category/TMATH/APMC_UPL_SCR_DATA_TYPE_CD/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
  },
  //GET APMC MODE
  useGetForecastModeAscQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/TMATH/DATA_MODE/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //GET OPERATION TYPE
  useShipmentOperationTypeQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/COMMON/OPERATION_TYPE/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //POST DOWNLOAD EXCEL TEMPLATE
  useDownloadTemplateMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body) => {
      return await mutate(
        {
          url: `${BaseServicePath}/download-upload/template/excel`,
          method: "POST",
          data: body,

          responseType: "arraybuffer",
        },
        {
          onSettled: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, `${fileName}`);
          },
        }
      );
    };
  },
});

export const {
  useGetForecastTypeQuery,
  useGetForecastModeDescQuery,
  useGetForecastModeAscQuery,
  useGetForecastDataTypeQuery,
  useGetForecastUploadStatusQuery,
  useGetApmcForecastDataTypeQuery,
  useShipmentOperationTypeQuery,
  useDownloadTemplateMutation,
} = masterApiService();
