import { Grid } from "@mui/material";
import React from "react";
import TemplateBar from "./TemplateBar";
import { InputUploadFile, LinkFile } from "components/UI";
import { responseDownloadFileErrors, responseErrors } from "utils";
import { MessageType } from "state/enum";
import { validationUploadFile } from "utils/validation";
import { isUndefined } from "lodash";
import { TEMPLATE_NAME_UPLOAD } from "../constants/uploadRpkg";
import { useDownloadTemplateMutation } from "shared/services/inventory-forecast";
import { TEMPLATE_FILE_CODE } from "shared/constants";
import { userProfile } from "constant";
import { useLoadingContext } from "shared/contexts/LoadingContext";

export default function HeaderSectionContainer({ form, setForm, setMsg: { setMsgError, setMsgAlert } }) {
  const { startLoading, stopLoading } = useLoadingContext();
  const exportTemplate = useDownloadTemplateMutation();
  // ? Upload File
  const uploadProcess = async (field, file, maxLength) => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      if (isUndefined(file)) {
        setForm(prev => ({
          ...prev,
          file: {},
          fileName: "",
        }));
        return true;
      }
      // validate
      const { isSuccess, errors } = validationUploadFile({
        file: file,
        rule: [
          {
            type: MessageType.TYPE_EXCEL,
          },
          {
            type: MessageType.FILE_NAME,
            key: [`Uploaded File Name`, maxLength],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        setForm({ file: "", fileName: "" });
        window.scrollTo(0, 0);
        return;
      }
      setForm(prev => ({
        ...prev,
        file: file,
        fileName: file.name,
      }));
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const handleDownloadTemplate = async templateName => {
    try {
      setMsgAlert([]);
      const infoFile = TEMPLATE_FILE_CODE.find(v => v.value === templateName);
      // const fileName = infoFile?.fileName;
      const body = { dataOwner: userProfile.dataOwner, cd: infoFile?.cd };
      startLoading();
      await exportTemplate(body);
      stopLoading();

      return true;
    } catch (error) {
      stopLoading();
      const msgError = responseDownloadFileErrors(error);
      setMsgError(msgError);
      return false;
    }
  };
  return (
    <Grid container spacing={2} sx={{ alignItems: "center" }}>
      <Grid item xs={12}>
        <strong>Please browse the Excel file, and click Upload button.</strong>
      </Grid>
      <Grid item xs={1}>
        <strong>*Excel File :</strong>
      </Grid>
      <Grid item xs={5}>
        <InputUploadFile
          id="input-upload-excel-container"
          fileType=".xlsx"
          required={true}
          onConfirmDelete={false}
          value={form?.file}
          uploadProcess={uploadProcess}
          maxLength={50}
        />
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <LinkFile
          id={TEMPLATE_NAME_UPLOAD.CONT_STATUS}
          component="button"
          variant="body2"
          value={TEMPLATE_NAME_UPLOAD.CONT_STATUS}
          onClick={() => handleDownloadTemplate(TEMPLATE_NAME_UPLOAD.CONT_STATUS)}
        />
      </Grid>
      <Grid></Grid>
    </Grid>
  );
}
