import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { DropDown } from "components/UI";
import ButtonBar from "./ButtonBar";
import { API_REPROCESS } from "shared/constants/api-name/common/reprocess";
import { DEFAULT_REPROCESS_UPLOAD } from "../constants/reprocess.constant";
import { ModeAction } from "state/enum";

export default function FilterBar(props) {
  const {
    mode,
    setMode,
    form,
    setForm,
    setFormUpload,
    rows,
    setRows,
    setOnSearch,
    refresh,
    setMsg,
    pagination,
    table: { rowModesModel, rowSelectionModel, setRowModesModel, setRowSelectionModel },
    isAdmin,
    dataList: { businessDataList, plantList, batchRoundList },
    functionId,
  } = props;

  const handleChangeBusinessData = event => {
    try {
      setFormUpload(DEFAULT_REPROCESS_UPLOAD);
      setMode(ModeAction.VIEW);
      setOnSearch(false);
      setRowModesModel({});
      setRowSelectionModel([]);
      setRows({});
      setMsg.setMsgError([])
      setForm(prev => ({
        ...prev,
        [API_REPROCESS.BUSINESS_NAME]: event.target.value,
        [API_REPROCESS.PLANT]: "",
        [API_REPROCESS.BATCH_ROUND]: "",
        [API_REPROCESS.FILE]: {},
      }));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <Grid item xs={12} sx={{ display: "flex", flexFlow: "row wrap" }}>
        <Grid item xs={2}>
          <strong>Business Data:</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-download-business-data"
            required
            value={form?.businessName}
            menu={businessDataList?.map(val => ({
              key: val?.businessName,
              value: val?.businessName,
            }))}
            onChange={handleChangeBusinessData}
            disabled={false}
            defaultValue=""
            placeholder="<Select>"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={{ display: "flex", flexFlow: "row wrap" }}>
        <Grid item xs={2}>
          <strong>Plant: </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-download-plant"
            value={form?.plant}
            menu={plantList?.map(val => ({
              key: val.plant,
              value: val.plant,
            }))}
            disabled={false}
            onChange={event => {
              setForm(prev => ({
                ...prev,
                [API_REPROCESS.PLANT]: event.target.value,
              }));
              setMsg.setMsgError([])
            }}
            defaultValue=""
            placeholder="<All>"
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} sx={{ display: "flex", flexFlow: "row wrap" }}>
        <Grid item xs={2}>
          <strong>Batch Round: </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-download-file-name"
            value={form?.batchRound}
            menu={batchRoundList?.map(val => ({
              key: val.batchRound,
              value: val.batchRound,
            }))}
            onChange={event => {
              setForm(prev => ({
                ...prev,
                [API_REPROCESS.BATCH_ROUND]: event.target.value,
              }));
              setMsg.setMsgError([])
            }}
            disabled={false}
            defaultValue=""
            placeholder="<All>"
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "flex-end",
            alignItems: "center",
            ml: 4,
          }}>
          <ButtonBar
            mode={mode}
            setMode={setMode}
            form={form}
            setForm={setForm}
            rows={rows}
            isAdmin={isAdmin}
            setRows={setRows}
            setOnSearch={setOnSearch}
            refresh={refresh}
            table={{
              rowModesModel,
              rowSelectionModel,
              setRowModesModel,
              setRowSelectionModel,
            }}
            setMsg={setMsg}
            pagination={pagination}
            functionId={functionId}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
