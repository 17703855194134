import { useMutateData, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
const RPkgTypeMasterApiService = () => ({
  useRPkgTypeMasterCreateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/r-package/type/create`,
        method: "POST",
        data: body,
      });
    };
  },
  useRPkgTypeMasterEditMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (body, rPkgTypeId) => {
      return await mutate({
        url: `${BaseServicePath}/r-package/type/edit/${rPkgTypeId}`,
        method: "PATCH",
        data: body,
      });
    };
  },
  useRPkgTypeMasterDownloadTemplateMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body) => {
      return await mutate(
        {
          url: `${BaseServicePath}/r-package/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
          },
        }
      );
    };
  },
});

export const {
  useRPkgTypeMasterCreateMutation,
  useRPkgTypeMasterEditMutation,
  useRPkgTypeMasterDownloadTemplateMutation,
} = RPkgTypeMasterApiService();
