import { getLocalDate } from "./init-config-date";

export const formatDate = (date = null, format = "YYYY-MM-DD") => {
  if (!date) return "";
  if (date instanceof Date === false) date = new Date(date);

  if (getLocalDate(date).isValid()) {
    return getLocalDate(date).format(format);
  }
  return "";
};

export const formatDateToSave = date => {
  if (!date) {
    return "";
  }
  if (date === "DD/MM/YYYY" || date === "") {
    return "";
  }

  if (!isValidDate(date, "DD/MM/YYYY")) {
    return "Invalid Date";
  }

  return getLocalDate(date, "DD/MM/YYYY").format("DD/MM/YYYY");
};

export const isValidDate = (date, format = "YYYY-MM-DD") => {
  return getLocalDate(date, format).format(format) === date;
};

export const isPastDate = date => {
  return getLocalDate(getLocalDate(date), "DD/MM/YYYY").diff(getLocalDate(), "day") >= 0;
};
