import { API_MONTHLY } from "shared/constants";

export default function transformResponse(dataList) {
  try {
    const data =
      dataList.map((item, index) => ({
        no: index + 1,
        [API_MONTHLY.ROW_NUMBER]: item?.rowNumber,
        [API_MONTHLY.FORECAST_TYPE]: item?.forecastType,
        [API_MONTHLY.FORECAST_MONTH]: item?.forecastMonth,
        [API_MONTHLY.DATA_TYPE]: item?.dataType,
        [API_MONTHLY.DATA_TYPE_CD]: item?.dataTypeCd,
        [API_MONTHLY.ORG_FILE_NAME]: item?.orgFileName,
        [API_MONTHLY.UPLOAD_STATUS]: item?.uploadStatus,
        [API_MONTHLY.UPLOAD_BY]: item?.uploadBy,
        [API_MONTHLY.UPLOAD_DATE]: item?.uploadDt,
        [API_MONTHLY.USE_STATUS]: item?.useSts,
        [API_MONTHLY.USE_DATE]: item?.useDt,
        [API_MONTHLY.USE_BY]: item?.useBy,
        [API_MONTHLY.FILE_UPLOAD_ID]: item?.fileUploadId,
        [API_MONTHLY.ROW_NUM]: item?.rowNum,
        [API_MONTHLY.UPDATE_DATE]: item?.updateDt,
        [API_MONTHLY.UPDATE_BY]: item?.updateBy,
        [API_MONTHLY.MODE_CD]: item?.modeCd,
        [API_MONTHLY.API_ID]: item?.aplId,
        [API_MONTHLY.MODULE_ID]: item?.moduleId,
        [API_MONTHLY.FUNCTION_ID]: item?.functionId,
        [API_MONTHLY.D_HODTCRE]: item?.dHODTCRE,
      })) ?? [];

    return data;
  } catch (error) {
    console.error(error);
  }
}
