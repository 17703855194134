import React, { memo, useState, useEffect, useRef } from "react";
import { TableRow, TableBody, Grid, Table, styled } from "@mui/material";
import { StyledTableCellHeader, StyledTableHead, StyledTableCellBody } from "./StyledTable";
import { useVirtualizer } from "@tanstack/react-virtual";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { isNull } from "lodash";
const StyledGrid = styled(Grid)(({ theme }) => {
  return {
    overflow: "auto", //our scrollable table container
    position: "relative", //needed for sticky header
    // minHeight: "300px", //should be a fixed height
    width: "100%",
    border: "none",
    borderRadius: ".5rem",
  };
});

const getMergeHeaderGroups = headerGroups => {
  if (headerGroups.length === 1) return headerGroups;
  const columnsIds = new Set();
  const result = headerGroups.map((group, depth, { length: fullDepth }) => ({
    ...group,
    headers: group.headers
      .filter(header => !columnsIds.has(header.column.id)) // Ignore already merged columns
      .map(header => {
        columnsIds.add(header.column.id);
        return header.isPlaceholder
          ? {
              ...header,
              // If the cell is placeholder(empty), then there will be no subgroup below it,
              // and this means that you can merge it with all lower cells in the column header
              isPlaceholder: false,
              rowSpan: fullDepth - depth,
            }
          : { ...header, rowSpan: 1 };
      }),
  }));
  return result;
};

const CustomTanStackTable = ({
  // initial table
  id,
  mode,
  setMode,
  getRowId,
  // rows
  data,
  // column
  columns,
  columnVisibility,
  processRowUpdate,
  tableHeight = "400px",
  //row selection
  rowSelectionKey,
  rowSelection,
  setRowSelect,
  enableRowSelection = false,
  enableMultiRowSelection = false,
  handleRowDisableSelect = null,
  handleRowSelectable,
  onRowSelectionChange,
  isShipment,
  isrPkg,
  rowsChanged,
}) => {
  const [rows, setRows] = useState([...data]);
  const [originalData] = useState([...data]);

  useEffect(() => {
    // console.log("data", data);
    setRows(data);
  }, [data]);

  const table = useReactTable({
    data: rows,
    columns: columns,
    columnResizeDirection: "ltr",
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: row => {
      if (enableRowSelection) {
        return isNull(handleRowSelectable) ? enableRowSelection : handleRowSelectable(row);
      } else {
        return false;
      }
    },
    enableMultiRowSelection: enableMultiRowSelection,
    onRowSelectionChange: fn => {
      onRowSelectionChange(fn);
    },
    getRowId: getRowId,
    state: {
      columnVisibility: columnVisibility,
      //   pagination: pagination,
      rowSelection: rowSelectionKey,
    },
    manualPagination: false,
    rowCount: data?.rowCount,
    meta: {
      updateData: ({ rowIndex, columnId, value }) => {
        try {
          let tempRowModesModel;
          let tempRowSelectModel;
          // multi select
          if (enableRowSelection && !enableMultiRowSelection) {
            // editable single row
            tempRowSelectModel = rowSelection.map(row => {
              if (row?.rowNumber === rowIndex) {
                return { ...row, [columnId]: value };
              }
              return row;
            });
            setRowSelect([...tempRowSelectModel]);
            return true;
          } else {
            // not use row selection feature
            const existId = rowSelection?.find(v => v?.rowNumber === rowIndex);
            const row = rows?.find(v => v.rowNumber === rowIndex);

            // check id has edit data before
            if (!existId) {
              tempRowModesModel = { ...row, [columnId]: value };
              setRowSelect(prev => [...prev, tempRowModesModel]);
              return true;
            } else {
              tempRowSelectModel = rowSelection?.map((item, index) => {
                if (item?.rowNumber === rowIndex) {
                  tempRowModesModel = { ...item, [columnId]: value };
                  return tempRowModesModel;
                }
                return item;
              });
              setRowSelect(tempRowSelectModel);
              return true;
            }
          }
        } catch (error) {
          console.error(error);
          return false;
        }
      },
    },
  });
  const { rows: row } = table?.getRowModel();
  //The virtualizer needs to know the scrollable container element
  const tableContainerRef = useRef();
  const rowVirtualizer = useVirtualizer({
    count: row.length,
    // estimateSize: () => 45, //estimate row height for accurate scrollbar dragging
    estimateSize: () => 20,
    getScrollElement: () => tableContainerRef.current,
    overscan: Math.ceil(row.length / 2) * 10,
  });
  // merge table group
  const handleHeaderTable = () => {
    const headerGroups = table.getHeaderGroups();
    const mergeHeaderGroup = getMergeHeaderGroups(headerGroups);
    return (
      <StyledTableHead>
        {mergeHeaderGroup.map(headerGroup => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <StyledTableCellHeader
                key={header.id}
                colSpan={header.colSpan}
                rowSpan={header.rowSpan}
                width={`${!header?.column?.columnDef?.size ? 100 : header?.column?.columnDef?.size}px`}
                sx={{
                  textAlign: !header?.column?.columnDef?.headerAlign
                    ? "center"
                    : `${header?.column?.columnDef?.headerAlign}`,
                }}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </StyledTableCellHeader>
            ))}
          </TableRow>
        ))}
      </StyledTableHead>
    );
  };

  //handle style row
  const handleStyleRow = props => {
    if (isShipment) {
      return props?.index % 4 === 0 || props?.index % 4 === 1 ? "#e3e3e3" : "white";
    }
    if (isrPkg) {
      if (rowsChanged.includes(props?.index + 1)) {
        return "#fcd5b4";
      } else {
        return props?.index % 2 === 0 ? "#e3e3e3" : "white";
      }
    }
    if (!enableRowSelection) {
      return props?.index % 2 === 0 ? "#e3e3e3" : "white";
    }
    if (props.getIsSelected()) {
      return "white";
    }
    if (!props.getCanSelect()) {
      return "#bababa";
    }
    return props?.index % 4 === 0 ? "#e3e3e3" : "white";
  };

  return (
    <>
      {row?.length > 0 && (
        <StyledGrid container spacing={0} ref={tableContainerRef} sx={{ height: `${tableHeight}` }}>
          <Grid item xs={12}>
            <Table id={id} data-testid={id}>
              {/* Get Table Header */}
              {handleHeaderTable()}
              <TableBody>
                {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
                  const item = row[virtualRow.index];
                  return (
                    <TableRow
                      // hover={true}
                      key={index}
                      sx={{
                        height: "12px",
                        // transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                      }}>
                      {item.getVisibleCells().map(cell => {
                        if (cell.column.columnDef.enableRowSpan) {
                          if (cell.row.original?.rowSpan) {
                            return (
                              <StyledTableCellBody
                                key={cell.id}
                                rowSpan={cell.row.original?.rowSpan}
                                sx={{
                                  backgroundColor: handleStyleRow(item),
                                  border: "1px solid #C4C4C4",
                                  textAlign: !cell?.column?.columnDef?.align
                                    ? "center"
                                    : `${cell?.column?.columnDef?.align}`,
                                }}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </StyledTableCellBody>
                            );
                          }
                          return <></>;
                        } else {
                          return (
                            <StyledTableCellBody
                              key={cell.id}
                              sx={{
                                border: "1px solid #C4C4C4",
                                textAlign: !cell?.column?.columnDef?.align
                                  ? "center"
                                  : `${cell?.column?.columnDef?.align}`,
                                backgroundColor: handleStyleRow(item),
                              }}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </StyledTableCellBody>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </StyledGrid>
      )}
    </>
  );
};

export default memo(CustomTanStackTable);
