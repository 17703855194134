import { useCustomQuery, useMutateData } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_INVENTORY;

const WareHouseSummaryApiService = () => ({
  //GET R-Package Owner
  useWareHouseSummaryGetRPkgOwnerQuery: () => {
    const data = useCustomQuery({
      key: "r-package-owner",
      url: `${BaseServicePath}/warehouse-allocation-summary/get-r-package-owner`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //GET Warehouse
  useWareHouseSummaryGetWareHouseQuery: dataOwner => {
    const data = useCustomQuery({
      key: "warehouse",
      url: `${BaseServicePath}/warehouse-allocation-summary/get-warehouse`,
      method: "GET",
      params: { rPkgOwner: dataOwner },
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //GET R-package type
  useWareHouseSummaryGetRPkgTypeQuery: ({ rPkgOwner, warehouse }) => {
    const data = useCustomQuery({
      key: "r-package-type",
      url: `${BaseServicePath}/warehouse-allocation-summary/get-r-package-type?rPkgOwner=${rPkgOwner}&warehouse=${warehouse}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //GET Holiday
  useWareHouseSummaryGetHolidayQuery: rPkgOwner => {
    const data = useCustomQuery({
      key: "r-package-type",
      url: `${BaseServicePath}/warehouse-allocation-summary/get-holiday-list?rPkgOwner=${rPkgOwner}`,
      method: "GET",
      enabled: rPkgOwner ? true : false,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },

  //GET search table
  useWareHouseSummarySearchTableMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/warehouse-allocation-summary/list`,
        method: "POST",
        data: body,
      });
    };
  },
  
  //GET search graph
  useWareHouseSummarySearchGraphMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (body, appId) => {
      return await mutate({
        url: `${BaseServicePath}/warehouse-allocation-summary/graph`,
        method: "POST",
        data: body,
        params: appId,
      });
    };
  },
  //GET Generate graph
  useWareHouseSummaryGenerateGraphMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/warehouse-allocation-summary/generate-graph`,
        method: "POST",
        data: body,
      });
    };
  },
  //GET Generate status graph
  useWareHouseSummaryStatusGenerateGraphMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async appId => {
      return await mutate({
        url: `${BaseServicePath}/warehouse-allocation-summary/graph-status`,
        method: "GET",
        params: appId,
      });
    };
  },
  //POST search graph
  useWarehouseConfirmAllocateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/warehouse-allocation-summary/confirm-allocation`,
        method: "POST",
        data: body,
      });
    };
  },
});
export const {
  useWareHouseSummaryGetRPkgOwnerQuery,
  useWareHouseSummaryGetWareHouseQuery,
  useWareHouseSummaryGetHolidayQuery,
  useWareHouseSummaryGetRPkgTypeQuery,
  useWareHouseSummarySearchTableMutation,
  useWareHouseSummarySearchGraphMutation,
  useWareHouseSummaryStatusGenerateGraphMutation,
  useWareHouseSummaryGenerateGraphMutation,
  useWarehouseConfirmAllocateMutation,
} = WareHouseSummaryApiService();
