import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Select, FormControl, InputLabel, styled } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { Margin } from "@mui/icons-material";

const StyledSelectField = styled(InputBase)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    borderRadius: ".3rem",
    padding: "5px 0px 5px 12px",
    color: `${theme.palette.primary.dark}`,
  },
  "& :focus-within": {
    border: `1.5px solid ${theme.palette.primary.dark} !important`,
    color: `${theme.palette.primary.dark}`,
  },
}));

export default function DropDown({
  id,
  value,
  menu,
  onChange,
  disabled = false,
  bgColor = "white",
  className = "",
  required,
  placeholder = "<Select>",
  defaultValue = "",
  autoFocus = false,
  onBlur,
  filterKey = null, // New prop to filter menu items by key
  ...rest
}) {
  // Filter menu items based on filterKey
  const filteredMenu = filterKey
    ? menu.filter((item) => item.key.includes(filterKey))
    : menu;

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel
        id="test-select-label"
        shrink={false}
        size="small"
        variant="outlined"
        style={{
          top: "50%",
          left: "5%",
          transform: "translateY(-50%)",
        }}
      >
        {value === "" && placeholder}
      </InputLabel>
      <Select
        {...rest}
        data-testid={id}
        id={id}
        sx={{ background: bgColor }}
        // size="small"
        value={value}
        label=""
        onChange={onChange}
        disabled={disabled}
        className={className}
        required={required}
        placeholder={placeholder}
        autoFocus={autoFocus}
        input={<StyledSelectField />}
      >
        <MenuItem value={defaultValue}>
          <span>{placeholder}</span>
        </MenuItem>
        {menu &&
          menu.map((val, index) => (
            <MenuItem key={index} value={val.key}>
              {val.value}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
