import { Typography } from '@mui/material';
import React, { Fragment } from 'react';

export default function CustomTypography({ content = '', ...props }) {
  return (
    <Fragment>
      <Typography {...props} paragraph={false}>
        {content}
      </Typography>
    </Fragment>
  );
}
