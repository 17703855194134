import useQuery from "hooks/useQuery";
import { useCustomQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
// export const useGetType = ({ dataOwner }) => {
//   return useQuery({
//     url: `/category/gentan-i/type/COMMON/GENTAN_I_TYPE/${dataOwner}`,
//     method: "GET",
//     enabled: true,
//     select: data => data.result,
//   });
// };
export const useGetType = ({ dataOwner }) => {
  const data = useCustomQuery({
    url: `${BaseServicePath}/category/gentan-i/type/COMMON/GENTAN_I_TYPE/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
    keepPreviousData: false,
  });
  return data;
};
export const useGetImpExpCdDropDown = ({ dataOwner, type }) => {
  return useQuery({
    url: `/category/gentan-i/imp-exp/${type}/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetImpExpCdTableDropDown = ({ dataOwner, type }) => {
  return useQuery({
    url: `/category/gentan-i/imp-exp/plant/${type}/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetRpkgTypeTableDropDown = ({ dataOwner, rPkgOwnerCompAbbr }) => {
  return useQuery({
    url: `/gentan-i/r-package/company/${dataOwner}/${rPkgOwnerCompAbbr}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetCarFamilyCodeTableDropDown = ({ dataOwner }) => {
  return useQuery({
    url: `/category/car/CAR_FAMILY_CD/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetSubSeriesTableDropDown = ({ dataOwner }) => {
  return useQuery({
    url: `/category/car/SUB_SERIES/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};
