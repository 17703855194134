import React, { useEffect, useMemo, useState } from "react";
import { Box, Stack, Link } from "@mui/material";
// SHARE COMPONENT
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import SubHeaderSection from "./components/SubHeaderSection";
import BodySectionApmc from "./components/BodySectionApmc";

// TYPE
import useColumnApmc from "pages/Forecast/MonthlyDataUploadScreen/hooks/useColumnApmc";

export default function ApmcSourceDataScreen(props) {
  const { mode, onSearch, setMode, formApmc, dataApmc, setOnOpen, onOpen, handleLinkUploadStatus } = props;
  const [rows, setRows] = useState(dataApmc?.data);
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  const columns = useMemo(
    () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useColumnApmc({ rows, mode, handleLinkUploadStatus }),
    [rows, mode]
  );
  useEffect(() => {
    setPagination(dataApmc?.searchData?.result?.pagination ?? {});
    setPageNumber(dataApmc?.searchData?.result?.pagination?.pageNumber);
    setRows(dataApmc?.data);
  }, [dataApmc]);
  return (
    <Box>
      <Stack sx={{ display: "flex", alignItems: "flex-start" }}>
        <Link href="#" color="#000" onClick={() => setOnOpen(!onOpen)} size="small" 
          // sx={{ fontSize: "12px" }}
        >
          Close
        </Link>
      </Stack>
      <Stack sx={{ alignItems: "center" }}>
        <HeaderContentBar title="WDN92011 : APMC Source Data Screen" />
      </Stack>
      <Stack sx={{ alignItems: "left" }}>
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
      </Stack>
      <Stack>
        <SubHeaderSection forecastMonth={formApmc?.forecastMonth} dataType={formApmc?.dataType} />
      </Stack>
      <Stack>
        <BodySectionApmc
          mode={mode}
          setMode={setMode}
          rows={rows}
          setRows={setRows}
          columns={columns}
          onSearch={onSearch}
          searchForm={formApmc}
          table={{ rowSelectionModel, setRowSelectionModel, setRowModesModel, rowModesModel }}
          pagination={{ pageNumber, pagination, setPageNumber, setPagination }}
          setMsg={{ setMsgError }}
        />
      </Stack>
      {/* </Grid> */}
    </Box>
  );
}
