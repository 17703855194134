import React, { useEffect } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { AlertMessage, InputButton, InputText } from "components/UI";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import "./ModalPossibleStatus.css";
import { usePossibleStatus } from "service/routepoint";
import { message, messageTypeDisplay } from "utils";
import { MessageType, ModeAction } from "state/enum";
import ConfirmDialog from "components/UI/ConfirmDialog";
import { HeaderContentBar } from "components/Layout";
import { getLocalDate } from "utils/init-config-date";

export function ModalPossibleStatus({ open = true, setOpen, setRows, rows = [], rowNumber, mode, functionId }) {
  const [msgError, setMsgError] = useState([]);

  const [selectedList, setSelectedList] = useState([]);
  const [isConfirmCancelOpen, setConfirmCancelOpen] = useState(false);

  useEffect(() => {
    const dataSelected = rows.find(v => v.rowNumber === rowNumber)?.possibleStatusId || [];

    setSelectedList(dataSelected);
  }, [rows, rowNumber, open]);

  const { data: possibleList } = usePossibleStatus({});

  const handleClose = () => {
    setConfirmCancelOpen(true);
  };

  const handleCancelClick = () => {
    setConfirmCancelOpen(true);
  };

  const handleCancelConfirm = () => {
    setOpen(false);
    setSelectedList([]);
    setMsgError([]);
  };

  const handleSaveClick = () => {
    // validate
    if (!selectedList.length) {
      setMsgError([message({ type: "empty", msg: "List of possible status" })]);
      return;
    }
    if (selectedList.some(v => parseInt(v.seqNo) === 0)) {
      setMsgError([message({ type: "moreThan0", msg: "Sequence" })]);
      return;
    }

    const newCurrency = selectedList.map((item, i) => ({
      ...item,
      seqNo: item.seqNo,
      routePointSts: item.routePointSts,
    }));

    setRows(prevRows => {
      const updatedRows = prevRows.map(row => {
        const itemRows = { ...row };
        if (row.rowNumber === rowNumber) {
          return {
            ...itemRows,
            possibleStatusId: newCurrency,
          };
        }
        return itemRows;
      });
      return updatedRows;
    });

    setOpen(false);
  };

  const handleAddList = cd => {
    const newData = possibleList.find(v => v.cd === cd);
    setSelectedList(old => [...old, { seqNo: selectedList.length + 1, routePointSts: newData.value }]);
  };

  const handleDeleteSelected = id => {
    const newArray = [...selectedList];
    newArray.splice(id, 1);
    setSelectedList(newArray);
  };

  const handleChangeSeqNo = (event, index) => {
    setSelectedList(prev => {
      return prev.map((item, i) => (i === index ? { ...item, seqNo: event.target.value } : item));
    });
  };

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose}>
        <DialogTitle>Possible Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box className="area-row-header">
              <div className="btn-close" onClick={handleClose}>
                Close
              </div>
              <div>User: UserPNG4</div>
            </Box>
            <Box className="area-row-header">
              {/* <HeaderContentBar title="WDN91031 : List of Possible Status Screen" /> */}
              <div>WDN91031 : List of Possible Status Screen</div>
              <div>{getLocalDate().format("DD MMM YYYY HH:mm")}</div>
            </Box>
          </DialogContentText>
          {/* AlertMessage */}
          <AlertMessage type={"warning"} message={msgError} />
          <Box className="box">
            <Box className="box-left">
              <div className="head">
                <span>Status</span>
                <span>Add</span>
              </div>
              <ul className="list-data">
                {possibleList?.map((val, i) => (
                  <li key={i}>
                    <div>
                      <span>{val.value}</span>
                    </div>
                    <div>
                      <InputButton
                        value="Add"
                        color="info"
                        size="small"
                        style={{
                          minWidth: "45px",
                          // fontSize: "10px",
                          padding: "2px",
                          textTransform: "none",
                        }}
                        onClick={() => handleAddList(val.cd)}
                        disabled={[ModeAction.VIEW, ModeAction.SELECTED].includes(mode)}
                        noRoleRequired={true}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </Box>
            <Box className="box-right">
              <div className="head">
                <span>Sequence</span>
                <span>List of possible status</span>
                <span>Delete</span>
              </div>
              <ul className="list-data-seleted">
                {selectedList?.map((val, i) => (
                  <li key={i}>
                    <div className="area-data-selected">
                      <div style={{ width: "50%", textAlign: "center" }}>
                        <InputText
                          type="text"
                          fullWidth={false}
                          style={{
                            width: "40px",
                            padding: 0,
                            textAlign: "center",
                          }}
                          value={val.seqNo}
                          onChange={e => {
                            handleChangeSeqNo(e, i);
                          }}
                          maxLength={1}
                          disabled={[ModeAction.VIEW, ModeAction.SELECTED].includes(mode)}
                        />
                      </div>
                      <div style={{ width: "50%" }}>
                        <span className="selected-name">{val.routePointSts}</span>
                      </div>
                    </div>
                    <div className="icon-delete">
                      <DeleteIcon
                        color={[ModeAction.VIEW, ModeAction.SELECTED].includes(mode) ? "disabled" : "error"}
                        fontSize="small"
                        onClick={() =>
                          ![ModeAction.VIEW, ModeAction.SELECTED].includes(mode) ? handleDeleteSelected(i) : null
                        }
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            {![ModeAction.VIEW, ModeAction.SELECTED].includes(mode) && (
              <>
                <InputButton onClick={handleSaveClick} color={"primary"} value={"Confirm"} name={"WDN91031Confirm"} />
                <InputButton onClick={handleCancelClick} color={"secondary"} value={"Cancel"} name={"WDN91031Cancel"} />
              </>
            )}
          </Box>
          <Box
            style={{
              background: "#EB0A1E",
              textAlign: "center",
              color: "#fff",
              padding: "4px 0",
              marginTop: "24px",
            }}>
            &copy; 2024 -TOYOTA MOTOR ASIA (THAILAND) CO., LTD. All Rights Reserved
          </Box>
        </DialogContent>
      </Dialog>
      {/* ConfirmDialog Close Modal */}
      <ConfirmDialog
        open={isConfirmCancelOpen}
        onClose={() => setConfirmCancelOpen(false)}
        onConfirm={() => {
          setConfirmCancelOpen(false);
          handleCancelConfirm();
        }}
        message={message({ type: "confirmCancel" })}
        noRoleRequired={true}
      />
    </React.Fragment>
  );
}
