import { useMutateData, useCustomQuery, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";

const BaseServicePath = process.env.REACT_APP_API_STANDARD;

const OnDemandExcelApiService = () => ({
  // GET REPORT STATUS
  useOnDemandExcelReportStatusQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/on-demand-excel/report/status`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  // POST SEARCH
  useOnDemandExcelSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/on-demand-excel/search`,
        method: "POST",
        data: searchData,
      });
    };
  },
  //DEL CANCEL
  useOnDemandExcelCancelMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (docId, cancelData) => {
      return await mutate({
        url: `${BaseServicePath}/on-demand-excel/cancel/${docId}`,
        data: cancelData,
        method: "POST",
      });
    };
  },
  //DEL DELETE
  useOnDemandExcelDeleteMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (docId, deleteData) => {
      return await mutate({
        url: `${BaseServicePath}/on-demand-excel/delete/${docId}`,
        data: deleteData,
        method: "DELETE",
      });
    };
  },
  //POST DOWNLOAD
  useOnDemandExcelDownloadMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body) => {
      return await mutate(
        {
          url: `${BaseServicePath}/on-demand-excel/download`,
          method: "POST",
          data: body,
          responseType: "blob"
        },
        {
          onSuccess: response => {
            saveAs(response);
            // console.log(data);
            // const blob = new Blob([data]);
            // (blob, fileName);
          },
        }
      );
      

    };
  },
});

export const {
  useOnDemandExcelReportStatusQuery,
  useOnDemandExcelSearchMutation,
  useOnDemandExcelCancelMutation,
  useOnDemandExcelDeleteMutation,
  useOnDemandExcelDownloadMutation,
} = OnDemandExcelApiService();
