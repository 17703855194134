import { ModeAction } from 'state/enum';
export const useButtonMode = (
  mode,
  setActivateForecastButton,
  setSearchButton,
  setClearButton,
  setAddButton,
  setEditButton,
  setDeleteButton
) => {
  switch (mode) {
    case ModeAction.VIEW:
      setActivateForecastButton(false);
      setSearchButton(false);
      setClearButton(false);
      setAddButton(false);
      setEditButton(true);
      setDeleteButton(true);
      break;

    case ModeAction.ADD:
      setActivateForecastButton(true);
      setSearchButton(true);
      setClearButton(true);
      setAddButton(true);
      setEditButton(true);
      setDeleteButton(true);
      break;

    case ModeAction.EDIT:
      setActivateForecastButton(true);
      setSearchButton(true);
      setClearButton(true);
      setAddButton(true);
      setEditButton(true);
      setDeleteButton(true);
      break;

    case ModeAction.SELECTED:
      setActivateForecastButton(false);
      setSearchButton(false);
      setClearButton(false);
      setAddButton(false);
      setEditButton(false);
      setDeleteButton(false);
      break;
    // case ModeAction.SEARCH:
    //   setActivateForecastButton(false);
    //   setSearchButton(false);
    //   setClearButton(false);
    //   setEditButton(false);
    //   setAddButton(false);
    //   setDeleteButton(false);
    //   break;
    default:
      break;
  }
};
