import React from "react";
import { EditableDatePicker, EditableTextField } from "components/UI";
import { COLUMN_EXPORT_INVOICE_CREATION_INFO } from "../constants/table";
import { ModeAction } from "state/enum";
import { API_EXPORT_INVOICE_CREATION_INFO } from "shared/constants/api-name/invoice/exportInvoiceCreation";
export const useColumn = ({ rows, mode, dataList: {} }) => {
  return [
    // Col 1
    {
      field: API_EXPORT_INVOICE_CREATION_INFO.ROW_NUMBER,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_INFO.NO,
      headerAlign: "center",
      align: "right",
      width: 50,
      sortable: false,
      editable: false,
      renderCell: params => {
        if (params?.row?.rowNumber === rows.slice(-1)?.[0]?.rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params.value;
      },
    },
    // Col 2
    {
      field: API_EXPORT_INVOICE_CREATION_INFO.SHIPMENT_CODE,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_INFO.SHIPMENT_CODE,
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 3
    {
      field: API_EXPORT_INVOICE_CREATION_INFO.INVOICE_NO,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_INFO.INVOICE_NO,
      headerAlign: "center",
      align: "left",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.invNo;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 4
    {
      field: API_EXPORT_INVOICE_CREATION_INFO.ETD_DATE,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_INFO.ETD_DATE,
      headerAlign: "center",
      align: "center",
      minWidth: 120,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 5
    {
      field: API_EXPORT_INVOICE_CREATION_INFO.IMPORTER_COMPANY,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_INFO.IMPORTER_COMPANY,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 6
    {
      field: API_EXPORT_INVOICE_CREATION_INFO.VESSEL_NAME,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_INFO.VESSEL_NAME,
      headerAlign: "center",
      align: "left",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.vesselName;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 7
    {
      field: API_EXPORT_INVOICE_CREATION_INFO.INVOICE_DATE,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_INFO.INVOICE_DATE,
      headerAlign: "center",
      align: "center",
      minWidth: 120,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.invDt;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableDatePicker {...params} required />;
        }
        return params.value;
      },
    },
    // Col 8
    {
      field: API_EXPORT_INVOICE_CREATION_INFO.INVOICE_NET_WEIGHT,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_INFO.INVOICE_NET_WEIGHT,
      headerAlign: "center",
      align: "right",
      minWidth: 120,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.invNetWeight;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 9
    {
      field: API_EXPORT_INVOICE_CREATION_INFO.INVOICE_FOB_PRICE,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_INFO.INVOICE_FOB_PRICE,
      headerAlign: "center",
      align: "right",
      minWidth: 120,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return (
          params.row[API_EXPORT_INVOICE_CREATION_INFO.INVOICE_FOB_PRICE] +
          " (" +
          params.row[API_EXPORT_INVOICE_CREATION_INFO.CURRENCY_CD] +
          ")"
        );
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 10
    {
      field: API_EXPORT_INVOICE_CREATION_INFO.EXP_CUSTOMS_STK_DATE,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_INFO.EXP_CUSTOMS_STK_DATE,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row[API_EXPORT_INVOICE_CREATION_INFO.EXP_CUSTOMS_STK_DATE];
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableDatePicker {...params} required />;
        }
        return params.value;
      },
    },
  ];
};
