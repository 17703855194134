import { userProfile } from "constant";
import useMutation from "hooks/useMutation";
import useQuery from "hooks/useQuery";
import _ from "lodash";

export const useCategory = () => {
  return useQuery({
    url: "/category/TMATH/R_PKG_CATEGORY/ASC",
    method: "GET",
    enabled: true,
    select: data => data.result,
    keepPreviousData: true,
  });
};

export const useGetOwner = () => {
  return useQuery({
    url: "/r-package/owner",
    method: "GET",
    enabled: true,
    select: data => data.result,
    keepPreviousData: true,
  });
};

export const useGetOwnerByDataOwner = dataOwner => {
  return useQuery({
    url: `/r-package/owner/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
    keepPreviousData: true,
  });
};

export const useGetType = ({ dataOwner, rPkgOwnerCompId }) => {
  // TODO: dynamic dataOwner
  return useQuery({
    url: `/r-package/type/owner/${dataOwner}/${rPkgOwnerCompId}`,
    method: "GET",
    enabled: rPkgOwnerCompId !== "",
    select: data => data.result,
  });
};

export const useGetCurrency = ({ dataOwner = userProfile.dataOwner }) => {
  return useQuery({
    url: `/category/${dataOwner}/CURRENCY_CD/ASC`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useSearchRpackage = ({ rPkgOwnerCompId, rPkgTypeId, rPkgDesc, pageNumber, rowsPerPage, dataOwner }) => {
  return useMutation({
    url: "/r-package/type/search",
    method: "POST",
    data: {
      rPkgOwnerCompId,
      rPkgTypeId,
      rPkgDesc,
      pageNumber,
      rowsPerPage,
      dataOwner,
    },
  });
};

export const useCreateRpackage = body => {
  return useMutation({
    url: "/r-package/type/create",
    method: "POST",
    data: { ...body },
  });
};

export const useUpdateRpackage = body => {
  return useMutation({
    url: "/r-package/type/edit",
    method: "POST",
    data: { ...body },
  });
};

export const useDeleteRpackage = rPkgTypeId => {
  return useMutation({
    url: `/r-package/type/delete/${rPkgTypeId}`,
    method: "DELETE",
    key: [rPkgTypeId],
    enabled: !_.isEmpty(rPkgTypeId),
  });
};

export const useDownloadExcel = ({ rPkgOwnerCompId }) => {
  return useMutation({
    url: `/r-package/download/excel`,
    method: "POST",
    data: { rPkgOwnerCompId },
  });
};
