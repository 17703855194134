import _ from "lodash"
import { formatCurrency } from "./format-currency";

export const setDataPriceList = (data = []) => {
    if (_.isEmpty(data)) return data;
    
    let result = "";

    data.map((v) => {
      result += v.price
        ? `${formatCurrency({ number: v.price })}(${
            v.currentCd
          })\n`
        : "";
    });
    
    return result;
}