import React, { Fragment, useMemo, useState, useEffect } from "react";
//Components
import { Box, Divider, Grid } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, CheckBox } from "components/UI";
import FilterBar from "./components/FilterBar";
import TableBar from "./components/TableBar";
import ActionBar from "./components/ActionBar";
import OptionsBar from "./components/OptionsBar";
import "./style.css";

import useColumn from "./hooks/useColumn";
// Constant
import { API_EXCEL, FIRST_PAGE } from "shared/constants";
import { DEFAULT_EXCEL_SCREEN } from "./constants/excelDownload.constant";
// Service
import {
  useGetExcelDownloadLogOptionQuery,
  useGetExcelDownloadBookmarksQuery,
  useGetExcelDownloadTableNameQuery,
} from "shared/services/common/monitoring";
export default function ExcelDownloadScreen() {
  const functionId = "WSTD0090";

  // Form
  const [form, setForm] = useState(DEFAULT_EXCEL_SCREEN);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(false);
  // Table
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const { data: logOprList } = useGetExcelDownloadLogOptionQuery();
  const userInfo = {
    [API_EXCEL.DATA_OWNER]: form?.dataOwner,
    [API_EXCEL.USER]: {
      [API_EXCEL.USER_INFO]: { [API_EXCEL.NAME]: form?.name },
      [API_EXCEL.ROLE]: [{ [API_EXCEL.ROLE_ID]: form?.roleId }],
    },
  };

  const { data: tableViewList } = useGetExcelDownloadTableNameQuery(userInfo);
  const { data: bookMarkList, refetch } = useGetExcelDownloadBookmarksQuery(userInfo, form?.tableName);

  const columns = useMemo(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    () => useColumn(logOprList),
    [logOprList]
  );

  useEffect(() => {
    document.getElementById("select-table-view").focus();
  }, []);

  return (
    <Fragment>
      <Box sx={{ padding: "1rem 1rem 0rem 1rem" }}>
        <HeaderContentBar title="WSTD0090 : Excel Download Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
      </Box>
      <Box sx={{ padding: "0 20px 0 20px" }}>
        <FilterBar
          refetch={refetch}
          dataList={{
            tableViewList: tableViewList,
            bookMarkList: bookMarkList,
          }}
          // firstRow={firstRow}
          // setFirstRow={setFirstRow}
          rows={rows}
          setRows={setRows}
          form={form}
          setForm={setForm}
          setOnSearch={setOnSearch}
          setMsg={{ setMsgError, setMsgAlert }}
          setTable={{ setRowModesModel, setRowSelectionModel, rowSelectionModel }}
        />
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <OptionsBar form={form} setForm={setForm} onSearch={onSearch} />
          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
            <Divider />
          </Grid>
        </Grid>
      </Box>
      {onSearch && (
        <>
          <Grid
            item
            xs={12}
            sx={{
              paddingRight: "1rem",
              margin: 1,
            }}>
            <TableBar
              rows={rows}
              columns={columns}
              setRows={setRows}
              setMode={setMode}
              setMsg={{ setMsgAlert, setMsgError }}
              setRowModesModel={setRowModesModel}
              rowModesModel={rowModesModel}
              rowSelectionModel={rowSelectionModel}
              setRowSelectionModel={setRowSelectionModel}
              pageNumber={FIRST_PAGE}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckBox
              id="check-big-data"
              placement="end"
              checked={form?.bigData}
              onChange={e => {
                setForm(prev => ({
                  ...prev,
                  [API_EXCEL.BIG_DATA]: e.target.checked,
                }));
              }}
              label="Big Data?"
              sx={{
                color: "#000",
                fontFamily: "Kanit",
                fontWeight: "bold",
                ml: 1,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              paddingRight: "1rem",
              mb: 1,
            }}>
            <ActionBar
              rows={rows}
              form={form}
              setMsg={{ setMsgError, setMsgAlert }}
              rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
              functionId={functionId}
            />
          </Grid>
        </>
      )}
      {/* </Grid> */}
      {/* </Box> */}
    </Fragment>
  );
}
