import React from "react";
import { Link } from "@mui/material";
export default function LinkTableCell({
  id,
  field,
  setValue = undefined,
  value,
  variant = "contained",
  color = "#0d47a1",
  component = "button",
  disabled = false,
  size = "medium",
  style = {},
  rest,
  params,
  processScreen = async () => {},
}) {
  const handleValueChange = event => {
    return processScreen(params?.row, event);
  };

  return (
    <Link
      {...rest}
      component={component}
      variant={variant}
      color={color}
      disabled={disabled}
      onClick={handleValueChange}
      size={size}
      underline="always"
      sx={{
        ...style,
      }}>
      {value}
    </Link>
  );
}
