import React, { Fragment } from 'react';
import { Grid, Divider } from '@mui/material';
import { InputText, Typography } from 'components/UI';
export default function SubHeaderSection({ forecastMonth, dataType }) {
  return (
    <Fragment>
      <Grid container spacing={0} columns={{ xs: 6 }} sx={{ alignItems: 'center' }}>
        <Grid item xs={1}>
          <Typography
            content="Forecast Month : "
            sx={{
              color: '#000',
              fontFamily: 'Kanit',
              fontWeight: 'bold',
            }}
            noWrap={false}
          />
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }}>
          <InputText disabled={true} value={forecastMonth} />
          <Typography
            content="(MM/YYYY)"
            sx={{
              color: '#000',
              fontFamily: 'Kanit',
              fontWeight: 'bold',
              ml: 2,
            }}
            noWrap={false}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}>
          <Typography
            content="Data Type :"
            sx={{
              color: '#000',
              fontFamily: 'Kanit',
              fontWeight: 'bold',
            }}
            noWrap={false}
          />
        </Grid>
        <Grid item xs={4}>
          <InputText disabled={true} value={dataType} />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
          <Divider />
        </Grid>
      </Grid>
    </Fragment>
  );
}
