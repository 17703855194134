import React, { Fragment } from "react";
// Components
import { Grid } from "@mui/material";
import { InputButton } from "components/UI";
// Hooks
import useLocalStorage from "shared/hooks/useLocalStorage";
// Utils
import { responseErrors, messageDisplay } from "utils";
// Service
import { useLogDetailSearchMutation } from "shared/services/common/monitoring";
// Type
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import { API_LOG_MONITORING } from "shared/constants/api-name/common/logMonitoring";
import { PAGINATION, ROW_PER_PAGE, DEFAULT_LOCAL_STATE, BUTTON_VALUE, FIRST_PAGE } from "shared/constants";
import { DEFAULT_STATE_DISPLAY } from "../../constants/logMonitoring";
import { validationSearchForm } from "utils/validation";
import { userProfile } from "constant";
import dayjs from "dayjs";
import { useLoadingContext } from "shared/contexts/LoadingContext";
export default function ButtonBar(props) {
  const {
    setRows,
    setMode,
    setOnSearch,
    searchForm,
    setSearchForm,
    column: { setColumnsHidden, displayColumns, setDisplayColumns },
    setMsg: { setMsgAlert, setMsgError },
    rowSelection: { setRowSelectionModel, setRowModesModel },
    pagination: { setPagination, setPageNumber },
    functionId,
  } = props;
  const { startLoading, stopLoading } = useLoadingContext();
  const [logDetail, setLogDetail] = useLocalStorage("logDetail");
  // api
  const searchLogMonitoring = useLogDetailSearchMutation();
  const handleSearch = async e => {
    try {
      e.preventDefault();
      setRows([]);
      setMsgError([]);
      setMsgAlert([]);
      setPageNumber(1);
      setRowSelectionModel([]);
      setPageNumber(FIRST_PAGE);
      setColumnsHidden({
        appId: !displayColumns.appId,
        moduleId: !displayColumns.moduleId,
        functionId: !displayColumns.functionId,
        status: !displayColumns.status,
        level: !displayColumns.levelId,
        userId: !displayColumns.userId,
      });

      const body = {
        [API_LOG_MONITORING.DATA_OWNER]: searchForm.dataOwner,
        [API_LOG_MONITORING.MODULE_ID]: searchForm.moduleId,
        funtionId: searchForm.functionId,
        [API_LOG_MONITORING.STATUS_ID]: searchForm.statusId,
        [API_LOG_MONITORING.LEVEL_ID]: searchForm.levelId,
        [API_LOG_MONITORING.USER_ID]: searchForm.userId,
        [API_LOG_MONITORING.APP_ID]: searchForm.appId,
        [API_LOG_MONITORING.DATE_FORM]: !searchForm?.dateFrom ? "" : searchForm?.dateFrom,
        [API_LOG_MONITORING.DATE_TO]: !searchForm?.dateTo ? "" : searchForm?.dateTo,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_LOG_MONITORING.DATE_FORM,
            type: MessageType.DATE_FORMAT_45,
            key: ["From Date", "DD/MM/YYYY"],
          },
          {
            field: API_LOG_MONITORING.DATE_TO,
            type: MessageType.DATE_FORMAT_45,
            key: ["To Date", "DD/MM/YYYY"],
          },
        ],
      });
      // MSTD0021AERR: To Date must be greater than From Date
      setMsgError(errors);
      let stopProcess = true;
      const dateFrom = dayjs(body?.dateFrom, "DD/MM/YYYY");
      if (dayjs(body?.dateTo, "DD/MM/YYYY").isBefore(dateFrom, "day")) {
        // MSTD0021AERR: To Date must be greater than From Date
        const output = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MSTD0021AERR",
          msg: ["To Date", "From Date"],
        });
        setMsgError(prev => [...prev, output]);
        stopProcess = false;
      }

      if (!isSuccess || !stopProcess) {
        window.scrollTo(0, 0);
        return;
      }
      // validate
      startLoading();
      const searchData = await searchLogMonitoring(body);
      stopLoading();
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_LOG_MONITORING.ROW_NUMBER]: item.rowNumber,
          [API_LOG_MONITORING.DATE]: item.date,
          [API_LOG_MONITORING.APP_ID]: item.aplId,
          [API_LOG_MONITORING.MODULE_ID]: item.moduleId,
          [API_LOG_MONITORING.LEVEL_ID]: item.messageType,
          [API_LOG_MONITORING.FUNCTION_ID]: item.functionId,
          [API_LOG_MONITORING.STATUS]: item.status,
          [API_LOG_MONITORING.MESSAGE_TYPE]: item.messageType,
          [API_LOG_MONITORING.MESSAGE]: item.message,
          [API_LOG_MONITORING.USER_ID]: item.userId,
        })) ?? [];
      if (!data.length) {
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: "" })]);
        return;
      }
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
      setOnSearch(true);
    } catch (error) {
      stopLoading()
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // handleClear
  const handleClear = () => {
    setMsgError([]);
    setMsgAlert([]);
    setMode(ModeAction.VIEW);
    setOnSearch(false);
    setRowModesModel({});
    setRowSelectionModel([]);
    setRows([]);
    setPageNumber(FIRST_PAGE);
    setPagination({});
    setDisplayColumns(DEFAULT_STATE_DISPLAY);
    setSearchForm({
      [API_LOG_MONITORING.DATA_OWNER]: userProfile.dataOwner,
      [API_LOG_MONITORING.MODULE_ID]: "",
      [API_LOG_MONITORING.FUNCTION_ID]: "",
      [API_LOG_MONITORING.STATUS_ID]: "",
      [API_LOG_MONITORING.LEVEL_ID]: "",
      [API_LOG_MONITORING.USER_ID]: "",
      [API_LOG_MONITORING.APP_ID]: "",
      [API_LOG_MONITORING.DATE_FORM]: dayjs().format("DD/MM/YYYY"),
      [API_LOG_MONITORING.DATE_TO]: dayjs().format("DD/MM/YYYY"),
    });
    setLogDetail(DEFAULT_LOCAL_STATE);
    document.getElementById("select_module").focus();
  };
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <InputButton
            id="btn_search"
            value={BUTTON_VALUE.SEARCH}
            onClick={handleSearch}
            name={`${functionId}Search`}
          />
          <InputButton id="btn_clear" value={BUTTON_VALUE.CLEAR} onClick={handleClear} name={`${functionId}Clear`} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
