import { Box, Grid } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import { InputEditTableCurrency, InputEditTableDropDown } from "components/UI";
import { userProfile } from "constant";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import DataTable from "pages/DataTable";
import ActionBar from "pages/Partials/ActionBar";
import SubmitBar from "pages/Partials/SubmitBar";
import React, { Fragment, useMemo, useState } from "react";
import {
  useCreateVolumeDimension,
  useDeleteVolumeDimension,
  useGetContainerTypeDropDown,
  useGetImpCdTableDropDown,
  useGetRpkgCategoryTableDropDown,
  useGetRpkgTypeTableDropDown,
  useGetparentRpkgTypeTableDropDown,
  useSearchVolumeDimension,
  useUpdateVolumeDimension,
  useVolumeRPkgDownloadExcelMutation,
} from "service/volumeDimension";
import { MessageType, ModeAction } from "state/enum";
import {
  findObject,
  formatCurrency,
  message,
  messageTypeDisplay,
  responseDownloadFileErrors,
  responseErrors,
} from "utils";
import { columEditTable } from "utils/columEditTable";
import {
  validationRequestErrors,
  validationSearchForm,
} from "utils/validation";
import { GridRowModes } from "@mui/x-data-grid";
import { getLocalDate } from "utils/init-config-date";

const RpackageTable = ({
  searchForm,
  setSearchForm,
  setMsgError,
  setMsgAlert,
  setOnSearch,
  onSearch,
  mode,
  setMode,
  refetchImporterData,
  refetchContainerTypeData,
  refetchRpkgTypeData,
}) => {
  const confirmDialogCtx = useConfirmDialogContext();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [rPkgCategoryCdTable, setRPkgCategoryCdTable] = useState("");
  const [parentRPkgTypeTable, setParentRPkgTypeTable] = useState("");

  // 1. pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const handleChangePagination = async (event, value) => {
    setPageNumber(value);
    const searchData = await searchDataAsync({
      ...searchForm,
      pageNumber: value,
      rowsPerPage: 10,
    });

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        ...item,
      })) ?? [];

    setPagination(searchData?.result?.pagination ?? {});
    setPageNumber(searchData?.result?.pagination?.pageNumber);

    setRows(data);
  };

  // 2. api
  const downloadExcel = useVolumeRPkgDownloadExcelMutation();
  const { mutateAsync: searchDataAsync } = useSearchVolumeDimension({});
  const { mutateAsync: createDataAsync } = useCreateVolumeDimension({
    type: "r-package",
  });
  const { mutateAsync: editDataAsync } = useUpdateVolumeDimension({
    type: "r-package",
    VolumeDimensionId: rows.find(
      (val) => val.rowNumber === rowSelectionModel[0]
    )?.rPkgDimensionId,
  });
  const { mutateAsync: deleteDataAsync } = useDeleteVolumeDimension({
    type: "r-package",
    VolumeDimensionId: rows.find(
      (val) => val.rowNumber === rowSelectionModel[0]
    )?.rPkgDimensionId,
  });
  const { data: importerTableData } = useGetImpCdTableDropDown({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  const { data: containerTypeTableData } = useGetContainerTypeDropDown({
    // type: "R_PACKAGE", // TODO: get dataOwner from profile
    type: "CONTAINER",
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  const { data: rpkgCategoryTableData } = useGetRpkgCategoryTableDropDown({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  const { data: parentRPkgTypeTableData } = useGetparentRpkgTypeTableDropDown({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
    parentRPkgTypeTable: parentRPkgTypeTable,
  });

  const { data: rpkgTypeTableData } = useGetRpkgTypeTableDropDown({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.rPkgOwnerCompAbbr,
    categoryCd: rPkgCategoryCdTable,
  });

  const columns = useMemo(
    () => [
      {
        field: "rowNumber",
        sortable: false,
        headerName: "No",
        align: 'right',
        width: 50,
        editable: false,
        renderHeader: () => <div></div>,
        renderCell: (params) => {
          if (
            params.row.rowNumber === rows.slice(-1)?.[0].rowNumber &&
            ModeAction.ADD === mode
          ) {
            return <div></div>;
          }
          // return params.value;
          return params.value;
        },
      },
      {
        field: "importer",
      },
      columEditTable({
        onFocus: true,
        field: "refImpExpCd",
        align: 'left',
        editable: mode === ModeAction.ADD,
        minWidth:
          mode === ModeAction.EDIT || mode === ModeAction.ADD ? 160 : 120,
        type: "dropdown",
        required: true,
        inputOption: {
          data: importerTableData,
          property: "refImpExpCd",
          field: "importer",
          memu: importerTableData?.map((val) => ({
            key: val.refImpExpCd,
            value: val.importer,
          })),
          placeholder: "<Select>",
        },
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Importer"],
          },
        ],
        renderCell: (params) => {
          return params.row.importer;
        },
        flex: 1,
      }),
      columEditTable({
        field: "containerType",
        editable: mode === ModeAction.ADD,
        minWidth: 120,
        type: "dropdown",
        required: true,
        inputOption: {
          data: containerTypeTableData,
          property: "containerType",
          field: "containerType",
          memu: containerTypeTableData?.map((val) => ({
            key: val.containerType,
            value: val.containerType,
          })),
          placeholder: "<Select>",
        },
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Container Type"],
          },
        ],
        renderCell: (params) => {
          return params.value;
        },
        flex: 1,
      }),
      {
        field: "rPkgCategoryCd",
        align: "left",
        headerAlign: "center",
        headerName: "",
        sortable: false,
        minWidth: 110,
        renderHeader: () => <div></div>,
        editable: mode === ModeAction.ADD,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["R-Package Category"],
          },
        ],
        renderEditCell: (params) => {
          if (params.value !== "") {
            setRPkgCategoryCdTable(params.value);
          }

          return (
            <InputEditTableDropDown
              {...params}
              required={true}
              disabled={false}
              // {...inputOption}
              memu={rpkgCategoryTableData?.map((val) => ({
                key: val.cd,
                value: val.value,
              }))}
              placeholder="<Select>"
            />
          );
        },
        renderCell: (params) => {
          const name =
            params.value &&
            findObject({
              data: rpkgCategoryTableData,
              value: params.value,
              property: "cd",
              field: "value",
            });
          return <p>{name}</p>;
        },
        flex: 1,
      },
      { field: "rPkgType" },
      { field: "containerTypeId" },
      {
        field: "rPkgTypeId",
        align: "left",
        headerAlign: "center",
        headerName: "",
        sortable: false,
        minWidth: 110,
        renderHeader: () => <div></div>,
        editable: mode === ModeAction.ADD,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["R-Package Type"],
          },
        ],
        renderEditCell: (params) => {
          return (
            <InputEditTableDropDown
              {...params}
              required={true}
              disabled={false}
              memu={rpkgTypeTableData?.map((val) => ({
                key: val.rPkgTypeId,
                value: val.rPkgType,
              }))}
              placeholder="<Select>"
            />
          );
        },
        renderCell: (params) => {
          const name = params.row.rPkgType;
          return <p>{name}</p>;
        },
        flex: 1,
      },
      {
        field: "lengthRatio",
        align: "right",
        headerAlign: "center",
        headerName: "Length",
        sortable: false,
        minWidth: 80,
        editable: true,
        rule: [
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Length", "0"],
          },
          {
            type: MessageType.EMPTY,
            key: ["Length"],
          },
        ],
        renderEditCell: (params) => {
          let lengthRatio =
            params.row.lengthRatio !== "" ? params.row.lengthRatio : 0;
          let widthRatio =
            params.row.widthRatio !== "" ? params.row.widthRatio : 0;
          let heightRatio =
            params.row.heightRatio !== "" ? params.row.heightRatio : 0;

          let sumTotal = lengthRatio * widthRatio * heightRatio;

          // console.log(sumTotal);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const apiRef = useGridApiContext();

          apiRef.current.setEditCellValue({
            id: params.id,
            field: "totalRatio",
            value: sumTotal,
          });

          return (
            <InputEditTableCurrency {...params} required maxLimit={1000} />
          );
        },
        renderCell: (params) => {
          return formatCurrency({ number: params.value, digits: 0 });
        },
        flex: 1,
      },
      {
        field: "widthRatio",
        align: "right",
        headerName: "Width",
        headerAlign: "center",
        sortable: false,
        minWidth: 80,
        editable: true,
        rule: [
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Width", "0"],
          },
          {
            type: MessageType.EMPTY,
            key: ["Width"],
          },
        ],
        renderEditCell: (params) => {
          let lengthRatio =
            params.row.lengthRatio !== "" ? params.row.lengthRatio : 0;
          let widthRatio =
            params.row.widthRatio !== "" ? params.row.widthRatio : 0;
          let heightRatio =
            params.row.heightRatio !== "" ? params.row.heightRatio : 0;

          let sumTotal = lengthRatio * widthRatio * heightRatio;

          // console.log(sumTotal);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const apiRef = useGridApiContext();

          apiRef.current.setEditCellValue({
            id: params.id,
            field: "totalRatio",
            value: sumTotal,
          });

          return (
            <InputEditTableCurrency {...params} required maxLimit={1000} />
          );
        },
        renderCell: (params) => {
          return formatCurrency({ number: params.value, digits: 0 });
        },
        flex: 1,
      },
      {
        field: "heightRatio",
        align: "right",
        headerName: "Height",
        headerAlign: "center",
        sortable: false,
        minWidth: 80,
        editable: true,
        rule: [
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Height", "0"],
          },
          {
            type: MessageType.EMPTY,
            key: ["Height"],
          },
        ],
        renderEditCell: (params) => {
          let lengthRatio =
            params.row.lengthRatio !== "" ? params.row.lengthRatio : 0;
          let widthRatio =
            params.row.widthRatio !== "" ? params.row.widthRatio : 0;
          let heightRatio =
            params.row.heightRatio !== "" ? params.row.heightRatio : 0;

          let sumTotal = lengthRatio * widthRatio * heightRatio;

          // console.log(sumTotal);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const apiRef = useGridApiContext();

          apiRef.current.setEditCellValue({
            id: params.id,
            field: "totalRatio",
            value: sumTotal,
          });

          return (
            <InputEditTableCurrency {...params} required maxLimit={1000} />
          );
        },
        renderCell: (params) => {
          return formatCurrency({ number: params.value, digits: 0 });
        },
        flex: 1,
      },
      columEditTable({
        field: "totalRatio",
        minWidth: 120,
        editable: true,
        type: "number",
        align: "right",
        maxLimit: 1000000,
        disabled: true,
        renderCell: (params) => {
          return formatCurrency({ number: params.value, digits: 0 });
        },
        flex: 1,
      }),
      columEditTable({
        field: "noOfStack",
        minWidth: 120,
        editable: true,
        type: "number",
        align: "right",
        required: true,
        maxLimit: 10000,
        rule: [
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Number of stack", "0"],
          },
          {
            type: MessageType.EMPTY,
            key: ["Number of stack"],
          },
        ],
        flex: 1,
      }),
      {
        field: "parentRPkgTypeId",
        align: "center",
        headerAlign: "center",
        headerName: "",
        sortable: false,
        minWidth: 120,
        renderHeader: () => <div></div>,
        editable: true,
        renderEditCell: (params) => {
          if (params.row.rPkgTypeId !== "") {
            setParentRPkgTypeTable(params.row.rPkgTypeId);
          }

          return (
            <InputEditTableDropDown
              {...params}
              // required={true}
              disabled={false}
              memu={parentRPkgTypeTableData?.map((val) => ({
                key: val.rPkgTypeId,
                value: val.rPkgType,
              }))}
              placeholder="<Select>"
            />
          );
        },
        renderCell: (params) => {
          const name = params.row.parentRPkgType;
          return <p>{name}</p>;
        },
        flex: 1,
      },
    ],
    [rows, rpkgTypeTableData, parentRPkgTypeTableData]
  );

  // 5. columnGroupingModel
  const columnGroupingModel = [
    {
      groupId: "No.",
      headerAlign: "center",
      children: [{ field: "rowNumber" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Importer",
      headerAlign: "center",
      children: [{ field: "refImpExpCd" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Container Type",
      headerAlign: "center",
      children: [{ field: "containerType" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "R-Package Category",
      headerAlign: "center",
      children: [{ field: "rPkgCategoryCd" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "R-Package Type",
      headerAlign: "center",
      children: [{ field: "rPkgTypeId" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "R-Package Ratio",
      headerAlign: "center",
      children: [
        { field: "lengthRatio" },
        { field: "widthRatio" },
        { field: "heightRatio" },
      ],
      headerClassName: "table-header-group",
    },
    {
      groupId: "Total Ratio (LxWxH)",
      headerAlign: "center",
      children: [{ field: "totalRatio" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Number of stack",
      headerAlign: "center",
      children: [{ field: "noOfStack" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Parent R-Package Type",
      headerAlign: "center",
      align: 'right',
      children: [{ field: "parentRPkgTypeId" }],
      headerClassName: "align-items-end",
    },
  ];

  const getSearch = async (pageNumber = 1) => {
    setOnSearch(true);
    const searchData = await searchDataAsync({
      ...searchForm,
      pageNumber: pageNumber,
      rowsPerPage: 10,
    });

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        ...item,
      })) ?? [];

    return { searchData, data };
  };

  const handleSearch = async (e) => {
    try {
      e.preventDefault();
      setRows([]);
      setMsgError([]);
      setMsgAlert([]);
      setRowSelectionModel([]);

      // validate
      // if (!searchForm.rPkgTypeId) {
      //   setMsgError((oldMsg) => [
      //     ...oldMsg,
      //     message({ type: "empty", msg: "R-Package Type" }),
      //   ]);
      //   return;
      // }

      const { searchData, data } = await getSearch();

      // data not found
      if (!data.length) {
        setMsgError([message({ type: "notFound" })]);
      }

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  // 9. handleClear
  const handleClear = () => {
    setMode(ModeAction.VIEW);
    setMsgError([]);
    setMsgAlert([]);
    setRowSelectionModel([]);
    setOnSearch(false);
    setSearchForm((prev) => ({
      ...prev,
      dataOwner: userProfile.dataOwner,
      dimensionType: "R",
      refCompanyAbbr: "",
      refImpExpCd: "",
      rPkgType: "",
      containerType: "",
    }));
    setRows([]);
  };

  // 10. handleDownloadExcel
  const handleDownloadExcel = async () => {
    // validate
    setMsgError([]);
    setMsgAlert([]);
    try {
      const { data } = await getSearch();
      if (!data.length) {
        const msg = messageTypeDisplay(MessageType.NOT_FOUND);
        setMsgError((old) => [...old, msg]);
        return;
      }
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
    try {
      const option = {
        dataOwner: userProfile.dataOwner,
        ...searchForm,
        pageNumber: pageNumber,
        rowsPerPage: 10,
      };
      // const filename = `Volumn_Dimension_Master_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;
      await downloadExcel(option);
      return;
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const handleDeleteClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
    if (!confirm) {
      return;
    }
    try {
      await deleteDataAsync({
        updateBy: userProfile.userId, // TODO: get dataOwner from profile
      });

      // const { searchData, data } = await getSearch(pageNumber);

      // setPagination(searchData?.result?.pagination ?? {});
      // setPageNumber(searchData?.result?.pagination?.pageNumber);
      // setRows(data);

      // setRowSelectionModel([]);
      // setMode(ModeAction.VIEW);

      const { searchData, data } = await getSearch();
      if (data.length === 0) {
        handleClear();
      } else {
        setMode(ModeAction.VIEW);
        setRowSelectionModel([]);
        setRows(data);
      }

      const msg = messageTypeDisplay(MessageType.DELETED);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      // Refetch Dropdown
      refetchContainerTypeData();
      refetchImporterData();
      refetchRpkgTypeData();
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const processRowUpdate = async (newRow) => {
    setMsgError([]);
    setMsgAlert([]);

    console.log("-newRow", newRow);

    // validate
    const { isSuccess, errors } = validationRequestErrors({
      columns: columns,
      data: newRow,
    });

    if (!isSuccess) {
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
    if (newRow.isNew) {
      // to do save
      try {
        await createDataAsync({
          dataOwner: userProfile.dataOwner,
          rPkgOwnerCompAbbr: userProfile.packageOwner,
          refCompanyId: importerTableData.find(
            (val) => val.refImpExpCd === newRow.refImpExpCd
          )?.refCompanyId,
          refCompanyAbbr: importerTableData?.find(
            (val) => val.refImpExpCd === newRow.refImpExpCd
          )?.refCompanyAbbr,
          refPalntId: importerTableData?.find(
            (val) => val.refImpExpCd === newRow.refImpExpCd
          )?.refPalntId,
          containerType: containerTypeTableData?.find(
            (val) => val.containerType === newRow.containerType
          )?.containerType,
          rPkgType: rpkgTypeTableData?.find(
            (val) => val.rPkgTypeId === newRow.rPkgTypeId
          )?.rPkgType,
          // rPkgType: newRow.rPkgType,
          parentRPkgType: findObject({
            data: parentRPkgTypeTableData,
            value: newRow.parentRPkgTypeId,
            property: "rPkgTypeId",
            field: "rPkgType",
          }),
          parentRPkgTypeId: findObject({
            data: parentRPkgTypeTableData,
            value: newRow.parentRPkgTypeId,
            property: "rPkgTypeId",
            field: "rPkgTypeId",
          }),
          parentRPkgOwnerCompAbbr:
            findObject({
              data: parentRPkgTypeTableData,
              value: newRow.parentRPkgTypeId,
              property: "rPkgTypeId",
              field: "rPkgOwnerCompAbbr",
            }) ?? "",
          refImpExpCd: newRow.refImpExpCd,
          containerTypeId: containerTypeTableData?.find(
            (val) => val.containerType === newRow.containerType
          )?.containerTypeId,
          rPkgTypeId: newRow.rPkgTypeId,
          lengthRatio: parseInt(newRow.lengthRatio.replace(",", "")),
          widthRatio: parseInt(newRow.widthRatio.replace(",", "")),
          heightRatio: parseInt(newRow.heightRatio.replace(",", "")),
          noOfStack: parseInt(newRow.noOfStack.replace(",", "")),
          // ...newRow,
          createBy: userProfile.userId, // TODO: get dataOwner from profile
        });

        const msg = messageTypeDisplay(MessageType.ADDED);
        setMsgAlert([msg]);
        window.scroll(0, 0);

        refetchImporterData();
        refetchContainerTypeData();
        refetchRpkgTypeData();
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    } else {
      // to do edit
      try {
        console.log(newRow.parentRPkgTypeId);
        await editDataAsync({
          ...newRow,
          lengthRatio: parseInt(newRow.lengthRatio.replace(",", "")),
          widthRatio: parseInt(newRow.widthRatio.replace(",", "")),
          heightRatio: parseInt(newRow.heightRatio.replace(",", "")),
          noOfStack: parseInt(newRow.noOfStack.replace(",", "")),
          parentRPkgType: findObject({
            data: parentRPkgTypeTableData,
            value: newRow.parentRPkgTypeId,
            property: "rPkgTypeId",
            field: "rPkgType",
          }),
          parentRPkgTypeId: findObject({
            data: parentRPkgTypeTableData,
            value: newRow.parentRPkgTypeId,
            property: "rPkgTypeId",
            field: "rPkgTypeId",
          }),
          parentRPkgOwnerCompAbbr: findObject({
            data: parentRPkgTypeTableData,
            value: newRow.parentRPkgTypeId,
            property: "rPkgTypeId",
            field: "rPkgOwnerCompAbbr",
          }),
          updateBy: userProfile.userId, // TODO: get dataOwner from profile
        });

        const msg = messageTypeDisplay(MessageType.UPDATED);
        setMsgAlert([msg]);
        window.scroll(0, 0);
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    }

    if (rows.length === 1 && ModeAction.ADD === mode) {
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRows([]);
    } else {
      const { searchData, data } = await getSearch(pageNumber);

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    }

    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  const handleCopyClick = () => {
    setMode(ModeAction.ADD);
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);
    // setDownloadButton(true);

    const rowCopy = rows.find((v) => v.rowNumber === rowSelectionModel[0]);
    const maxNo =
      !rows || rows.length === 0
        ? 1
        : Math.max(...rows.map((item) => item.rowNumber));

    console.log("+++rows", rows);
    console.log("+++rowCopy", rowCopy);

    setRows((oldRows) => [
      ...oldRows,
      {
        ...rowCopy,
        rowNumber: maxNo + 1,

        isNew: true,
      },
    ]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [maxNo + 1]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([maxNo + 1]);
  };

  return (
    <Fragment>
      <>
        <ActionBar
          mode={mode}
          rows={rows}
          columns={columns}
          setRows={setRows}
          setMode={setMode}
          setMsgError={setMsgError}
          setMsgAlert={setMsgAlert}
          setOnSearch={setOnSearch}
          setRowModesModel={setRowModesModel}
          setRowSelectionModel={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          rowModesModel={rowModesModel}
          handleSearch={handleSearch}
          handleClear={handleClear}
          handleDownloadExcel={handleDownloadExcel}
          handleDeleteClick={handleDeleteClick}
          handleCopyClick={handleCopyClick}
          firstField={"select_dimensionType"}
          functionId={"WDN91060"}
        />
        <Box sx={{ padding: "1rem" }}>
          <Grid>
            {onSearch && (
              <DataTable
                mode={mode}
                onSearch={onSearch}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                setMode={setMode}
                rows={rows}
                rowModesModel={rowModesModel}
                columns={columns}
                setRowModesModel={setRowModesModel}
                processRowUpdate={processRowUpdate}
                pagination={pagination}
                pageNumber={pageNumber}
                handleChangePagination={handleChangePagination}
                columnGroupingModel={columnGroupingModel}
                columnVisibilityModel={{
                  importer: false,
                  rPkgType: false,
                  containerTypeId: false,
                }}
                columnHeaderHeight={40}
              />
            )}
            <SubmitBar
              mode={mode}
              rows={rows}
              setMode={setMode}
              setRows={setRows}
              setRowModesModel={setRowModesModel}
              setMsgError={setMsgError}
              rowModesModel={rowModesModel}
              rowSelectionModel={rowSelectionModel}
              setRowSelectionModel={setRowSelectionModel}
              functionId={"WDN91060"}
            />
          </Grid>
        </Box>
      </>
    </Fragment>
  );
};

export default RpackageTable;
