import { userProfile } from "constant";
import dayjs from "dayjs";

const { API_LOG_MONITORING } = require("shared/constants/api-name/common/logMonitoring");

const DEFAULT_STATE_DISPLAY = {
  [API_LOG_MONITORING.APP_ID]: true,
  [API_LOG_MONITORING.MODULE_ID]: true,
  [API_LOG_MONITORING.FUNCTION_ID]: true,
  [API_LOG_MONITORING.STATUS]: false,
  [API_LOG_MONITORING.LEVEL_ID]: false,
  [API_LOG_MONITORING.USER_ID]: false,
};
// const DEFAULT_STATE_COLUMN = {};

const DEFAULT_FORM = {
  [API_LOG_MONITORING.DATA_OWNER]: userProfile.dataOwner,
  [API_LOG_MONITORING.MODULE_ID]: "",
  [API_LOG_MONITORING.FUNCTION_ID]: "",
  [API_LOG_MONITORING.STATUS_ID]: "",
  [API_LOG_MONITORING.LEVEL_ID]: "",
  [API_LOG_MONITORING.USER_ID]: "",
  [API_LOG_MONITORING.APP_ID]: "",
  [API_LOG_MONITORING.DATE_FORM]: dayjs().format("DD/MM/YYYY"),
  [API_LOG_MONITORING.DATE_TO]: dayjs().format("DD/MM/YYYY"),
};
export { DEFAULT_STATE_DISPLAY, DEFAULT_FORM };
