export const API_EXCEL = {
  ROW_NUMBER: "rowNumber",
  BOOK_MARKS: "bookMasks",
  SAVE_BOOK_MARK: "saveBookMask",
  OPTION_DISP: "optDisp",
  PK_OPTION: "pkOpt",

  DATA_OWNER: "dataOwner",
  USER: "user",
  USER_INFO: "userInfo",
  NAME: "name",
  EMAIL: "email",
  ROLE: "role",
  ROLE_ID: "roleId",
  SETTING_ID: "settingId",
  FIELD: "field",

  TABLE_NAME: "tableName",
  REPORT_NAME: "reportName",
  REPORT_TITLE: "reportTitle",
  START_ROW: "startRow",
  START_COLUMN: "startColumn",
  COLUMNS: "columns",
  DISPLAY_NAME: "displayName",
  ORDER_DISPLAY: "ordDisp",
  PK: "pk",
  DATA_TYPE: "dataType",
  CRITERIA: "criteria",
  LOGICAL_OPERATION: "logOpr",
  DISPLAY_OPTION: "dispOpt",
  SORT: "sort",
  BIG_DATA: "bigData",
};
