const DEFAULT_EXPORT_INVOICE_INFO = {
  importerId: "",
  vesselName: "",
  invoiceNo: "",
  etdFrom: "",
  etdTo: "",
  reportFormatCd: "",
  totalsM3Flag: true,
};

const DEFAULT_STATE_WDN95011 = {
  dataOwner: "",
  invNo: "",
  invType: "",
  etdDt: "",
  vesselName: "",
  impCompanyId: "",
  exporterPlantId: "",
  parkingMonth: "",
  vanningPlant: "",
  expBrokerCompanyAbbr: "",
  expBrokerCompanyId: "",
  expCustomsStkDt: "",
  nonComInvFlag: true,
  broker: "",
  currencyCd: "",
  expEntryNo: "",
  shipmentEtd: "",
  carrierCompanyAbbr: "",
  updateDt: "",
  updateBy: "",
  nonComInvHId: "",
  exporterCd: "",
};

const DEFAULT_STATE_WDN95012 = {
  etdDt: "",
  containerNo: "",
  companyId: "",
};

export { DEFAULT_EXPORT_INVOICE_INFO, DEFAULT_STATE_WDN95011, DEFAULT_STATE_WDN95012 };
