import { ModeAction } from "state/enum";
export default function useButtonMode(props) {
  const {
    mode,
    stateButton: { setSearchButton, setClearButton, setAddButton, setEditButton, setDeleteButton },
  } = props;
  switch (mode) {
    case ModeAction.VIEW:
      setSearchButton(false);
      setClearButton(false);
      setAddButton(false);
      setEditButton(true);
      setDeleteButton(true);
      break;

    case ModeAction.ADD:
      setSearchButton(true);
      setClearButton(true);
      setAddButton(true);
      setEditButton(true);
      setDeleteButton(true);
      break;

    case ModeAction.EDIT:
      setSearchButton(true);
      setClearButton(true);
      setAddButton(true);
      setEditButton(true);
      setDeleteButton(true);
      break;

    case ModeAction.SELECTED:
      setSearchButton(false);
      setClearButton(false);
      setAddButton(false);
      setEditButton(false);
      setDeleteButton(false);
      break;
    case ModeAction.SEARCH:
      setSearchButton(false);
      setClearButton(false);
      setEditButton(false);
      setAddButton(false);
      setDeleteButton(false);
      break;
    default:
      break;
  }
}
