import React, { Fragment } from "react";
import { TanStackTable } from "components/UI";
//Service
export default function TableSection(props) {
  const {
    mode,
    rows,
    columns,
    rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
    rowsChanged,
  } = props;

  return (
    <Fragment>
      <TanStackTable
        id="table-bar"
        rows={rows}
        columns={columns}
        mode={mode}
        rowSelectionKey={rowModesModel}
        setRowSelectionKey={setRowModesModel}
        rowSelection={rowSelectionModel}
        setRowSelect={setRowSelectionModel}
        columnVisibility={{
          wareHouseName: false,
          whCompanyAbbrId: false,
          whCompanyAbbr: false,
        }}
        tableHeight="600px"
        enableRowSelection={false}
        enableMultiRowSelection={true}
        onPagination={false}
        isrPkg={true}
        rowsChanged={rowsChanged}
      />
    </Fragment>
  );
}
