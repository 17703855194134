import React, { useState, useCallback, useEffect } from "react";
import { Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomButton = styled(Button)(({ theme }) => ({
  position: "relative",
  // display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const CustomCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  marginRight: "8px", // Adjust spacing between spinner and text
}));
const LoadingButton = ({
  id,
  value,
  variant = "contained",
  color = "primary",
  type = "button",
  disabled = false,
  onClick = undefined,
  size = "medium",
  style = {},
  props,
  minWidth = "100px",
  onKeyDown,
  loading,
  name,
}) => {
  const [hasPermission, setHasPermission] = useState(true);
  // const appRole = "DN9TISXXXX.tmap-em.toyota.co.th.asia"
  // const getRoles = useCallback(async () => {
  //   try {
  //     const roles = await fetch(
  //     "https://pgn-api-dev.toyota.co.th/web-api-common/access-control/function",
  //     {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json", },
  //       body: JSON.stringify({ appRole, }),
  //     });
  //     const { result: { functions }, } = await roles.json();
  //     //console.log(functions);
  //     const elements = functions?.find(({ screen }) => name.includes(screen.id))?.screen?.elements;
  //     if (!elements?.length) return;
  //     setHasPermission(elements.find(({ id }) => id === name)); }
  //   catch (error) {
  //     console.error("Error fetching roles:", error);
  //   }
  // }, [name]);

  // useEffect(() => { getRoles(); }, [getRoles]);

  //return (
  return (
    hasPermission && (
      <CustomButton
        {...props}
        data-testid={id}
        id={id}
        type={type}
        variant={variant}
        color={color}
        disabled={disabled || loading}
        onClick={onClick}
        size={size}
        onKeyDown={onKeyDown}
        style={{
          textTransform: "none",
          marginRight: "8px",
          minWidth: minWidth,
          ...style,
        }}>
        {loading && <CustomCircularProgress size={24} />}
        <span>{value}</span>
      </CustomButton>
    )
  );
};

export default LoadingButton;
