import React from "react";
import { useGridApiContext } from "@mui/x-data-grid";
import { InputButton } from ".";
import { ModeAction } from "state/enum";

const InputEditTableFile = ({
  field,
  id,
  disabled = false,
  setValue = undefined,
  className,
  value,
  uploadProcess = async () => {},
}) => {
  const style = {
    border: "1px solid #ccc",
    padding: "8px 15px",
    cursor: "pointer",
    background: "#dbdbdb",
    borderRadius: "4px",
    maring: "auto",
  };
  const apiRef = useGridApiContext();
  const [filename, setFilename] = React.useState(null);
  const [isLabelFileVisible, setIsLabelFileVisible] = React.useState(true);

  const handleValueChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (setValue) setValue(file.name);

    const fileid = await uploadProcess(field, file);
    fileid && setFilename(file.name);
    fileid && apiRef.current.setEditCellValue({ id, field, value: fileid });
  };

  const handleDeleteClick = () => {
    setIsLabelFileVisible(false);
    setFilename(false);
    apiRef.current.setEditCellValue({ id, field, value: "" });
  };

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      {!filename && !value ? (
        <label style={style}>
          Upload
          <input
            type="file"
            accept="image/*"
            onChange={handleValueChange}
            disabled={disabled}
            className={className}
            style={{ display: "none" }}
          />
        </label>
      ) : (
        <></>
      )}
      {(filename && isLabelFileVisible) || value ? (
        <div
          class="label-file"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              margin: "unset",
              width: "65%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              height: "1.2em",
              whiteSpace: "nowrap",
            }}
          >
            {" "}
            {filename ?? value}{" "}
          </p>
          <InputButton
            value={"X"}
            color="secondary"
            size="small"
            onClick={handleDeleteClick}
            style={{
              minWidth: "26px",
              height: "24px",
              color: "#ffff",
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>

    // <div style={{ width: "100%", textAlign: "center" }}>
    //   {!filename ? (
    //     <label style={style}>
    //       Upload
    //       <input
    //         type="file"
    //         accept="image/*"
    //         onChange={handleValueChange}
    //         disabled={disabled}
    //         className={className}
    //         style={{ display: "none" }}
    //       />
    //     </label>
    //   ) : (
    //     <></>
    //   )}
    //   {filename && isLabelFileVisible ? (
    //     <div
    //       class="label-file"
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <p
    //         style={{
    //           margin: "unset",
    //           width: "65%",
    //           textOverflow: "ellipsis",
    //           overflow: "hidden",
    //           height: "1.2em",
    //           whiteSpace: "nowrap",
    //         }}
    //       >
    //         {filename ?? value}
    //       </p>
    //       <InputButton
    //         value={"X"}
    //         color="secondary"
    //         size="small"
    //         onClick={handleDeleteClick}
    //         style={{
    //           minWidth: "26px",
    //           height: "24px",
    //           color: "#ffff",
    //         }}
    //       />
    //     </div>
    //   ) : (
    //     <></>
    //   )}
    // </div>
  );
};

export default InputEditTableFile;
