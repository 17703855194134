export default function ColWDN97053() {
  return [
    {
      field: "rowNumber",
      headerName: "No.",
      headerAlign: "center",
      align: "right",
      width: 50,
      sortable: false,
      editable: false,
    },
    {
      field: "actualDlvDt",
      headerName: "Actual Delivery Date (DD/MM/YYYY HH:MM:SS)",
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    {
      field: "dlvInstrShtNo",
      headerName: "Delivery Instruction Sheet No.",
      headerAlign: "center",
      align: "left",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    {
      field: "sender",
      headerName: "Sender",
      headerAlign: "center",
      align: "left",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    {
      field: "receiver",
      headerName: "Receiver",
      headerAlign: "center",
      align: "left",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    {
      field: "shippingInfo",
      headerName: "Shipping Information",
      headerAlign: "center",
      align: "left",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    {
      field: "containerNo",
      headerName: "Container No.",
      headerAlign: "center",
      align: "left",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    {
      field: "packSts",
      headerName: "Pack Status",
      headerAlign: "center",
      align: "left",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
  ];
}
