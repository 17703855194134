import { Box, Link, Stack } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import CriterialSection from "./components/CriterialSection";
import { userProfile } from "constant";
import {
  useExportInvoiceCategoryBrokerQuery,
  useExportInvoiceCategoryCurrencyQuery,
  useExportInvoiceCategoryQuery,
  useExportInvoiceExpCompanyQuery,
  useExportInvoiceOrderTypeQuery,
  useExportInvoiceSearchSubScreenMutation,
} from "shared/services/invoice";
import ButtonCriterial from "./components/ButtonCriterial";
import { FIRST_PAGE, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import DetailSection from "./components/DetailSection";
import TableSection from "./components/TableSection";
import { useColumnCreationSubScreen } from "../../hooks/useColumnCreationSubScreen";
import { ModeAction } from "state/enum";
import { transformSubScreenData } from "../../hooks/tranformData";
import { findObject, responseErrors } from "utils";
import { DEFAULT_STATE_WDN95011 } from "../../constants/constants";

export default function CreateInvoiceSubScreen({ onOpen, setOnOpen, alertMain, form, setForm, refreshRowsTableMain }) {
  const confirmDialogCtx = useConfirmDialogContext();
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [mode, setMode] = useState(ModeAction.VIEW);
  const [onSearch, setOnSearch] = useState(false);

  // Table
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(FIRST_PAGE);

  //Button State
  const [searchBtn, setSearchBtn] = useState(false);
  const [clearBtn, setClearBtn] = useState(false);

  const [shipmentEtdData, setShipmentEtdData] = useState([]);
  const [shipCarrierData, setShipCarrierData] = useState([]);

  // API
  const { data: importerData } = useExportInvoiceCategoryQuery();
  const { data: invoiceOrderTypeData } = useExportInvoiceOrderTypeQuery();
  const { data: expData } = useExportInvoiceExpCompanyQuery(userProfile.comAbbr);
  const { data: brokerData } = useExportInvoiceCategoryBrokerQuery("all");
  const { data: currencyData } = useExportInvoiceCategoryCurrencyQuery();
  const searchExportSubScreenInfo = useExportInvoiceSearchSubScreenMutation();

  // columns
  const columns = useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColumnCreationSubScreen({
      rows,
      mode: mode,
      dataList: {},
    });
  }, [rows, mode]);

  const handleClose = async () => {
    const confirm = await confirmDialogCtx.success({ type: "closeScreen" });
    if (!confirm) {
      return;
    }
    setForm(DEFAULT_STATE_WDN95011);
    setOnOpen({ open: false });
  };

  const refreshRowsTable = async () => {
    try {
      const body = {
        dataOwner: userProfile.dataOwner,
        nonCommInvHId: form?.nonCommInvHId ?? "", // TODO: mock here
        invoiceNo: form?.invNo,
        invoiceOrderType: form?.invType,
        etd: form?.etdDt,
        vesselName: form?.vesselName,
        importerId: form?.impCompanyId,
        importerCompanyAbbr: !form?.impCompanyId
          ? ""
          : findObject({
              data: importerData,
              value: form?.impCompanyId,
              property: "companyId",
              field: "companyAbbr",
            }),
        exporterId: !form?.exporterPlantId
          ? ""
          : findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterId",
            }),
        exporterCompanyAbbr: !form?.exporterPlantId
          ? ""
          : findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterCompanyAbbr",
            }),
        exporterCd: !form?.exporterPlantId
          ? ""
          : findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterCd",
            }),
        parkingMonth: form?.parkingMonth,
        vanningPlant: form?.vanningPlant,
        brokerId: form?.expBrokerCompanyId,
        loginUserCompanyAbbr: userProfile.comAbbr,
        isApmcRole: onOpen?.isAPMCUser,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        // [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const searchData = await searchExportSubScreenInfo(body);

      if (!searchData?.result?.data?.length) {
        setOnSearch(false);
        setPageNumber(FIRST_PAGE);
        setMode(ModeAction.VIEW);
        setRows([]);
        setRowSelectionModel([]);
        setRowModesModel({});
        setPagination({});
        return;
      }
      setRowSelectionModel([]);
      setRowModesModel({});
      setMode(ModeAction.VIEW);
      const data = transformSubScreenData(searchData?.result?.data);
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  return (
    <Fragment>
      <Stack>
        <Stack>
          <Link href="#" color="#000" onClick={handleClose} size="small">
            Close
          </Link>
        </Stack>
        <Stack mb={2}>
          <HeaderContentBar title="WDN95011 : Create Invoice Sub Screen" />
          <AlertMessage type={"warning"} message={msgError} />
          <AlertMessage type={"success"} message={msgAlert} />
        </Stack>
        <Stack sx={{ pl: "1rem", pr: "1rem" }}>
          <CriterialSection
            mode={onOpen?.actionType}
            form={form}
            setForm={setForm}
            onOpen={onOpen}
            dataList={{
              invoiceOrderTypeData,
              expData,
              brokerData,
              importerData,
            }}
            isAPMCUser={onOpen?.isAPMCUser}
          />
          <ButtonCriterial
            form={form}
            setForm={setForm}
            onOpen={onOpen}
            onSearch={onSearch}
            setOnSearch={setOnSearch}
            mode={onOpen.actionType}
            setMode={setMode}
            rows={rows}
            setRows={setRows}
            stateButton={{ searchBtn, clearBtn }}
            setMsg={{ setMsgAlert, setMsgError }}
            rowSelection={{
              rowModesModel,
              setRowModesModel,
              rowSelectionModel,
              setRowSelectionModel,
            }}
            pagination={{
              setPagination,
              setPageNumber,
            }}
            dataList={{
              expData,
              brokerData,
              importerData,
            }}
            isAPMCUser={onOpen?.isAPMCUser}
            setShipmentEtdData={setShipmentEtdData}
            setShipCarrierData={setShipCarrierData}
            functionId={"WDN95010"}
            stateBtn={{ setSearchBtn, setClearBtn }}
          />
          {onSearch ? (
            <Box sx={{ pr: "1rem", pl: "1rem" }}>
              <DetailSection
                onOpen={onOpen}
                isAPMCUser={onOpen?.isAPMCUser}
                mode={onOpen.actionType}
                form={form}
                setForm={setForm}
                dataList={{ currencyData, shipmentEtdData, shipCarrierData, expData, brokerData }}
                setShipCarrierData={setShipCarrierData}
                setMsg={{ setMsgAlert, setMsgError }}
              />
              <TableSection
                isEdit={onOpen.isEdit}
                isAPMCUser={onOpen?.isAPMCUser}
                mode={onOpen.actionType}
                setMode={setMode}
                setOnOpen={setOnOpen}
                rows={rows}
                setRows={setRows}
                columns={columns}
                form={form}
                setForm={setForm}
                alertMain={alertMain}
                refreshRowsTable={refreshRowsTable}
                setOnSearch={setOnSearch}
                setMsg={{ setMsgAlert, setMsgError }}
                rowSelection={{
                  rowModesModel,
                  setRowModesModel,
                  rowSelectionModel,
                  setRowSelectionModel,
                }}
                pagination={{
                  pagination,
                  setPagination,
                  pageNumber,
                  setPageNumber,
                }}
                dataList={{
                  importerData,
                  expData,
                  shipCarrierData,
                  shipmentEtdData,
                }}
                refreshRowsTableMain={refreshRowsTableMain}
              />
            </Box>
          ) : (
            <Stack mb={80}></Stack>
          )}
        </Stack>
      </Stack>
    </Fragment>
  );
}
