import useMutation from "hooks/useMutation";
import useQuery from "hooks/useQuery";
import _ from "lodash";
import { useMutateData, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
export const useGetRpkg = ({ dataOwner, rPkgOwnerCompAbbr }) => {
  return useQuery({
    url: `/gentan-i/r-package/${dataOwner}/${rPkgOwnerCompAbbr}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
    keepPreviousData: true,
  });
};

export const useSearchGentanI = ({
  dataOwner,
  gantanITypeCd,
  expCd,
  impCd,
  rPkgType,
  carFamilyCd,
  subSeriesCd,
  effDtFrom,
  effDtTo,
  pageNumber,
  rowsPerPage,
}) => {
  return useMutation({
    url: "/gentan-i/search",
    method: "POST",
    data: {
      dataOwner,
      gantanITypeCd,
      expCd,
      impCd,
      rPkgType,
      carFamilyCd,
      subSeriesCd,
      effDtFrom,
      effDtTo,
      pageNumber,
      rowsPerPage,
    },
  });
};

export const useCreateGentanI = () => {
  return useMutation({
    url: "/gentan-i/create",
    method: "POST",
  });
};

export const useUpdateGentanI = ({ gentanIId }) => {
  return useMutation({
    url: `/gentan-i/edit/${gentanIId}`,
    method: "PATCH",
  });
};

export const useDeleteGentanI = ({ gentanIId }) => {
  return useMutation({
    url: `/gentan-i/delete/${gentanIId}`,
    method: "DELETE",
    key: [gentanIId],
    enabled: !_.isEmpty(gentanIId),
  });
};

export const useDownloadExcel = ({ gentanIId }) => {
  return useMutation({
    url: `/gentan-i/download/excel`,
    method: "POST",
    data: { gentanIId },
  });
};
export const useGentanIDownloadExcelMutation = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate(
      {
        url: `${BaseServicePath}/gentan-i/download/excel`,
        method: "POST",
        data: body,
        responseType: "arraybuffer",
      },

      {
        onSuccess: response => {
          saveAs(response);
          // const blob = new Blob([data], {
          //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          // });
          // (blob, `${fileName}`);
        },
      }
    );
  };
};
