import React from "react";
import { ModeAction } from "state/enum";
import { API_MONTHLY } from "shared/constants/api-name/forecast/monthly";
import { APMC_COLUMNS_NAME } from "../constants/column.constant";
import { LinkTableCell } from "components/UI";
export default function useColumnApmc({ rows, mode, handleLinkUploadStatus }) {
  return [
    // Col 1
    {
      field: API_MONTHLY.ROW_NUMBER,
      headerName: APMC_COLUMNS_NAME.NO,
      headerAlign: "center",
      align: "right",
      width: 50,
      sortable: false,
      editable: false,
      renderCell: params => {
        if (rows.no === rows.slice(-1)?.[0].no && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params.value;
      },
    },
    // Col 2
    {
      field: API_MONTHLY.FORECAST_MONTH,
      headerName: APMC_COLUMNS_NAME.IMPORT_EXPORT,
      headerAlign: "center",
      align: "center",
      width: 100,
      sortable: false,
    },
    // Col 3
    {
      field: API_MONTHLY.DATA_TYPE,
      headerName: APMC_COLUMNS_NAME.DATA_TYPE,
      headerAlign: "center",
      align: "left",
      type: "select",
      width: 130,
      sortable: false,
    },
    // Col 4
    {
      field: API_MONTHLY.ORG_FILE_NAME,
      headerName: APMC_COLUMNS_NAME.FILE_NAME,
      headerAlign: "center",
      align: "left",
      width: 250,
      sortable: false,
    },
    // Col 5
    {
      field: API_MONTHLY.UPLOAD_STATUS,
      headerName: APMC_COLUMNS_NAME.UPLOAD_STATUS,
      headerAlign: "center",
      align: "left",
      width: 100,
      sortable: false,
      editable: false,
      renderCell: params => {
        // return <Link color="#1e88e5">{params?.value}</Link>;
        return (
          <LinkTableCell href="#" color="#1e88e5" processScreen={handleLinkUploadStatus} {...params} params={params}>
            {params?.value}
          </LinkTableCell>
        );
      },
    },
    // Col 6
    {
      field: API_MONTHLY.UPLOAD_DATE,
      headerName: APMC_COLUMNS_NAME.UPLOAD_DATE,
      headerAlign: "center",
      align: "center",
      width: 130,
      sortable: false,
      editable: false,
    },
    // Col 7
    {
      field: API_MONTHLY.UPLOAD_BY,
      headerName: APMC_COLUMNS_NAME.UPLOAD_BY,
      headerAlign: "center",
      align: "left",
      width: 100,
      sortable: false,
      editable: false,
    },
    {
      field: API_MONTHLY.FILE_UPLOAD_ID,
      editable: false,
    },
    {
      field: API_MONTHLY.API_ID,
      editable: false,
    },
    {
      field: API_MONTHLY.MODULE_ID,
      editable: false,
    },
    {
      field: API_MONTHLY.FUNCTION_ID,
      editable: false,
    },
    {
      field: API_MONTHLY.D_HODTCRE,
      editable: false,
    },
  ];
}
