import React, { useEffect, useMemo, useRef, useState, Fragment } from "react";
import { Box, Dialog, DialogContent, Stack } from "@mui/material";
import { GridRowModes } from "@mui/x-data-grid";
import { AlertMessage, InputButton } from "components/UI";

import "./ModalCompanyAndPlant.css";
import { MessageType, ModeAction } from "state/enum";
//Service
import {
  useCompanyPlantGetCompanyCategory,
  useCompanyPlantGetPlantCategory,
  useCompanyPlantCreateMutation,
  useCompanyPlantEditMutation,
} from "shared/services/master";
import ConfirmDialog from "components/UI/ConfirmDialog";
import {
  message,
  messageTypeDisplay,
  responseErrors,
  responseSuccess,
} from "utils";
import { userProfile } from "constant";
import DataTable from "pages/DataTable";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { validationSearchForm } from "utils/validation";
import useColumnModal from "../../hooks/useColumnModal";
import CompanyDetail from "./CompanyDetail";
import { BUTTON_VALUE } from "shared/constants";
import { HeaderContentBar } from "components/Layout";
import { getLocalDate } from "utils/init-config-date";
import { Link } from "react-router-dom";

export function ModalCompanyAndPlant({
  open = true,
  setOpen,
  setSelectedPossible,
  setRowsMain,
  rowsMain = [],
  rowNumber,
  mode,
  setMode,
  countryData,
  setMsgErrorMainScreen,
  setMsgAlertMainScreen,
  getSearch,
  refetchSearch,
  refetchCompanyAbbrData,
  setSearchForm,
  onMainSearch,
}) {
  const dialogRef = useRef(null);
  const [addButton, setAddButton] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);

  const [isConfirmSaveOpen, setConfirmSaveOpen] = useState(false);
  const [isConfirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isConfirmCloseModal, setConfirmCloseModal] = useState(false);

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  const [onSearch, setOnSearch] = useState(true);

  const confirmDialogCtx = useConfirmDialogContext();

  const [request, setRequest] = useState({
    dataOwner: userProfile.dataOwner,
    companyAbbr: "",
    companyCd: "",
    companyName: "",
    address1: "",
    address2: "",
    address3: "",
    poatcode: "",
    tel: "",
    fax: "",
    countryCd: "",
    refFileUploadId: "",
    createBy: userProfile.userId,
    category: [],
    plant: [],
  });

  // api
  const { data: companyCategoryData } = useCompanyPlantGetCompanyCategory({
    dataOwner: userProfile.dataOwner,
  });

  const { data: plantCategoryData } = useCompanyPlantGetPlantCategory({
    dataOwner: userProfile.dataOwner,
  });

  const createDataAsync = useCompanyPlantCreateMutation();
  const editDataAsync = useCompanyPlantEditMutation();

  const [boxStyle, setBoxStyle] = useState({});

  const handleResize = () => {
    const viewportHeight = window.innerHeight;
    setBoxStyle({ top: `${viewportHeight / 2}px` });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (open) {
      setMsgErrorMainScreen([]);
      setMsgAlertMainScreen([]);
      handleResize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (ModeAction.ADD === mode) {
      return rows.length < 1 ? handleAddClick() : "";
    }

    if (ModeAction.EDIT === mode || ModeAction.COPY === mode) {
      const data = JSON.parse(
        JSON.stringify(rowsMain.find((v) => v.rowNumber === rowNumber))
      );

      setRequest((old) => ({
        ...old,
        companyAbbr: data.companyAbbr,
        companyCd: data.companyCd,
        companyName: data.companyName,
        address1: data.address1,
        address2: data.address2,
        address3: data.address3,
        poatcode: data.poatcode,
        tel: data.tel,
        fax: data.fax,
        countryCd: data.countryCd,
        refFileUploadId: data.refFileUploadId,
        category: data.category,
        plant: data.plant?.map((val, i) => ({
          ...val,
          rowNumber: i + 1,
          category: val.category,
        })),
      }));

      if (data?.plant?.length) {
        data.plant.map((item, i) => {
          setRows((oldRows) => [
            ...oldRows,
            {
              rowNumber: i + 1,
              plantCd: item?.plantCd,
              plantName: item?.plantName,
              impExpCd: item?.impExpCd,
              category: "",
              isNew: true,
            },
          ]);
          setRowModesModel((oldModel) => ({
            ...oldModel,
            [i + 1]: { mode: GridRowModes.Edit },
          }));
          // setRowSelectionModel([i + 1]);

          return null;
        });
      }

      setDeleteButton(true);
      return;
    }
    return;
  }, [rowsMain, rowNumber, mode]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setConfirmCloseModal(true);
  };

  const handleSaveClick = () => {
    setConfirmSaveOpen(true);
  };

  const handleCancelClick = () => {
    setConfirmCancelOpen(true);
  };

  const handleAddClick = (cd) => {
    // TODO: unique id for DataGrid
    const field = {};
    columns?.forEach((column) => {
      field[column.field] = "";
    });

    setRows((oldRows) => [
      ...oldRows,
      {
        ...field,
        rowNumber: rows.length + 1,
        isNew: true,
      },
    ]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [rows.length + 1]: { mode: GridRowModes.Edit },
    }));
    setRowSelectionModel([rows.length + 1]);

    // set request

    setRequest((prevState) => {
      return {
        ...prevState,
        plant: [
          ...prevState.plant,
          {
            rowNumber: rows.length + 1,
            plantCd: field.plantCd,
            plantName: field.plantName,
            impExpCd: field.impExpCd,
            category: [],
          },
        ],
      };
    });

    if (rows.length + 1 > 1) {
      setDeleteButton(false);
    } else {
      setDeleteButton(true);
    }
  };

  const handleDeleteClick = (id) => {
    setConfirmDeleteOpen(true);
  };

  const handleRowSelectionModelChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel);
    if (newRowSelectionModel.length > 0) {
      setDeleteButton(false);
    } else {
      setDeleteButton(true);
    }
  };
  const handleCreate = async () => {
    try {
      // validate
      const body = {
        dataOwner: request.dataOwner,
        companyAbbr: request.companyAbbr,
        companyCd: request.companyCd,
        companyName: request.companyName,
        address1: request.address1,
        address2: request.address2,
        address3: request.address3,
        poatcode: request.poatcode,
        tel: request.tel,
        fax: request.fax,
        countryCd: request.countryCd,
        refFileUploadId: request.refFileUploadId,
        createBy: request.createBy,
        category: request.category,
        plant:
          request?.plant?.map((item) => ({
            plantCd: item.plantCd,
            plantName: item.plantName,
            impExpCd: item.impExpCd,
            refFileUploadId: item.refFileUploadId,
            category:
              item?.category?.map((cat) => ({
                categoryCd: cat.categoryCd,
                categoryDesc: cat.categoryDesc,
                refFileUploadId: cat.refFileUploadId,
              })) || [],
          })) || [],
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "companyAbbr",
            type: MessageType.EMPTY,
            key: ["Company Abbreviation"],
          },
          {
            field: "companyCd",
            type: MessageType.EMPTY,
            key: ["Company Code"],
          },
          {
            field: "companyName",
            type: MessageType.EMPTY,
            key: ["Company Name"],
          },
          {
            field: "address1",
            type: MessageType.EMPTY,
            key: ["Address Line 1"],
          },
          {
            field: "countryCd",
            type: MessageType.EMPTY,
            key: ["Country"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError((prev) => [...prev, ...errors]);
      }
      let stopProcess = false;

      if (!request?.category?.length) {
        const msg = messageTypeDisplay(MessageType.EMPTY, ["Company Category"]);
        setMsgError((olgMsg) => [...olgMsg, msg]);
        stopProcess = true;
      }

      if (!request?.plant?.length) {
        const msg1 = messageTypeDisplay(MessageType.EMPTY, ["Plant Code"]);
        const msg2 = messageTypeDisplay(MessageType.EMPTY, ["Plant Name"]);
        const msg3 = messageTypeDisplay(MessageType.EMPTY, [
          "Importer/Exporter Code",
        ]);
        setMsgError((olgMsg) => [...olgMsg, msg1, msg2, msg3]);
        stopProcess = true;
      }

      if (request?.plant?.length) {
        if (request?.plant?.some((val) => val.plantCd === "")) {
          const msg = messageTypeDisplay(MessageType.EMPTY, ["Plant Code"]);
          setMsgError((olgMsg) => [...olgMsg, msg]);
          stopProcess = true;
        }
        if (request?.plant?.some((val) => val.plantName === "")) {
          const msg = messageTypeDisplay(MessageType.EMPTY, ["Plant Name"]);
          setMsgError((olgMsg) => [...olgMsg, msg]);
          stopProcess = true;
        }
        if (request?.plant?.some((val) => val.impExpCd === "")) {
          const msg = messageTypeDisplay(MessageType.EMPTY, [
            "Importer/Exporter Code",
          ]);
          setMsgError((olgMsg) => [...olgMsg, msg]);
          stopProcess = true;
        }
        if (request?.plant?.some((val) => !val.category.length)) {
          const msg = messageTypeDisplay(MessageType.EMPTY, ["Plant Category"]);
          setMsgError((olgMsg) => [...olgMsg, msg]);
          stopProcess = true;
        }
      }

      if (stopProcess) {
        window.scrollTo(0, 0);
        dialogRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
          inline: "nearest",
        });
        return;
      }

      // action save

      await createDataAsync(body);

      const msg = messageTypeDisplay(MessageType.ADDED);
      setMsgAlertMainScreen([msg]);
      window.scrollTo(0, 0);
      dialogRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
        inline: "nearest",
      });
      await getSearch();
      refetchCompanyAbbrData();
      handleCloseConfirm();

      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      dialogRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
        inline: "nearest",
      });
      return false;
    }
  };

  const handleUpdate = async () => {
    try {
      let msg;
      const companyId = rowsMain.find(
        (val) => val.rowNumber === rowNumber
      )?.companyId;
      const body = {
        dataOwner: request.dataOwner,
        companyCd: request.companyCd,
        companyName: request.companyName,
        address1: request.address1,
        address2: request.address2,
        address3: request.address3,
        poatcode: request.poatcode,
        tel: request.tel,
        fax: request.fax,
        countryCd: request.countryCd,
        refFileUploadId: request.refFileUploadId,
        updateBy: userProfile.userId,
        category:
          request.category?.map((item) => ({
            categoryId: item?.categoryId,
            categoryCd: item?.categoryCd,
            categoryDesc: item?.categoryDesc,
            refFileUploadId: item?.refFileUploadId,
          })) || [],

        plant:
          request.plant?.map((item) => ({
            plantId: item?.plantId,
            plantCd: item?.plantCd,
            plantName: item?.plantName,
            impExpCd: item?.impExpCd,
            refFileUploadId: item?.refFileUploadId,
            category:
              item?.category.map((item) => ({
                categoryId: item?.categoryId,
                categoryCd: item?.categoryCd,
                categoryDesc: item?.categoryDesc,
                refFileUploadId: item?.refFileUploadId,
              })) || [],
          })) || [],
      };
      const response = await editDataAsync(body, companyId);
      msg = responseSuccess(response);

      msg = messageTypeDisplay(MessageType.UPDATED);
      setMsgAlertMainScreen([msg]);
      window.scrollTo(0, 0);
      dialogRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
        inline: "nearest",
      });
      handleCloseConfirm();
      await refetchSearch();
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      dialogRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
        inline: "nearest",
      });
      return false;
    }
  };
  const processRowUpdate = async (newRow) => {
    console.log("processRowUpdate", newRow);
  };

  const handleSaveConfirm = async () => {
    setMsgAlert([]);
    setMsgError([]);
    let isSuccess;
    // MDN90016AINF: Data is updated successfully.
    if ([ModeAction.ADD, ModeAction.COPY].includes(mode)) {
      isSuccess = await handleCreate();
      setRowModesModel({
        ...rowModesModel,
        [rows.length]: { mode: GridRowModes.View },
      });
    } else {
      console.log("--request :", request);
      isSuccess = await handleUpdate();
      let tempRowModesModel = rowModesModel;

      const rowSelect = rowSelectionModel.map((val) => {
        tempRowModesModel = {
          ...tempRowModesModel,
          [val]: { mode: GridRowModes.View },
        };
      });
      if (!isSuccess) return false;
      rowSelect && setRowModesModel(tempRowModesModel);
    }
  };

  const handleCancelConfirm = () => {
    setMode(ModeAction.VIEW);
    setRowSelectionModel([]);
    setRowModesModel({});
    setRows([]);
    setMsgError([]);
    setOpen(false);
    setAddButton(false);
    setRequest({
      dataOwner: userProfile.dataOwner,
      companyAbbr: "",
      companyCd: "",
      companyName: "",
      address1: "",
      address2: "",
      address3: "",
      poatcode: "",
      tel: "",
      fax: "",
      countryCd: "",
      refFileUploadId: "",
      createBy: userProfile.userId,
      category: [],
      plant: [
        // {
        //   plantCd: "",
        //   plantName: "",
        //   impExpCd: "",
        //   refFileUploadId: "",
        //   category: [],
        // },
      ],
    });
    // setRowModesModel({
    //   ...rowModesModel,
    //   [rows.length]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
  };

  const handleCloseConfirm = () => {
    setMode(ModeAction.VIEW);
    setRowSelectionModel([]);
    setRowModesModel({});
    setRows([]);
    setMsgError([]);
    setOpen(false);
    setAddButton(false);
    setRequest({
      dataOwner: userProfile.dataOwner,
      companyAbbr: "",
      companyCd: "",
      companyName: "",
      address1: "",
      address2: "",
      address3: "",
      poatcode: "",
      tel: "",
      fax: "",
      countryCd: "",
      refFileUploadId: "",
      createBy: userProfile.userId,
      category: [],
      plant: [
        // {
        //   plantCd: "",
        //   plantName: "",
        //   impExpCd: "",
        //   refFileUploadId: "",
        //   category: [],
        // },
      ],
    });
    setRowModesModel({
      ...rowModesModel,
      [rows.length]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleDeleteConfirm = () => {
    let newRows = rows;
    rowSelectionModel.map((val) => {
      newRows = newRows.filter((row) => row.rowNumber !== val);
    });
    setRows(newRows);
    setRowModesModel({});

    // set request
    setRequest((prevState) => {
      prevState.plant = prevState.plant.filter(
        (v) => !rowSelectionModel.includes(v.rowNumber)
      );

      return {
        ...prevState,
      };
    });

    setAddButton(false);
  };

  // handle plant code in datagrid
  const handleChangePlantCode = (value, index) => {
    setRequest((prevState) => {
      const updatedPlant = prevState.plant.find((v) => v.rowNumber === index);
      updatedPlant.plantCd = value?.toUpperCase();

      return {
        ...prevState,
      };
    });
  };

  // handle plant name in datagrid
  const handleChangePlantName = (value, index) => {
    setRequest((prevState) => {
      const updatedPlant = prevState.plant.find((v) => v.rowNumber === index);
      updatedPlant.plantName = value;

      return {
        ...prevState,
      };
    });
  };

  // handle impExpCd in datagrid
  const handleChangeImpExpCd = (value, index) => {
    setRequest((prevState) => {
      const updatedPlant = prevState.plant.find((v) => v.rowNumber === index);
      // console.log("object - updatedPlant -> ", updatedPlant);
      // console.log("object - value -> ", value);
      // console.log("object - index -> ", index);
      updatedPlant.impExpCd = value;

      return {
        ...prevState,
      };
    });
  };

  // handle change plant category in datagrid
  const handleCategoryCheckboxChange = (
    event,
    categoryCd,
    categoryDesc,
    refFileUploadId = "",
    index
  ) => {
    const { checked } = event.target;

    setRequest((prevState) => {
      if (checked) {
        const plant = prevState.plant.find((v) => v.rowNumber === index);
        plant.category.push({
          categoryCd,
          categoryDesc,
          refFileUploadId,
        });
      } else {
        const plant = prevState.plant.find(
          (v) => parseInt(v.rowNumber) === index
        );
        plant.category = plant.category.filter(
          (v) => v.categoryCd !== categoryCd
        );
      }

      return {
        ...prevState,
      };
    });
  };

  const columns = useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColumnModal({
      rowsMain,
      request,
      plantCategoryData,
      companyCategoryData,
      handleChangePlantCode,
      handleChangePlantName,
      handleChangeImpExpCd,
      handleCategoryCheckboxChange,
    });
  }, [rowsMain, request, plantCategoryData, companyCategoryData]);

  return (
    <Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
      >
        <div
          ref={dialogRef}
          style={{
            position: "fixed",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "100vh",
            maxWidth: "80vw",
            overflowY: "auto",
            padding: "20px",
            transition: "top",
            backgroundColor: "white",
            marginTop: "5%",
            ...boxStyle,
          }}
        >
          <DialogContent id="modalCompanyPlant">
            <Stack sx={{ display: "flex", alignItems: "flex-start" }}>
              <Link
                className="btn-close"
                href="#"
                color="#000"
                onClick={handleCancelClick}
                size="small"
                // sx={{ fontSize: "12px" }}
              >
                Close
              </Link>
            </Stack>
            <Stack sx={{ alignItems: "center" }}>
              <HeaderContentBar title="WDN910A1 : Company and Plant Master Maintenance Sub Screen" />
            </Stack>
            {/* AlertMessage */}
            <AlertMessage type={"warning"} message={msgError} />
            <AlertMessage type={"success"} message={msgAlert} />
            <Box className="box">
              <CompanyDetail
                mode={mode}
                request={request}
                setRequest={setRequest}
                companyCategoryData={companyCategoryData}
                countryData={countryData}
              />
            </Box>
            <Box className="box">
              <div>Plants : </div>
              <div>
                <InputButton
                  value={"Add"}
                  size="small"
                  onClick={handleAddClick}
                  disabled={addButton}
                  name={"WDN910A1Add"}
                />
                <InputButton
                  value={"Delete"}
                  size="small"
                  onClick={handleDeleteClick}
                  disabled={deleteButton}
                  name={"WDN910A1Delete"}
                />
              </div>
            </Box>
            <Box border="1 solid">
              <DataTable
                mode={mode}
                onSearch={onSearch}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                setMode={setMode}
                rows={rows}
                rowModesModel={rowModesModel}
                columns={columns}
                setRowModesModel={setRowModesModel}
                processRowUpdate={processRowUpdate}
                // rowHeight={250}
                isRowSelectable={true}
                onRowSelectionModelChange={handleRowSelectionModelChange}
                // pagination={pagination}
                // pageNumber={pageNumber}
                // handleChangePagination={handleChangePagination}
                // columnGroupingModel={[]}
                columnVisibilityModel={{
                  rowNumber: false,
                }}
                enabledPagination={false}
              />
            </Box>
            <Box display="flex" justifyContent="end">
              {ModeAction.VIEW !== mode && (
                <>
                  <InputButton
                    onClick={handleSaveClick}
                    color={"primary"}
                    value={BUTTON_VALUE.SAVE}
                    name={"WDN910A1Save"}
                  />
                  <InputButton
                    onClick={handleCancelClick}
                    color={"secondary"}
                    value={BUTTON_VALUE.CANCEL}
                    name={"WDN910A1Cancel"}
                  />
                </>
              )}
            </Box>
          </DialogContent>
        </div>
      </Dialog>

      {/* ConfirmDialog Save */}
      <ConfirmDialog
        open={isConfirmSaveOpen}
        onClose={() => setConfirmSaveOpen(false)}
        onConfirm={() => {
          setConfirmSaveOpen(false);
          handleSaveConfirm();
        }}
        message={message({ type: "confirmSave" })}
        functionId={"WDN910A1"}
        type={"Save"}
      />

      {/* ConfirmDialog Cancel */}
      <ConfirmDialog
        open={isConfirmCancelOpen}
        onClose={() => setConfirmCancelOpen(false)}
        onConfirm={() => {
          setConfirmCancelOpen(false);
          handleCancelConfirm();
        }}
        message={message({ type: "confirmCancel" })}
        functionId={"WDN910A1"}
        type={"Cancel"}
      />

      {/* ConfirmDialog Delete */}
      <ConfirmDialog
        open={isConfirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        onConfirm={() => {
          setConfirmDeleteOpen(false);
          handleDeleteConfirm();
        }}
        message={message({ type: "confirmDelete" })}
        functionId={"WDN910A1"}
        type={"Delete"}
      />

      {/* ConfirmDialog Close Modal */}
      <ConfirmDialog
        open={isConfirmCloseModal}
        onClose={() => setConfirmCloseModal(false)}
        onConfirm={() => {
          setConfirmCloseModal(false);
          handleCloseConfirm();
        }}
        message={message({ type: "closeScreen" })}
        functionId={"WDN910A1"}
        type={"Close"}
      />
    </Fragment>
  );
}
