import React from "react";
import { API_LOG_MONITORING } from "shared/constants/api-name/common/logMonitoring";
import { ModeAction } from "state/enum";

export default function useColumn({ rows, mode }) {
  return [
    {
      field: API_LOG_MONITORING.ROW_NUMBER,
      headerName: "No",
      flex: 0.1,
      minWidth: 80,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        if (
          params?.row?.rowNumber === rows.slice(-1)?.[0].rowNumber &&
          ModeAction.ADD === mode
        ) {
          return <div></div>;
        }
        return params.value;
      },
    },
    {
      field: API_LOG_MONITORING.DATE,
      headerName: "Date Time",
      headerAlign: "center",
      flex: 0.1,
      minWidth: 140,
      maxWidth: 140,
      editable: false,
      sortable: false,
    },
    {
      field: API_LOG_MONITORING.APP_ID,
      headerName: "App ID",
      headerAlign: "center",
      sortable: false,
      minWidth: 80,
      maxWidth: 80,
      editable: false,
      flex: 0.1,
    },
    {
      field: API_LOG_MONITORING.MODULE_ID,
      headerName: "Module",
      headerAlign: "center",
      flex: 0.1,
      minWidth: 100,
      maxWidth: 100,
      sortable: false,
      editable: false,
    },
    {
      field: API_LOG_MONITORING.FUNCTION_ID,
      headerName: "Function",
      headerAlign: "center",
      sortable: false,
      minWidth: 100,
      maxWidth: 100,
      editable: false,
      flex: 0.1,
    },
    {
      field: API_LOG_MONITORING.STATUS,
      headerName: "Status",
      headerAlign: "center",
      minWidth: 120,
      maxWidth: 120,
      flex: 0.1,
      editable: false,
      sortable: false,
    },
    {
      field: API_LOG_MONITORING.USER_ID,
      headerAlign: "center",
      headerName: "User ID",
      flex: 0.1,
      minWidth: 260,
      maxWidth: 260,
      sortable: false,
      editable: false,
    },
    {
      field: API_LOG_MONITORING.LEVEL_ID,
      headerName: "Level",
      headerAlign: "center",
      flex: 0.1,
      maxWidth: 100,
      sortable: false,
      editable: false,
    },
    {
      field: API_LOG_MONITORING.MESSAGE,
      headerName: "Message",
      headerAlign: "center",
      flex: 0.3,
      minWidth: 450,
      sortable: false,
      editable: false,
    },
    {
      field: API_LOG_MONITORING.MESSAGE_TYPE,
      editable: false,
    },
  ];
}
