export const formatCurrency = ({
  number = null,
  digits = 0,
}) => {
  if (!number) return;
  
  if (typeof number === "string") number = parseFloat(number.replace(/,/g, ''))

  const formattedCurrency = number.toLocaleString("en-US", {
    // style: "currency",
    // currency,
    minimumFractionDigits: digits,
    maximumFractionDigits: 5,
  });

  return formattedCurrency;
};

export const parseCurrencyString = (currencyString) => {
  if (!currencyString) return;
  if (typeof number !== "string") parseFloat(currencyString)
  // ลบเครื่องหมาย "," ออกจากสตริง
  const withoutCommas = currencyString.replace(/,/g, '');

  // แปลงสตริงเป็นตัวเลข
  const numberValue = parseFloat(withoutCommas);

  return numberValue;
}