export const UPLOAD_STATUS = {
  SUCCESS: "Success",
  WARNING: "Warning",
  ERROR: "Error",
  DUPLICATE: "Duplicate",
  PROCESSING: "Processing",
};
export const GENERATE_STATUS = {
  GENERATED: "Generated",
  ERROR: "Error",
  WAIT: "Waiting",
  ON_PROCESS: "Process",
};
export const IMPORT_INVOICE_DOWNLOAD_COLUMNS = {
  NO: "No.",
  EXPORTER: "Exporter",
  INVOICE_NO: "Invoice No.",
  INVOICE_DATE: "Invoice Date (DD/MM/YYYY)",
  ETD_DATE: "ETD (DD/MM/YYYY)",
  ETA_DATE: "ETA (DD/MM/YYYY)",
  VESSEL_NAME: "Vessel Name",
  RPACKAGE_TYPE: "R-Package Type",
  RPACKAGE_QTY: "R-Package Qty",
  IMPORT_CUSTOMS_STOCK_DATE: "Import Customs Stock Date (DD/MM/YYYY)",
  BROKER: "Broker",
  IMPORT_ENTRY_NO: "Import Entry No.",
};
export const TEMPLATE_NAME = {
  PRODUCTION: "Production",
  PACKING: "Packing",
  RETURN_SHIPPING: "Return Shipping",
  TRANSFERRING: "Transferring",
  RECEIVING: "Receiving",
};
