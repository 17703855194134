// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./reducers/counterSlice";
import authReducer from "shared/stores/reducers/authSlice";
import commonReducer from "shared/stores/reducers/commonSlice";

const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    common: commonReducer,
  },
});

export default store;
