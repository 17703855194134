import useMutation from "hooks/useMutation";
import { saveAs } from "utils/download-file";
import _ from "lodash";
import { useMutateData, useMutateDownload } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
export const useSearchVolumeDimension = ({
  dataOwner,
  dimensionType,
  refCompanyAbbr,
  refImpExpCd,
  rPkgType,
  containerType,
  pageNumber,
  rowsPerPage,
}) => {
  return useMutation({
    url: "/volumn-dimension/serach",
    method: "POST",
    data: {
      dataOwner,
      dimensionType,
      refCompanyAbbr,
      refImpExpCd,
      rPkgType,
      containerType,
      pageNumber,
      rowsPerPage,
    },
  });
};

export const useCreateVolumeDimension = ({ type }) => {
  return useMutation({
    url: `/volumn-dimension/${type}/create`,
    method: "POST",
  });
};

export const useUpdateVolumeDimension = ({ type, VolumeDimensionId }) => {
  return useMutation({
    url: `/volumn-dimension/${type}/edit/${VolumeDimensionId}`,
    method: "PATCH",
  });
};

export const useDeleteVolumeDimension = ({ type, VolumeDimensionId }) => {
  return useMutation({
    url: `/volumn-dimension/${type}/delete/${VolumeDimensionId}`,
    method: "DELETE",
    key: [VolumeDimensionId],
    enabled: !_.isEmpty(VolumeDimensionId),
  });
};

export const useDownloadExcelVolumeDimension = ({ type }) => {
  return useMutation({
    url: `/volumn-dimension/${type}/download-excel`,
    method: "POST",
  });
};
export const useVolumeRPkgDownloadExcelMutation = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async (body) => {
    return await mutate(
      {
        url: `${BaseServicePath}/volumn-dimension/download-excel`,
        method: "POST",
        data: body,
        responseType: "arraybuffer",
      },
      {
        onSuccess: response => {
          saveAs(response);
          // const blob = new Blob([data], {
          //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          // });
          // (blob, `${fileName}`);
        },
      }
    );
  };
};
export const useVolumeContainerDownloadExcelMutation = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async (body) => {
    return await mutate(
      {
        url: `${BaseServicePath}/volumn-dimension/download-excel`,
        method: "POST",
        data: body,
        responseType: "arraybuffer",
      },
      {
        onSuccess: response => {
          saveAs(response);
          // const blob = new Blob([data], {
          //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          // });
          // (blob, `${fileName}`);
        },
      }
    );
  };
};
