import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import blogImage from "../../assets/images/blogImage.avif";

import React from "react";

function StyledCard() {
  return (
    <Card sx={{ flex: 1 }}>
      <div style={{ position: "relative" }}>
        <CardMedia
          component="img"
          height="140px"
          image={blogImage}
          alt="green iguana"
        />

        <CardContent
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            padding: "16px",
            color: "white",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="inherit"
            gutterBottom
          >
            BLOG TITLE
          </Typography>
          <Typography variant="body2" color="inherit">
            Terminology and Wording
          </Typography>
        </CardContent>
        <CardContent
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            padding: "16px",
            color: "white",
          }}
        >
          <Typography variant="body2" color="inherit">
            13:48
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
}

export default StyledCard;
