/*
 */
export const COMMON_ENUM = {
  ACTION_RETURN_SUCCESS: "success",
  ACTION_RETURN_ERROR: "error",
  ACTION_RETURN_BACK: "back",

  ODB_BLANK_VALUE: "##",
  BLANK: "",
  UPLOAD_FILE_DATE_FORMAT: "dd.MM.yyyy",
  UPLOAD_FILE_MONTH_FORMAT: "MM.yyyy",
  DATE_FORMAT_YYYYMMDD: "YYYYMMDD",
  DATE_FORMAT_YYYYMM: "yyyyMM",
  // DATETIME_FORMAT : "YYYY-mm-dd HH:mm:ss",
  DATETIME_FORMAT_DDMMMYYYY: "dd MMM yyyy",
  DATETIME_FORMAT_DDMMMYYYYHHMMSS: "dd MMM yyyy HH:mm:ss",
  DATETIME_FORMAT_YYYYMMDDHHMMSS: "YYYYMMDDHHmmss",

  DATETIME_FORMAT_YYYYMMDDHHMMSS_SSS: "YYYYMMDDHHmmssSSS",
  DATE_FORMAT_ORACLE: "DD/MM/YYYY",
  YEAR_MONTH_FORMAT_ORACLE: "MM/YYYY",
  DEFAULT_DATE: "31/12/2999",
  DATETIME_FORMAT_ORACLE: "DD/MM/YYYY HH24:MI:SS",

  DATETIME_FORMAT_ORACLE_MS: "DD/MM/YYYY HH24:MI:SS:FF3",

  DATETIME_FORMAT_ORACLE_YYYYMMDDHH24MISSFF3: "YYYYMMDDHH24MISSFF3",

  DATETIME_FORMAT_JAVA: "dd/MM/yyyy HH:mm:ss",

  DATETIME_FORMAT_JAVA_MS: "dd/MM/yyyy HH:mm:ss:SSS",

  DATE_FORMAT_JAVA: "dd/MM/yyyy",
  YEAR_MONTH_FORMAT_JAVA: "MM/yyyy",
  DATA_SEPARATOR_JAVA: "\\|",
  DATA_SEPARATOR_ORA: "|",
  DOC_NO_GEN_SEPARATOR: "_",
  DATA_SEPARATOR_COMMA: ",",
  DATA_SEPARATOR_COLON: ":",
  DATA_INVALID_SIGN: " (X)",
  FTP_CONFIG_SEPARATOR: "~",

  ALPHA_NUMERIC_PATTERN: "^[0-9a-zA-Z]*$",
  ALPHA_NUMERIC_WILDCARD_PATTERN: "^[0-9a-zA-Z*]*$",
  ALPHA_NUMERIC_UPPER_PATTERN: "^[0-9A-Z]*$",
  FLOATING_POINT_PATTERN_2DECIMAL: "^[\\-0-9]+\\.?[0-9]{0,2}$",
  FLOATING_POINT_PATTERN_3DECIMAL: "^[\\-0-9]+\\.?[0-9]{0,3}$",
  NUMERIC_PATTERN: "^[\\-0-9]+[0-9]*$",
  EXCEL_FILE_PATTERN: "([a-z_\\-\\s0-9\\.]+)+\\.(?i)(xls|xlsx)$",

  ACTIVE_INACTIVE_FLOATING_NUMERIC_PATTERN: "^[-+]?\\d+(\\.\\d+)?$",
  INACTIVE_NUMERIC_PATTERN: "^[-]\\d+?$",
  ACTIVE_INACTIVE_NUMERIC_PATTERN: "^[-+]?\\d+?$",

  ALPHA_NUMERIC_DASH_USCROLL_SPACE_SLASH_WILDCARD: "^[0-9a-zA-Z* \\x2D\\x5F\\x2F]*$",

  FILE_SIZE_1MB: 1048576,
  DEFAULT_BUFFER_SIZE: 1024,
  FILE_UPLOAD_DEFAULT_FOLDER: "file_upload_default",
  INPUT_DEFAULT_FOLDER: "input_path_folder",

  COMPANY_CODE_REPLACE_MARK_FOR_FILE_ULOAD: "<COMP_ABBR>",
  SYSTEM_ID_REPLACE_MARK_FOR_FILE_ULOAD: "<SYSTEM_ID>",

  CUSTOM_STOCK_PROG_ID: "BDN910G0",
  SOP_PROG_ID: "BDN910H0",
  TRANSPORTATION_PROG_ID: "BDN910F0",
  WORKING_CALENDAR_PROG_ID: "BDN910E0",
  KATASHIKI_SUBSERIES_PROG_ID: "BDN910I0",
  LEADTIME_MASTER_PROG_ID: "BDN910L0",
  AVG_LEADTIME_GROUP_PROG_ID: "BDN910M0",

  MESSAGE_CD_DELETE_CONCURENCY: "MSTD0115AERR",
  MESSAGE_CD_UPDATE_CONCURENCY: "MSTD0114AERR",

  SC_MODE_INIT: "INIT",
  SC_MODE_BACK: "BACK",
  SC_MODE_SAVE_ADD: "SAVE_ADD",
  SC_MODE_SAVE_ADD_SUB: "SAVE_ADD_SUB",
  SC_MODE_SAVE_EDIT: "SAVE_EDIT",
  SC_MODE_SAVE_COPY: "SAVE_COPY",
  SC_MODE_SEARCH: "SEARCH",
  SC_MODE_ADD: "ADD",
  SC_MODE_CONFIRM: "CONFIRM",
  SC_MODE_COPY: "COPY",
  SC_MODE_ADD_CFC: "ADD_CFC",
  SC_MODE_ADD_SUB: "ADD_SUB",
  SC_MODE_EDIT: "EDIT",
  SC_MODE_DELETE: "DELETE",
  SC_MODE_SAVE: "SAVE",
  SC_MODE_CANCEL: "CANCEL",
  SC_MODE_DOWNLOAD: "DOWNLOAD",
  SC_MODE_DOWNLOAD_TEMPLATE: "DOWNLOAD_TEMPLATE",
  SC_MODE_UPLOAD: "UPLOAD",
  SC_MODE_CLEAR: "CLEAR",
  SC_MODE_PRINT: "PRINT",
  SC_MODE_APPROVE: "APPROVE",
  SC_MODE_LOG: "LOG",
  SC_MODE_VIEW: "VIEW",
  SC_MODE_FORECAST: "ACTIVATE_FORECAST",
  SC_MODE_DOWNLOAD_PLAN: "DOWNLOAD_PLAN",
  SC_MODE_GENERATE_REPORT: "GENERATE_REPORT",
  SC_MODE_CREATE_INVOICE: "CREATE_INVOICE",
  SC_MODE_CANCEL_INVOICE: "CANCEL_INVOICE",
  SC_MODE_CANCEL_VANNING: "CANCEL_VANNING",
  SC_MODE_GENERATE_FILE: "GENERATE_FILE",
  SC_MODE_SEND_EMAIL: "SEND_EMAIL",
  SC_MODE_CONFIRM_ALLOCATE: "CONFIRM_ALLOCATE",
  SC_MODE_DOWNLOAD_ALL_EXPORTER: "DOWNLOAD_ALL_EXPORTER",

  SC_MODE_CANCEL_SHIPMENT: "CANCEL_SHIPMENT",
  HANDHELD_MODE: "HANDHELD_MODE",

  C_SAFETY_STOCK: "S",
  C_OVERFLOW_STOCK: "O",
  C_R_MODULE: "RM",
  C_OTHER_MATERIAL: "OT",
  C_CATEGORY_COMPANY: "C",
  C_CATEGORY_OTHER: "O",
  C_REPORT_TYPE_LOCATION: "L",
  C_REPORT_TYPE_R_PKG: "R",

  C_MODE_NEW: "N",
  C_MODE_REPRINT: "R",
  C_MODE_PRINT_HISTORY: "H",

  C_DIMENSION_TYPE_CONTAINER: "C",
  C_DIMENSION_TYPE_RPACKAGE: "R",

  C_MASTER_TPYE_WORKING_CALENDAR: "W",
  C_MASTER_TPYE_TRANSPORTATION_LEADTIME: "T",
  C_MASTER_TPYE_CUSTOMS_STOCK: "C",
  C_MASTER_TPYE_SOP: "S",
  C_MASTER_TYPE_KATASHIKI_AND_SUBSERIES: "K",

  C_MODE_SEARCH_HISTORY: "S",
  C_MODE_MANAGE_DISCREPANCY: "M",
  C_MODE_CUT_LOSS: "C",
  C_MODE_REPLACING: "R",
  C_FORECAST_YEARLY: "Y",
  C_FORECAST_MONTHLY: "M",
  C_OVERSEA: "O",
  C_DOMESTIC: "D",

  C_DEFAULT_EFFECTIVE_DT_TO: "1",
  C_MAXIMUM_COPY: "MAXIMUM_COPY",
  C_MINIMUM_COPY: "MINIMUM_COPY",
  C_MAXIMUM_DAYS: "MAXIMUM_DAYS",
  C_HOLIDAY_FLAG: "H",
  C_TMATH_EM_COMPANY: "TMATH",
  C_TMATH_EM_COMPANY_OLD: "TMAP-EM",
  C_TDEM_EM_IMPEXP_OLD: "TMAPEM",
  C_TMAP_EM_IMPEXP: "TDEM",
  C_TMAP_EM_TH_YUSEN: "TH_YUSEN",

  C_SYSTEM_STATUS: "SYSTEM_STATUS",

  C_FREEZE_ALL: "FREEZE_ALL",
  C_APMC_COMPANY: "APMC",

  C_SYSTEM_STATUS_FLAG: "SYSTEM_STATUS_FLAG",

  C_SYSTEM_ONE: "1",

  C_COMMON_REPORT_TYPE_1: "1",
  C_COMMON_REPORT_TYPE_2: "2",
  C_COMMON_REPORT_TYPE_3: "3",
  C_COMMON_REPORT_TYPE_4: "4",
  C_COMMON_REPORT_TYPE_5: "5",
  C_COMMON_REPORT_TYPE_6: "6",
  C_COMMON_REPORT_TYPE_7: "7",
  C_COMMON_REPORT_TYPE_8: "8",
  C_COMMON_REPORT_TYPE_9: "9",
  C_COMMON_REPORT_TYPE_10: "10",
  C_COMMON_REPORT_TYPE_11: "11",
  C_COMMON_REPORT_TYPE_12: "12",
  C_COMMON_REPORT_TYPE_13: "13",
  C_COMMON_REPORT_TYPE_14: "14",
  C_COMMON_REPORT_TYPE_15: "15",

  C_COMMON_REPORT_COMPANY_TYPE_SUPPLIER: "Supplier",

  C_SM_CTG_COMMON: "COMMON",
  C_SM_CTG_TMAP_EM: "TMATH",

  C_SM_SUB_CTG_CONVERT_USER_LOCATION: "CONVERT_USER_LOCATION",
  C_SM_SUB_CTG_PLANT_SRV: "PLANT_SERVER",
  C_SM_SUB_CTG_TRANS_CATEGORY: "TRANS_CATEGORY",
  C_SM_SUB_CTG_GEN_INTERFACE_DATA_TYPE: "GEN_INTERFACE_DATA_TYPE",
  C_SM_SUB_CTG_REPORT_RECORDS_NUMBER: "REPORT_RECORD_NUMBER",
  C_SM_SUB_CTG_CUSTOMS_BLN_STS: "CUSTOMS_BLN_STS",
  C_SM_SUB_CTG_ADJUSTMENT_MODE: "ADJUSTMENT_MODE",
  C_SM_SUB_CTG_ADJUSTMENT_STATUS: "ADJUSTMENT_STATUS",
  C_SM_SUB_CTG_ALLOCATION_STATUS: "ALLOCATION_STATUS",
  C_SM_SUB_CTG_CHANGING_MODE: "CHANGING_MODE",
  C_SM_SUB_CTG_DATA_MODE: "DATA_MODE",
  C_SM_SUB_CTG_YEARLY_DATA_TYPE: "YEARLY_DATA_TYPE",
  C_SM_SUB_CTG_GENTAN_I_TYPE: "GENTAN_I_TYPE",
  C_SM_SUB_CTG_ROUTE_POINT_CATEGORY: "ROUTE_POINT_CATEGORY",
  C_SM_SUB_CTG_IN_OUT_STATUS: "IN_OUT_STATUS",
  C_SM_SUB_CTG_RPKG_STATUS: "RPKG_STATUS",
  C_SM_SUB_CTG_PACK_STATUS: "PACK_STATUS",
  C_SM_SUB_CTG_DEFAULT_DATA_OWNER: "DEFAULT_DATA_OWNER",
  C_SM_SUB_CTG_MAT_CAL_PART_MAP: "MAT_CAL_PART_MAP",
  C_SM_SUB_CTG_MAT_CAL_EXP_MAP: "MAT_CAL_EXP_MAP",
  C_SM_SUB_CTG_MAT_CAL_WH_MAP: "MAT_CAL_WH_MAP",
  C_SM_SUB_CTG_PART_SUP_PLANT_CD: "PART_SUP_PLANT_CD",
  C_SM_SUB_CTG_DEFAULT_LIMIT_LEADTIME: "DEFAULT_LIMIT_LEADTIME",

  C_SM_SUB_CTG_DEFAULT_EFFECTIVE_DT: "DEFAULT_EFF_DATE_TO",
  C_SM_SUB_CTG_WRN_E_MAIL_TO: "WRN_E_MAIL_TO",
  C_SM_SUB_CTG_WRN_E_MAIL_CC: "WRN_E_MAIL_CC",
  C_SM_SUB_CTG_DOUWNLOAD_UPLOAD_MST: "DOWNLOAD_UPLOAD_MASTER",
  C_SM_SUB_CTG_UPLOAD_STS: "UPLOAD_STATUS",
  C_SM_SUB_CTG_FILE_ID_MAP: "FILE_ID_MAP",
  C_SM_SUB_CTG_MAX_RECORD: "MAX_RECORD",
  C_SM_SUB_CTG_TYPE_OF_DIMENSION: "TYPE_OF_DIMENSION",

  C_SM_SUB_CTG_R_PKG_CATEGORY: "R_PKG_CATEGORY",
  C_SM_SUB_CTG_R_PKG_CATEGORY_GROUP: "R_PKG_CATEGORY_GROUP",
  C_SM_SUB_CTG_R_PKG_GROUP: "R_PKG_GROUP",
  C_SM_SUB_CTG_BARCODE_GEN_RULE_R_PKG_OWNER: "BARCODE_GEN_RULE_R_PKG_OWNER",
  C_SM_SUB_CTG_BARCODE_GEN_RULE_R_PKG_MAKER: "BARCODE_GEN_RULE_R_PKG_MAKER",
  C_SM_SUB_CTG_BARCODE_GEN_RULE_R_PKG_TYPE: "BARCODE_GEN_RULE_R_PKG_TYPE",
  C_SM_SUB_CTG_BARCODE_GEN_RULE_DUMMY_USAGE: "BARCODE_GEN_RULE_DUMMY_USAGE",
  C_SM_SUB_CTG_BARCODE_PRINT_SCR_MODE: "BARCODE_PRINT_MASTER_SCREEN_MODE",
  C_SM_SUB_CTG_BARCODE_PRINT_TYPE: "BARCODE_PRINT_MASTER_PRINT_TYPE",
  C_SM_SUB_CTG_BARCODE_PRINT_NUM_OF_COPY: "BARCODE_PRINT_MASTER_NUM_OF_COPY",
  C_SM_SUB_CTG_BARCODE_PRINT_DLV_PLAN_PERIOD: "BARCODE_PRINT_MASTER_DLV_PLAN_PERIOD",
  C_SM_SUB_CTG_BARCODE_HANDLING: "BARCODE_HANDLING",

  C_SM_CD_SAFE_PERIOD: "SAFE_PERIOD",
  C_SM_CD_ASCII_FILE_ID: "ASCII_GEN_FILE_ID",
  C_SM_SUB_REPORT_COMPANY_NAME: "REPORT_COMPANY_NAME",
  C_SM_SUB_REPORT_NAME: "REPORT_NAME",
  C_SM_SUB_TIMEZONE_ADJUST: "TIMEZONE_ADJUST",
  C_SM_SUB_CTG_MM_MODE: "MM_MODE",

  C_SM_SUB_CTG_INVOICE_TYPE: "INVOICE_TYPE",
  C_SM_SUB_CTG_ORDER_TYPE: "ORDER_TYPE",

  C_SM_SUB_CTG_STK_SUM_TYPE: "REPORT_STK_SUM_TYPE",

  C_SM_SUB_CTG_COMPANY_PLANT_EXT: "COMPANY_PLANT_EXT_CD",
  C_SM_CD_COMPANY_PLANT_EXT_PRODUCTION: "C",
  C_SM_CD_COMPANY_PLANT_EXT_PACKING: "P",
  C_SM_CD_COMPANY_PLANT_EXT_NOT_SPEC: "N",

  C_SM_CD_TMAP_COMPANY_NAME: "TMAP_COMPANY_NAME",
  C_SM_SUB_CTG_DEFAULT_AGING_STOCK_DAY: "DEFAULT_AGING_STOCK_DAY",
  C_SM_SUB_CTG_DEFAULT_TARGET_LINE_WIP1_EMPTY: "DEFAULT_TARGET_LINE_WIP1_EMPTY",
  C_SM_SUB_CTG_DEFAULT_TARGET_LINE_WIP2_EMPTY: "DEFAULT_TARGET_LINE_WIP2_EMPTY",
  C_SM_SUB_CTG_DEFAULT_TARGET_LINE_FG_EMPTY: "DEFAULT_TARGET_LINE_FG_EMPTY",
  C_SM_SUB_CTG_DEFAULT_TARGET_LINE_FG_PACK: "DEFAULT_TARGET_LINE_FG_PACK",
  C_SM_SUB_CTG_DEFAULT_TARGET_LINE_NG_EMPTY: "DEFAULT_TARGET_LINE_NG_EMPTY",
  C_SM_SUB_CTG_DEFAULT_TARGET_LINE_NG_PACK: "DEFAULT_TARGET_LINE_NG_PACK",
  C_SM_SUB_CTG_APMC_COMPANY_ABBR: "APMC_COMPANY_ABBR",
  C_SM_SUB_CTG_E_MAIL_SUBJECT: "E_MAIL_SUBJECT",
  C_SM_SUB_CTG_E_MAIL_TEMPLATE: "E_MAIL_TEMPLATE",

  C_SM_SUB_CTG_RBOX_MULTI_DEVANNING: "RBOX_MULTI_DEVANNING",
  C_SM_SUB_CTG_DUMMY_PO_NO: "DUMMY_PO_NO",
  C_SM_SUB_CTG_VANNING_UPLOAD: "VANNING_UPLOAD",
  C_SM_SUB_CTG_ONEWAY_BARCODE: "ONEWAY_BARCODE",
  C_SM_SUB_CTG_ONEWAY_RPKG_TYPE: "ONEWAY_RPKG_TYPE",
  C_SM_SUB_CTG_RET_SHIP_VANNING_BASIS: "RET_SHIP_VANNING_BASIS",
  PREFIX_ONEWAY_BARCODE: "##",

  C_SM_SUB_CTG_MAX_LAST_SCAN_DAY: "MAX_LAST_SCAN_DAY",

  C_SM_SUB_CTG_SUPPLIER_WH_BOX: "SUPPLIER_WH_BOX",
  C_SM_SUB_CTG_SUPPLIER_WH_MODULE: "SUPPLIER_WH_MODULE",
  C_SM_SUB_CTG_MAX_FILE_SIZE: "MAX_FILE_SIZE",

  C_SM_CD_BOX: "B",
  C_SM_CD_MODULE: "D",

  C_SM_CD_ORDER_TYPE_CPO: "C",
  C_SM_CD_ORDER_TYPE_REGULAR: "R",
  C_SM_CD_ORDER_TYPE_SPO: "S",

  C_SM_SUB_CTG_STD_MOVEMENT_LEAD_TIME: "STD_MOVEMENT_LEAD_TIME",
  C_SM_SUB_CTG_ROUTE_POINT_GROUP_TMAP_EM: "ROUTE_POINT_GROUP_TMAP",

  C_SM_SUB_CTG_TRANSACTION_MODE: "TRANSACTION_MODE",
  C_SM_SUB_CTG_TRANSACTION_CATEGORY: "TRANSACTION_CATEGORY",

  WH_ALLOC_ETA_BEFORE: "WH_ALLOC_ETA_BEFORE",

  C_SM_SUB_CTG_REPORT_BASIS: "REPORT_BASIS",

  C_SM_SUB_CTG_STK_INDICATOR_TYPE: "STK_INDICATOR_TYPE",

  C_SM_SUB_CTG_SHIP_INFO_EXP_CD_DISPLAY: "SHIP_INFO_EXP_CD_DISPLAY",

  C_SM_SUB_COMBOBOX_MATCHING: "COMBOBOX_MATCHING",

  START_PROCESS: "S",
  END_PROCESS: "E",
  PROCESSING: "P",
  STATUS_IN_QUEUE: "Q",
  STATUS_CONVERTED: "C",
  STATUS_COMPLETED: "C",

  EXIT_SUCCESS: 0,
  EXIT_ERROR: 1,
  EXIT_WARNING: 2,

  MSG_CD_START_PROCESS: "MSTD7000BINF",
  MSG_CD_END_PROCESS_SUCCESS: "MSTD7001BINF",
  MSG_CD_END_PROCESS_ERROR: "MSTD7002BINF",
  MSG_CD_END_PROCESS_WARNING: "MSTD7003BINF",
  MSG_CD_NO_DATA_FOUND: "MSTD7026BINF",
  MSG_CD_NOT_EXIST_ERROR: "MSTD0012AERR",
  MSG_CD_MUST_BE_SAME_WITH_ERROR: "MSTD0027AERR",
  MSG_CD_MUST_NOT_BE_SAME_ERROR: "MSTD0028AERR",
  MSG_CD_GREATER_THAN_ERROR: "MSTD0021AERR",
  MSG_CD_UNDEFINED_ERROR: "MSTD0067AERR",
  MSG_CD_NOT_BE_EMPTY: "MSTD0031AERR",
  MSG_CD_EXIST_IN_PRC_ERROR: "MDN90022AERR",
  MSG_CD_NOT_EXIST_IN_STD_PROPS_FILE_ERROR: "MSTD1061AERR",
  MSG_CD_MUST_EQUAL_LATER_THAN_ERROR: "MSTD0019AERR",
  MSG_CD_MUST_EQUAL_GREATER_THAN_ERROR: "MSTD0018AERR",
  MSG_CD_MUST_EQUAL_GREATER_THAN_TODAY_ERROR: "MSTD0020AERR",
  MSG_CD_INVALID_MONTH_VALUE_ERROR: "MSTD0054AERR",
  MSG_CD_MUST_LESS_THAN_EQUAL_ERROR: "MSTD0025AERR",
  MSG_CD_DATA_NOT_FOUND_ERROR: "MSTD7021BERR",
  MSG_CD_INVALID_LENGTH_ERROR: "MSTD0051AERR",
  MSG_CD_INVALID_ERROR: "MSTD0043AERR",
  MSG_CD_INVALID_VALUE_ERROR: "MSTD0044AERR",
  MSG_CD_BARCODE_CONCURRENT_ERROR: "MDN91012AERR",
  MSG_CD_EXCEPTION_ERROR: "MSTD7020BERR",
  MSG_CD_FORMAT_INCORRECT_ERROR: "MSTD0050AERR",
  MSG_CD_LENGTH_INCORRECT_ERROR: "MSTD0052AERR",
  MSG_CD_INVALID_NUMERIC_FORMAT_ERROR: "MSTD0056AERR",
  MSG_CD_INVALID_DATE_FORMAT_ERROR: "MSTD0045AERR",
  MSG_CD_INVALID_TIME_FORMAT_ERROR: "MSTD0057AERR",
  MSG_CD_UPL_FILE_EMPTY_ERROR: "MSTD1049AERR",
  MSG_CD_MUST_DATE_FUTURE: "MDN91010AERR",
  MSG_CD_MUST_BETWEEN: "MSTD0016AERR",
  MSG_CD_MUST_EQUAL_EARLIER_THAN_ERROR: "MSTD0017AERR",
  MSG_CD_MUST_BE_ALPHANUMERIC: "MSTD0015AERR",

  MSG_CD_MUST_BE_ERROR: "MDN91007AERR",
  MSG_CD_SEND_EMAIL_SUCCESS: "MDN90026AINF",
  MSG_CD_SEND_EMAIL_ERROR: "MDN90027AINF",
  MSG_CD_MUST_BE_SAME_ERROR: "MDN92007AERR",
  MSG_CD_TIME_OVERLAP_ERROR: "MDN92008AERR",
  MSG_CD_BE_SAME_ALL_RECORD_ERROR: "MDN92022AERR",
  MSG_CD_FILE_ID_NOT_REGISTER: "MDN90035AERR",

  MSG_CD_DISCREPANCY_FOUND: "MDN93010AINF",

  MSG_CD_SHIPMENT_NOT_FOUND_ERROR: "MDN93010AERR",
  MSG_CD_ATA_DATE_LESS_THAN_DATENOW_WRN: "MDN93007AWRN",

  COMPANY_CTG_CD_EXPORTER: "E",
  COMPANY_CTG_CD_IMPORTER: "I",
  COMPANY_CTG_CD_BROKER: "B",
  COMPANY_CTG_CD_DUNAGE: "D",
  COMPANY_CTG_CD_PACKING: "K",
  COMPANY_CTG_CD_PRODUCTION: "P",
  COMPANY_CTG_CD_MAKER: "M",
  COMPANY_CTG_CD_OWNER: "O",
  COMPANY_CTG_CD_TOYOTA: "T",
  COMPANY_CTG_CD_WH_APMC: "X",
  PLANT_CTG_CD_SUPPLIER: "P",

  COMPANY_CTG_CD_WH_TMAP: "W",
  COMPANY_CTG_CD_CONSIGNEE: "C",

  CATEGORY_TYPE_PLANT: "P",
  CATEGORY_TYPE_COMPANY: "C",
  CATEGORY_TYPE_WAREHOUSE: "W",

  CATEGORY_COMMON: "COMMON",
  SUB_CATEGORY_ORDER_TYE: "ORDER_TYPE",

  TBL_COMPANY_MASTER: "TB_M_COMPANY",
  TBL_FILE_UPLOAD_HISTORY: "TB_H_FILE_UPLOAD",
  TBM_COMPANY_PLANT: "TB_M_COMPANY_PLANT",
  TBM_OVERFLOW_STOCK: "TB_M_OVERFLOW_STOCK",
  TBM_SAFETY_STOCK: "TB_M_SAFETY_STOCK",

  FLAG_YES: "Y",
  FLAG_NO: "N",

  STATUS_SUCCESS: "S",
  STATUS_ERROR: "E",
  STATUS_WARNING: "W",

  TRANS_TYPE_IN: "I",
  TRANS_TYPE_OUT: "O",
  TRANS_TYPE_UPD: "U",

  TRACK_STS_IN: "I",
  TRACK_STS_OUT: "O",
  TRACK_STS_IN_OUT: "B",
  TRACK_STS_NONE: "N",

  SCAN_STATUS_SYS1: "SYS1",
  SCAN_STATUS_SYS2: "SYS2",

  R_PKG_STATUS_FG: "FG",
  R_PKG_STATUS_SCRAP: "SCRAP",
  R_PKG_STATUS_NG: "NG",
  R_PKG_STATUS_NEW: "NEW",
  R_PKG_STATUS_WIP1: "WIP1",
  R_PKG_STATUS_WIP2: "WIP2",
  R_PKG_STATUS_LOST: "LOST",
  R_PKG_STATUS_QUEUE: "QUEUE",

  PACK_STATUS_EMPTY: "EMPTY",
  PACK_STATUS_PACK: "PACK",

  LOCATION_TYPE_PATH: "P",
  LOCATION_TYPE_ROUTE_POINT: "R",

  REPO_STOCK_RET_SHIPPING: "R",
  REPO_STOCK_INVENTORY: "I",
  REPO_STOCK_INC_PROD: "P",

  TRANSACTION_STANDARD_MOVEMENT: "S",
  TRANSACTION_CUT_LOSS: "C",
  TRANSACTION_STOCK_ADJUST: "A",
  TRANSACTION_DISCREPANCY_ADJUST: "D",
  TRANSACTION_HANDHELD: "H",
  TRANSACTION_REPLACING: "R",

  DISCREPANCY_STS_WAITING: "W",
  DISCREPANCY_STS_ADJUST: "A",

  TRANSACTION_NORMAL: "N",
  TRANSACTION_IGNORE: "I",
  TRANSACTION_SYSTEM: "S",
  TRANSACTION_MISSED: "M",

  ALLOCATION_STS_AUTO_ALLOCATED: "A",
  ALLOCATION_STS_CONFIRMED: "C",
  ALLOCATION_STS_MANUAL_ALLOCATED: "M",
  ALLOCATION_STS_RECEIVED: "R",

  REPORT_BASIS_DAILY: "D",
  REPORT_BASIS_MONTHLY: "M",

  DEFAULT_USER_NAME: "SYSTEM",
  ORACLE_DATE_FORMAT_YYMM: "YYMM",
  ORACLE_DATE_FORMAT_YYMMDD: "YYMMDD",
  ORACLE_DATE_FORMAT_YYYYMMDD: "YYYYMMDD",
  ORACLE_DATE_FORMAT_YYYYMM: "YYYYMM",
  ORACLE_DATE_FORMAT_YYYY: "YYYY",
  ORACLE_DATE_FORMAT_MM: "MM",

  ORACLE_YEAR_MONTH_DATE_FORMAT: "yyyy/mm/dd",
  MODE_TYPE_ONE: "1",
  MODE_TYPE_TWO: "2",

  SUB_CAT_EMAIL_CONFIG: "EMAIL_CONFIG",
  CD_MAIL_SERVER: "MAIL_SERVER",
  CD_SMTP_STRTLS: "SMTP_STRTLS",
  CD_SMTP_PORT: "SMTP_PORT",
  CD_MAIL_FROM: "MAIL_FROM",
  CD_LOGIN_PASSWORD: "LOGIN_PASSWORD",

  PARAM_PROJECT_ID: "PROJECT_ID",
  PARAM_MODULE_ID: "MODULE_ID",
  PARAM_MODULE_NAME: "MODULE_NAME",
  PARAM_DATA_OWNER: "DATA_OWNER",
  PARAM_SYSTEM_ID: "SYSTEM_ID",
  PARAM_FUNCTION_ID: "FUNCTION_ID",
  PARAM_FUNCTION_NAME: "FUNCTION_NAME",
  PARAM_FILE_ID: "FILE_ID",
  PARAM_FILE_NAME: "FILE_NAME",
  PARAM_FILE_TYPE: "FILE_TYPE",
  PARAM_TRANSFER_TYPE: "TRANSFER_TYPE",
  PARAM_REF_SYSTEM: "REF_SYSTEM",
  PARAM_REF_COMPANY_ABBR: "REF_COMPANY_ABBR",
  PARAM_STAGING_TABLE: "STAGING_TABLE",
  PARAM_PARKING_TABLE: "PARKING_TABLE",
  PARAM_BASE_TABLE: "BASE_TABLE",
  PARAM_FILE_UPLOAD_ID: "FILE_UPLOAD_ID",
  PARAM_USER_NAME: "USER_NAME",
  PARAM_UPDATE_DT: "UPDATE_DT",
  PARAM_APL_ID: "APL_ID",
  PARAM_FILE_ID_LIST: "FILE_ID_LIST",
  PARAM_KEY_COLUMNS: "KEY_COLUMNS",
  PARAM_COLUMN_MAP: "COLUMN_MAP",
  PARAM_OVERLAPPED_CHECK_FLAG: "OVERLAPPED_CHECK_FLAG",
  PARAM_CONTINUOUS_CHECK_FLAG: "CONTINUOUS_CHECK_FLAG",
  PARAM_DELETE_STS_CHECKING_FLAG: "DELETE_STS_CHECKING_FLAG",
  PARAM_DEFAULT_EFF_DATE_TO: "DEFAULT_EFF_DATE_TO",
  PARAM_PRIMARY_KEY_ID_COL: "PRIMARY_KEY_ID_COL",
  PARAM_DATE_FORMAT_PATTERN: "DATE_FORMAT_PATTERN",
  PARAM_ENDING_BALANCE_QTY: "ENDING_BALANCE_QTY",
  PARAM_OVER_REQUIRE_QTY: "OVER_REQUIRE_QTY",

  REPORT_MODULE_ID: "DN96",
  PARAM_EXCEL_TEMPLATE: "EXCEL_TEMPLATE",
  PARAM_ONLINE_REPORT: "ONLINE_REPORT",
  PARAM_OUTPUT_REPORT_FILE: "OUTPUT_REPORT_FILE",
  TOTAL_RECORD: "TOTAL_RECORD",
  PARAM_0: "PARAM_0",
  PARAM_1: "PARAM_1",
  PARAM_2: "PARAM_2",
  PARAM_3: "PARAM_3",
  PARAM_4: "PARAM_4",
  PARAM_5: "PARAM_5",
  PARAM_6: "PARAM_6",
  PARAM_7: "PARAM_7",
  PARAM_8: "PARAM_8",
  PARAM_9: "PARAM_9",
  PARAM_10: "PARAM_10",
  PARAM_11: "PARAM_11",
  PARAM_12: "PARAM_12",
  PARAM_13: "PARAM_13",
  PARAM_14: "PARAM_14",
  PARAM_15: "PARAM_15",

  PARAM_16: "PARAM_16",
  PARAM_17: "PARAM_17",

  C_SM_SUB_CAT_WARNING_CHECK_DAY: "WARNING_CHECK_DAY",
  C_SM_CD_SHORTAGE_CONFIG_DAY: "SHORTAGE_CONFIG_DAY",
  C_SM_CD_OVERFLOW_CONFIG_DAY: "OVERFLOW_CONFIG_DAY",
  C_SM_CD_TH_EXPORTER: "TH_EXPORTER",

  SUB_CAT_FORECAST_PERIOD: "FORECAST_PERIOD",
  CD_FORECAST_PERIOD_YEARLY: "YEARLY",
  CD_FORECAST_PERIOD_MONTHLY: "MONTHLY",
  FORECAST_CAL_FLAG_Y: "Y",
  FORECAST_CAL_FLAG_N: "N",

  UPL_MODE_INSERT: 1,
  UPL_MODE_UPDATE: 2,
  UPL_MODE_DELETE: 3,

  MODE_ADD: "A",
  MODE_EDIT: "E",

  STD_MM_MODE_RCV: "R",
  STD_MM_MODE_CHG: "U",
  STD_MM_MODE_DLV: "D",

  C_SM_SUB_CTG_COUNTRY_CD: "COUNTRY_CD",
  C_SM_SUB_CTG_TRANS_MODE: "TRANS_MODE",
  SUB_CAT_COMPANY_CATEGORY: "COMPANY_CATEGORY",
  SUB_CAT_PLANT_CATEGORY: "PLANT_CATEGORY",
  SUB_CAT_USE_STATUS: "USE_STATUS",
  COMPANY_ID_PREFIX: "CMP",
  CATEGORY_ID_PREFIX: "CAT",
  PLANT_ID_PREFIX: "PLT",
  FILE_UPLOAD_ID_PREFIX: "FLH",
  NON_COMM_INV_H_PREFIX: "IVH",
  NON_COMM_INV_D_PREFIX: "IVD",
  NON_COMM_INV_INFO_PREFIX: "IHI",
  SHIPPING_INFO_HEADER_PREFIX: "SIH",
  SHIPPING_INFO_CONT_PREFIX: "SDC",
  SHIPPING_INFO_PKG_PREFIX: "SDP",
  SHIPMENT_SUMMARY_H_PREFIX: "SSH",
  SHIPMENT_SUMMARY_D_CONT_PREFIX: "SSC",
  SHIPMENT_SUMMARY_D_PKG_PREFIX: "SSP",
  VANNING_INFO_HEADER_PREFIX: "VNH",
  VANNING_INFO_DETAIL_PREFIX: "VND",

  SUB_CAT_MAIN_SERVER_FILE_GEN_FILE_ID: "MAIN_SERVER_FILE_GEN_FILE_ID",
  SUB_CAT_FORECAST_TYPE: "FORECAST_TYPE",
  SUB_CAT_MONTHLY_DATA_TYPE: "YEARLY_MONTHLY_UPL_SCR_MONTHLY_DATA_TYPE",
  SUB_CAT_YEARLY_MONTHLY_DATA_TYPE_TABLE: "YEARLY_MONTHLY_DATA_TYPE_TABLE",
  SUB_CAT_APMC_SOURCE_CD: "APMC_UPL_SCR_DATA_TYPE_CD",
  SUB_CAT_REPO_DATA_TYPE: "REPO_SIMULATION_SCR_REPO_DATA_TYPE",
  SUB_CAT_REPO_SHORTAGE_PERIOD: "REPO_SIMULATION_SCR_SHORTAGE_PERIOD",
  SUB_CAT_REPORT_FORMAT: "REPORT_FORMAT",
  SUB_CAT_EXPORTER_COMPANY: "EXPORTER_COMPANY",
  SUB_CAT_CURRENCY_CD: "CURRENCY_CD",
  SUB_CAT_NON_COMM_INV_R_PACKAGE_CAT: "NON_COMM_INV_R_PACKAGE_CATEGORY",
  SUB_CAT_VANNING_STATUS: "VANNING_STATUS",
  SUB_CAT_WITH_PART_FLAG: "WITH_PART_FLAG",
  CODE_REPO_SHORTAGE_INTERVAL: "SHORTAGE_INTERVAL",
  DATA_TYPE_MATERIAL_SUPPLIER: "1",
  DATA_TYPE_MATERIAL_TYPE: "2",
  DATA_TYPE_MATERIAL_USAGE: "3",
  DATA_TYPE_DELIVERY_PLAN: "4",
  DATA_TYPE_APMC_SOURCE: "5",
  DATA_TYPE_FIRM_MODE: "Firm",
  DATA_TYPE_KEIHEN_MODE: "Keihen",
  DATA_TYPE_OAP: "O",
  DATA_TYPE_RAP: "R",
  TEMPLATE_OAP: "OAP_RAP_Template.xls",
  TEMPLATE_RAP: "OAP_RAP_Template.xls",
  TEMPLATE_DELIVERY_PLAN: "Delivery_Plan_Template.xls",
  TEMPLATE_PACKING_PLAN: "Packing_Plan_Template.xls",
  TEMPLATE_PRODUCTION_PLAN: "Production_Plan_Template.xls",
  TEMPLATE_RET_SHIP_PLAN: "Return_Shipping_Plan_Template.xls",
  TEMPLATE_TRANSFERRING_PLAN: "Transferring_Plan_Template.xls",
  TEMPLATE_RECEIVING_PLAN: "Receiving_Plan_Template.xls",

  TEMPLATE_R_PKG_RCV: "R-Package Receiving Template.xls",
  TEMPLATE_R_PKG_STS_CHG: "R-Package Status Change Template.xls",
  TEMPLATE_R_PKG_DLV: "R-Package Delivery Template.xls",
  TEMPLATE_ACTUAL_INVOICE: "Import_Invoice_Excel_Template.xls",

  STATUS_PROCESSING: "P",
  STATUS_DUPLICATED: "D",
  STATUS_GENERATED: "G",
  STATUS_WAITING: "W",
  STATUS_NEW: "N",
  STATUS_DESC_SUCCESS: "Success",
  STATUS_DESC_ERROR: "Error",
  STATUS_DESC_WARNING: "Warning",
  STATUS_DESC_PROCESSING: "Processing",
  STATUS_DESC_DUPLICATED: "Duplicated",
  STATUS_DESC_GENERATED: "Generated",
  STATUS_DESC_WAITING: "Waiting",
  STATUS_DESC_PROCESS: "Process",
  REPO_DATA_TYPE_APMC: "O",
  REPO_DATA_RPACKAGE: "D",
  VANNING_CUT_OFF_ETD: "ETD",
  VANNING_CUT_OFF_CY: "C",

  PARAM_REPOSITION_FOR: "REPOSITION_FOR",
  PARAM_RPACKAGE_TYPE: "RPACKAGE_TYPE",
  PARAM_SHORTAGE_DATE: "SHORTAGE_DATE",
  PARAM_LEADTIME: "LEADTIME_BEFORE_SHORTAGE",
  PARAM_LOCATION: "LOCATION",
  PARAM_PERIOD_START: "PERIOD_START",
  PARAM_PERIOD_END: "PERIOD_END",
  PARAM_USER_ID: "USER_ID",
  PARAM_FORECAST_PERIOD: "FORECAST_PERIOD",

  PARAM_SELECTED_INVOICE: "SELECTED_INVOICE",
  LOADING_PORT: "LOADING_PORT",
  DISCHARGING_PORT: "DISCHARGING_PORT",
  SYSTEM_NAME: "POLYGON",
  VANNING_STATUS_COMPLETED: "Y",
  VANNING_STATUS_INCOMPLETE: "N",

  C_SM_CD_APMC_UPL_SCR_DATA_TYPE_PACKING_PLAN: "PK",
  C_SM_CD_APMC_UPL_SCR_DATA_TYPE_PROD_PLAN: "PD",
  C_SM_CD_APMC_UPL_SCR_DATA_TYPE_RET_SHIP_PLAN: "PT",
  C_SM_CD_APMC_UPL_SCR_DATA_TYPE_TRANSFERRING_PLAN: "TR",
  C_SM_CD_APMC_UPL_SCR_DATA_TYPE_RECEIVING_PLAN: "RC",

  ASCII_SYSTEM_MST_FILE_ID: "DN97080",
  ASCII_COMP_MST_FILE_ID: "DN97081",
  ASCII_COMP_PLANT_CTG_MST_FILE_ID: "DN97082",
  ASCII_COMP_PLANT_MST_FILE_ID: "DN97083",
  ASCII_ROUTE_POINT_MST_FILE_ID: "DN97084",
  ASCII_ROUTE_POINT_STATUS_MST_FILE_ID: "DN97085",
  ASCII_DLV_INSTR_SHEET_FILE_ID: "DN97086",
  ASCII_SHIPPING_INFO_FILE_ID: "DN97087",
  ASCII_RPKG_TYPE_MST_FILE_ID: "DN97088",

  ASCII_PLANT_SERVER_DLV_INSTR_SHEET_FILE_ID: "DN97090",
  ASCII_SCAN_TRANSACTION_FILE_ID: "DN97091",

  UPL_SOURCE_TYPE_EXCEL: "E",
  PROJECT_ID: "DN9",

  WAREHOUSE_ALLOCATION_MODE_ACTUAL: "A",
  WAREHOUE_ALLOCATION_MODE_FORECAST: "F",
  REPO_MODE_OVERSEA_CD: "O",
  REPO_MODE_DOMESTIC_CD: "D",
  REPO_TYPE_RETURN_SHIPPING_CD: "R",
  REPO_TYPE_INVENTORY_CD: "I",
  REPO_TYPE_INC_PROD_CD: "P",

  NULL: "null",
  PARAM_SIGN: "-",
  MULTI_SEGMENT_SIGN: "MS",

  OUTPUT_FOLDER: "output",
  ARCHIVE_FOLDER: "archive",
  SUCCESS_FOLDER: "success",
  WARNING_FOLDER: "warning",
  ERROR_FOLDER: "error",

  INFO_STR: "I",
  ERROR_STR: "E",
  WARNING_STR: "W",
  FATAL_STR: "F",
  DEBUG_STR: "D",
  SUCCESS_STR: "S",

  XLS_REPORT_EXTENTION: ".xls",
  XLSX_REPORT_EXTENTION: ".xlsx",

  FREEZE_FLAG_YES: "0",
  FREEZE_FLAG_NO: "1",

  MAX_MESSAGE_LENGTH: 300,

  zip_content_type: "application/zip",

  PERMISSION_DENIED: "Permission denied",
  ACCESS_IS_DENIED: "Access is denied",
  EXPORT_PDF_IS_DENIED: "Error trying to export to file",

  BARCODE_DUMMY: "BARCODE_DUMMY",
  INVOICE_EXP_COMP_FORMAT_CONV: "INVOICE_EXP_COMP_FORMAT_CONV",
  INVOICE_EXP_COMP_CONV: "INVOICE_EXP_COMP_CONV",

  PATH_SUB_FOLDER_ROEM: "ROEM",
  PATH_SUB_FOLDER_RNQC: "RNQC",
  PATH_SUB_FOLDER_SCMS: "SCMS",
  EXTENSION_PACKING_CD: "P",

  COMBOBOX_ALL_PLANT: "COMBOBOX_ALL_PLANT",

  PARAM_SCREEN_MODE: "SCREEN_MODE",
  PARAM_STAGING_FIELDS: "STAGING_FIELDS",

  EMAIL_LEVEL_COUNTRY: 1,
  EMAIL_LEVEL_COMPANY: 2,
  EMAIL_LEVEL_PLANT: 3,
  EMAIL_LEVEL_TRANS: 4,
  EMAIL_LEVEL_MONTLY_FORECAST_NOTI: 5,

  EMAIL_SUB_CAT_PREFIX_COUNTRY_TO: "COUNTRY_E_MAIL_TO",
  EMAIL_SUB_CAT_PREFIX_COUNTRY_CC: "COUNTRY_E_MAIL_CC",
  EMAIL_SUB_CAT_PREFIX_COMPANY_TO: "COMPANY_E_MAIL_TO",
  EMAIL_SUB_CAT_PREFIX_COMPANY_CC: "COMPANY_E_MAIL_CC",
  EMAIL_SUB_CAT_PREFIX_PLANT_TO: "PLANT_E_MAIL_TO",
  EMAIL_SUB_CAT_PREFIX_PLANT_CC: "PLANT_E_MAIL_CC",
  EMAIL_SUB_CAT_PREFIX_TRANS_TO: "TRANS_E_MAIL_TO",
  EMAIL_SUB_CAT_PREFIX_TRANS_CC: "TRANS_E_MAIL_CC",
  EMAIL_SUB_CAT_PREFIX_LP_TO: "MODULE_OWNER_E_MAIL_TO",
  EMAIL_SUB_CAT_PREFIX_LP_CC: "MODULE_OWNER_E_MAIL_CC",
  EMAIL_SUB_CAT_PREFIX_MONTHLY_FORECAST_TO: "MONTHLY_FORE_NOTI_E_MAIL_TO",
  EMAIL_SUB_CAT_PREFIX_MONTHLY_FORECAST_CC: "MONTHLY_FORE_NOTI_E_MAIL_CC",

  SUB_CAT_MONTHLY_FORECAST_TARGET_FILE_NAME_TMAP: "MONTHLY_FORE_NOTI_TARGET_FILE_NAME_TMAP",
  SUB_CAT_MONTHLY_FORECAST_TARGET_FILE_NAME_APMC: "MONTHLY_FORE_NOTI_TARGET_FILE_NAME_APMC",

  EMAIL_SUB_CAT_SENDER: "E_MAIL_SENDER",
  EMAIL_CD_SENDER: "SENDER",
  EMAIL_SUB_CAT_EMAIL: "EMAIL",

  EMAIL_SENDING_TYPE_LP: "LP",
  EMAIL_SENDING_TYPE_EXP: "EXP",
  EMAIL_SENDING_TYPE_IMP: "IMP",
  EMAIL_SENDING_TYPE_TRANS: "TRANS",
  EMAIL_SENDING_TYPE_BROKER: "BROKER",

  ACTION_DAILY_INVENTORY_GEN: "D",
  ACTION_REPO_SIMULATION: "S",
  ACTION_DO_ALL: "B",

  REPOSITIONING_CONFIRMED: "C",
  REPOSITIONING_UNCONFIRMED: "U",

  COMPANY_ABBR_TMMIN: "TMMIN",

  DISCREPANCY_TYPE_RMODULE: "R",
  DISCREPANCY_TYPE_QUANTITY: "Q",

  OPERATION_TYPE: "OPERATION_TYPE",
  SHIPMENT_STATUS: "SHIPMENT_STATUS",

  OPERATION_GROUPPING: "G",
  OPERATION_MAPPING: "M",

  SHIPMENT_STATUS_PLAN: "10",
  SHIPMENT_STATUS_ACTUAL: "20",
  SHIPMENT_STATUS_ACTUAL_NCV: "21",
  SHIPMENT_STATUS_READY: "30",
  SHIPMENT_STATUS_ALLOCATED: "40",
  SHIPMENT_STATUS_CONFIRMED: "50",
  SHIPMENT_STATUS_RECEIVED: "60",
  SHIPMENT_STATUS_CANCEL: "99",

  SEQUENCES_CODE_PART_RECEIVE: "PRV",
  SEQUENCES_CODE_PART_RECEIVE_CANCEL: "PRC",
  SEQUENCES_CODE_STD_MOVEMENT_H: "MH",
  SEQUENCES_CODE_STD_MOVEMENT_D: "MD",
  SEQUENCES_CODE_MOVEMENT_QUEUE: "MQH",

  SEQ_FORMAT_PART_RECEIVE: "${SEQ}${P1}${SEQ KEY}${P2}${XXXXXX}",
  SEQ_FORMAT_STD_MOVEMENT: "${SEQ}${P1}${SEQ KEY}${P2}${XXXXX}",
  SEQ_FORMAT_MOVEMENT_QUEUE: "${SEQ}${P1}${SEQ KEY}${P2}${XXXXXX}",

  R_PACKAGE_TYPE_BOX_CATEGORY: "B",
  R_PACKAGE_TYPE_MODULE_CATEGORY: "M",

  CALENDAR_WORKING_FLAG: "W",
  CALENDAR_HOLIDAY_FLAG: "H",

  MODE_ACTUAL: "A",
  MODE_PLAN: "P",

  WAREHOUSE_TMAP_ALL: "TMATH:ALL",
  DELIVERY_PLAN_DATA_TYPE_ACTUAL: "A",
  SUB_CAT_CONTAINER_FORMAT_LENGTH: "CONTAINER_FORMAT_LENGTH",
  SUB_CAT_PLANT_IGNORE_MISSED_RCV: "PLANT_IGNORE_MISSED_RCV",

  SUB_CAT_WH_PARTIAL_SCAN_DAY: "WH_PARTIAL_SCAN_DAY",
  SUB_CAT_CONTAINER_PREFIX: "CONTAINER_PREFIX",
  SUB_CAT_DOMESTIC: "DOMESTIC",
  SUB_CAT_ACTUAL_NCV_DURATION: "ACTUAL_NCV_DURATION",

  SHIPMENT_CD_SYSTEM_GENERATE: "0",
  SHIPMENT_CD_MANUAL_GENERATE: "D",

  DISCP_TYPE_SUGGESTS: "S",
  DISCP_TYPE_ADJUST: "A",
  C_SM_SUB_CTG_DISCP_RCV_DATE_RANGE: "DISCP_RCV_DATE_RANGE",
  C_SM_SUB_CTG_DISCP_DLV_RCV_DATE_RANGE: "DISCP_DLV_RCV_DATE_RANGE",
  C_SM_SUB_CTG_DISCP_GROUP_TMAP: "DISCP_GROUP_TMAP",
  C_SM_SUB_CTG_DISCP_PERCENTAGE_RATE: "DISCP_PERCENTAGE_RATE",
  C_SM_SUB_CTG_EXE_ADJ_CONT: "DISCP_EXE_ADJUST_CONTAINER",
  C_SM_SUB_CTG_EXE_SUG_CONT: "DISCP_EXE_SUGGEST_CONTAINER",
  C_SM_SUB_CTG_EXE_SUG_BAR: "DISCP_EXE_SUGGEST_BARCODE",
  C_SM_SUB_CTG_EXE_ADJ_SORT: "DISCP_EXE_ADJUST_SORTING",

  C_SM_SUB_EXP_INV_CONVERSION: "EXP_INV_CONVERSION",
  C_SM_SUB_MAT_CAL_WH_PART_MAP: "MAT_CAL_WH_PART_MAP",

  /// NEW ENUM
  C_OVERSEA_NAME: "Oversea",
  C_DOMESTIC_NAME: "Domestic",
  FIRM_MODE: "F",
  KEIHEN_MODE: "M",
  DATETIME_FORMAT: "YYYY-MM-DD HH:mm:ss",
  DATETIME_FORMAT_0: "YYYY-MM-DD 00:00:00",
  DATE_FORMAT: "YYYY-MM-DD",
  YEAR_MONTH_FORMAT: "YYYYMM",
  MY_SQL_YEAR_MONTH_FORMAT: "%Y%m",
  MY_SQL_DATE_FORMAT: "%Y-%m-%d",
  MY_SQL_DATE_FORMAT_NO_DASH: "%Y%m%d",
  MY_SQL_DATETIME_FORMAT: "%Y-%m-%d %H:%i:%s",
};

export const Type = {
  M: "M",
};

export const ForecastType = {
  F: "F",
  K: "K",
};

export const System = {
  Importer: "Importer",
  Exporter: "Exporter",
  Common: "COMMON",
  StatusY: "Y",
};
