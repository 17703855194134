import React from "react";
import { Grid } from "@mui/material";
import { GridRowModes } from "@mui/x-data-grid";
import { InputButton } from "components/UI";
//Utils
import { messageDisplay, responseErrors, responseSuccess } from "utils";
//Hooks
import { useConfirmDialogContext } from "context/confirmDialogContext";
//Type
import { BUTTON_VALUE } from "shared/constants";
import { MSG_TYPE, ModeAction } from "state/enum";
import { useShippingInfoDeleteMutation } from "shared/services/invoice";
import { getLocalDate } from "utils/init-config-date";
export default function ButtonTable(props) {
  const {
    exporterList,
    mode,
    setMode,
    onSearch,
    setOnSearch,
    rows,
    setRows,
    columns,
    form,
    setValueFieldTable,
    refreshRowsTable,
    setMsg: { setMsgAlert, setMsgError },
    stateButton: { addBtn, copyBtn, editBtn, deleteBtn },
    rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
    functionId,
  } = props;
  const confirmDialogCtx = useConfirmDialogContext();
  const deleteShippingInfo = useShippingInfoDeleteMutation();
  const handleAdd = () => {
    try {
      let maxNo = -1;
      setMsgError([]);
      setMsgAlert([]);
      setMode(ModeAction.ADD);
      setOnSearch(true);
      const getFirstExporter = exporterList[0];
      const field = {};
      columns.forEach(column => {
        if (column.field === "rowNumber") {
          maxNo = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));
          field[column.field] = rows.length ? maxNo + 1 : maxNo;
        } else {
          field[column.field] = "";
        }
      });

      setRows(prev => [
        ...prev,
        {
          ...field,
          exporterCode: !getFirstExporter ? "" : getFirstExporter?.impExpCd,
          isNew: true,
        },
      ]);
      setValueFieldTable({ impCompanyAbbrId: "", exporterCd: getFirstExporter?.impExpCd });
      const maxNoModel = rows.length ? maxNo + 1 : maxNo;

      setRowModesModel(oldModel => ({
        ...oldModel,
        [maxNoModel]: { mode: GridRowModes.Edit },
      }));

      setRowSelectionModel([maxNoModel]);
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const handleCopy = () => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      setMode(ModeAction.ADD);
      setOnSearch(true);
      const row = rows.find(v => v.rowNumber === rowSelectionModel[0]);
      const maxNo = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));
      setRows(oldRows => [
        ...oldRows,
        {
          rowNumber: maxNo + 1,
          exporterCode: row.exporterCode,
          importerCode: row.importerCode,
          importerCompany: row?.impCompanyAbbr,
          impCompanyAbbrId: row?.impCompanyAbbrId,
          consigneeId: "",
          consignee: "",
          loadingPort: row.loadingPort,
          dischargingPort: row.dischargingPort,
          vesselName: row?.vesselName,
          broker: "",
          carFamilyCode: row.carFamilyCode,
          isNew: true,
        },
      ]);
      setValueFieldTable({ impCompanyAbbrId: row?.impCompanyAbbrId, exporterCd: row?.exporterCode });

      setRowModesModel(oldModel => ({
        ...oldModel,
        [maxNo + 1]: { mode: GridRowModes.Edit },
      }));

      setRowSelectionModel([maxNo + 1]);
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const handleEdit = () => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      const currentDate = getLocalDate();
      const row = rows.find(v => v.rowNumber === rowSelectionModel[0]);
      if (getLocalDate(row?.etd, "DD/MM/YYYY").isBefore(currentDate, "day")) {
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MDN95040AERR", msg: ["Edit"] })]);
        return;
      }
      setValueFieldTable({ impCompanyAbbrId: row?.impCompanyAbbrId, exporterCd: row?.exporterCode });
      setMode(ModeAction.EDIT);
      let tempRowModesModel = rowModesModel;

      const rowSelect = rowSelectionModel.map(val => {
        return (tempRowModesModel = {
          ...tempRowModesModel,
          [val]: { mode: GridRowModes.Edit },
        });
      });
      if (rowSelect) {
        setRowModesModel(tempRowModesModel);
      }
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const handleDelete = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
      if (!confirm) {
        return;
      }
      const currentDate = getLocalDate();
      const selectedNo = rowSelectionModel[0];
      const row = rows.find(v => v.rowNumber === selectedNo);
      if (getLocalDate(row?.etd, "DD/MM/YYYY").isBefore(currentDate, "day")) {
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MDN95040AERR", msg: ["Delete"] })]);
        return;
      }
      const body = {
        shippingInfoHId: row?.shippingInfoHId,
        updateDt: row?.updateDt,
        updateBy: row?.updateBy,
        // updateBy: userProfile?.userId,
      };
      const response = await deleteShippingInfo(body);
      const newRows = rows
        .filter(row => row.rowNumber !== selectedNo)
        .map(el => (el.rowNumber > selectedNo ? { ...el, no: el.no - 1, rowNumber: el.rowNumber - 1 } : el));
      setRows(newRows);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      await refreshRowsTable();
      return;
    } catch (error) {
      // console.error(error?.response?.data?.message);
      // setMsgAlert([error?.response?.data?.message]);
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <InputButton
          id="btn_add"
          value={BUTTON_VALUE.ADD}
          disabled={addBtn}
          onClick={handleAdd} 
          onKeyDown={e => {
            if (!onSearch && e.key === "Tab") {
              e.preventDefault();
              document.getElementById("select-exporter-code").focus();
            }
          }}
          name={`${functionId}Add`}
        />
        <InputButton id="btn_copy" value={BUTTON_VALUE.COPY} disabled={copyBtn} onClick={handleCopy} name={`${functionId}Copy`} />
        <InputButton id="btn_edit" value={BUTTON_VALUE.EDIT} disabled={editBtn} onClick={handleEdit} name={`${functionId}Edit`} />
        <InputButton id="btn_delete" value={BUTTON_VALUE.DELETE} disabled={deleteBtn} onClick={handleDelete} name={`${functionId}Delete`} />
      </Grid>
    </Grid>
  );
}
