import React, { Fragment } from "react";
import { DataGridTable } from "components/UI";
import { messageDisplay, responseErrors } from "utils";
import { MSG_TYPE } from "state/enum";
import { API_REPROCESS, PAGINATION, ROW_PER_PAGE } from "shared/constants";

export default function TableBar(props) {
  const {
    form,
    rows,
    setRows,
    columns,
    setRowModesModel,
    rowModesModel,
    rowSelectionModel,
    setRowSelectionModel,
    getSearch,
    mode: { mode, setMode },
    setMsg: { setMsgAlert, setMsgError },
    pagination: { setPagination, setPageNumber, pagination, pageNumber },
  } = props ?? {};
  const handleChangePagination = async (event, value) => {
    try {
      setPageNumber(value);
      const body = {
        [API_REPROCESS.DATA_OWNER]: form?.dataOwner,
        [API_REPROCESS.BUSINESS_NAME]: form?.businessName,
        [API_REPROCESS.PLANT]: form?.plant,
        [API_REPROCESS.BATCH_ROUND]: form?.batchRound,
        [PAGINATION.PAGE_NUMBER]: value,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const { searchData, data } = await getSearch(body);
      if (!data.length) {
        // setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: "" })]);
        return false;
      }
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  return (
    <Fragment>
      <DataGridTable
        id="table-screen"
        mode={mode}
        setMode={setMode}
        rowHeight={40}
        isMultipleSelection={true}
        checkboxSelection={true}
        rows={rows}
        onCellClick={false}
        disableCheckBoxAll={false}
        setMsg={{setMsgAlert, setMsgError}}
        // processRowUpdate={processRowUpdate}
        onPagination={true}
        rowSelection={{
          rowModesModel: rowModesModel,
          setRowModesModel: setRowModesModel,
          rowSelectionModel: rowSelectionModel,
          setRowSelectionModel: setRowSelectionModel,
        }}
        pagination={{
          pagination: pagination,
          pageNumber: pageNumber,
          handleChangePagination: handleChangePagination,
        }}
        column={{
          columns: columns,
          columnGroupingModel: null,
          columnVisibilityModel: {
            [API_REPROCESS.ROW_NUMBER]: false,
            [API_REPROCESS.BARCODE_ID]: false,
          },
        }}
      />
    </Fragment>
  );
}
