import { Box, Grid } from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Photo } from "@mui/icons-material";
import {
  InputButton,
  InputText,
  InputDropDown,
  InputEditTableDropDown,
  InputEditTableFile,
  InputEditTableDate,
  InputEditTableCurrency,
  // InputDatePicker,
} from "components/UI/index";
import { useCategory, useGetOwner, useGetType, useSearchRpackage } from "service/rpackagetype";
import { ModalPrice } from "components/RpackageType/ModalPrice";
import {
  apiClient,
  message,
  // apiClientTypeBlob,
  setDataPriceList,
  parseCurrencyString,
  formatDateToSave,
  formatDate,
  findObject,
  responseErrors,
} from "utils";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import InputEditTableText from "components/UI/InputEditTableText";
import ActionBar from "../Partials/ActionBar";
import { ModeAction } from "state/enum";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import SubmitBar from "pages/Partials/SubmitBar";
import DataTable from "pages/DataTable";
import ModalPreviewImage from "components/RpackageType/ModalPreviewImage";
import { ModalLogistic } from "components/Logistic/ModalLogistic";
import { userProfile } from "constant";
import { getLocalDate } from "utils/init-config-date";
// import InputDateCustom from "components/UI/InputDateCustom";

const LogisticRouteScreen = () => {
  const confirmDialogCtx = useConfirmDialogContext();

  const [mode, setMode] = useState("view");
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [onSearch, setOnSearch] = useState(false);

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  const [searchForm, setSearchForm] = useState({
    logRname: "",
    sRpointName: "",
    eRpointName: "",
  });

  // 1. pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const handleChangePagination = async (event, value) => {
    setPageNumber(value);
    const searchData = await searchDataAsync({
      rPkgOwnerCompId: searchForm.rPkgOwnerCompId,
      rPkgType: searchForm.rPkgTypeId,
      rPkgDesc: searchForm.rPkgDesc,
      pageNumber: value,
      rowsPerPage: 10,
    });

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        rPkgOwnerCompId: item.companyId,
        categoryCd: item.categoryCd,
        rPkgType: item.rPkgType,
        rPkgTypeId: item.rPkgTypeId,
        rPkgDesc: item.rPkgDesc,
        weight: item.weight,
        asbLength: item.asbLength,
        asbWidth: item.asbWidth,
        asbHeight: item.asbHeight,
        pic: item.asbPic,
        dsbLength: item.dsbLength,
        dsbWidth: item.dsbWidth,
        dsbHeight: item.dsbHeight,
        dsbPic: item.dsbPic,
        price: (() => setDataPriceList(item.currencys))(),
        currencys: item.currencys,
        color: item.color,
        effDtFrom: new Date(item.effDtFrom),
        effDtTo: new Date(item.effDtTo),
        value: item.value,
      })) ?? [];

    setPagination(searchData?.result?.pagination ?? {});
    setPageNumber(searchData?.result?.pagination?.pageNumber);

    setRows(data);
  };

  // 2. api
  const { data: ownerData, isSuccess: isSuccessOwnerData } = useGetOwner();

  const { data: typeData, isSuccess: isSuccessTypeData } = useGetType({
    rPkgOwnerCompId: searchForm.rPkgOwnerCompId,
  });

  const { mutateAsync: searchDataAsync } = useSearchRpackage({});

  const { data: categoryData } = useCategory();

  // 3. initail value search from
  useEffect(() => {
    if (isSuccessOwnerData) {
      // TODO: R-Package Owner ค่า Default ต้องเป็น Company ที่ Login
      setSearchForm(old => ({
        ...old,
        rPkgOwnerCompId: ownerData?.[0]?.rPkgOwnerCompId,
      }));
    }
    return;
  }, [isSuccessOwnerData, ownerData]);

  useEffect(() => {
    if (isSuccessTypeData) {
      setSearchForm(old => ({
        ...old,
        rPkgTypeId: "",
      }));
    }
    return;
  }, [isSuccessTypeData]);

  // 4. columns
  const columns = useMemo(
    () => [
      {
        field: "rowNumber",
        sortable: false,
        headerName: "No",
        width: 50,
        editable: false,
        renderHeader: () => <div></div>,
        renderCell: params => {
          if (params.row.rowNumber === rows.slice(-1)?.[0].rowNumber && ModeAction.ADD === mode) {
            return <div></div>;
          }
          return params.value;
        },
      },
      {
        field: "rPkgOwnerCompId",
        sortable: false,
        headerAlign: "center",
        align: "center",
        headerName: "R-Package Owner",
        width: 150,
        editable: true,
        renderHeader: () => <div></div>,
        renderEditCell: params => {
          if (mode === ModeAction.ADD) {
            return (
              <InputEditTableDropDown
                {...params}
                required
                memu={ownerData?.map(val => ({
                  key: val.rPkgOwnerCompId,
                  value: val.rPkgOwnerCompAbbr,
                }))}
                placeholder="<Select>"
                defaultValue=""
              />
            );
          } else {
            const name =
              params.value &&
              // ownerData?.find((val) => {
              //   return val.rPkgOwnerCompId == params.value;
              // })?.rPkgOwnerCompAbbr;
              findObject({
                data: ownerData,
                value: params.value,
                property: "rPkgOwnerCompId",
                field: "rPkgOwnerCompAbbr",
              });
            return <p>{name}</p>;
          }
        },
        renderCell: params => {
          const name =
            // params.value &&
            // ownerData?.find((val) => {
            //   return val.rPkgOwnerCompId == params.value;
            // })?.rPkgOwnerCompAbbr;

            findObject({
              data: ownerData,
              value: params.value,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            });

          return <p>{name}</p>;
        },
      },
      {
        field: "categoryCd",
        sortable: false,
        headerAlign: "center",
        align: "center",
        headerName: "R-Package Category",
        type: "select",
        width: 150,
        editable: true,
        renderHeader: () => <div></div>,
        renderEditCell: params => {
          if (mode === ModeAction.ADD) {
            return (
              <InputEditTableDropDown
                {...params}
                required
                memu={categoryData?.map(val => ({
                  key: val.cd,
                  value: val.value,
                }))}
                placeholder="<Select>"
                defaultValue=""
              />
            );
          } else {
            return params?.value;
            // return (
            //   <InputEditTableDropDown
            //     {...params}
            //     required
            //     memu={categoryData?.map((val) => ({
            //       key: val.cd,
            //       value: val.value,
            //     }))}
            //     placeholder="<Select>"
            //     defaultValue=""
            //     disabledd
            //   />
            // );
          }
        },
        renderCell: params => {
          const name =
            // params.value &&
            // categoryData?.find((val) => {
            //   return val.cd == params.value;
            // })?.value;

            findObject({
              data: categoryData,
              value: params.value,
              property: "cd",
              field: "value",
            });
          return <p>{name}</p>;
        },
      },
      {
        field: "rPkgType",
        sortable: false,
        headerAlign: "center",
        align: "center",
        headerName: "R-Package Type",
        width: 150,
        editable: true,
        renderHeader: () => <div></div>,
        renderEditCell: params => {
          if (mode === ModeAction.ADD) {
            return (
              <InputEditTableText
                {...params}
                required
                maxLength={5}
                regularExp={/[!@#$%^&*(),.?":{}|<>]/}
                // specialCharsRegex change by surasith
              />
            );
          } else {
            // return <InputEditTableText {...params} required disabled />;
            return params?.value;
          }
        },
        renderCell: params => {
          return params?.value;
        },
      },
      {
        field: "rPkgTypeId",
      },
      {
        field: "rPkgDesc",
        sortable: false,
        headerAlign: "center",
        align: "center",
        headerName: "R-Package Type Description",
        width: 250,
        editable: true,
        renderHeader: () => <div></div>,
        renderEditCell: params => {
          return <InputEditTableText {...params} required maxLength={30} />;
        },
        renderCell: params => {
          return params?.value;
        },
      },
      {
        field: "weight",
        sortable: false,
        headerName: "Weight (Kg)",
        editable: true,
        align: "right",
        headerAlign: "center",
        renderHeader: () => <div></div>,
        renderEditCell: params => <InputEditTableCurrency {...params} required decimalScale={3} maxLimit={10000} />,
        renderCell: params => {
          return params?.value;
        },
      },
      {
        field: "asbLength",
        sortable: false,
        headerName: "Length",
        type: "string",
        editable: true,
        align: "right",
        headerAlign: "center",
        renderEditCell: params => {
          return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
        },
        renderCell: params => {
          return params?.value;
        },
      },
      {
        field: "asbWidth",
        sortable: false,
        headerName: "Width",
        type: "text",
        editable: true,
        align: "right",
        headerAlign: "center",
        renderEditCell: params => {
          return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
        },
        renderCell: params => params?.value,
      },
      {
        field: "asbHeight",
        sortable: false,
        headerName: "Height",
        type: "string",
        editable: true,
        align: "right",
        headerAlign: "center",
        renderEditCell: params => {
          return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
        },
        renderCell: params => params?.value,
      },
      {
        field: "pic",
        sortable: false,
        headerName: "Picture",
        editable: true,
        align: "center",
        headerAlign: "center",
        type: "actions",
        renderEditCell: params => <InputEditTableFile {...params} uploadProcess={handleUploadPic} />,
        getActions: params => [
          <GridActionsCellItem
            style={{ alignContent: "center" }}
            key={0}
            icon={<Photo />}
            onClick={e => {
              e.preventDefault();
              if (params.row.pic) {
                handleModalImage(params.row, "dsb");
                // window.open(
                //   `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}${params.row.pic}`,
                //   "_blank"
                // );
              }
            }}
          />,
        ],
      },
      {
        field: "dsbLength",
        sortable: false,
        headerName: "Length",
        type: "string",
        editable: true,
        align: "right",
        headerAlign: "center",
        renderEditCell: params => {
          return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
        },
        renderCell: params => {
          return params?.value;
        },
      },
      {
        field: "dsbWidth",
        sortable: false,
        headerName: "Width",
        type: "string",
        editable: true,
        align: "right",
        headerAlign: "center",
        renderEditCell: params => {
          return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
        },
        renderCell: params => {
          return params?.value;
        },
      },
      {
        field: "dsbHeight",
        sortable: false,
        headerName: "Height",
        type: "string",
        editable: true,
        align: "right",
        headerAlign: "center",
        renderEditCell: params => {
          return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
        },
        renderCell: params => {
          return params?.value;
        },
      },
      {
        field: "dsbPic",
        sortable: false,
        headerName: "Picture",
        editable: true,
        align: "center",
        headerAlign: "center",
        type: "actions",
        renderEditCell: params => {
          return (
            <>
              <InputEditTableFile {...params} uploadProcess={handleUploadPic} />
              {/* <label>{rows.find((v) => v.rowNumber === params.id)?.pic}</label> */}
            </>
          );
        },
        getActions: params => [
          <GridActionsCellItem
            style={{ alignContent: "center" }}
            key={0}
            icon={<Photo />}
            onClick={e => {
              e.preventDefault();
              if (params.row.dsbPic) {
                handleModalImage(params.row, "asb");
                // window.open(
                //   `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/${params.row.dsbPic}`,
                //   "_blank"
                // );
              }
            }}
          />,
        ],
      },
      {
        field: "price",
        sortable: false,
        width: 200,
        headerName: "Price",
        editable: true,
        align: "center",
        headerAlign: "center",
        renderHeader: () => <div></div>,
        renderEditCell: params => {
          const findRow = rows.find(v => v.rowNumber === params.id).currencys;
          if (findRow.length) {
            return (
              <div
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => handleModalPrice(params.id, params.row.currencys)}>
                {findRow.map((val, i) => {
                  return <div key={i}>{`${val.price}(${val.currentCd})`}</div>;
                })}
              </div>
            );
          }
          return (
            <InputButton
              value={"Detail"}
              color="info"
              size="small"
              style={{ textTransform: "none" }}
              onClick={() => handleModalPrice(params.id)}
              minWidth="80px"
            />
          );
        },
        renderCell: params => {
          const findRow = rows.find(v => v.rowNumber === params.id).currencys;
          if (findRow.length) {
            return (
              <div
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => handleModalPrice(params.id, params.row.currencys)}>
                {findRow.map((val, i) => {
                  return <div key={i}>{`${val.price}(${val.currentCd})`}</div>;
                })}
              </div>
            );
          }
          return (
            <InputButton
              value={"Detail"}
              color="info"
              size="small"
              style={{ textTransform: "none" }}
              onClick={() => handleModalPrice(params.id)}
              minWidth="80px"
            />
          );
        },
      },
      {
        field: "color",
        sortable: false,
        width: 160,
        headerName: "Color",
        editable: true,
        align: "left",
        headerAlign: "center",
        renderHeader: () => <div></div>,
        renderEditCell: params => {
          return <InputEditTableText {...params} required maxLength={20} />;
        },
        renderCell: params => {
          return params?.value;
        },
      },
      {
        field: "effDtFrom",
        width: 150,
        sortable: false,
        headerName: "From",
        editable: true,
        align: "center",
        headerAlign: "center",
        renderEditCell: params => {
          if (mode === ModeAction.ADD) {
            return <InputEditTableDate {...params} required />;
          } else {
            return <InputEditTableDate {...params} required disabled />;
          }
        },
        renderCell: params => formatDate(params.value, "DD/MM/YYYY"),
        // valueGetter: (params) => formatDate(params.value, "DD/MM/YYYY"),
      },
      {
        field: "effDtTo",
        width: 150,
        sortable: false,
        headerName: "To",
        editable: true,
        align: "center",
        headerAlign: "center",
        renderEditCell: params => {
          return <InputEditTableDate {...params} />;
          // return <InputDateCustom {...params} />;
          // return <InputDatePicker {...params} format="DD/MM/YYYY" />;
        },
        renderCell: params => formatDate(params.value, "DD/MM/YYYY"),
      },
      {
        field: "currencys",
      },
    ],
    [rows]
  );

  // 5. columnGroupingModel
  const columnGroupingModel = [
    {
      groupId: "No.",
      headerAlign: "center",
      children: [{ field: "rowNumber" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "R-Package Owner",
      headerAlign: "center",
      children: [{ field: "rPkgOwnerCompId" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "R-Package Category",
      headerAlign: "center",
      children: [{ field: "categoryCd" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "R-Package Type",
      headerAlign: "center",
      children: [{ field: "rPkgType" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "R-Package Type Description",
      headerAlign: "center",
      children: [{ field: "rPkgDesc" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Weight (Kg)",
      headerAlign: "center",
      children: [{ field: "weight" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Assembly Size(mm)",
      headerAlign: "center",
      children: [{ field: "asbLength" }, { field: "asbWidth" }, { field: "asbHeight" }, { field: "pic" }],
      headerClassName: "table-header-group",
    },
    {
      groupId: "Dis-assembly Size(mm)",
      headerAlign: "center",
      children: [{ field: "dsbLength" }, { field: "dsbWidth" }, { field: "dsbHeight" }, { field: "dsbPic" }],
      headerClassName: "table-header-group",
    },
    {
      groupId: "Price",
      headerAlign: "center",
      children: [{ field: "price" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Color",
      headerAlign: "center",
      children: [{ field: "color" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Effective Date (DD/MM/YYYY)",
      headerAlign: "center",
      children: [{ field: "effDtFrom" }, { field: "effDtTo" }],
      headerClassName: "table-header-group",
    },
  ];

  // 6. handleSearch
  const handleSearch = async e => {
    try {
      e.preventDefault();
      setRows([]);
      setMsgError([]);
      setMsgAlert([]);
      setRowSelectionModel([]);

      // validate
      if (!searchForm.rPkgOwnerCompId) {
        setMsgError(oldMsg => [...oldMsg, message({ type: "empty", msg: "R-Package Owner" })]);
        return;
      }

      setOnSearch(true);
      const searchData = await searchDataAsync({
        rPkgOwnerCompId: searchForm.rPkgOwnerCompId,
        rPkgType: searchForm.rPkgTypeId,
        rPkgDesc: searchForm.rPkgDesc,
        pageNumber: pageNumber,
        rowsPerPage: 10,
      });

      const data =
        searchData?.result?.data?.map((item, index) => ({
          rowNumber: index + 1,
          rPkgOwnerCompId: item.companyId,
          categoryCd: item.categoryCd,
          rPkgType: item.rPkgType,
          rPkgTypeId: item.rPkgTypeId,
          rPkgDesc: item.rPkgDesc,
          weight: item.weight,
          asbLength: item.asbLength,
          asbWidth: item.asbWidth,
          asbHeight: item.asbHeight,
          pic: item.asbPic,
          dsbLength: item.dsbLength,
          dsbWidth: item.dsbWidth,
          dsbHeight: item.dsbHeight,
          dsbPic: item.dsbPic,
          // price: (() => setDataPriceList(item.currencys))(),
          price: item.currencys,
          currencys: item.currencys,
          color: item.color,
          effDtFrom: item.effDtFrom,
          effDtTo: item.effDtTo,
          value: item.value,
        })) ?? [];

      // data not found
      if (!data.length) {
        setMsgError([message({ type: "notFound" })]);
      }

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  // 7. handleDeleteClick
  const handleDeleteClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
    if (!confirm) {
      return;
    }
    // TODO: Action Delete
    try {
      const selectedNo = rowSelectionModel[0];
      const id = rows.find(v => v.rowNumber === selectedNo)?.rPkgTypeId;

      await apiClient.delete(`/r-package/type/delete/${id}`, {
        data: { updateBy: "papimata" },
      });

      const newRows = rows.filter(row => !rowSelectionModel.includes(row.rowNumber));

      setRows(newRows);

      setMsgAlert([message({ type: "deleted" })]);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  // 8. processRowUpdate (Action Add and Edit)
  const processRowUpdate = async newRow => {
    // console.log("+++newRow", newRow);
    // console.log(parseCurrencyString(newRow.weight));
    // validate
    setMsgError([]);
    setMsgAlert([]);

    if (newRow.isNew) {
      // action Save
      let stopProcess = false;
      // const errorValidation = (msg = "") => {
      //   setMsgError((oldMsg) => [...oldMsg, message({ type: "empty", msg })]);
      //   stopProcess = true;
      // };

      // if (!newRow.rPkgOwnerCompId) errorValidation("R-Package Owner");
      // if (!newRow.categoryCd) errorValidation("R-Package Category");
      // if (!newRow.rPkgType) errorValidation("R-Package Type");
      // if (!newRow.rPkgDesc) errorValidation("R-Package Type Description");
      // if (!newRow.weight) errorValidation("Weight");
      // if (!newRow.asbLength) errorValidation("Assemble Size(mm) Length");
      // if (!newRow.asbWidth) errorValidation("Assemble Size(mm) Width");
      // if (!newRow.asbHeight) errorValidation("Assemble Size(mm) Height");
      // if (!newRow.dsbLength) errorValidation("Dis-assemble Size(mm) Length");
      // if (!newRow.dsbWidth) errorValidation("Dis-assemble Size(mm) Width");
      // if (!newRow.dsbHeight) errorValidation("Dis-assemble Size(mm) Height");
      // if (!newRow.color) errorValidation("Color");
      // if (!dayjs(newRow.effDtFrom).isValid() || !newRow.effDtFrom)
      //   errorValidation("EffectiveDate From");

      // if (!newRow.currencys.length) errorValidation("Price");

      if (stopProcess) return;
      try {
        await apiClient.post(`/r-package/type/create`, {
          rPkgOwnerCompId: newRow.rPkgOwnerCompId,
          rPkgOwnerCompArrr: userProfile.packageOwner,
          dataOwner: userProfile.dataOwner,
          categoryCd: newRow.categoryCd,
          rPkgType: newRow.rPkgType,
          rPkgDesc: newRow.rPkgDesc,
          weight: parseCurrencyString(newRow.weight),
          asbLength: parseCurrencyString(newRow.asbLength),
          asbWidth: parseCurrencyString(newRow.asbWidth),
          asbHeight: parseCurrencyString(newRow.asbHeight),
          asbPic: newRow.pic,
          dsbLength: parseCurrencyString(newRow.dsbLength),
          dsbWidth: parseCurrencyString(newRow.dsbWidth),
          dsbHeight: parseCurrencyString(newRow.dsbHeight),
          dsbPic: newRow.dsbPic,
          color: newRow.color,
          effDtFrom: formatDateToSave(newRow.effDtFrom),
          effDtTo: formatDateToSave(newRow.effDtTo),
          createBy: "papimata",
          currencys: newRow.currencys?.map(v => {
            return {
              seqNo: v.seqNo,
              // price: parseFloat(v.price.replace(",", "")),
              price: parseCurrencyString(v.price),
              currentCd: v.currentCd,
            };
          }),
        });

        setMsgAlert([message({ type: "added" })]);
        setRows([]);
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        return;
      }
    } else {
      // action Update
      // let stopProcess = false;
      // const errorValidation = (msg = "") => {
      //   setMsgError((oldMsg) => [...oldMsg, message({ type: "empty", msg })]);
      //   stopProcess = true;
      // };
      // if (!newRow.rPkgDesc) errorValidation("R-Package Type Description");
      // if (!newRow.weight) errorValidation("Weight");
      // if (!newRow.color) errorValidation("Color");
      // if (!dayjs(newRow.effDtFrom).isValid() || !newRow.effDtFrom)
      //   errorValidation("EffectiveDate From");
      // if (!newRow.currencys.length) errorValidation("Price");
      // if (stopProcess) return;
      // try {
      //   await apiClient.patch(`/r-package/type/edit/${newRow.rPkgTypeId}`, {
      //     rPkgOwnerCompId: newRow.rPkgOwnerCompId,
      //     rPkgOwnerCompArrr: "TMATH",
      //     dataOwner: "TMATH",
      //     // categoryCd: newRow.categoryCd,
      //     // rPkgType: newRow.rPkgType,
      //     rPkgDesc: newRow.rPkgDesc,
      //     weight: parseCurrencyString(newRow.weight),
      //     asbLength: parseCurrencyString(newRow.asbLength),
      //     asbWidth: parseCurrencyString(newRow.asbWidth),
      //     asbHeight: parseCurrencyString(newRow.asbHeight),
      //     asbPic: newRow.pic,
      //     dsbLength: parseCurrencyString(newRow.dsbLength),
      //     dsbWidth: parseCurrencyString(newRow.dsbWidth),
      //     dsbHeight: parseCurrencyString(newRow.dsbHeight),
      //     dsbPic: newRow.dsbPic,
      //     color: newRow.color,
      //     effDtFrom: formatDateToSave(newRow.effDtFrom),
      //     effDtTo: formatDateToSave(newRow.effDtTo),
      //     updateBy: "papimata",
      //     currencys: newRow.currencys?.map((v) => {
      //       return {
      //         seqNo: v.seqNo,
      //         price: parseFloat(v.price.replace(",", "")),
      //         currentCd: v.currentCd,
      //       };
      //     }),
      //   });
      //   setMsgAlert([message({ type: "updated" })]);
      // } catch (error) {
      //   const { errors } = error.response.data;
      //   setMsgError([`${errors[0].code} ${errors[0].message}`]);
      //   return;
      // }
    }

    setMode(ModeAction.VIEW);
    setRowSelectionModel([]);

    const updatedRow = { ...newRow, isNew: false };
    setRows(prevRows => prevRows.map(row => (row.rowNumber === newRow?.rowNumber ? updatedRow : row)));

    return updatedRow;
  };

  // 9. handleClear
  const handleClear = () => {
    setMode(ModeAction.VIEW);
    setMsgError([]);
    setMsgAlert([]);
    setRowSelectionModel([]);
    setOnSearch(false);
    setSearchForm({
      rPkgDesc: "",
      rPkgOwnerCompId: "CMP_1208_000001",
      rPkgTypeId: "",
    });
    setRows([]);
  };

  // 10. handleDownloadExcel
  const handleDownloadExcel = async () => {
    // validate
    setMsgError([]);
    setMsgAlert([]);
    if (!searchForm.rPkgOwnerCompId) {
      setMsgError(oldMsg => [
        ...oldMsg,
        // message({ type: "empty", msg: "R-Package Owner" }),
        message({ type: "notFound" }),
      ]);
      return;
    }
    if (!rows.length) {
      setMsgError([message({ type: "notFound" })]);
      return;
    }

    try {
      // await apiClientTypeBlob({
      //   url: `/r-package/download/excel`,
      //   option: {
      //     rPkgOwnerCompId: searchForm.rPkgOwnerCompId,
      //     rPkgType: searchForm.rPkgTypeId,
      //     rPkgDesc: searchForm.rPkgDesc,
      //     pageNumber: pageNumber,
      //     rowsPerPage: 10,
      //   },
      //   filename: `R_Package_Type_Master_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`,
      // });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setMsgError(oldMsg => [...oldMsg, message({ type: "notFound" })]);
      } else {
        const errors = responseErrors(error);
        setMsgError(errors);
        return;
      }
    }
  };

  // modal logistic
  const [isOpenModalLogistic, setIsOpenModalLogistic] = useState(false);
  const handleAdd = () => {
    setIsOpenModalLogistic(true);
  };

  // modal price
  const [isOpenModalPrice, setIsOpenModalPrice] = useState(false);
  const [rowNumberPrice, setRowNumberPrice] = useState(null);
  const [rowInitialDataPrice, setRowInitialDataPrice] = useState([]);

  const handleModalPrice = (id, initialData = []) => {
    setIsOpenModalPrice(true);
    setRowNumberPrice(id);
    setRowInitialDataPrice(initialData);
  };

  // modal preview image
  const [isOpenModalImage, setIsOpenModalImage] = useState(false);
  const [rowNumberImage, setRowNumberImage] = useState(null);
  const [modalImageType, setModalImageTypee] = useState(null);

  const handleModalImage = (id, type) => {
    setIsOpenModalImage(true);
    setRowNumberImage(id);
    setModalImageTypee(type);
  };

  const handleUploadPic = async (type, file) => {
    // TODO: Action Upload
    try {
      const _type = type === "dsbPic" ? "dsb" : "asb";

      // const ids =
      //   rows.find((v) => v.rowNumber === id)?.rPkgTypeId ||
      //   typeData.find((v) => v.rPkgType === "");

      let res = await apiClient.post(
        `/r-package/upload/${_type}`,
        {
          image: file,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.status == 200) {
        alert("upload successfully");
        return res.data.result.path;
      }
      return false;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        {/* Header */}
        <HeaderContentBar title="WDN91050 : Logistic Route Master Maintenance Screen" />
        {/* Alert Message */}
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container spacing={0} sx={{ mt: 4, alignItems: "center" }}>
          <Grid item xs={2}>
            <strong>Logistics Route Name : </strong>
          </Grid>
          <Grid item xs={2}>
            <InputText
              id="input_logRname"
              fullWidth={true}
              value={searchForm.logRname}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  logRname: e.target.value,
                }))
              }
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              maxLength={30}
            />
          </Grid>
          <Grid item xs={8}></Grid>

          <Grid item xs={2} sx={{ mt: 2 }}>
            <strong>Start Route Point Name : </strong>
          </Grid>
          <Grid item xs={2} sx={{ mt: 2 }}>
            <InputDropDown
              id="select_sRpointName"
              value={searchForm.sRpointName}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  sRpointName: e.target.value,
                }))
              }
              memu={typeData?.map(val => ({
                key: val.rPkgType,
                value: val.rPkgType,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<All>"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={2} sx={{ mt: 2 }}>
            <strong>End Route Point Name : </strong>
          </Grid>
          <Grid item xs={2} sx={{ mt: 1, ml: 3 }}>
            <InputDropDown
              id="select_eRpointName"
              value={searchForm.eRpointName}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  eRpointName: e.target.value,
                }))
              }
              memu={typeData?.map(val => ({
                key: val.rPkgType,
                value: val.rPkgType,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<All>"
              defaultValue=""
            />
          </Grid>
        </Grid>
      </Box>
      <ActionBar
        mode={mode}
        rows={rows}
        columns={columns}
        setRows={setRows}
        setMode={setMode}
        setMsgError={setMsgError}
        setMsgAlert={setMsgAlert}
        setOnSearch={setOnSearch}
        setRowModesModel={setRowModesModel}
        setRowSelectionModel={setRowSelectionModel}
        rowSelectionModel={rowSelectionModel}
        rowModesModel={rowModesModel}
        handleSearch={handleSearch}
        handleClear={handleClear}
        handleDownloadExcel={handleDownloadExcel}
        handleDeleteClick={handleDeleteClick}
        handleAdd={handleAdd}
      />
      <Box sx={{ padding: "1rem" }}>
        <Grid container spacing={2}>
          {onSearch && rows.length !== 0 && (
            <>
              <DataTable
                mode={mode}
                onSearch={onSearch}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                setMode={setMode}
                rows={rows}
                rowModesModel={rowModesModel}
                columns={columns}
                setRowModesModel={setRowModesModel}
                processRowUpdate={processRowUpdate}
                pagination={pagination}
                pageNumber={pageNumber}
                handleChangePagination={handleChangePagination}
                columnGroupingModel={columnGroupingModel}
                columnVisibilityModel={{
                  currencys: false,
                  rPkgTypeId: false,
                }}
              />
            </>
          )}
          <SubmitBar
            mode={mode}
            rows={rows}
            setMode={setMode}
            setRows={setRows}
            setRowModesModel={setRowModesModel}
            setMsgError={setMsgError}
            rowModesModel={rowModesModel}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
          />
        </Grid>
      </Box>

      {/* ModalPrice */}
      <ModalPrice
        open={isOpenModalPrice}
        setOpen={setIsOpenModalPrice}
        rowNumber={rowNumberPrice}
        initialData={rowInitialDataPrice}
        setRowsCurrencys={setRows}
        rowsCurrencys={rows}
        modeRows={mode}
      />

      {/* ModalPreviewImage */}
      <ModalPreviewImage
        open={isOpenModalImage}
        setOpen={setIsOpenModalImage}
        row={rowNumberImage}
        // initialData={rows}
        type={modalImageType}
      />

      {/* ModalLogistic */}
      <ModalLogistic
        open={isOpenModalLogistic}
        setOpen={setIsOpenModalLogistic}
        rowNumber={rowNumberPrice}
        initialData={rowInitialDataPrice}
        setRowsCurrencys={setRows}
        rowsCurrencys={rows}
        modeRows={mode}
      />
    </Fragment>
  );
};

export default LogisticRouteScreen;
