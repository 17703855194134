import { Grid } from "@mui/material";
import { DatePickerField, DropDown } from "components/UI";
import React, { useEffect, useState, Fragment } from "react";
import ButtonAction from "../ButtonAction";
import { FORM_DEFAULT_MONTH } from "pages/Report/constants/constants";
import {
  useReportCommonGetCountryListQuery,
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
  useReportCommonGetWarehouseListQuery,
  useReportCommonRPackageCategoryQuery,
  useGetDetailDataTypeListQuery,
  useExcelDownloadOnlineMutation,
  useExcelDownloadOnlineJavaMutation,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
import { getLocalDate } from "utils/init-config-date";
import axios from "axios";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { ResponseText } from "utils/5xxResponseText";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";

export default function MonthlyForecastReport(props) {
  const [form, setForm] = useState(FORM_DEFAULT_MONTH);

  const { data: countryList, failureReason: countryFailureReason } = useReportCommonGetCountryListQuery();
  const { data: warehouseList, failureReason: warehouseFailureReason } = useReportCommonGetWarehouseListQuery({
    countryCd: form?.countryId,
  });

  const { data: dataTypeList, failureReason: dataTypeFailureReason } = useGetDetailDataTypeListQuery();
  const { data: rPkgOwnerList, failureReason: rPkgOwnerFailureReason } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgCategoryList, failureReason: rPkgCategoryFailureReason } = useReportCommonRPackageCategoryQuery();
  const { data: rPkgTypeList, failureReason: rPkgTypeFailureReason } = useReportCommonGetRPackageTypeQuery({
    rPackageOwner: userProfile.dataOwner,
  });

  useEffect(() => {
    let message = [];
    if (countryFailureReason?.response?.status >= 404) {
      message.push(`Country List: ${ResponseText[countryFailureReason?.response?.status]}`);
    }
    if (warehouseFailureReason?.response?.status >= 404) {
      message.push(`Warehouse List: ${ResponseText[warehouseFailureReason?.response?.status]}`);
    }
    if (dataTypeFailureReason?.response?.status >= 404) {
      message.push(`Data Type List: ${ResponseText[dataTypeFailureReason?.response?.status]}`);
    }
    if (rPkgOwnerFailureReason?.response?.status >= 404) {
      message.push(`R-Package Owner List: ${ResponseText[rPkgOwnerFailureReason?.response?.status]}`);
    }
    if (rPkgCategoryFailureReason?.response?.status >= 404) {
      message.push(`R-Package Category List: ${ResponseText[rPkgCategoryFailureReason?.response?.status]}`);
    }
    if (rPkgTypeFailureReason?.response?.status >= 404) {
      message.push(`R-Package Type List: ${ResponseText[rPkgTypeFailureReason?.response?.status]}`);
    }
    props.setMsgServerErrorAlert(message);
  }, [
    countryFailureReason?.response?.status,
    warehouseFailureReason?.response?.status,
    dataTypeFailureReason?.response?.status,
    rPkgOwnerFailureReason?.response?.status,
    rPkgCategoryFailureReason?.response?.status,
    rPkgTypeFailureReason?.response?.status,
    props,
  ]);

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const generateReport = useExcelDownloadOnlineJavaMutation();
  // const generateReport = useExcelDownloadOnlineMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        month: form?.month,
        country: form?.countryId,
        warehouse: form?.warehouseId,
        rPackageOwner: form?.rPkgTypeOwner,
        rPackageCategory: form?.rPkgTypeCategory,
        rPackageType: form?.rPkgType,
        dataType: form?.dataTypeId,
        userId: userProfile.userId,
        displayName: userProfile.displayName,
        dataOwner: userProfile.dataOwner,
        userCompAbbr: userProfile.comAbbr,
      };
      const pathReport = "generate-monthly-forecast-report";
      startLoading();
      const response = await generateReport(body, pathReport);
      waitAndDownloadExcel(60, response.data, props.setMsgError, stopLoading);
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      console.error(error);

      // if (error?.response?.status >= 500) {
      //   props.setMsgServerErrorAlert([ResponseText[error?.response?.status]]);
      // } else {
      const msgError = responseErrors(error);
      props.setMsgError(msgError);
      // }
      setDisabledSubmitBtn(false);
      stopLoading();
    }
  };

  useEffect(() => {
    document.getElementById("select-country").focus();
  }, []);

  return (
    <Fragment>
      <Grid container sx={{ alignItems: "center" }}>
        <Grid item xs={5} style={{ display: "flex", margin: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>*Report : </strong>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "1", value: "Monthly Forecast Report" }]}
            value={form?.reportId}
            disabled
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5} style={{ display: "flex", margin: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>*Country : </strong>
          <DropDown
            id="select-country"
            fullWidth
            required
            value={form?.countryId}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={countryList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={async e => {
              setForm(old => ({
                ...old,
                countryId: e.target.value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", margin: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>*Warehouse : </strong>
          <DropDown
            id="select-warehouse"
            fullWidth
            required
            value={form?.warehouseId}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={warehouseList?.map(val => ({
              key: `${val.companyAbbr}:${val.impExpCd}`,
              value: `${val.companyAbbr}:${val.impExpCd}`,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                warehouseId: e.target.value,
              }))
            }
            disabled={!warehouseList?.length}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", margin: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>*Month : </strong>
          <DatePickerField
            views={["year", "month"]}
            format="MM/YYYY"
            mask="MM/YYYY"
            fullWidth={true}
            value={form?.month}
            onChange={e =>
              setForm(old => ({
                ...old,
                month: e,
              }))
            }
            required
          />
        </Grid>
        <Grid item xs={1}>
          (MM/YYYY)
        </Grid>
        <Grid item xs={5} style={{ display: "flex", margin: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>*Data Type : </strong>
          <DropDown
            id="select-data-type"
            fullWidth
            required
            value={form?.dataTypeId}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={dataTypeList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                dataTypeId: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", margin: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>*R-Package Owner : </strong>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            required
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={rPkgOwnerList?.map(val => ({
              key: val.rPkgOwnerCompAbbr,
              value: val.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", margin: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>*R-Package Category : </strong>
          <DropDown
            id="select-rpackage-category"
            fullWidth
            required
            value={form?.rPkgTypeCategory}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={rPkgCategoryList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeCategory: e.target.value,
              }))
            }
            disabled={!form.rPkgTypeOwner}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", margin: "4px 0" }}>
          <strong style={{margin: "auto 0px", width: "300px" }}>R-Package Type : </strong>
          <DropDown
            id="select-rpackage-type"
            fullWidth
            value={form?.rPkgType}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPkgTypeList?.map(val => ({
              key: val.rPkgType,
              value: val.rPkgType,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgType: e.target.value,
              }))
            }
            disabled={!form.rPkgTypeCategory}
          />
        </Grid>
      </Grid>
      <ButtonAction
        handleDownload={handleDownload}
        setFrom={setForm}
        defaultFrom={FORM_DEFAULT_MONTH}
        disabled={disabledSubmitBtn}
        setDisabled={setDisabledSubmitBtn}
        clearMsg={() => {
          props.setMsgAlert([]);
          props.setMsgError([]);
        }}
        firstField={"select-country"}
      />
    </Fragment>
  );
}
