import { useMutateData } from "shared/services/base.service";

const BaseServicePath = process.env.REACT_APP_API_INVENTORY_FORECAST;

const MonthlyApiService = () => ({
  //POST MONTHLY SEARCH
  useMonthlySearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/yearly-monthly/monthly/search`,
        method: "POST",
        data: body,
      });
    };
  },

  //POST MONThLY ACTIVATE
  useMonthlyActivateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async activateData => {
      return await mutate({
        url: `${BaseServicePath}/yearly-monthly/monthly/activate`,
        method: "POST",
        data: activateData,
      });
    };
  },
  //POST MONTHLY CREATE
  useMonthlyCreateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async createData => {
      return await mutate(
        {
          url: `${BaseServicePath}/yearly-monthly/monthly/create`,
          data: createData,
          method: "POST",
          headers: {
            "content-type": "multipart/form-data",
          },
        },
        { retry: false }
      );
    };
  },
  //POST MONTHLY EDIT
  useMonthlyUpdateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (id, updateData) => {
      return await mutate({
        url: `${BaseServicePath}/yearly-monthly/monthly/edit/${id}`,
        data: updateData,
        method: "PATCH",
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    };
  },
  //DELETE MONTHLY DELETE
  useMonthlyDeleteMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (id, deleteData) => {
      return await mutate({
        url: `${BaseServicePath}/yearly-monthly/monthly/delete/${id}`,
        data: deleteData,
        method: "DELETE",
      });
    };
  },
  //POST APMC SEARCH
  useSearchApmcMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/yearly-monthly/monthly/apmc/search`,
        method: "POST",
        data: body,
      });
    };
  },
});

export const {
  useMonthlyUpdateMutation,
  useMonthlySearchMutation,
  useMonthlyDeleteMutation,
  useMonthlyCreateMutation,
  useMonthlyActivateMutation,
  useSearchApmcMutation,
  useExportTemplateMutation,
} = MonthlyApiService();
