import useMutation from "hooks/useMutation";
import { useMutateData, useMutateDownload } from "shared/services/base.service";

import { saveAs } from 'utils/download-file'

const BaseServicePath = process.env.REACT_APP_API_PREFIX;

export const useSearchNewBarcodePrint = () => {
  return useMutation({
    url: `/barcode-print/new/search`,
    method: "POST",
  });
};

// export const useNewPrint = () => {
//   return useMutation({
//     url: `/barcode-print/new-print`,
//     method: "POST",
//   });
// };
export const useNewPrint = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate(
      {
        url: `${BaseServicePath}/barcode-print/new-print`,
        method: "POST",
        data: body,
      },
    );
  };
};
export const useRePrint = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate(
      {
        url: `${BaseServicePath}/barcode-print/re-print`,
        method: "POST",
        data: body,
      },
    );
  };
};

export const useHistorySearch = () => {
  return useMutation({
    url: `/barcode-print/history/search`,
    method: "POST",
  });
};
